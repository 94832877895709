import React, { useEffect, useState } from "react";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import CloseIcon from "@mui/icons-material/Close";
import FantasyRunIcon from "../../assets/images/fantasyy/runsipl.png";
import FantasyCoinImage from "../../assets/images/fantasyy/fantasycoin.png";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import axios from "axios";
import { getStorageItem } from "../../utils/sessionStorage";
import "./_playerScorePage.scss";

const PlayerScorePageModal = ({
  open,
  onClose,
  playerData,
  teamAvatars,
  teamGradient,
}) => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [currentPage, setCurrentPage] = useState(0);
  const [runData, setRunsData] = useState({"catches": 0,
  "dots": 0,
  "fours": 0,
  "ones": 0,
  "rewardPoints": 0,
  "sixes": 0,
  "twos": 0,
  "wickets": 0});
  const [data, setData] = useState([
    { event: "Fours", value: 0,  points: 0},
    { event: "Sixes", value: 0, points: 0},
    { event: "Twos", value: 0, points: 0},
    { event: "Ones", value: 0, points: 0},
    { event: "Dots", value: 0, points: 0},
    { event: "Catches", value: 0, points: 0},
    { event: "Wickets", value: 0, points: 0},
  ]);
  const {
    playerDetails,
    runsScored,
    fours,
    sixes,
    twos,
    ones,
    dots,
    catches,
    wickets,
  } = playerData;
  const { playerName, role, team, basePrice,country } = playerDetails;

  const handleNavigate = () => {
    if (isMobile) {
      navigate("/PlayerSelectPage");
    }
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };
  const getRunsByNameForFantasyScore = async () => {
    try {
      const accessToken = getStorageItem("token");
      const response = await axios.get(
        global.config.ROOTURL.prod + "/defaultRun/getRunsByNameForFantasyScore",
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );
  
      if (response.status === 200) {
        setRunsData(response.data);
        setData([
          { event: "Fours", value: fours,  points: fours * response?.data?.fours},
          { event: "Sixes", value: sixes, points: sixes * response?.data?.sixes},
          { event: "Twos", value: twos, points: twos * response?.data?.twos},
          { event: "Ones", value: ones, points: ones * response?.data?.ones},
          { event: "Dots", value: dots, points: dots * response?.data?.dots},
          { event: "Catches", value: catches, points: catches * response?.data?.catches},
          { event: "Wickets", value: wickets, points: wickets * response?.data?.wickets},
        ])
      } else {
        console.error("API call failed with status:", response.status);
      }

    } catch (error) {
      console.error("error", error.message);
    }
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    getRunsByNameForFantasyScore();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const calculateTotalFantasyRuns = () => {
    const total =
       data[0]?.points +
       data[1]?.points +
       data[2]?.points +
       data[3]?.points +
       data[4]?.points +
       data[5]?.points +
       data[6]?.points;
       return  !isNaN(total *runData?.rewardPoints) ?total *runData?.rewardPoints:0
       
  };
 

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="scoreboard-container">
        <div className="header-section" style={{ backgroundImage: teamGradient[country]}}>
          <CloseIcon onClick={onClose} className="cancel-icon" />
          {isMobile && (
            <>
              <div className="mobile-header">
                {/* <KeyboardBackspaceIcon
                  className="arrow-icon"
                  onClick={handleNavigate}
                /> */}
                <p className="mobile-heading">Player Info</p>
                {/* <InfoOutlinedIcon className="info-icon" /> */}
              </div>
            </>
          )}

          <div className="player-info">
            <img
              src={teamAvatars[country]}
              alt="Player"
              className="player-image"
            />
            <div className="player-details">
              <p className="player-name">
                {playerName}{" "}
                <span>
                  {" "}
                  <img src={FantasyCoinImage} alt="Fantasy Coin" />
                </span>
              </p>

              <div className="team-cont">
                <div>
                  <p className="team-naame">Team Name</p>
                  <p className="team">{country}</p>
                </div>

                <div>
                  <p className="team-naame1">Earned Run</p>
                  <p className="team-runs">
                    <span className="span-icon">
                      <img
                        src={FantasyRunIcon}
                        className="price-icon"
                        alt="Fantasy Run Icon"
                      />
                      {calculateTotalFantasyRuns()}{" "}{" "}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <table className="scoreboarde-table">
          <thead>
            <tr>
              <th>Event</th>
              <th>Score</th>
              <th>FantasyPoints</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>{item?.event}</td>
                <td>{item?.value || 0}</td> 
                <td>{item?.points || 0}</td> 
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Modal>
  );
};

export default PlayerScorePageModal;
