import { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/header/logo.png";
import darkMode from "../../assets/images/header/dark.png";
import Plus from "../../assets/images/header/plus.svg";
import defaultAvatar from "../../assets/images/profile/default_avatar.png";
import Notification from "./notification";
import { Tooltip } from "carbon-components-react";
import axios from "axios";
import SearchBar from "./searchBar";
import Runcard from "../posts/run";
import "./index.scss";
import { resetLogin } from "../../redux/reducers/login";
import { useDispatch } from "react-redux";
import { clearStorage, getStorageItem } from "../../utils/sessionStorage";
import Switch from "react-switch";
import { PathAction } from "../../redux/reducers/pitchselection";
import fireBaseFuntion from "../../firebase";
import { useMediaQuery } from "@material-ui/core";
import { ReactComponent as Bell } from "../../assets/images/icons/Notification.svg";
import logoPlus from "../../assets/images/navbar/logoPlus.png";
import champHunt from "../../assets/images/navbar/Vector.png";

const Header = (props) => {
  const { onlyLogo, showAdd } = props;
  const [checked, setChecked] = useState(false);
  const [userRuns, setUserRuns] = useState(0);
  const mobile = useMediaQuery("(max-width: 700px)");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    avatar: "",
  });
  const [pendigMessage, setPendingMessage] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [activeNotifications, setActiveNotifications] = useState(0);
  const accessToken = getStorageItem("token");
  const userId = getStorageItem("user_id");
  const [isDarkMode, setIsDarkMode] = useState(false);

  // const reduxState=useSelector((state)=>state.Pitchselction.PitchSelction);

  // commented by rishabh

  // const [showSecondaryMenu, setShowSecondaryMenu] = useState(false);
  // const handleSecondaryMenu = () => {
  //   !showSecondaryMenu
  //     ? setShowSecondaryMenu(true)
  //     : setShowSecondaryMenu(false);
  // };
  const handleAction = () => {
    setIsOpen(!isOpen);
  };

  const handleDropdown = () => {
    if (isOpen) {
      setIsOpen(!isOpen);
    }
  };

  const togglechanged = () => {
    setChecked(!checked);
    let checkmaindata;
    if (!checked) {
      checkmaindata = "ALL";
    } else {
      checkmaindata = "Following";
    }
    dispatch(PathAction.handlePitchSelction({ payload: checkmaindata }));
    const shared = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/auth/updatePitchSelectionType",
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
      data: {
        pitchSelectionType: checkmaindata,
      },
    };
    if (checkmaindata) {
      axios(shared)
        .then((response) => {
          //  setStorageItem('pitchSelectionType',checkmaindata)
          // navigate(0)
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
          }
        });
    }
  };

  const handleMyProfile = () => {
    navigate(`/profile/${userId}`);
  };
  const handleMyMessage = () => {
    navigate(`/profile/chat/${userId}`);
  };
  const handleAboutUs = () => {
    navigate("/about-us");
  };
  const handlePrivacy = () => {
    navigate("/privacy");
  };
  const handleRules = () => {
    navigate("/rules");
  };
  const handleLogout = () => {
    if (!userId) return;
    var logOutOptions = {
      method: "post",
      url: global.config.ROOTURL.prod + "/auth/logout/",
      headers: {
        Authorization: "Bearer " + accessToken,
      },
      data: {
        id: userId,
      },
    };
    axios(logOutOptions)
      .then(() => {
        dispatch(resetLogin());
        localStorage.clear();
        navigate("/login");
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          clearStorage();
          navigate("/login");
        }
      });
  };

  const handleContactUs = () => {
    navigate("/contact-us");
  };

  const secondaryMenuRef = useRef();

  // commented by rishabh

  // useEffect(() => {
  //   let handler = (e) => {
  //     .log(secondaryMenuRef)
  //     if (!secondaryMenuRef.current.contains(e.target)) {
  //       setShowSecondaryMenu(false);
  //     }
  //   };
  //   document.addEventListener("mousedown", handler);
  //   return () => {
  //     document.removeEventListener("mousedown", handler);
  //   }
  // });

  const fetchUserInfo = () => {
    if (!userId) return;
    const getUserInfo = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/auth/profile/",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      data: {
        userId: userId,
      },
    };
    axios(getUserInfo)
      .then((response) => {
        setUserInfo({
          firstName: response.data.firstName ? response.data.firstName : "",
          lastName: response.data.lastName ? response.data.lastName : "",
          avatar: response.data.profilePhoto
            ? response.data.profilePhoto
            : defaultAvatar,
        });
        localStorage.setItem(
          "locationpermission",
          response.data.locationpermission
        );
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          clearStorage();
          navigate("/login");
        }
      });
  };
  const setpitchselection = () => {
    if (!accessToken) return;
    const shared = {
      method: "GET",
      url: global.config.ROOTURL.prod + "/auth/getPitchSelectionType",
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
    };
    axios(shared)
      .then((response) => {
        const checkdata = response.data.pitchSelectionType === "ALL";

        setChecked(checkdata);
        dispatch(
          PathAction.handlePitchSelction({
            payload: response.data.pitchSelectionType,
          })
        );
      })

      .catch((error) => {
        if (error?.response?.status == 401) {
        }
      });
  };
  const fetchNotification = () => {
    if (!accessToken) return;
    const options = {
      method: "GET",
      url: `${global.config.ROOTURL.prod}/notification/get-top-10/${userId}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    axios(options)
      .then((response) => {
        setActiveNotifications(response.data.activeNotificationsCount);
        console.log(
          "response.data.activeNotificationsCount",
          response.data.activeNotificationsCount
        );
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          clearStorage();
          navigate("/login");
        }
      });
  };
  const realTimeNotificationUpdate = () => {
    if (userId) {
      const { onValue, starCountRef } = fireBaseFuntion(
        "user/notification/" + userId
      );

      onValue(starCountRef, (snapShot) => {
        const data = snapShot.val();
        if (data?.createdUserId === userId) {
          if (activeNotifications > 0) {
            setActiveNotifications(activeNotifications);
          }
        }
      });
    }
  };
  const fetchPendingMessage = () => {
    const shared = {
      method: "GET",
      url: global.config.ROOTURL.prod + "/chat/getNotSeenMessageByUser",
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
    };
    axios(shared)
      .then((response) => {
        setPendingMessage(response.data);
      })

      .catch((error) => {
        if (error?.response?.status == 401) {
          console.log(error.response.status);
        }
      });
  };
  useEffect(() => {}, [checked]);
  useEffect(() => {
    fetchUserInfo();
    setpitchselection();
    fetchPendingMessage();
    fetchNotification();
  }, []);
  useEffect(() => {
    realTimeNotificationUpdate();
  }, [activeNotifications]);
  return (
    <div>
      <header className="header">
        <div className="header-cnt">
          <div className="logo-block">
            <NavLink to="/pitch">
              <img src={Logo} alt="Champhunt" />
              <span
                style={{
                  paddingLeft: "15px",
                  fontSize: "25px",
                  fontWeight: 500,
                }}
                className="label title-label"
              >
                CHAMPHUNT
              </span>
            </NavLink>
          </div>

          {!onlyLogo && (
            <div className="search-block">
              <SearchBar
                onSearchTextChange={(searchText) =>
                  props.onSearchTextChange(searchText)
                }
              />
            </div>
          )}

          {showAdd && (
            <div className="desktop-only add-funds">
              <div className="add-button">
                <img src={Plus} alt="" />
              </div>
              <p className="money">0.00</p>
            </div>
          )}
          {!onlyLogo && (
            <div className="desktop-only profile-block">
              <Runcard run={userRuns} />
              {/* <div>
                <img src={darkMode} alt="dark" 
                style={{ width: "30px", height: "30px", marginTop: "7px" }}
                onClick={toggleDarkMode} />
              </div> */}
              <Notification />
              <div className="avatar">
                <img
                  src={userInfo.avatar}
                  alt=""
                  style={{ width: "40Spx", height: "43px", borderRadius: 50 }}
                />
              </div>
              <div>
                <div className="primary" onClick={handleAction}>
                  {/* {userInfo.firstName + " " + userInfo.lastName} */}
                  <i className="arrow down"></i>
                  <div
                    direction="bottom"
                    className="header-options"
                    style={{ visibility: isOpen ? "visible" : "hidden" }}
                  >
                    <div className="caret"></div>
                    <div className="options" style={{ width: "149px" }}>
                      <ul>
                        <li onClick={handleMyProfile}>My Profile</li>
                      </ul>
                      <ul>
                        <li onClick={handleMyMessage}>
                          <div>
                            Message
                            {pendigMessage > 0 && (
                              <p className="MessageContainerdiv1">
                                {pendigMessage}
                              </p>
                            )}
                          </div>
                        </li>
                      </ul>
                      <ul>
                        <li onClick={handleAboutUs}>About us</li>
                      </ul>
                      <ul>
                        <li onClick={handlePrivacy}>Privacy</li>
                      </ul>
                      <ul>
                        <li onClick={handleRules}>Rules</li>
                      </ul>
                      <ul style={{ paddingLeft: "28px" }}>
                        <li>
                          <label
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <span style={{ paddingRight: "7px" }}>
                              GlobalPitch
                            </span>
                            <Switch
                              onChange={togglechanged}
                              checked={checked}
                              onColor="#86d3ff"
                              onHandleColor="#2693e6"
                              handleDiameter={20}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                              height={19}
                              width={41}
                              className="react-switch"
                              id="material-switch"
                            />
                          </label>
                        </li>
                      </ul>
                      <ul>
                        <li onClick={handleLogout}>Sign Out</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {
            mobile && (
              <div>
                {activeNotifications > 0 ? (
                  <>
                    <div
                      style={{
                        height: "8px",
                        backgroundColor: "red",
                        borderRadius: " 50%",
                        width: "9px",
                        position: "absolute",
                        marginLeft: "12px",
                      }}
                    ></div>
                    <Bell
                      style={{ height: "20px", width: "20px" }}
                      onClick={() => {
                        navigate("/notification");
                        setActiveNotifications(0);
                      }}
                    />
                  </>
                ) : (
                  <Bell
                    style={{ height: "20px", width: "20px" }}
                    onClick={() => {
                      navigate("/notification");
                    }}
                  />
                )}
              </div>
            )
            //  < Notification/>
          }
        </div>
      </header>
      <header className="header2" onClick={handleDropdown}>
        <div>
          {!onlyLogo && (
            <div className="desktop-only nav-links">
              <NavLink
                to="/pitch"
                className={`nav-link ${
                  window.location.pathname.includes("/pitch") ? "active" : ""
                }`}
              >
                Pitch
              </NavLink>

              <NavLink
                to="/rewards"
                className={`nav-link ${
                  window.location.pathname.includes("/rewards") ? "active" : ""
                }`}
              >
                {/* Rewards */}
                Arena
              </NavLink>
              {/* Advertisments */}
              {/* <NavLink
              to="/earn-rewards"
              className={`nav-link ${window.location.pathname.includes("/earn-rewards") ? "active" : ""
                }`}
            >
              Earn Rewards
            </NavLink> */}
              <NavLink
                to="/deals"
                className={`nav-link ${
                  window.location.pathname.includes("/deals") ? "active" : ""
                }`}
              >
                Deals
              </NavLink>

              <NavLink
                to="/jobs"
                className={`nav-link ${
                  window.location.pathname.includes("/jobs") ? "active" : ""
                }`}
              >
                Jobs
              </NavLink>
              <NavLink
                to="/marketplace"
                className={`nav-link ${
                  window.location.pathname.includes("/marketplace")
                    ? "active"
                    : ""
                }`}
              >
                Marketplace
              </NavLink>

              {/* <NavLink
                to="/groupchat"
                className={`nav-link ${
                  window.location.pathname.includes("/groupchat")
                    ? "active"
                    : ""
                }`}
              >
                Dugout
              </NavLink> */}

              <NavLink
                to="/contact-us" // Update the "to" attribute as per your routing setup
                className={`nav-link ${
                  window.location.pathname.includes("/contact-us")
                    ? "active"
                    : ""
                }`}
                onClick={handleContactUs} // Handle the click event
              >
                Contact Us
              </NavLink>

              <NavLink
                to="/champhunt-plus"
                className={`nav-link ${
                  window.location.pathname.includes("/champhunt-plus")
                    ? "active"
                    : ""
                }`}
              >
                Subscription
              </NavLink>
            </div>
          )}
        </div>
      </header>
    </div>
  );
};

export default Header;

{
  /* <NavLink
to="/champhunt-plus"
>
<div className="champhunt-plus">
  <img className="plus-logo" src={logoPlus} alt="hand" />
  <p className="small-text">Champhunt <spnn className="plus-icon">+</spnn></p>
  <img className="champhunt-name" src={champHunt} alt="logo" />
</div>
</NavLink> */
}
