import { Box, Typography } from "@material-ui/core";
import React from "react";
// import EventScroll from "../../components/find-all-events/eventScroll";
import CarouselScroll from "../../components/manage-carousel/carouselScroll"
import DealScroll from "../../components/manage-deal/dealScroll"
import UpdateDealScroll from "./updateDealScroll"

const UpdateDeal = () => {
  return (
    <Box>
      <Typography variant="h4">Update Deals</Typography>
      <Box marginTop={2}> <UpdateDealScroll/></Box>
    </Box>
  );
};

export default UpdateDeal;
