import './index.css'



import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'

import App from './App';
import './config';
import store from './redux/store'
import './firebase';

TimeAgo.addDefaultLocale(en)

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(<Provider store={store}><App /></Provider>, rootElement);
} else {
  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    rootElement
  );
}