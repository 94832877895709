import React from 'react'
import phone from '../../assets/images/landigpage2/phone.png'
import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
export default function Fourth() {
  const mobileView = useMediaQuery("(max-width:600px)");


  return (

    <Box sx={{ height: { xs: '780px', md: "529px" }, backgroundColor: { xs: '', md: "#FFFFFF" } }}>
 
      <Grid container spacing={1} sx={{ paddingLeft: { xs: '', md: '2vw' },justifyContent:{xs:'',md:'space-around'} }}>
        <Box>

      <Grid item xs={12} md={6} sx={{ backgroundColor: { xs: '#F8F4EE', md: "#FFFFFF" } }}>
  
        <img src={phone} className={mobileView ? 'width100' : ''} />
        </Grid>
        </Box>
    
        <Grid item xs={12} md={6} sx={{marginTop:{xs:'',md:'100px'},backgroundColor: { xs: '#FFFFFF', md: "" } }}  className={mobileView &&'paddingclass'}  >
          <p  className={mobileView ? 'font24popins' : 'font36'}  style={{ color: '#323232' }}> About <span style={{ color: '#E7489D' }}> Champhunt</span> </p>
          <p className={!mobileView ? 'font24popins' : 'font16'} style={{ paddingTop: 40 }}>Champhunt is a one of its kind community that aims to bring all stakeholders of cricket together on one platform. It is a virtual home of cricket lovers where they can interact with other fans, share their opinions, have discussions, analyze games as well as showcase their talent.</p>
        </Grid>

      </Grid>

    </Box>
  )
}
