import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  searchByPostData: [],
  searchByPostIsLoading: false,
  searchByPostError: null,
  searchByPostSearchText: '',
}

export const searchByPostSlice = createSlice({
  name: 'searchByPost',
  initialState,
  reducers: {
    fetchSearchByPostData: (state) => ({
      ...state,
      searchByPostIsLoading: !initialState.searchByPostIsLoading,
    }),
    setSearchByPostData: (state, action) => ({
      ...initialState,
      ...state,
      searchByPostData: action.payload,
      searchByPostIsLoading: initialState.searchByPostIsLoading,
    }),
    setSearchByPostLoading: (state, action) => ({
      ...initialState,
      ...state,
      searchByPostIsLoading: (typeof action.payload === 'undefined') ? initialState.searchByPostIsLoading : action.payload,
    }),
    setSearchByPostError: (state, action) => ({
      ...initialState,
      ...state,
      searchByPostError: action.payload || initialState.searchByPostError,
    }),
    resetSearchByPost: (state) => ({
      ...initialState,
    }),
    setSearchText: (state, action) => ({
      ...initialState,
      ...state,
      searchByPostSearchText: action.payload,
    }),
  },
})

// Action creators are generated for each case reducer function
export const {
  fetchSearchByPostData, setSearchByPostLoading, setSearchByPostData, resetSearchByPost, setSearchByPostError, setSearchText,
} = searchByPostSlice.actions

export default searchByPostSlice.reducer
