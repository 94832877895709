import React, { useState } from 'react';
import { IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box, Button} from '@mui/material';
import Lottie from 'react-lottie';
import animationData from "../../assets/images/Events/verification.json";
import closeIcon from "../../assets/images/Events/Dismiss-circle.png";

const RollNoModal = ({ open, onClose, onSubmit }) => {
    // roll no. submit fn
    const [rollNo, setRollNo] = useState('')
    const handleSubmit = () => {
        onSubmit(rollNo);
    };

    // lottie
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <Dialog open={open} onClose={onClose} sx={{ textAlign: 'center' }}>
            <IconButton onClick={onClose} aria-label="close" sx={{ justifyContent: "flex-end !important", borderRadius: "0 !important" }}>
                <img src={closeIcon} alt="Close" style={{ width: 24, height: 24 }} />
            </IconButton>
            <Box>
                <Lottie
                    options={defaultOptions}
                    height={200}
                    width={200}
                />
            </Box>

            <DialogTitle sx={{ color: '#0C5BA0', fontSize: '22px' }}>Verify Your Access</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ color: '#62666A', fontSize: '18px' }}>
                    Enter Your College Passcode to Join the Tournament
                </DialogContentText>

                <DialogContentText sx={{ color: '#95999D', fontSize: '12px', fontStyle: 'italic' }}>
                    Welcome to the tournament! To ensure a fair and secure experience for all participants, please enter your college passcode below. Let's get you into the action!
                </DialogContentText>
            </DialogContent>

            <DialogActions sx={{ width: '100%', justifyContent: 'center !important', WebkitAlignItems: 'center !important' }}>
                <input
                    aria-label="rollNo"
                    placeholder="Enter your Roll No."
                    value={rollNo}
                    onChange={(e) => setRollNo(e.target.value)}
                    style={{
                        width: '50%',
                        height: '37px',
                        border: '1px solid #b4b4b4',
                        borderRadius: '5px',
                        paddingLeft: '10px',
                        fontSize: '16px'
                    }}
                />
                <Button onClick={handleSubmit} variant="contained" color="primary" sx={{ marginLeft: '15px' }}>
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RollNoModal;
