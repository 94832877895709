import axios from 'axios'
import { call, put } from 'redux-saga/effects'
import { getStorageItem } from '../../../utils/sessionStorage'

import { setSearchByPostData, setSearchByPostError } from '../../reducers/searchByPost'

export const searchByPostAPI = (data) => {
    var apiOptions = {
        method: "post",
        url: global.config.ROOTURL.prod + "/auth/name-search",
        data: JSON.stringify(data),
        headers: {
            Authorization: "Bearer " + getStorageItem('token'),
            "Content-Type": "application/json",
        },
        json: true,
    }
    return axios(apiOptions)
}

export function* loginApiSaga(action) {
    const { data, prevData } = action.payload
    try {
        const response = yield call(searchByPostAPI, data)
        yield put(setSearchByPostData([...prevData, ...response?.data]))
    } catch (error) {
        yield put(setSearchByPostError(error))
    }
}
