import * as React from "react";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PitchEdit from "../pitch-edit-modal/index";
import PitchDelete from "../pitch-delete-modal/index";
import Report from "../report-modal/index";
import PollEdit from "../poll/poll-edit-modal";
import axios from "axios";
import { clearStorage, getStorageItem } from "../../utils/sessionStorage";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { PathAction } from "../../redux/reducers/globalPath";
import CommentDeletePopup from "../comments/CommentDeletePopup";

export default function DropdownMenu(props) {
  const navigate = useNavigate();
  const { challengeId,
    postType,
    postId,
    userId,
    content,
    image,
    sharedContent,
    sharedDetail,
    postObj,
    setPostObj,
    pageLoad,
    setPollQuestion,
    isCommentDelete,
    deleteCommentId,
    nestedComment,
    parentCommentId,
    shareJObId
  } = props;

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [ownPost, setOwnPost] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [openPollEditModal, setOpenPollEditModal] = React.useState(false);
  const [openJobEditModal, setOpenJobEditModal] = React.useState(false);
  const [openPitchEditModal, setOpenPitchEditModal] = React.useState(false);
  const [openPitchDeleteModal, setOpenPitchDeleteModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleOpenPitchEditModal = () => setOpenModal(true);
  const handleClosePitchEditModal = () => setOpenPitchEditModal(false);
  const handleOpenPollEditModal = () => setOpenModal(true);
  const handleClosePollEditModal = () => setOpenPollEditModal(false);
  const handleOpenPitchDeleteModal = () => setOpenModal(true);
  const reload = useSelector((state) => state.path.shouldLoad);
  const shouldLoad = useSelector(state => state.path.loadComment);
  const shouldLoadnested = useSelector(state => state.path.loadNestedCmts);
  const number = useSelector((state) => state.path.PostCount);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const dispatch = useDispatch();
  const handleClosePitchDeleteModal = () => {
    setOpenPitchDeleteModal(false);
  };
  const accessToken = getStorageItem("token");
  const [sharedPost, setSharedPost] = React.useState(false);
  const [sharedJob, setSharedJob] = React.useState(false);
  const [DeleteComent, setDeleteComent] = React.useState(false);
  useEffect(() => { }, [open]);

  const handleToggle = (userId) => {
    setOwnPost(getStorageItem("user_id") === userId);
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handleUpdatePost = (
    postId,
    newContent,
    postMessageWithoutHtml,
    description
  ) => {
    if (postId && newContent) {
      let pitchData = {
        id: postId,
        postMessage: newContent,
        postMessageWithoutHtml,
        description,
      };

      let updatePostDetailsOptions = {
        method: "POST",
        url: global.config.ROOTURL.prod + "/pitch/update",
        headers: {
          Authorization: "Bearer " + accessToken,
        },
        data: pitchData,
      };

      if (postType === "shareJob") {
        pitchData = {
          id: postId,
          shareJobMessage: newContent,
          shareJobWithoutHtml: postObj?.shareJobWithoutHtml,
          description: postObj?.description,
        };

        updatePostDetailsOptions = {
          method: "POST",
          url: global.config.ROOTURL.prod + "/job/sharejob/update",
          headers: {
            Authorization: "Bearer " + accessToken,
          },
          data: pitchData,
        };
      }

      axios(updatePostDetailsOptions)
        .then((response) => {
          if (!postObj.pinpoll) {
            response.data.feed_id = response.data._id;
          }
          setPostObj(response.data);
          // dispatch(PathAction.handleCreatedObject({ payload: response.data }));
          handleClosePitchEditModal();
          dispatch(PathAction.handleReload({ payload: !reload }));
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            clearStorage();
            navigate("/login");
          }
        });
    }
  };
  //
  const handleUpdatePoll = (postId, option, question, questionHastag) => {
    let pollData = {
      pollId: postId,
      question,
      options: option,
      questionHastag: questionHastag,
    };
    if (!postId) return;
    const updatePollDetailsOptions = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/poll/editPoll",
      headers: {
        Authorization: "Bearer " + accessToken,
      },
      data: pollData,
    };
    axios(updatePollDetailsOptions)
      .then((response) => {
        if (!postObj.pinpoll) {
          response.data.feed_id = response.data._id;
        }
        setPostObj(response.data);
        setPollQuestion(response.data.question);
        handleClosePollEditModal();
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          clearStorage();
          navigate("/login");
        }
      });
  };
  //

  const handleUpdateSharedPost = (postId, newContent, newImage) => {
    if (!postId) return;
    let pitchData = {
      updatedSharedText: newContent,
      pitchId: postId,
      sharedbyUserId: sharedDetail.userId,
      description: postObj?.description,
      postMessageWithoutHtml: postObj?.postMessageWithoutHtml,
    };

    if (newImage) {
      pitchData.append("uploader", newImage);
    }

    var UpdateSharedPost = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/pitch/update-shared-pitch",
      headers: {
        Authorization: "Bearer " + accessToken,
      },
      data: pitchData,
    };
    axios(UpdateSharedPost)
      .then((response) => {
        if (!postObj.pinpoll) {
          response.data.feed_id = response.data._id;
        }
        setPostObj(response.data);
        handleClosePitchEditModal();
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          clearStorage();
          navigate("/login");
        }
      });
  };

  const deletePostHashTag = async (_id) => {
    if (!_id) return;
    var deletePostHashTagOptions = {
      method: "GET",
      url:
        global.config.ROOTURL.prod +
        `/hash-profile-tag/postHashTag/deleteByPitchId/${_id}`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };
    axios(deletePostHashTagOptions)
      .then((response) => {
        console.log("fullfilling PostHashTag deletion");
        console.log(response);
        dispatch(PathAction.handleReload({ payload: !reload }));
        handleClosePitchDeleteModal();
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          clearStorage();
          navigate("/login");
        }
      });
  };

  const handleShareJobEdit = (
    _id,
    editedMessage,
    shareJobWithoutHtml,
    description
  ) => {
    if (!_id) return;
    var editPostOptions = {
      method: "POST",
      url: `${global.config.ROOTURL.prod}/job/sharejob/update`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
      data: {
        shareJobWithoutHtml,
        description,
        sharedJobMessage: editedMessage,
        id: _id,
      },
    };
    axios(editPostOptions)
      .then((response) => {
        if (!postObj.pinpoll) {
          response.data.feed_id = response.data._id;
        }
        setPostObj(response.data);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          clearStorage();
          navigate("/login");
        }
      });
  };
  const handleShareJobDelete = () => {
    if (!postId) return;
    var deletePostOptions = {
      method: "DELETE",
      url: `${global.config.ROOTURL.prod}/job/sharejob/delete/${postId}`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };
    axios(deletePostOptions)
      .then((response) => {
        dispatch(PathAction.handleDeleteobject({ payload: postId }));
        dispatch(PathAction.handleReload({ payload: !reload }));
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          clearStorage();
          navigate("/login");
        }
      });
  };
  //

  const handleDeletePost = () => {
    if (!postId) return;
    var deletePostOptions = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/pitch/delete",
      headers: {
        Authorization: "Bearer " + accessToken,
      },
      data: {
        id: postId,
      },
    };
    axios(deletePostOptions)
      .then((response) => {
        deletePostHashTag(postId);
        dispatch(PathAction.handleDeleteobject({ payload: postId }));
        dispatch(PathAction.handleReload({ payload: !reload }));
        dispatch(PathAction.handlePostCount({ payload: number - 1 }));
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          clearStorage();
          navigate("/login");
        }
      });
  };
  //
  const handlePollDelete = () => {
    if (!postId) return;
    var deletePostOptions = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/poll/delete",
      headers: {
        Authorization: "Bearer " + accessToken,
      },
      data: {
        pollId: postId,
      },
    };
    axios(deletePostOptions)
      .then((response) => {
        console.log("deleted", response);
        dispatch(PathAction.handleDeleteobject({ payload: postId }));
        dispatch(PathAction.handleReload({ payload: !reload }));
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          clearStorage();
          navigate("/login");
        }
      });
  };
  const handleChallengeDelete = () => {
    if (!postId) return;
    var deletePostOptions = {
      method: "POST",
      url: global.config.ROOTURL.prod + `/contest/userchallenge/deleteUserChallenge/${challengeId}/${postId}`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },

    };
    axios(deletePostOptions)
      .then((response) => {
        console.log("deleted", response);
        if (response.data.modifiedCount === 1) {
          dispatch(PathAction.handleDeleteobject({ payload: postId }));
          dispatch(PathAction.handleReload({ payload: !reload }));
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          clearStorage();
          navigate("/login");
        }
      });
  };
  const handleCommentDeleteModel = () => {

    if (postId) {


      var deletePostOptions = {
        method: "POST",
        url: global.config.ROOTURL.prod + "/pitch/comment/delete",
        headers: {
          Authorization: "Bearer " + accessToken,
        },
        data: {
          pitchId: postId,
          commentId: deleteCommentId,
          parentCommentId: parentCommentId
        },
      };
      axios(deletePostOptions)
        .then((response) => {
          // if (nestedComment) {
          //   dispatch(PathAction.handleReloadComments({ payload: !shouldLoadnested }));
          // } else {
          dispatch(PathAction.handleReloadMainComments({ payload: !shouldLoad }));
          // }
          setDeleteComent(false);
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            clearStorage();
            navigate("/login");
          }
        });
    }
    if (shareJObId) {


      var deletePostOptions = {
        method: "POST",
        url: global.config.ROOTURL.prod + "/job/sharejob/comment/delete",
        headers: {
          Authorization: "Bearer " + accessToken,
        },
        data: {
          pitchId: shareJObId,
          commentId: deleteCommentId,
          parentCommentId: parentCommentId
        },
      };
      axios(deletePostOptions)
        .then((response) => {
          dispatch(PathAction.handleReloadMainComments({ payload: !shouldLoad }));
          setDeleteComent(false);
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            clearStorage();
            navigate("/login");
          }
        });
    }
  }
  const handleCommentDelete = () => {
    setDeleteComent(false)
  }
  React.useEffect(() => {
    if (sharedDetail != undefined) {
      setSharedPost(true);

      if (postObj?.jobId) {
        setSharedJob(true);
      }
    }
  }, []);

  const handleReportPost = (reportType) => {
    if (reportType && postId) {
      var reportOptions = {
        method: "post",
        url: global.config.ROOTURL.prod + "/pitch/report/create",
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        data: {
          pitchId: postId,
          reportText: reportType,
        },
        json: true,
      };
      axios(reportOptions)
        .then((response) => {
          dispatch(PathAction.handleDeleteobject({ payload: postId }));
          dispatch(PathAction.handleReload({ payload: !reload }));
          handleCloseModal();
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            clearStorage();
            navigate("/login");
          }
        });
    }
  };
  const handleEdit = (postId) => {
    setOpenPitchEditModal(true);
  };
  const handlePollEdit = () => {
    setOpenPollEditModal(true);
  };
  const handleJobEdit = () => {
    setOpenPitchEditModal(true);
  };

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleDeletePostCallback = () => {
    // Handle post deletion logic here, e.g., call your deletePost function
    if (postObj?.options && !challengeId && postObj?.question) {
    
      handlePollDelete();
    } else if (postObj?.jobId && !challengeId) {
      handleShareJobDelete();
    } else if (challengeId) {
      handleChallengeDelete()
    } else {
      handleDeletePost();
    }

    // Close the PitchDelete modal
    setOpenDeleteModal(false);
  };


  const handleDelete = () => {
    if (deleteCommentId) {
      setDeleteComent(true);
    }
    else if (postObj?.options && postObj?.question && !challengeId) {
      handlePollDelete();
    } else if (postObj?.jobId) {
      handleShareJobDelete();
    }
    else if (challengeId) {
      setOpenDeleteModal(true);
    } else {
      // Open the PitchDelete modal
      setOpenDeleteModal(true);
    }
  };

  const handleReport = (event) => {
    handleClose(event);
    setOpenModal(true);
  };

  return (
    <div>
      <div>
        <Button
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? "composition-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={() => handleToggle(userId)}
          disableRipple={true}
        >
          <MoreVertIcon style={{ color: "black" }} />
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "left top" : "left bottom",
              }}
            >
              <div className="dropdown__paper">
                <ClickAwayListener onClickAway={handleClose}>
                  <div
                    className="dropdown__list"
                    autofocusitem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    {props.type === "post-menu" && (
                      <ul>
                        {!isCommentDelete && ownPost && !challengeId && (
                          <li
                            onClick={
                              postObj?.options
                                ? () => handlePollEdit()
                                : postObj?.jobId
                                  ? () => handleJobEdit(postId)
                                  : () => handleEdit(postId)
                            }
                            open={"false"}
                          >
                            Edit
                          </li>
                        )}
                        {ownPost && <li onClick={handleDelete}>Delete</li>}
                        {!ownPost && <li onClick={handleReport}>Report</li>}
                      </ul>
                    )}
                  </div>
                </ClickAwayListener>
              </div>
            </Grow>
          )}
        </Popper>
      </div>
      <PitchEdit
        handleOpen={handleOpenPitchEditModal}
        handleClose={handleClosePitchEditModal}
        handleUpdatePost={handleUpdatePost}
        handleUpdateSharedPost={handleUpdateSharedPost}
        setPostObj={setPostObj}
        postObj={postObj}
        open={openPitchEditModal}
        content={sharedDetail ? sharedDetail.sharedText : content}
        image={image}
        postId={postId}
        sharedPost={sharedPost}
        sharedJob={sharedJob}
        handleShareJobEdit={handleShareJobEdit}
      // pageLoad={props.hprops}
      />
      {openPollEditModal && (
        <PollEdit
          handleOpen={handleOpenPollEditModal}
          handleClose={handleClosePollEditModal}
          handleUpdatePoll={handleUpdatePoll}
          setPostObj={setPostObj}
          postObj={postObj}
          open={openPollEditModal}
          postId={postId}
        // pageLoad={props.hprops}
        />
      )}

      {openDeleteModal && (
        <PitchDelete
          handleOpen={handleOpenDeleteModal}
          open={openDeleteModal}
          handleClose={() => setOpenDeleteModal(false)}
          handleDeletePost1={handleDeletePostCallback}
          postId={postId}
          challengeId={challengeId}
        />
      )}
      {/* <PitchDelete
        handleOpen={handleOpenPitchDeleteModal}
        handleClose={handleClosePitchDeleteModal}
        open={openPitchDeleteModal}
        handleDeletePost1={
          !postObj?.options
            ? postObj?.jobId
              ? handleShareJobDelete
              : handleDeletePost
            : handlePollDelete
        }
        postId={postId}
      /> */}
      <Report
        handleOpen={handleOpenModal}
        handleClose={handleCloseModal}
        open={openModal}
        handleReportPost={handleReportPost}
        postId={postId}
      />


      {/* DeleteComment popup */}
      {DeleteComent && <CommentDeletePopup
        Open={DeleteComent}
        handleMainClose={handleCommentDeleteModel}
        handleCommentDelete={handleCommentDelete}
      />}

    </div>
  );
}
