import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import { Modal, Box } from "@mui/material";
import { toast } from "react-toastify";
import { getStorageItem } from "../../../utils/sessionStorage";
import IconButton from "@mui/material/IconButton";
import {MenuItem,InputLabel,Select} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { SettingsRemote } from "@mui/icons-material";
const userID = getStorageItem("user_id");
const accessToken = getStorageItem("token");
const moment = require("moment");

const Index = (props) => {
  const [name, setName] = useState("");
  const [role, setRole]= useState("");
  const [country, setCountry]=useState("");
  const [team, setTeam]=useState("");
  const [img, setImg]=useState("");
  
  const handleEditPlayer = async () => {
    if (!accessToken) return
    
    const data1 = {
      id: props?.playerData?._id,
      playerName: name ? name : props?.playerData?.playerName,
      role: role ? role : props?.playerData?.role,
      country: country ? country : props?.playerData?.country,
      team: team ? team : props?.playerData?.team,
      playerImage: img ? img : props?.playerData?.playerImage,
      
    };
    const options = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/contest/fantasyPlayers/updatePlayer",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
        role: "Admin",
      },
      data: data1,
    };
    axios(options)
      .then(({ data }) => {
        toast.success(" Player Edited successfully");
        props.setData(data)
        props.handleClose();
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          console.log(error);
        }
      });
  };
  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <div className="contest-admin-create">
          <div className="contest-admin-create-wrapper">
            <div className="contest-admin-create-wrapper-main">
              <Box style={{ float: "right" }}>
                <IconButton onClick={props.handleClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <div className="contest-admin-create-wrapper-header">
                <p className="contest-admin-create-wrapper-header--text">Edit Player</p>
              </div>
              
              <div className="contest-admin-create-wrapper-form">
                <div className="contest-create-name-wrapper">
                  Name-
                  <input
                    type="text"
                    className="contest-create-name"
                    placeholder="Player Name"
                    defaultValue={props?.playerData?.playerName}
                    onChange={(event) => {
                      setName(event.target.value);
                    }}
                    required
                  />
                  </div>
                  <div className="contest-create-name-wrapper">
                  Country-
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-medium"
                    value={country}
                    style={{width:"100px",marginLeft:"5px"}}
                    onChange={(event) => {
                      setCountry(event.target.value);
                    }}
                  >
                  <MenuItem value="">
                    <em>Country</em>
                  </MenuItem>
                   {['IND', 'PAK', 'IRE', 'CAN', 'USA', 'ENG', 'AUS', 'NAM', 'SCO', 'OMA', 'NZ', 'WI', 'AFG', 'UGA', 'PNG', 'SA', 'SL', 'BAN', 'NET', 'NEP','ZIM'].map((item) => (
                      <MenuItem key={item} value={item}>{item}</MenuItem>
                  ))}
                      </Select>
                  </div>
                  <div className="contest-create-name-wrapper">
                  Role-
                  <input
                    type="text"
                    className="contest-create-name"
                    placeholder="Player Name"
                    defaultValue={props?.playerData?.role}
                    onChange={(event) => {
                      setRole(event.target.value);
                    }}
                    required
                  />
                  </div>
                  <div className="contest-create-name-wrapper">
                  Image Link-
                  <input
                    type="text"
                    className="contest-create-name"
                    placeholder="img link"
                    defaultValue={props?.playerData?.playerImage}
                    onChange={(event) => {
                      setImg(event.target.value);
                    }}
                  />
                  </div>
                  <div className="contest-create-name-wrapper">
                  IPL Team-
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={team}
                    style={{width:"120px",marginLeft:"5px"}}
                    onChange={(event) => {
                      setTeam(event.target.value);
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={'Bangalore'}>Bangalore</MenuItem>
                  <MenuItem value={'Chennai'}>Chennai</MenuItem>
                  <MenuItem value={'Mumbai'}>Mumbai</MenuItem>
                  <MenuItem value={'Gujarat'}>Gujarat</MenuItem>
                  <MenuItem value={'Lucknow'}>Lucknow</MenuItem>
                  <MenuItem value={'Kolkata'}>Kolkata</MenuItem>
                  <MenuItem value={'Rajasthan'}>Rajasthan</MenuItem>
                  <MenuItem value={'Hyderabad'}>Hyderabad</MenuItem>
                  <MenuItem value={'Punjab'}>Punjab</MenuItem>
                  <MenuItem value={'Delhi'}>Delhi</MenuItem>
                  </Select>
                  
                 
                </div>
                
                
                <button
                  onClick={handleEditPlayer}
                  className="contest-preediction-admin-creation-submit-button"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Index;
