import React, { useEffect, useRef, useState } from "react";
import "./index.css";
// import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
// import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
// import PaginationRounded from "../PaginationRounded";
import { toast, ToastContainer } from "react-toastify";
import { getStorageItem } from "../../utils/sessionStorage";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
const userID = getStorageItem("user_id");
const accessToken = getStorageItem("token");

const Index = (props) => {
    const location = useLocation()
    const { data } = location.state;
    const navigate = useNavigate();
    const [selectedOption, setSelectedOption] = useState(data.correctOption);
    const [challenge, setChallenge] = useState(data);
    const handleChange = (option) => {
        console.log("option.....", option)
        setSelectedOption(option);
    };
    const { id } = useParams();
    const navigateBack = () => {
        navigate('/admin/challenge')
    };
    // const getChallengeById = async () => {
    //     if (!accessToken) return
    //     const options = {
    //         method: "GET",
    //         url:
    //             global.config.ROOTURL.prod +
    //             `/contest/challenge/getChallengeByChallengeId/${id}`,
    //         headers: {
    //             Accept: "application/json",
    //             "Content-Type": "application/json",
    //             Authorization: "Bearer " + accessToken,
    //         },
    //     };
    //     await axios(options)
    //         .then(({ data }) => {
    //             console.log("data", data);
    //             console.log("data?.correctOption", data?.correctOption)
    //             setChallenge(data);
    //             setSelectedOption(data?.correctOption)
    //         })
    //         .catch((error) => {
    //             if (error?.response?.status === 401) {
    //                 console.log(error);
    //             }
    //         });
    // };
    const submitAnswer = () => {
        if (!accessToken) return
        if (!selectedOption) return toast.error("option not selected");

        const option = {
            method: "POST",
            url:
                global.config.ROOTURL.prod + "/contest/challenge/updateChallengeResult",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
                role: "Admin",
            },
            data: {
                challengeId: id,
                correctOption: selectedOption,
            },
        };
        axios(option)
            .then(({ data }) => {
                toast.success("Answer updated successfully");
                navigateBack();
            })
            .catch((error) => {
                console.log(error);
            });
    };

    // useEffect(() => { getChallengeById() }, [])
    return (
        <div>
            <ToastContainer />
            <div className="contest-admin-create">
                <div className="contest-admin-create-wrapper">
                    <div className="contest-admin-create-wrapper-main">
                        <div className="contest-admin-create-wrapper-header">
                            <p className="contest-admin-create-wrapper-header--text">
                                {/* {challenge?.challengeQuestion} */}
                            </p>
                            <p
                                onClick={() => {
                                    navigateBack();
                                }}
                                className="contest-admin-create-wrapper-header--delete-text"
                            >
                                back
                            </p>
                        </div>
                        <div className="contest-admin-create-wrapper-form">
                            <div className="contest-create-row4-wrapper">
                                <p className="admin-contest-creation-general-text">Q</p>
                                <input
                                    type="text"
                                    className="contest-create-start-input-full"
                                    value={challenge?.challengeQuestion}
                                />
                            </div>
                            <div className="contest-create-row5-wrapper" >
                                <p className="admin-contest-creation-general-text">
                                    option1
                                </p>
                                <div className="contest-create-radio-button-wrapper">
                                    <label
                                        className="contest-create-radio-label"
                                        htmlFor={`option-1`}
                                    >
                                        {challenge?.challengeOption1}
                                    </label>
                                    <input
                                        type="radio"
                                        className="contest-create-start-input-radio"
                                        name="Options"
                                        id="1"
                                        defaultChecked={challenge?.correctOption === challenge?.challengeOption1}
                                        onChange={() => {
                                            handleChange(challenge?.challengeOption1);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="contest-create-row5-wrapper" >
                                <p className="admin-contest-creation-general-text">
                                    option2
                                </p>
                                <div className="contest-create-radio-button-wrapper">
                                    <label
                                        className="contest-create-radio-label"
                                        htmlFor={`option-2`}
                                    >
                                        {challenge?.challengeOption2}
                                    </label>
                                    <input
                                        type="radio"
                                        className="contest-create-start-input-radio"
                                        name="Options"
                                        id="2"
                                        defaultChecked={challenge?.correctOption === challenge?.challengeOption2}
                                        onChange={() => {
                                            handleChange(challenge?.challengeOption2);
                                        }}
                                    />
                                </div>
                            </div>
                            {challenge?.challengeOption3 && <div className="contest-create-row5-wrapper" >
                                <p className="admin-contest-creation-general-text">
                                    option3
                                </p>
                                <div className="contest-create-radio-button-wrapper">
                                    <label
                                        className="contest-create-radio-label"
                                        htmlFor={`option-3`}
                                    >
                                        {challenge?.challengeOption3}
                                    </label>
                                    <input
                                        type="radio"
                                        className="contest-create-start-input-radio"
                                        name="Options"
                                        id="3"
                                        defaultChecked={challenge?.correctOption === challenge?.challengeOption3}
                                        onChange={() => {
                                            handleChange(challenge?.challengeOption3);
                                        }}
                                    />
                                </div>
                            </div>}
                            {challenge?.challengeOption4 && <div className="contest-create-row5-wrapper" >
                                <p className="admin-contest-creation-general-text">
                                    option4
                                </p>
                                <div className="contest-create-radio-button-wrapper">
                                    <label
                                        className="contest-create-radio-label"
                                        htmlFor={`option-4`}
                                    >
                                        {challenge?.challengeOption4}
                                    </label>
                                    <input
                                        type="radio"
                                        className="contest-create-start-input-radio"
                                        name="Options4"
                                        id="4"
                                        defaultChecked={challenge?.correctOption === challenge?.challengeOption4}
                                        onChange={() => {
                                            handleChange(challenge?.challengeOption4);
                                        }}
                                    />
                                </div>
                            </div>}
                            {challenge?.challengeOption5 && <div className="contest-create-row5-wrapper" >
                                <p className="admin-contest-creation-general-text">
                                    option5
                                </p>
                                <div className="contest-create-radio-button-wrapper">
                                    <label
                                        className="contest-create-radio-label"
                                        htmlFor={`option-5`}
                                    >
                                        {challenge?.challengeOption5}
                                    </label>
                                    <input
                                        type="radio"
                                        className="contest-create-start-input-radio"
                                        name="Options"
                                        id="5"
                                        defaultChecked={challenge?.correctOption === challenge?.challengeOption5}
                                        onChange={() => {
                                            handleChange(challenge?.challengeOption5);
                                        }}
                                    />
                                </div>
                            </div>}

                            <button
                                onClick={submitAnswer}
                                className="contest-preediction-admin-creation-submit-button"
                            >
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;
