import { useState, useEffect } from "react";
import axios from "axios";

function useScroller({ query, pageNum, req,shouldLoadPage }) {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [list, setList] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [pageNo, setPageNo] = useState(1);
// console.log(  "pops",req,query,pageNum,shouldLoadPage)
  useEffect(() => {
    setList([]);
    if (req.url === "") {
      // console.log('i am empty')
      return []};
      // console.log('i am pageno',pageNum)
    if (pageNum === 1) {
      // console.log('i am page1')
      const CancelToken = axios.CancelToken;
      let cancel;

      setIsLoading(true);
      setError(false);

      axios(req, {
        cancelToken: new CancelToken((c) => (cancel = c)),
      })
        .then((res) => {
          // console.log('i am res',res)
          setList((prev) => {
            return [...res.data];
          });
          setHasMore(res.data.length > 0);
          setIsLoading(false);
        })
        .catch((err) => {
          if (axios.isCancel(err)) return;
          setError(err);
        });
    }
  }, [query, shouldLoadPage]);

  useEffect(() => {
    // console.log('i am secound one')
    if (req.url === "") return [];
      const CancelToken = axios.CancelToken;
      let cancel;

      setIsLoading(true);
      setError(false);

      axios(req, {
        cancelToken: new CancelToken((c) => (cancel = c)),
      })
        .then((res) => {
          setList((prev) => {
            return [...new Set([...prev, ...res.data])];
          });
          setHasMore(res.data.length > 0);
          setIsLoading(false);
        })
        .catch((err) => {
          if (axios.isCancel(err)) return;
          setError(err);
        });

      return () => cancel();
    
  }, [pageNum]);

  return { isLoading, error, list, hasMore };
}

export default useScroller;
