global.config = {
    ROOTURL: {
         prod: 'https://champhunt.live'
    },
    share_url:'https://www.champhunt.com',
    pagePerSize: 10,
    pichPagePerSize:2,
    crickDataApiKey: '1915b5e8-0012-404e-ad95-c6e94eee181d',
    RECAPTCHA_PUBLIC_SITE_KEY:"6LcH3W8iAAAAAD0HWu_IaTUvcYLmsND-J8jySQN4",
    // SOCKET_URL:'https://champhunt.live'
    NODE_ENV: 'production',
    share_url: 'http://champhunt.dev.s3-website.ap-south-1.amazonaws.com',
};

export default global.config;
