import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getStorageItem } from "../../../utils/sessionStorage";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './index.scss';

const Advertisments = () => {
  const [advertisments, setAdvertisments] = useState([]);
  const accessToken = getStorageItem("token");

  useEffect(() => {
    const fetchAdvertisments = async () => {
      try {
        const response = await axios.get(`${global.config.ROOTURL.prod}/ads/fetch`, {
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/json",
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "*",
          },
        });
        setAdvertisments(response.data);
      } catch (error) {
        console.error("Error fetching advertisments: ", error);
        toast.error('Error fetching advertisments');
      }
    };

    fetchAdvertisments();
  }, []);

  const handleDelete = async (id) => {
    try {
      const res = await axios.post(`${global.config.ROOTURL.prod}/ads/delete-ad`, {
        _id: id
      }, {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      });
      if (res?.status === 200) {
        setAdvertisments(advertisments.filter(ad => ad._id !== id));
        toast.success('Advertisment deleted successfully');
      }
    } catch (error) {
      console.error("Error deleting advertisment: ", error);
      toast.error('Error deleting advertisment');
    }
  };

  return (
    <div className='advertisments'>
      <h1 className='advertisments__title'>Advertisments</h1>
      <div className='advertisments__list'>
        {advertisments && advertisments.length > 0 && advertisments.map(ad => (
          <div key={ad?._id} className='advertisment'>
            <video controls className='advertisment__video'>
              <source src={ad?.url} type='video/mp4' />
              Your browser does not support the video tag.
            </video>
            <div className='advertisment__content'>
              <h2 className='advertisment__title'>{ad?.title}</h2>
              <p className='advertisment__description'>{ad?.description}</p>
            </div>
            <button className='advertisment__delete-button' onClick={() => handleDelete(ad?._id)}>
              Delete
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Advertisments;
