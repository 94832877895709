import React from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import "./landigindex.scss";
import First from './First';
import Secound from './Secound';
import Third from './Third';
import Fourth from './Fourth';
import Fifth from './Fifth';
import Footer from './Footer';

export default function SecoundLandingPage() {
  return (
    <>
   
    <First/>
    <Secound/>
    <Third/>
    <Fourth/>
    <Fifth/>
    <Footer/>
    </>
  )
}
