import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
// import PaginationRounded from "../PaginationRounded";
import { toast } from "react-toastify";
import { getStorageItem } from "../../utils/sessionStorage";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
const userID = getStorageItem("user_id");
const accessToken = getStorageItem("token");
const moment = require("moment");

const UpdateChallenge = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { data } = location.state
    const [challenge, setChallenge] = useState({
        challengeQuestion: data.challengeQuestion,
        challengeOption1: data.challengeOption1,
        challengeOption2: data.challengeOption2,
        challengeOption3: data.challengeOption3 ? data.challengeOption3 : '',
        challengeOption4: data.challengeOption4 ? data.challengeOption4 : '',
        challengeOption5: data.challengeOption5 ? data.challengeOption5 : '',
        startTime: data.startTime,
        startDate: moment(data.startDate).format("YYYY-MM-DD"),
        endTime: data.endTime,
        endDate: moment(data.endDate).format("YYYY-MM-DD"),
    })
    const handleChallengeUpdate = async () => {
        if (!accessToken) return
        // if (!challenge.challengeQuestion || !challenge.challengeOption1 || !challenge.challengeOption2 ||
        //     !challenge.startDate ||
        //     !challenge.startTime ||
        //     !challenge.endDate ||
        //     !challenge.endTime
        // ) {
        //     toast.error("Unfilled required fields");
        //     return;
        // }

        const input = {
            id: data._id,
            challengeQuestion: challenge.challengeQuestion,
            challengeOption1: challenge.challengeOption1,
            challengeOption2: challenge.challengeOption2,
            challengeOption3: challenge.challengeOption3 || '',
            challengeOption4: challenge.challengeOption4 || '',
            challengeOption5: challenge.challengeOption5 || '',
            startTime: challenge.startTime,
            startDate: moment(challenge.startDate).format("YYYY-MM-DD"),
            endTime: challenge.endTime,
            // redeemRun: runsForEntryRefValue,
            endDate: moment(challenge.endDate).format("YYYY-MM-DD"),
        };
        const options = {
            method: "POST",
            url: global.config.ROOTURL.prod + "/contest/challenge/updateChallenge",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
                role: "Admin",
            },
            data: input,
        };
        axios(options)
            .then(({ data }) => {

                if (data._id) {

                    toast.success("Challenge updated successfully");
                    navigate('/admin/challenge')
                }


            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    console.log(error);
                }
            });
    };
    useEffect(() => {
        if (challenge.challengeQuestion && challenge.challengeQuestion.length > 25) {
            const updatedNameValue = challenge.challengeQuestion.slice(0, 25);
            setChallenge({ ...challenge, challengeQuestion: updatedNameValue })
        }
    }, [challenge?.challengeQuestion]);
    return (
        <div className="contest-admin-create">
            <div className="contest-admin-create-wrapper">
                <div className="contest-admin-create-wrapper-main">
                    <div className="contest-admin-create-wrapper-header">
                        <p className="contest-admin-create-wrapper-header--text">Name</p>
                        {/* <p className="contest-admin-create-wrapper-header--delete-text">
                            Delete
                        </p> */}
                    </div>
                    <div className="contest-admin-create-wrapper-form">
                        <div className="contest-create-name-wrapper">
                            <input
                                type="text"
                                id="question"
                                className="contest-create-name"
                                placeholder="question..."
                                value={challenge.challengeQuestion}
                                onChange={(e) => {
                                    setChallenge({ ...challenge, challengeQuestion: e.target.value })
                                }}
                                required
                            />
                            <p className="contest-create-name-extra">Max 25 letters</p>
                            <p className="contest-create-name-count-indicator">
                                {challenge.challengeQuestion?.length && challenge.challengeQuestion?.length < 25 ? 25 - challenge.challengeQuestion?.length : 0}
                            </p>
                        </div>
                        <div className="contest-create-row2-wrapper">
                            <div className="contest-create-row2-block1">
                                <p className="admin-contest-creation-general-text">Start</p>
                                <input
                                    type="date"
                                    className="contest-create-start-date"
                                    // ref={startDayRef}
                                    value={challenge.startDate}
                                    onChange={(e) => {
                                        setChallenge({ ...challenge, startDate: e.target.value })
                                    }}
                                    required
                                />
                                <input
                                    type="time"
                                    className="contest-create-start-time"
                                    value={challenge.startTime}
                                    onChange={(e) => {
                                        setChallenge({ ...challenge, startTime: e.target.value })
                                    }}
                                    required
                                />
                            </div>
                            {/* <div className="contest-create-row2-block2">
                                <p className="admin-contest-creation-general-text">
                                    Runs for Entry
                                </p>
                                <input
                                    type="text"
                                    className="contest-create-start-input"
                                    ref={runsForEntryRef}
                                    required
                                />
                            </div> */}
                        </div>
                        <div className="contest-create-row3-wrapper">
                            <div className="contest-create-row3-block1">
                                <p className="admin-contest-creation-general-text">End</p>
                                <input
                                    type="date"
                                    className="contest-create-start-date"
                                    value={challenge.endDate}
                                    onChange={(e) => {
                                        setChallenge({ ...challenge, endDate: e.target.value })
                                    }}
                                    required
                                />
                                <input
                                    type="time"
                                    className="contest-create-start-time"
                                    value={challenge.endTime}
                                    onChange={(e) => {
                                        setChallenge({ ...challenge, endTime: e.target.value })
                                    }}
                                    required
                                />
                            </div>
                            {/* <div className="contest-create-row3-block2">
                                <p className="admin-contest-creation-general-text">Reward</p>
                                <input
                                    type="text"
                                    className="contest-create-start-input"
                                    ref={rewardPriceRef}
                                    required
                                />
                            </div> */}
                        </div>
                        {/* <div className="contest-create-row4-wrapper">
                            <p className="admin-contest-creation-general-text">Q1</p>
                            <input
                                type="text"
                                className="contest-create-start-input-full"
                                ref={challengeQuestion}
                                required
                            />
                        </div> */}
                        <div className="contest-create-row5-wrapper">
                            <p className="admin-contest-creation-general-text">A1</p>
                            <div className="contest-create-radio-button-wrapper">
                                <input
                                    type="text"
                                    className="contest-create-start-input-options"
                                    placeholder="Option-1"
                                    id="option-1"
                                    value={challenge.challengeOption1}
                                    onChange={(e) => {
                                        setChallenge({ ...challenge, challengeOption1: e.target.value })
                                    }}
                                />
                            </div>
                        </div>
                        <div className="contest-create-row5-wrapper">
                            <p className="admin-contest-creation-general-text">A2</p>
                            <div className="contest-create-radio-button-wrapper">
                                <input
                                    type="text"
                                    className="contest-create-start-input-options"
                                    placeholder="Option-2"
                                    id="option-2"
                                    value={challenge.challengeOption2}
                                    onChange={(e) => {
                                        setChallenge({ ...challenge, challengeOption2: e.target.value })
                                    }}
                                />
                            </div>
                        </div>
                        {challenge.challengeOption3 && <div className="contest-create-row5-wrapper">
                            <p className="admin-contest-creation-general-text">A3</p>
                            <div className="contest-create-radio-button-wrapper">
                                <input
                                    type="text"
                                    className="contest-create-start-input-options"
                                    placeholder="Option-3"
                                    id="option-3"
                                    value={challenge.challengeOption3}
                                    onChange={(e) => {
                                        setChallenge({ ...challenge, challengeOption3: e.target.value })
                                    }}
                                />
                            </div>
                        </div>}
                        {challenge.challengeOption4 && <div className="contest-create-row5-wrapper">
                            <p className="admin-contest-creation-general-text">A4</p>
                            <div className="contest-create-radio-button-wrapper">
                                <input
                                    type="text"
                                    className="contest-create-start-input-options"
                                    placeholder="Option-4"
                                    id="option-4"
                                    value={challenge.challengeOption4}
                                    onChange={(e) => {
                                        setChallenge({ ...challenge, challengeOption4: e.target.value })
                                    }}
                                />
                            </div>
                        </div>}
                        {challenge.challengeOption5 && (
                            <div className="contest-create-row5-wrapper">
                                <p className="admin-contest-creation-general-text">A5</p>
                                <div className="contest-create-radio-button-wrapper">
                                    <input
                                        type="text"
                                        className="contest-create-start-input-options"
                                        placeholder="Option-5"
                                        id="option-5"
                                        value={challenge.challengeOption5}
                                        onChange={(e) => {
                                            setChallenge({ ...challenge, challengeOption5: e.target.value })
                                        }}
                                        required
                                    />
                                </div>
                            </div>
                        )}


                        <button
                            onClick={() => handleChallengeUpdate()}
                            className="contest-preediction-admin-creation-submit-button"
                        >
                            Update
                        </button>
                        {/* <div className="contest-create-pagination-rounded-wrapper">
              <PaginationRounded />
            </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdateChallenge;
