import React from 'react';
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import successIcon from '../../assets/images/subscription/success-icon.png'; // Replace with your tick icon path
import './coupon.scss';
import { useNavigate } from "react-router-dom";

const SuccessModal = ({ open, onClose }) => {
    const navigate = useNavigate();

  return (
    <Dialog open={open} onClose={onClose} className="success-modal">
      <DialogContent className="success-modal-content">
        <img src={successIcon} alt="Success" />
        <p>Coupon claimed successfully!</p>
        <p className="note">It will be auto-applied during checkout.</p>
        <DialogActions className="btn-container">
          <Button
            variant="contained"
            color="primary"
            onClick={()=>navigate('/champhunt-plus')}
          >
            Go to Subscription Page
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default SuccessModal;
