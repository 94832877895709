import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import { FormControl,InputLabel,MenuItem,Select } from "@mui/material";
import PaginationRounded from "../PaginationRounded";
import { toast } from "react-toastify";
import { getStorageItem } from "../../../utils/sessionStorage";
import axios from "axios";
const userID = getStorageItem("user_id");
const accessToken = getStorageItem("token");
const moment = require("moment");

const Index = () => {
  const [matchName, setmatchName] = useState("");
  const [team1, setteam1] = useState("");
  const [team2, setteam2] = useState("");
  const [runsRequired,setrunsRequired] = useState(0);
 
  const handlePredictionCreation = async () => {
    if (!accessToken) return
   
    
    const data = {
      matchName: matchName ,
      runsRequired: runsRequired,
      team1: team1,
      team2: team2,
    };
    const options = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/contest/fantasy/createMatch",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
        role: "Admin",
      },
      data: data,
    };
    axios(options)
      .then(({ data }) => {

        if (data._id) {

          setmatchName("");
          
          toast.success("New Match created successfully");

        }

      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          console.log(error);
        }
      });
  };
  
  return (
    <div className="contest-admin-create">
      <div className="contest-admin-create-wrapper">
        <div className="contest-admin-create-wrapper-main">
          <div className="contest-admin-create-wrapper-form">
            <h1>Create Match</h1>
            <div className="contest-create-name-wrapper">
              Match Name-
              <input
                type="text"
                className="contest-create-name"

                value={matchName}
                onChange={(event) => {
                  setmatchName(event.target.value);
                }}
                required
              /> 
            </div>
            
                  <div className="contest-create-name-wrapper">
                  Runs Required-
                  <input
                    type="text"
                    className="contest-create-name"
    
                    value={runsRequired}
                    onChange={(event) => {
                      setrunsRequired(event.target.value);
                    }}
                    required
                  />
                </div>
                <div className="contest-create-name-wrapper">
                  Team 1 -
                  <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                 <InputLabel id="demo-select-small-label">Team1</InputLabel>
                    <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={team1}
                  label="Team1"
                  onChange={(event)=>{
                    setteam1(event.target.value);
                  }}
                >
                  <MenuItem value="">
                    <em>Team 1</em>
                  </MenuItem>
                   {['IND', 'PAK', 'IRE', 'CAN', 'USA', 'ENG', 'AUS', 'NAM', 'SCO', 'OMA', 'NZ', 'WI', 'AFG', 'UGA', 'PNG', 'SA', 'SL', 'BAN', 'NET', 'NEP','ZIM'].map((item) => (
                      <MenuItem key={item} value={item}>{item}</MenuItem>
                  ))}
                      </Select>
                      </FormControl>
                  </div>
                  <div className="contest-create-name-wrapper">
                    Team 2 -
                  <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                      <InputLabel id="demo-select-small-label">Team 2</InputLabel>
                    <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={team2}
                  label="Team2"
                  onChange={(event)=>{
                    setteam2(event.target.value);
                  }}
                >
                  <MenuItem value="">
                    <em>Team 2</em>
                  </MenuItem>
                   {['IND', 'PAK', 'IRE', 'CAN', 'USA', 'ENG', 'AUS', 'NAM', 'SCO', 'OMA', 'NZ', 'WI', 'AFG', 'UGA', 'PNG', 'SA', 'SL', 'BAN', 'NET', 'NEP','ZIM'].map((item) => (
                      <MenuItem key={item} value={item}>{item}</MenuItem>
                  ))}
                      </Select>
      
              </FormControl>
                 </div>
            
            
            <button
              onClick={handlePredictionCreation}
              className="contest-preediction-admin-creation-submit-button"
            >
              Confirm
            </button>
            {/* <div className="contest-create-pagination-rounded-wrapper">
              <PaginationRounded />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
