import React, { useState, useEffect } from 'react';
import {
  Button,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios'; 
import { getStorageItem } from '../../utils/sessionStorage';
import {toast } from "react-toastify";

const PinPost = () => {
  const [postId, setPostId] = useState('');
  const accessToken = getStorageItem("token"); 
  const [allPitch, setDefaultUsers] = useState([]);
  const [pitchData,setPitchdata] = useState([]);
  

  const addPost = () => {
    if (postId) {
      const options = {
        method: "POST",
        url: global.config.ROOTURL.prod + "/pitch/createPostPin",
        headers: {
          Authorization: "Bearer " + accessToken,
        },
        data: {
          postId: postId, 
        },
      };

      axios(options)
        .then(({ data }) => {
          toast.success("PIn Post Added ");
        })
        .catch((error) => {
          console.error("Error creating pinned post:", error);
        });
    }
  };
  
  const deletePinnedPost = (postId) => {
    if (window.confirm("Unpin post")) {
      if (!postId) return
      const options = {
        method: "POST",
        url: global.config.ROOTURL.prod + `/pitch/deletePostPin`,
        headers: {
          Authorization: "Bearer " + accessToken,
        },
        data: { postId: postId }

      };
    
      axios(options)
        .then(({ data }) => {
          toast.success("unpinPost Successfully");
          fetchDatas();
        })
        .catch((error) => {
          if (
            error?.response?.status === 401 ||
            error?.response?.status === 500
          ) {
            toast.error("Some error occured");
          }
        });
    }
  };

useEffect(() => {
  fetchDatas();
  fetchDatas1();
}, []);

  const fetchDatas1 = () => {
    if (!accessToken) return;
    const options = {
      method: "get",
      url: global.config.ROOTURL.prod + `/pitch/getCreatedPost`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };

    axios(options)
      .then(({ data }) => {
        setPitchdata([...data])
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          toast.error("Some error occured");
        }
      });
  };

  const fetchDatas = () => {
    if (!accessToken) return;
    const options = {
      method: "get",
      url: global.config.ROOTURL.prod + `/pitch/getAllPinPost`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };

    axios(options)
      .then(({ data }) => {
        setDefaultUsers([...data]);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          toast.error("Some error occured");
        }
      });
  };

  return (
    <div>
      <div>
        <Typography variant="h6">Enter your PostId</Typography>
        <TextField
          variant="outlined"
          fullWidth
          value={postId} 
          onChange={(e) => setPostId(e.target.value)} 
          style={{ backgroundColor: "white", width: "60%" }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={addPost}
          style={{ marginTop: '10px', marginLeft: "10px" }}
        >
          Add
        </Button>
      </div>

      <div className="pinpostcontainer">
        {allPitch.map((data) => (
          <div key={data.id} style={{ paddingLeft: '13px' }}>
            <p>{data.postMessage}</p>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => deletePinnedPost(data._id)}
            >
              UnPin
            </Button>
          </div>
        ))}
      </div>

      <div style={{marginTop:'40px'}}>
        <h3>Post ID Created by your account</h3>
        {pitchData.map((data, index) => (
          <div style={{paddingLeft:'13px'}}>
            <p>{data.postMessage}</p>
            <p style={{color:'red',cursor:'pointer'}}>{data._id}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PinPost;
