import React from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import logo from '../../assets/images/landigpage2/logo.svg'
import { Button, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SignUp from '../../components/signin-signup/signup/signup.component';
import hardik from '../../assets/images/Events/hardik1.png'
import Kayn from '../../assets/images/Events/kiean1.png'
import Eventsignup from './signupEvent/Eventsignup';

export default function First() {
    const mobileView = useMediaQuery("(max-width:600px)");
    const navigate = useNavigate();
    return (
        <div className='First' >
            <Box className='headergradieant' >
                <Grid container>
                    <Grid item xs={6}>
                        <Box>
                            <Grid container >
                                <Grid item xs={3} md={1}>
                                    <img src={logo} />
                                </Grid>
                                <Grid item xs={9} md={10} sx={{margin:'auto'}} >
                                    <div className={mobileView ? 'headerTitle1 font14' : 'headerTitle1 font24'}><p>ChampHunt</p></div>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sx={{textAlign:'end',margin:'auto'}}>
                        <Button size="small" style={{ backgroundColor: '#FFFFFF',padding:'10px 20px',textTransform:'none' }} className="font14" onClick={() => navigate('/login')}>Login</Button>
                    </Grid>

                </Grid>
            </Box>
            <Box className='paddingHeader1' >
                <Grid container sx={{ flexDirection: { xs: 'column-reverse', md: 'row' } }}>
                    <Grid item xs={12}md={6} className="headerLet1">
                        <div><img src={hardik} className='hardik'/> </div>
                        <div><img src={Kayn}  className="kayn"/> </div>
                        <div className='Teamsplaying'>IND <span className='vs'>VS</span> NZ </div>
                       
                    </Grid>
                    <Grid item xs={12} md={6}className='signupcontainer2' >      
                           <Eventsignup/>
                    </Grid>

                </Grid>
            </Box>
        </div>
    )
}
