import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";

class Area extends Component {
  constructor(props) {
    super(props);

    const groupedData = this.groupData(this.props.data);

    this.state = {
      series: [{
        type: 'area',
        name: 'reach',
        data: groupedData.map(item => item.count)
      }],
      options: {
        chart: {
          height: 300,
          type: 'line',
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        fill: {
          type: 'solid',
          opacity: [0.35, 1],
        },
        colors: ['#A865B5'],
        labels: groupedData.map(item => item.date),
        markers: {
          size: 0
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
      },
    };
  }

  groupData(data) {
    if (data.length > 40) {
      return this.groupByMonth(data);
    } else if (data.length >= 20) {
      return this.groupByDateRange(data, 5);
    } else {
      return data;
    }
  }

  groupByMonth(data) {
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const groupedData = data.reduce((acc, curr) => {
      const [day, month] = curr.date.split('/');
      const monthName = monthNames[parseInt(month, 10) - 1];
      if (!acc[monthName]) {
        acc[monthName] = { date: monthName, count: 0 };
      }
      acc[monthName].count += curr.count;
      return acc;
    }, {});

    return Object.values(groupedData).sort((a, b) => {
      return monthNames.indexOf(a.date) - monthNames.indexOf(b.date);
    });
  }

  groupByDateRange(data, rangeSize) {
    const groupedData = data.reduce((acc, curr) => {
      const [day, month] = curr.date.split('/');
      const dayNumber = parseInt(day, 10);
      const rangeStart = Math.floor((dayNumber - 1) / rangeSize) * rangeSize + 1;
      const rangeEnd = rangeStart + rangeSize - 1;
      const rangeKey = `${rangeStart}-${rangeEnd}/${month}`;
      if (!acc[rangeKey]) {
        acc[rangeKey] = { date: rangeKey, count: 0 };
      }
      acc[rangeKey].count += curr.count;
      return acc;
    }, {});

    return Object.values(groupedData).sort((a, b) => {
      const [rangeA, monthA] = a.date.split('/');
      const [rangeB, monthB] = b.date.split('/');
      const [startA] = rangeA.split('-').map(Number);
      const [startB] = rangeB.split('-').map(Number);
      return new Date(2000, monthA - 1, startA) - new Date(2000, monthB - 1, startB);
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      const groupedData = this.groupData(this.props.data);

      this.setState({
        options: {
          ...this.state.options,
          labels: groupedData.map(item => item.date),
        },
        series: [{
          type: 'area',
          data: groupedData.map(item => item.count)
        }]
      });
    }
  }

  render() {
    return (
      <div>
        <div id="chart">
          <ReactApexChart options={this.state.options} series={this.state.series} type="area" height={310} />
        </div>
        <div id="html-dist"></div>
      </div>
    );
  }
}

export default Area;
