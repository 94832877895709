import React from "react";
import { useEffect, useState, useRef, useCallback } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./index.scss";
import { getStorageItem } from "../../utils/sessionStorage";
import PinPost from "./PinPost";

import {
  Button,
  CardContent,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

// React-Toastifyimport
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CardHeader } from "@material-ui/core";
import Avatar from "@mui/material/Avatar";

const PinPOll = (props) => {
  const navigate = useNavigate();
  const accessToken = getStorageItem("token");
  const userId = getStorageItem("user_id");

  const [pageNumber, setPageNumber] = useState(1);
  const [totalPageNumber, setTotalPageNumber] = useState(0);
  const [pollData, setPolldata] = useState([]);
  const [allPoll, setDefaultUsers] = useState([]);
  const [pollId, setPollId] = useState("");
  const [id, setId] = useState("");

  const addDefaultUser = () => {
    console.log("kadas", pollId);
    if (pollId) {
      const options = {
        method: "POST",
        // url: global.config.ROOTURL.prod + `/carousel/getAll/${pageNumber}/6`,
        url: global.config.ROOTURL.prod + `/poll/createPinpoll`,
        headers: {
          Authorization: "Bearer " + accessToken,
        },
        data: {
          pollId: pollId,
        },
      };

      axios(options)
        .then(({ data }) => {
          console.log(data);
          toast.success("PIn Poll Added ");
          fetchData();
        })
        .catch((error) => {
          if (
            error?.response?.status === 401 ||
            error?.response?.status === 500
          ) {
            toast.error("Some error occured");
          }
        });
    }
  };

  const removeDefaultUser = (pollId) => {
    if (window.confirm("Remove pinPoll")) {
      if (!pollId) return;
      const options = {
        method: "POST",
        // url: global.config.ROOTURL.prod + `/carousel/getAll/${pageNumber}/6`,
        url: global.config.ROOTURL.prod + `/poll/deletepinpoll`,
        headers: {
          Authorization: "Bearer " + accessToken,
        },
        data: { pollId: pollId },
      };

      axios(options)
        .then(({ data }) => {
          console.log(data);
          toast.success("pinPol  Removed");
          fetchData();
        })
        .catch((error) => {
          if (
            error?.response?.status === 401 ||
            error?.response?.status === 500
          ) {
            toast.error("Some error occured");
          }
        });
    }
  };

  // Fetching length of data
  useEffect(() => {
    fetchData();
    fetchData1();
  }, []);

  // Ferching Page Data

  const fetchData1 = () => {
    if (!accessToken) return;
    const options = {
      method: "get",
      // url: global.config.ROOTURL.prod + `/carousel/getAll/${pageNumber}/6`,
      url: global.config.ROOTURL.prod + `/poll/getCreatedPoll`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };

    axios(options)
      .then(({ data }) => {
        console.log(data);
        setPolldata([...data]);
        // setDefaultUsers([...data]);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          toast.error("Some error occured");
        }
      });
  };
  const fetchData = () => {
    if (!accessToken) return;
    const options = {
      method: "get",
      // url: global.config.ROOTURL.prod + `/carousel/getAll/${pageNumber}/6`,
      url: global.config.ROOTURL.prod + `/poll/getAllPinPoll`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };

    axios(options)
      .then(({ data }) => {
        console.log(data);
        setDefaultUsers([...data]);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          toast.error("Some error occured");
        }
      });
  };

  return (
    <div>
      <div>
        <Typography variant="h6">Enter your PollId</Typography>
        <TextField
          type="text"
          id="textInput"
          name="textInput"
          variant="outlined"
          fullWidth
          value={pollId}
          onChange={(e) => setPollId(e.target.value)}
          style={{ backgroundColor: "white", width: "60%" }}
        />
        <Button  style={{ marginTop: '10px', marginLeft: "10px" }} variant="contained" color="primary" onClick={addDefaultUser}>
          ADD
        </Button>
      </div>
      <div className="pinpollcontainer">
        {allPoll.map((data, index) => (
          <div style={{ paddingLeft: "13px" }}>
            <p>{data.question}</p>
            <p
              onClick={() => removeDefaultUser(data._id)}
              style={{ color: "red", cursor: "pointer" }}
            >
              Delete
            </p>
          </div>
        ))}
      </div>

      <div style={{ marginTop: "40px", marginBottom: "40px" }}>
        <h3>Poll ID Created by you account</h3>
        {pollData.map((data, index) => (
          <div style={{ paddingLeft: "13px" }}>
            <p>{data.question}</p>
            <p style={{ color: "red", cursor: "pointer" }}>{data._id}</p>
          </div>
        ))}
      </div>

      <PinPost />
    </div>
  );
};

export default PinPOll;
