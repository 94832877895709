import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    // matchType: '',
    // status: null,
    // venue: '',
    // Date: '',
    // teamInfo: [{ team: '',img: ''},{ team: '',img: ''}],
    // Score:[{run:'',over:'',wicket:''},{run:'',over:'',wicket:''}],
    // matchended:false,
    // matchstart:false,
    Data:"",
   
}

export const LivescorSlice=createSlice({
    name: 'live',
    initialState,
    reducers: {
        liveDataFromServer(state,action) {
        //   state.matchType=action.payload.payload
        //   state.status=action.payload,
        //   state.venue=action.payload,
        //   state.Date=action.payload,
        //   state.teamInfo=action.payload,
        //   state.Score=action.payload,
        //   state.matchended=action.payload,
        //   state.matchstart=action.payload
        state.Data=action.payload.payload

        }
      
    },
})

// Action creators are generated for each case reducer function
export const LiveAction = LivescorSlice.actions

export default LivescorSlice