import React from 'react';
import FollowingList from './FollowingList';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import './index.scss'
const FollowingPage = ({userId}) => {

  const navigate = useNavigate()
  
  const containersStyle = {
    backgroundColor: "#e5e5e5",
    minHeight: "100vh",
    paddingTop: "120px",
  };

  const rowsStyle = {
    display: 'flex',
    margin: 'auto',
    paddingBottom: '100px',
    width: '80%',
    alignItems: 'center',
    justifyContent: 'center',
  };

  return (
    <div style={containersStyle}>
      <h3 style={{ display:'flex', borderBottom: "1px solid #aaa", margin: "14px 30px 40px", padding: "0", fontSize: "20px", fontWeight: "500" }}><h5 h5 style={{padding:'2px 6px', cursor:'pointer'}} onClick={()=>{navigate(-1)}}><ArrowBackIcon /></h5>Following Page</h3>
      <div style={rowsStyle} className='infiniteScrollConatinerToChangeCustomCss'>
      <FollowingList userId={userId} />
      </div>
    </div>
  );
}

export default FollowingPage;