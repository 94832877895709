import axios from "axios";
import { useState, useEffect, useContext } from "react";
import "./index.scss";
import { clearStorage, getStorageItem } from "../../utils/sessionStorage";
import { useNavigate } from "react-router-dom";
import Papa from "papaparse";
import { toast } from "react-toastify";
const UserCount = () => {
  const navigate = useNavigate();
  const accessToken = getStorageItem("token");
  const [totalUsersCount, setTotalUsersCount] = useState(0);
  const [totalUsersCountToday, setTotalUsersCountToday] = useState(0);
  const [totalPitchesCountToday, setPitchesCountToday] = useState(0);
  const [SubscribersCount, setSubscribersCount] = useState(0);
  const [totalHighlightsCountToday, setHighlightsCountToday] = useState(0);
  const allowedEmails = [
    "sameer.kanva11@gmail.com",
    "anuragwadikar568@gmail.com",
  ];
  const userEmail = getStorageItem("user_email");
  const getAllUsersCount = () => {
    if (!accessToken) return;
    axios({
      method: "GET",
      url: global.config.ROOTURL.prod + "/auth/get-all-user-count/",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((response) => setTotalUsersCount(response.data.totalCount))
      .catch((error) => {
        if (error?.response?.status === 401) {
          clearStorage();
          navigate("/login");
        }
      });
  };
  const getAllUsersCountToday = () => {
    if (!accessToken) return;
    axios({
      method: "GET",
      url: global.config.ROOTURL.prod + "/auth/get-user-count-today/",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((response) => setTotalUsersCountToday(response.data.totalCount))
      .catch((error) => {
        if (error?.response?.status === 401) {
          clearStorage();
          navigate("/login");
        }
      });
  };
  const getAllPitchesCountToday = () => {
    if (!accessToken) return;
    axios({
      method: "GET",
      url: global.config.ROOTURL.prod + "/pitch/get-pitch-count-today/",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((response) => setPitchesCountToday(response.data.totalCount))
      .catch((error) => {
        if (error?.response?.status === 401) {
          clearStorage();
          navigate("/login");
        }
      });
  };
  const getAllActiveSubscribersCount = () => {
    if (!accessToken) return;
    axios({
      method: "GET",
      url: global.config.ROOTURL.prod + "/pay/getActiveSubscribersCount",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((response) =>
        setSubscribersCount(response.data.activeSubscriptions)
      )
      .catch((error) => {
        if (error?.response?.status === 401) {
          clearStorage();
          navigate("/login");
        }
      });
  };
  // const getAllHighlightsCountToday = () => {
  //   if (!accessToken) return;
  //   axios({
  //     method: "GET",
  //     url: global.config.ROOTURL.prod + "/auth/get-all-user-count/",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + accessToken,
  //     },
  //   })
  //     .then((response) => setHighlightsCountToday(response.data.totalCount))
  //     .catch((error) => {
  //       if (error?.response?.status === 401) {
  //         clearStorage();
  //         navigate("/login");
  //       }
  //     });
  // };
  useEffect(() => {
    console.log(userEmail);
    // Check user identity
    if (!allowedEmails.includes(userEmail)) {
      navigate("/pitch");
    }
  }, [userEmail]);

  useEffect(() => {
    // Get all users count
    getAllUsersCount();
    // Get users today
    getAllUsersCountToday();
    // Get all pitches count today
    getAllPitchesCountToday();
    // Get all highlights count today
    // getAllHighlightsCountToday();
    //Get all active subscribers count
    getAllActiveSubscribersCount();
  }, []);

  const [activeAction, setActiveAction] = useState(0);

  // User Tracking
  const [userTrackingIds, setUserTrackingIds] = useState("");
  const [selectedTrackingOption, setSelectedTrackingOption] = useState("1d");

  // User Blocking
  const [userBlockingIds, setUserBlockingIds] = useState("");

  // Deleting Comments
  const [deletingCommentsEmails, setDeletingCommentsEmails] = useState("");
  const [selectedCommentDeletionOption, setSelectedCommentDeletionOption] =
    useState("1d");

  // Deleting Pitches
  const [deletingPitchesEmails, setDeletingPitchesEmails] = useState("");
  const [selectedPitchDeletionOption, setSelectedPitchDeletionOption] =
    useState("1d");

  const handleUserTracking = () => {
    const idsArray = userTrackingIds.split(" ");
    if (!accessToken) return;
    const userTrackingOptions = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/auth/get-user-tracking-record/",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      data: {
        ids: idsArray,
        selectedTimeFrame: selectedTrackingOption,
      },
    };

    axios(userTrackingOptions)
      .then(({ data }) => {
        const { commentsData, pitchesData } = data;
        console.log(data);

        // Convert pitchesData to CSV
        const pitchesCsv = Papa.unparse(pitchesData);
        const pitchesBlob = new Blob([pitchesCsv], { type: "text/csv" });
        const pitchesUrl = window.URL.createObjectURL(pitchesBlob);

        const pitchesLink = document.createElement("a");
        pitchesLink.style.display = "none";
        pitchesLink.href = pitchesUrl;
        pitchesLink.download = "user_tracking_pitches.csv";
        document.body.appendChild(pitchesLink);
        pitchesLink.click();

        window.URL.revokeObjectURL(pitchesUrl);

        // Convert commentsData to CSV
        const commentsCsv = Papa.unparse(commentsData);
        const commentsBlob = new Blob([commentsCsv], { type: "text/csv" });
        const commentsUrl = window.URL.createObjectURL(commentsBlob);

        const commentsLink = document.createElement("a");
        commentsLink.style.display = "none";
        commentsLink.href = commentsUrl;
        commentsLink.download = "user_tracking_comments.csv";
        document.body.appendChild(commentsLink);
        commentsLink.click();

        window.URL.revokeObjectURL(commentsUrl);
      })
      .catch((error) => {
        console.error("Error fetching user tracking data:", error);
      });
  };

  const handleUserBlocking = () => {
    const idsArray = userBlockingIds.split(" ");
    if (!accessToken) return;
    const userTrackingOptions = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/auth/block-user-list/",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      data: {
        ids: idsArray,
      },
    };
    axios(userTrackingOptions)
      .then((data) => {
        console.log(data);
        toast.success("Users Blocked");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleUserCommentDeletion = () => {
    const emailArray = deletingCommentsEmails.split(" ");
    if (!accessToken) return;
    const userCommentDeletionOptions = {
      method: "POST",
      url:
        global.config.ROOTURL.prod +
        "/pitch/comment/get-user-comment-deletion/",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      data: {
        emails: emailArray,
        selectedTimeFrame: selectedCommentDeletionOption,
      },
    };
    axios(userCommentDeletionOptions)
      .then((data) => {
        console.log(data);
        toast.success("Comments deleted");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleUserPitchDeletion = () => {
    const emailArray = deletingPitchesEmails.split(" ");
    if (!accessToken) return;
    const userPitchDeletionOptions = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/pitch/get-user-pitch-deletion/",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      data: {
        emails: emailArray,
        selectedTimeFrame: selectedPitchDeletionOption,
      },
    };
    axios(userPitchDeletionOptions)
      .then((data) => {
        console.log(data);
        toast.success("Pitches deleted");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    allowedEmails.includes(userEmail) && (
      <div className="user-statistics">
        {/* Count */}
        <div className="user-statistics__count-main">
          <div className="user-statistics__count">
            <div className="user-count">
              <div className="total-users-count-box">
                <div>
                  <h2 className="total-users__heading">Total Users</h2>
                  <h4 className="total-users__value">{totalUsersCount}</h4>
                </div>
              </div>
              <div className="today-user-count-box">
                <div>
                  <h2 className="total-users__heading">Users Today</h2>
                  <h4 className="total-users__value">{totalUsersCountToday}</h4>
                </div>
              </div>
              <div className="today-pitch-count-box">
                <div>
                  <h2 className="total-users__heading">Pitches Today</h2>
                  <h4 className="total-users__value">
                    {totalPitchesCountToday}
                  </h4>
                </div>
              </div>
              <div className="today-pitch-count-box">
                <div>
                  <h2 className="total-users__heading">
                    Total Active Subscribers
                  </h2>
                  <h4 className="total-users__value">{SubscribersCount}</h4>
                </div>
              </div>
              {/* <div className="today-highlights-count-box">
              <div>
                <h2 className="total-users__heading">Highlights Today</h2>
                <h4 className="total-users__value">
                  {totalHighlightsCountToday}
                </h4>
              </div>
            </div> */}
            </div>
          </div>
        </div>
        <div className="user-statistics__top-main">
          <div className="user-statistics__top">
            {/* Head */}
            <div className="user-statistics__head">
              <div className="company-header">
                <h3 className="company-title">Champhunt LLC</h3>
                <h3 className="company-year">{new Date().getFullYear()}</h3>
              </div>
              <h1 className="page-title">
                User management unleashed—grasp the power.
              </h1>
            </div>
            {/* Banner */}
            <div className="user-statistics__banner">
              <div className="section-first">
                <div className="section-first__content">
                  <div className="dividing__line"></div>
                  <h3 className="section-first__text">
                    Welcome to Champhunt LLC, your go-to for all things cricket!
                  </h3>
                </div>
              </div>
              <div className="section-second">
                <img
                  src="https://framerusercontent.com/images/knpitJXg0MEAD9VCalGd4Qt0k.jpg?scale-down-to=1024"
                  alt="cricket"
                  className="section-second__image"
                />
              </div>
              <div className="section-third">
                <img
                  src="https://framerusercontent.com/images/lfoH7gfwa4fiUm2lMFCUgeCAePs.jpg?scale-down-to=1024"
                  alt="cricket"
                  className="section-third__image"
                />
              </div>
              <div className="section-fourth">
                <img
                  src="/apple-touch-icon.png"
                  alt="champhunt-logo"
                  className="section-fourth__image"
                />
              </div>
            </div>
          </div>
        </div>
        {/* Actions panel */}
        <div className="actions-panel">
          <div className="action-panel-main">
            <div
              className={
                activeAction === 0 ? "action-button" : "action-button--active"
              }
              onClick={() => {
                setActiveAction(0);
              }}
            >
              User Tracking
            </div>
            <div
              className={
                activeAction === 1 ? "action-button" : "action-button--active"
              }
              onClick={() => {
                setActiveAction(1);
              }}
            >
              Block User
            </div>
            <div
              className={
                activeAction === 2 ? "action-button" : "action-button--active"
              }
              onClick={() => {
                setActiveAction(2);
              }}
            >
              Deduct Runs
            </div>
            <div
              className={
                activeAction === 3 ? "action-button" : "action-button--active"
              }
              onClick={() => {
                setActiveAction(3);
              }}
            >
              Delete Comments
            </div>
            <div
              className={
                activeAction === 4 ? "action-button" : "action-button--active"
              }
              onClick={() => {
                setActiveAction(4);
              }}
            >
              Delete Pitches
            </div>
          </div>
        </div>
        {/* User Tracking */}
        {activeAction === 0 && (
          <div className="user-tracking__count-main">
            <div className="user-tracking__count">
              {/* <div className="section--left-image">
            <img src="/total_users.jpg" alt="admin-checking-user-list" className="left-image" />
          </div> */}
              <div className="section--right-text">
                <h2 className="user-tracking__heading">Users Gone Wild!</h2>
                <p className="user-tracking__detail">
                  Select a user-list and timeline to reveal their uncanny
                  activity trends. Opt for 1 day, 1 week, or 1 month to unleash
                  the hidden high-flyers.
                </p>
                <div className="all_users_tracking_group">
                  <input
                    type="text"
                    className="user-tracking-list__input"
                    value={userTrackingIds}
                    placeholder="Enter user ids separated by spaces"
                    onChange={(e) => setUserTrackingIds(e.target.value)}
                  />
                  <select
                    name="cars"
                    className="user-tracking-select"
                    value={selectedTrackingOption}
                    onChange={(e) => setSelectedTrackingOption(e.target.value)}
                  >
                    <option value="1d">1 Day</option>
                    <option value="1w">1 Week</option>
                    <option value="1m">1 Month</option>
                  </select>
                  <button
                    className="generate-csv--tracking"
                    onClick={handleUserTracking}
                  >
                    Generate CSV
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* User Blocking */}
        {activeAction === 1 && (
          <div className="user-blocking__main">
            <div className="user-blocking">
              <h2 className="user-blocking__heading">Block'em!</h2>
              <p className="user-blocking__detail">
                Got some troublemakers wreaking havoc like it's Friday the 13th?
                No worries! Pop their emails into the field and watch them
                vanish into the abyss! Poof! Now they're inactive.
              </p>
              <div className="users_blocking_group">
                <input
                  type="text"
                  className="user-blocking-list__input"
                  value={userBlockingIds}
                  placeholder="Enter user ids separated by spaces to block."
                  onChange={(e) => setUserBlockingIds(e.target.value)}
                />
                <button className="block-button" onClick={handleUserBlocking}>
                  Block Now
                </button>
              </div>
            </div>
          </div>
        )}
        {/* Deduct User Runs */}
        {activeAction === 2 && (
          <div className="user-blocking__main">
            <div className="user-blocking">
              <h2 className="user-blocking__heading">
                Catching Mischief with a Wink!
              </h2>
              <p className="user-blocking__detail">
                Keep your digital realm light-hearted and mischievous users in
                check. Watch out for the pranksters, rule-breakers, and sneaky
                folks, and give them a nudge (or a wink!).
              </p>
              <div className="users_blocking_group">
                <input type="text" className="user-blocking-list__input" />
                <input
                  type="number"
                  className="deducted-runs"
                  placeholder="Enter user ids to deduct runs."
                />
                <button className="block-button">Deduct Runs</button>
              </div>
            </div>
          </div>
        )}
        {/* Delete User Comments */}
        {activeAction === 3 && (
          <div className="user-blocking__main">
            <div className="user-blocking">
              <h2 className="user-blocking__heading">
                Clean Up with Precision
              </h2>
              <p className="user-blocking__detail">
                Say goodbye to unwanted comments with our 'Sweep Away Comments'
                feature. It's like having a digital broom for your platform!
              </p>
              <div className="users_blocking_group">
                <div className="all_users_tracking_group">
                  <input
                    type="text"
                    className="user-blocking-list__input"
                    value={deletingCommentsEmails}
                    placeholder="Enter user ids to delete comments."
                    onChange={(e) => setDeletingCommentsEmails(e.target.value)}
                  />
                  <select
                    name="cars"
                    className="user-tracking-select"
                    value={selectedCommentDeletionOption}
                    placeholder="Enter user ids to delete pitches."
                    onChange={(e) =>
                      setSelectedCommentDeletionOption(e.target.value)
                    }
                  >
                    <option value="1d">1 Day</option>
                    <option value="1w">1 Week</option>
                    <option value="1m">1 Month</option>
                  </select>
                  <button
                    className="block-button"
                    onClick={handleUserCommentDeletion}
                  >
                    Delete Comments
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* Delete Pitches */}
        {activeAction === 4 && (
          <div className="user-blocking__main">
            <div className="user-blocking">
              <h2 className="user-blocking__heading">
                User-Generated Content Cleanup
              </h2>
              <p className="user-blocking__detail">
                Maintain a tidy and pristine platform with our 'User-Generated
                Content Cleanup' tool.
              </p>
              <div className="users_blocking_group">
                <div className="all_users_tracking_group">
                  <input
                    type="text"
                    className="user-tracking-list__input"
                    value={deletingPitchesEmails}
                    onChange={(e) => setDeletingPitchesEmails(e.target.value)}
                  />
                  <select name="cars" className="user-tracking-select">
                    <option value="1d">1 Day</option>
                    <option value="1w">1 Week</option>
                    <option value="1m">1 Month</option>
                    {/* <option value="all">All</option> */}
                  </select>
                  <button
                    className="block-button"
                    onClick={handleUserPitchDeletion}
                  >
                    Delete Pitches
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  );
};

export default UserCount;
