
import React, { useEffect, useState, useRef, useCallback } from "react";
import axios from "axios";
import { getStorageItem } from "../../../utils/sessionStorage";
import { Grid } from "@mui/material";
import useScroller from "../../../commons/useScroller";
import UserCard from "../../followers/followers";
import { useParams } from "react-router";
import InfiniteScroll from 'react-infinite-scroll-component';
import { BallTriangle } from 'react-loader-spinner';


const FollowersList = () => {
  const accessToken = getStorageItem("token");
  const userId = getStorageItem("user_id");
  const [followers, setFollowers] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [pagePerSize, setPagePerSize] = useState(6);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const { id } = useParams();
  const [shouldload, setShouldload] = useState(false);

  const fetchMoreData = () => {
    // Fetch additional 
    if (!accessToken) return
    const req = {
      method: "GET",
      url:
        global.config.ROOTURL.prod +
        `/auth/get-followers-byuserid/${id}/${pageNum}/${global.config.pagePerSize}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    axios(req).then(response => {
      if (response.data.length === 0) {
        setHasMore(false);
      } else {
        // setFollowers(prevItems => prevItems.concat(response.data));
        const newFollowersMap = new Map(followers.map(follower => [follower._id, follower]));
        response.data.forEach(follower => {
          if (!newFollowersMap.has(follower._id)) {
            newFollowersMap.set(follower._id, follower);
          }
        });
        const updatedFollowers = Array.from(newFollowersMap.values());
        setFollowers(updatedFollowers);
        setPageNum(prevPage => prevPage + 1);
        setShouldload(true);
      }
    })
      .catch(error => console.log(error));
  };



  useEffect(() => {
    fetchMoreData();
  }, [userId]);

  return (
    <>
      {shouldload &&
        (
          <InfiniteScroll
            className="page_profile"
            dataLength={followers.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={
              <BallTriangle
                height="100"
                width="100"
                color="grey"
                ariaLabel="loading"
              />}
          >
            {followers
              .sort((a, b) => {
                const nameA = a.firstName.toLowerCase() + ' ' + a.lastName.toLowerCase();
                const nameB = b.firstName.toLowerCase() + ' ' + b.lastName.toLowerCase();
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return 0;
              })
              .map((follower, index) => (
                <div
                  className="single-user-main-wrapper"
                  key={index}
                  style={{ paddingRight: '10px' }}
                >
                  <UserCard following={follower} />
                </div>
              ))}

          </InfiniteScroll>
          // </div>
        )}
    </>
  );
};

export default FollowersList;
