import React, { useEffect, useState } from 'react'
import defaultAvatar from '../../assets/images/profile/default_avatar.png'
import { Link, useNavigate } from 'react-router-dom'
import ReactTimeAgo from 'react-time-ago'
import DropdownMenu from '../dropdown-menu/dropdownMenu'
import { Box, Button } from '@mui/material'
import { getStorageItem } from '../../utils/sessionStorage'
import axios from 'axios'
import runball from '../../assets/images/icons/newBall.png'
import { toast } from 'react-toastify'
import AcceptChallengeModal from './AccepteChallengeModal'
import { BorderBottom } from '@mui/icons-material'
import fireBaseFuntion from "../../firebase";
import Countdown from 'react-countdown'
import './FeedChallenge.css'
const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
        return <span style={{ color: "#0C5BA0", fontSize: "11px", fontWeight: 500 }}> Challenge Expired</span>
    } else {
        return <span style={{ color: "#0C5BA0", fontSize: "11px", fontWeight: 500 }}>
            {days}:{hours}:{minutes}:{seconds}  left</span>
    }
}
const OptionBox = ({ option, profilePhoto, userId }) => {
    const navigate = useNavigate();
    return (
        <div
            className="feed__challenge__option"
        > <div style={{ marginLeft: '10px' }}>{option}</div>
            {profilePhoto ? <div>
                <img onClick={() => navigate(`/profile/${userId}`)}
                    src={profilePhoto || defaultAvatar}
                    alt=""
                    style={{
                        border: '2px solid #0C5BA0',
                        width: "25px",
                        height: "25px",
                        borderRadius: "50%",
                    }}
                />
            </div>
                : <div></div>}

        </div>
    )
}
const FeedChallenge = (props) => {
    const [totalRuns, setTotalRuns] = useState(0)
    const [acceptChallengePopup, setAcceptChellengePopup] = useState(false)
    const [answerPredictedByUser, setAnswerPredictedByUser] = useState(false)

    const [createClicked, setCreateClicked] = useState(false);
    const { postObj } = props;
    const [post, setPost] = useState(postObj)
    const { challengeQuestion, challengeOption1, challengeOption3,
        challengeOption4, challengeOption5, challengeOption2 } = post?.challengeDetails
    const userId = localStorage.getItem('user_id');
    const accessToken = getStorageItem('token');
    const [rejected, setRejected] = useState(false)
    const [endDate, setEndDate] = useState(timer())
    const rejectChallenge = () => {
        if (!accessToken) return;
        var rejectdetails = {
            method: "POST",
            url: global.config.ROOTURL.prod + `/contest/userchallenge/rejectChallenge/${post?.challengeId}/${post?.feed_id}`,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
        };
        axios(rejectdetails)
            .then((response) => {
                // setPageNum(1);
                if (response.data._id) {
                    setPost(response.data)
                    setRejected(true)
                    toast.success("Challenge Rejected!")
                    // handleClose()
                }
                // handleClose();
            })
            .catch((error) => {
                console.log("error", error);
            });
    }
    const realTimePitchUpdate = () => {
        if (userId) {
            const { onValue, starCountRef } = fireBaseFuntion(
                "user/challengedetail/" + postObj?.feed_id || postObj?._id
            );

            onValue(starCountRef, (snapShot) => {
                const data = snapShot.val();
                if (data) {
                    setPost(data)
                }
            });
        }
    };
    const acceptChallenge = () => {
        setCreateClicked(true)
        if (!accessToken) return;
        if (!answerPredictedByUser || !post?.betRuns) {
            toast.error("select an option")
            return;
        }
        const data = {
            challengeQuestion, challengeOption1,
            challengeOption2,
            challengeOption3,
            challengeOption4, challengeOption5,
            betRuns: post?.betRuns,
            challengeId: post?.challengeId,
            userChallengeId: post?.feed_id || post?._id,
            answerPredicted: answerPredictedByUser,
        }
        console.log("acceptChallenge", data)
        var acceptdetails = {
            method: "POST",
            url: global.config.ROOTURL.prod + '/contest/userchallenge/acceptUserChallenge',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
            data: data
        };
        axios(acceptdetails)
            .then((response) => {
                if (response.data._id) {
                    toast.success("Challenge accepted!");
                    setPost(response.data)
                    setRejected(true)
                    handleAcceptChallengePopup();

                }
            })
            .catch((error) => {
                console.log("error", error);
            });
        realTimePitchUpdate()
    }
    const getTotalRuns = async () => {
        if (!accessToken) return;
        if (!userId) return console.error("userId required");

        const getTotalRun = {
            method: "GET",
            url: global.config.ROOTURL.prod + "/pitch/getTotalRun/" + userId,
            headers: {
                Authorization: "Bearer " + accessToken,
            },
        };
        return axios(getTotalRun)
            .then((response) => {
                setTotalRuns(response.data);
                console.log("totalruns", response.data)
                return response.data;
            })
            .catch((error) => {
                console.log("2")
                console.log(error);
                return 0;
            });
    };
    const handleAcceptChallengePopup = async () => {

        if (await getTotalRuns() < post?.betRuns) { toast.error("Insufficient Runs") } else { setAcceptChellengePopup(!acceptChallengePopup) }
    }
    function timer() {
        const targetDate = new Date(
            post?.challengeDetails?.challengeEndDateAndTime);

        const offsetMillis = 5 * 60 * 60 * 1000 + 30 * 60 * 1000;
        const newDateMillis = targetDate.getTime() - offsetMillis;

        console.log("new Date(newDateMillis)",
            new Date(newDateMillis))
        return (new Date(newDateMillis).toISOString());
    }
    const isUserAcceptedChallenge = post?.acceptedUserDetails?.userId === userId;
    const isChallengeExpired = post?.challengeDetails?.endDateAndTime <= endDate;
    // const isChallengeEnded = post?.endChallenge;
    const isUserChallenger = post?.userId === userId;
    const isUserRejected = post?.rejectedUserIds.includes(userId);
    console.log("enddate", endDate)
    return (
        <div key={post.feed_id || post._id} className="challenge__wrapper">
            <Box> <div className="feed__challenge__option" style={{
                color: '#0C5BA0', fontWeight: 500,
                borderBottom: '1px solid #EAEAEA', padding: "3px"
            }}>
                <div style={{ marginLeft: "5px" }}>Challenge</div>
                <div style={{ marginRight: "5px", display: "flex", flexDirection: "row" }}>
                    <div > <img src={runball} alt="" style={{
                        height: "15px",
                        width: "15px",
                        marginRight: "3px"
                    }} /></div>  <div style={{ fontSize: "13px", marginTop: "2px" }}>
                        {isUserChallenger || post?.acceptedUserDetails?.userId || rejected ?
                            `${post?.betRuns} Runs`
                            : `${post?.betRuns} Runs To Play`}</div>
                </div>
            </div></Box>
            <div style={{ padding: "20px" }}>

                <div className="poll__user">
                    <img
                        src=
                        {post.userId === userId && post?.acceptedUserDetails?.userId ?
                            (post?.acceptedUserDetails?.userProfilePhoto || defaultAvatar) :
                            (post.userProfilePhoto || defaultAvatar)}
                        alt="user"
                    />
                    <div className="user__details">
                        <Link to={`/profile/${post.userId === userId && post?.acceptedUserDetails?.userId ? post?.acceptedUserDetails?.userId : post.userId}`} style={{ textDecoration: 'none' }}> <h3 style={{
                            fontWeight: "600",
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.1px",
                            color: "#1a1919"
                        }}>            {post.userId === userId && post?.acceptedUserDetails?.userId ?
                            post?.acceptedUserDetails?.userName :
                            post?.userName}</h3></Link>
                        <p >
                            {post?.createdDate && (
                                <ReactTimeAgo date={Date.parse(post?.createdDate)} locale="en-US" />
                            )}

                        </p>
                    </div>
                    <div style={{ textAlign: "right" }}>
                        <div className="post-edit">
                            {isUserChallenger && <DropdownMenu
                                type="post-menu"
                                postId={post.feed_id || post._id}
                                userId={post?.userId}
                                // setPostObj={setPostObj}
                                challengeId={post?.challengeId}
                                post={post}
                                pageLoad={props.hprops}
                            />}

                        </div>
                    </div>
                </div>
                <p className="poll__question"
                    dangerouslySetInnerHTML={{
                        __html: post.challengeQuestion
                    }}
                />

                <section className="radio__section">

                    <div className="poll__questions">
                        {post?.userId !== userId && !post?.acceptedUserDetails?.userId && <Box style={{ color: "#0C5BA0", margin: "5px", fontWeight: "600", textAlign: 'center' }}>{post?.userName} has challenged you</Box>

                        }     <Box style={{ color: "#1A1919", margin: "5px", fontWeight: "600", textAlign: 'center' }}>Predict the winner!</Box>

                        <Box style={{ color: "#0C5BA0", margin: "5px", fontWeight: "600", textAlign: 'center', }}>
                            {isUserAcceptedChallenge ?
                                `You have accepted ${post?.userName}'s challenge`
                                : post?.acceptedUserDetails?.userId && isUserChallenger
                                    ? `${post?.acceptedUserDetails?.userName} has accepted your challenge` :
                                    post?.acceptedUserDetails?.userId && post?.acceptedUserDetails?.userId !== userId ?
                                        `${post?.acceptedUserDetails?.userName} has accepted the challenge`
                                        :
                                        post?.acceptedUserDetails?.userId === '' && post?.userId !== userId ?
                                            `${post?.userName} has challenged you` : ''}
                        </Box>

                        <Box style={{ margin: "5px", textAlign: 'center' }}>{post?.challengeDetails?.challengeQuestion}</Box>

                        <Box style={{ textAlign: 'right' }}>
                            {post?.endChallenge ?
                                <span style={{ color: "#0C5BA0", fontSize: "11px", fontWeight: 500 }}> Challenge Expired</span> :
                                <><Countdown date={endDate} renderer={renderer} /></>}  </Box>
                        <Box p={2} boxShadow={3} mb={2}>
                            <OptionBox option={post?.challengeDetails?.challengeOption1}
                                profilePhoto={post?.acceptedUserDetails?.answerPredicted === post?.challengeDetails?.challengeOption1 ?
                                    post?.acceptedUserDetails.userProfilePhoto :
                                    post?.answerPredicted === post?.challengeDetails?.challengeOption1
                                        ? post?.userProfilePhoto : ''} userId={
                                            post?.acceptedUserDetails?.answerPredicted === post?.challengeDetails?.challengeOption1 ?
                                                post?.acceptedUserDetails.userId :
                                                post?.answerPredicted === post?.challengeDetails?.challengeOption1
                                                    ? post?.userId : ''}
                            />
                        </Box>

                        <Box p={2} boxShadow={3} mt={2} mb={2}>
                            <OptionBox option={post?.challengeDetails?.challengeOption2}
                                profilePhoto={post?.acceptedUserDetails?.answerPredicted === post?.challengeDetails?.challengeOption2 ?
                                    post?.acceptedUserDetails.userProfilePhoto :
                                    post?.answerPredicted === post?.challengeDetails?.challengeOption2 ?
                                        post?.userProfilePhoto : ''}
                                userId={
                                    post?.acceptedUserDetails?.answerPredicted === post?.challengeDetails?.challengeOption2 ?
                                        post?.acceptedUserDetails.userId :
                                        post?.answerPredicted === post?.challengeDetails?.challengeOption2
                                            ? post?.userId : ''} />
                        </Box>
                        {post?.challengeDetails?.challengeOption3 && <Box p={2} boxShadow={3} mt={2} mb={2}>
                            <OptionBox option={post?.challengeDetails?.challengeOption3}
                                profilePhoto={post?.acceptedUserDetails?.answerPredicted === post?.challengeDetails?.challengeOption3 ?
                                    post?.acceptedUserDetails.userProfilePhoto :
                                    post?.answerPredicted === post?.challengeDetails?.challengeOption3 ?
                                        post?.userProfilePhoto : ''}
                                userId={
                                    post?.acceptedUserDetails?.answerPredicted === post?.challengeDetails?.challengeOption3 ?
                                        post?.acceptedUserDetails.userId :
                                        post?.answerPredicted === post?.challengeDetails?.challengeOption3
                                            ? post?.userId : ''} />

                        </Box>}
                        {post?.challengeDetails?.challengeOption4 &&
                            <Box p={2} boxShadow={3} mt={2} mb={2}>
                                <OptionBox option={post?.challengeDetails?.challengeOption4}
                                    profilePhoto={post?.acceptedUserDetails?.answerPredicted === post?.challengeDetails?.challengeOption4 ?
                                        post?.acceptedUserDetails.userProfilePhoto :
                                        post?.answerPredicted === post?.challengeDetails?.challengeOption4
                                            ? post?.userProfilePhoto : ""}
                                    userId={
                                        post?.acceptedUserDetails?.answerPredicted === post?.challengeDetails?.challengeOption4 ?
                                            post?.acceptedUserDetails.userId :
                                            post?.answerPredicted === post?.challengeDetails?.challengeOption4
                                                ? post?.userId : ''} />

                            </Box>}
                        {post?.challengeDetails?.challengeOption5 &&
                            <Box p={2} boxShadow={3} mt={2} mb={2}>
                                <OptionBox option={post?.challengeDetails?.challengeOption5}
                                    profilePhoto={post?.acceptedUserDetails?.answerPredicted === post?.challengeDetails?.challengeOption5 ?
                                        post?.acceptedUserDetails.userProfilePhoto :
                                        post?.answerPredicted === post?.challengeDetails?.challengeOption5 ?
                                            post?.userProfilePhoto : ""}
                                    userId={
                                        post?.acceptedUserDetails?.answerPredicted === post?.challengeDetails?.challengeOption5 ?
                                            post?.acceptedUserDetails.userId :
                                            post?.answerPredicted === post?.challengeDetails?.challengeOption5
                                                ? post?.userId : ''} />

                            </Box>}

                        {(isUserRejected || isChallengeExpired || post?.endChallenge || isUserChallenger ||
                            post?.acceptedUserDetails?.userId || rejected) ?
                            <div style={{ marginTop: "5px" }}></div> : <><Box mt={2} >
                                <Button style={{ width: "100%", backgroundColor: "#0C5BA0" }}
                                    onClick={handleAcceptChallengePopup}
                                    variant="contained" disabled={createClicked}
                                >Accept Challenge</Button>
                            </Box>
                                <Box mt={2}>
                                    <Button style={{ width: "100%" }} onClick={rejectChallenge}
                                        // onClick={handleClose}
                                        variant="outlined"
                                    >Reject</Button>
                                </Box></>}
                    </div>

                </section>

            </div >
            {acceptChallengePopup && <AcceptChallengeModal post={post} acceptChallenge={acceptChallenge} open={acceptChallengePopup} setAnswerPredictedByUser={setAnswerPredictedByUser}
                handleClose={handleAcceptChallengePopup} />}
        </div>
    )
}

export default FeedChallenge