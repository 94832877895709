import React, { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import Modal from "@mui/material/Modal";
import Box from "@material-ui/core/Box";
import { Divide, Search } from "lucide-react";
import { X } from "lucide-react";
import { Divider } from "@mui/material";
import { getStorageItem } from "../../../utils/sessionStorage";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
  p: 4,
  height: "700px",
};

const ParticipantModal = ({
  openParticipantModal,
  handleCloseParticipantModal,
  participants,
}) => {
  console.log(participants);
  const accessToken = getStorageItem("token");

  //   const getparticipnats = () => {
  //     if (!accessToken) return;

  //     const options = {
  //       method: "get",
  //       url: `http://localhost:3002/groupchat/getParticipants/66a0106265bc771a44cb647f`,
  //       headers: {
  //         Authorization: "Bearer " + accessToken,
  //       },
  //     };

  //     axios(options)
  //       .then(({ data }) => {
  //         setParticipants(data.participants);
  //       })
  //       .catch((error) => {
  //         if (error?.response?.status === 401) {
  //           console.log("Unauthorized:", error);
  //         } else {
  //           console.log("Error fetching stories:", error);
  //         }
  //       });
  //   };
  //   useEffect(() => {
  //     getparticipnats();
  //   }, []);

  return (
    <Modal
      open={openParticipantModal}
      onClose={handleCloseParticipantModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p style={{ fontSize: "20px" }}>Participants</p>
          <p
            style={{
              backgroundColor: "#FFF2E2",
              padding: "5px",
              borderRadius: "7px",
              cursor: "pointer",
            }}
          >
            <X onClick={handleCloseParticipantModal} />
          </p>
        </div>

        {/* <p
          style={{ color: "#8B8D97", marginTop: "14px", marginBottom: "20px" }}
        >
          Search a user to start a conversation
        </p> */}
        <div
          style={{
            display: "flex",
            padding: "10px",
            border: "0.5px solid gray",
            borderRadius: "10px",
          }}
        >
          <Search />
          <input
            type="text"
            style={{
              outline: "none",
              border: "none",
              marginLeft: "10px",
              width: "230px",
            }}
          />
        </div>
        <div style={{ marginTop: "10px" }}>
          {participants.map((user, ind) => (
            <>
              <div style={{ display: "flex", gap: "10px", padding: "10px" }}>
                <img
                  src={user.profilePhoto}
                  width={48}
                  height={48}
                  style={{
                    borderRadius: "10px",
                  }}
                />
                <p style={{ color: "#45464E" }}>
                  {user.firstName} {user.lastName}
                </p>
              </div>
              <Divider />
            </>
          ))}
        </div>
      </Box>
    </Modal>
  );
};

export default ParticipantModal;
