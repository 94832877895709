import React from 'react'
import { Grid, Typography, Box} from "@mui/material";
import images1 from "./image/images1.svg";
import images2 from "./image/images2.svg";
import images3 from "./image/images3.svg";
import line1 from "./image/line1.png";
import line2image from "./image/line2image.png";
import line3img from "./image/line3img.png";
import "./index.scss";



const ThirdBlock = () => {
  return (
    <Box className='thirdblock'>
      <Grid container spacing={2}>
        <div >
        <img className='linefirst' src={line1} alt="lineimage"></img>
        </div>
        <img className='thirdblock1' src={images1} alt='thirdrowsimg1'></img>
        
       
        <div>
        <img className='linesecond' src={line2image} alt="lineimage"></img>
        </div>
        <img  className='thirdblock2' src={images2} alt='thirdrowsimg2'></img>
        
        <div>
        <img className='linethird' src={line3img} alt="lineimage"></img>
        </div>
        <img  className='thirdblock3' src={images3} alt="thirdrowsimg3"></img>
        
      </Grid>
    </Box> 
  );
}

export default ThirdBlock
