import * as React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { useMediaQuery, Grid, Container, Link, Button, TextField, Input, Avatar } from "@mui/material";
import Typography from '@mui/material/Typography';
import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { getStorageItem } from "../../utils/sessionStorage";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import config from '../../config';
const baseURL = config.ROOTURL.prod;

const EasyApply = () => {

    const { id } = useParams();
    const mobileView = useMediaQuery("(max-width:959px)");
    const accessToken = getStorageItem("token");
    const userId = getStorageItem("user_id");
    const userName = getStorageItem("full_name");
    const profilePhotoUrl = getStorageItem('avatar');
    const [post, setPost] = useState("");
    const [error, setError] = useState(null);
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    console.log("this is id:", id);
    console.log("this is UserId:", userId);



    let handleSubmit = async (e) => {
        e.preventDefault();
        if(!email || !mobile) {
            toast.error("Field is required!");
            return;
        }
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
       
          if (!email.match(validRegex)) {
         
            toast.error("Invalid email address!");
            return;
        
          }
        if(mobile.length!=10){
                toast.error("Invalid Phone Number")
                return;
        }
        //...if we want to use file upload then call aws file  microservice api and then pass
        const option = {
            emailId: email,
            jobId:id,
            userId,
            contactNo:mobile,
            userName
        }

        let postData = {
            method: "POST",
            url: `${baseURL}/job/applyJob`,
            data: option,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
        };
        console.log("this is postData:", postData);
        axios(postData).then((response) => {
            console.log("this is post response:", response)
            if (response) {
                toast.success("Job Submited successfully", { theme: "colored" });
                setEmail("");
                setMobile("");
                console.log("Job posted successfully");
                setTimeout((e)=>{
                    window.location.href="/jobs";
                },1000)
            }
            else {
                toast.error("Some error occured", { theme: "colored" });
                console.log("error occured:");
            }
        }).catch((error) => {
            console.log(error);
        });
    };
    useEffect(() => {
        axios.get(`${baseURL}/job/getByjobId/${id}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            }
        }).then((response) => {
            setPost(response.data.data);
            console.log("this is response data:", response.data)
        }).catch(error => {
            setError(error);
        });
    }, [id,accessToken]);
    if (error) return `Error: ${error.message}`;
    if (!post) return "No post!"
    const theme = createTheme(
        {
            typography: {
                fontFamily: 'poppins, Arial',
            },
        }
    );

    return (
        // <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 8 }}>
        <ThemeProvider theme={theme}>
            <React.Fragment>
                {(post._id === id) &&
                    <Grid container spacing={mobileView ? 0 : 2} >
                        <Grid item sm={12} md={5} lg={6} columns={{ xs: 4, sm: 8, md: 8, }} sx={{ mx: 'auto' }}>
                            <Card container variant="outlined" sx={{ minWidth: 275, p: 3, mt: 5 }}>
                                <Typography sx={{ fontSize: 20, fontWeight: 'regular', color: 'black', borderBottom: '2px solid #a7a7a8' }} color="text.dark" gutterBottom>
                                    {post.jobTitle}
                                </Typography>

                                <Typography sx={{ fontSize: 20, fontWeight: 'regular', color: 'black' }} color="text.dark" gutterBottom>
                                    Contact Info
                                </Typography>
                                <Box display="inline-block" >
                                    <img src={profilePhotoUrl} alt='' width={60} height={60} sx={{ border: '1px solid red', ml: 2, width: '60px', height: '60px', }} />
                                </Box>
                                <Box display="inline-block" sx={{ ml: 2 }} >    <span sx={{ fontWeight: 'bold' }}>{userName}</span><br />
                                </Box>
                                {/* <CardContent> */}
                                <Box>
                                    <Typography variant="regular" 
                                    color="text.secondary" 
                                    sx={{ fontSize: 15, fontWeight: 'regular' }} 
                                    component="div">
                                        Email*:
                                        <TextField 
                                        id="standard-basic" 
                                        label="Email"
                                         size="small" 
                                         variant="outlined" 
                                         fullWidth sx={{ marginBottom: 1 }}
                                        onChange={e=>setEmail(e.target.value)}
                                        value={email}/>
                                    </Typography>
                                    <Typography variant="regular" color="text.secondary" sx={{ fontSize: 15, fontWeight: 'regular' }} component="div">
                                        Phone Number*:
                                        <TextField id="standard-basic" 
                                        type="number"
                                        label="Phone Number" 
                                        size="small" variant="outlined" 
                                        fullWidth sx={{ marginBottom: 1 }}
                                        onChange={(e) => setMobile(e.target.value)} 
                                        value={mobile}/>
                                    </Typography>
                                </Box>
                                <Box sx={{ textAlign: 'right', borderTop: '2px solid ' }}>
                                    <div style={{display: "flex",justifyContent: "space-between"}}>
                                    <div>
                                    <Button size="small" variant='contained' onClick={(e)=>{window.location.href="/jobs"}}>Cancel</Button>  
                                    </div>
                                    <div>
                                    <Button size="small" variant='contained' onClick={handleSubmit}>Submit</Button>  
                                    </div>
                                    </div>
                                   
                                </Box>

                            </Card >
                        </Grid>
                    </Grid>
                }
            </React.Fragment >
        </ThemeProvider>
    );
};
export default EasyApply;
