
import {  useState, useRef, useCallback } from "react";
import { Paper, Card, CardContent, Grid, Typography, Box, Modal, Button, Divider } from "@mui/material";
import { BallTriangle } from "react-loader-spinner";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import defaultAvatar from "../../assets/images/profile/default_avatar.png";
import {  getStorageItem } from "../../utils/sessionStorage";
import pollVoterListScroller from "../../components/poll/votedListUseScroller";
const accessToken = getStorageItem("token");
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: '500px',
  width: '400px',
  // left: '0px',
  // top: '0px',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  borderRadius: '12px',
  p: 4,

};
const Votepagination = (props) => {
  const [pageNum, setPageNum] = useState(1);
  let pSize = 5;
  let req = {
    method: "GET",
    url: global.config.ROOTURL.prod + `/poll/getAllVotedUserByPollIdAndOptionId/${props.optionId}/${props.pollId}/${pageNum}/${pSize}`,
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  }

  const { isLoading, error, list, hasMore } = pollVoterListScroller(pageNum, req);
  const observer = useRef();
  const lastElementRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNum((prev) => prev + 1);
          console.log("hhg")
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, hasMore]
  );



  return (

    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >

      <Box sx={style}>
        <Card style={{ overflowY: "scroll" }} sx={style}>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1} >People Who Voted</Box>
            <Box>
              <IconButton onClick={props.handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <Divider />





          {list.map((votedUsers, index) => {
            if (votedUsers.length === index + 1) {
              return (
                <Grid ref={lastElementRef}>
                  <CardContent key={index} sx={{ fontSize: 14 }} style={{ padding: "9px 0px 0px 0px" }} color="text.secondary" >

                    <div className="poll__user">
                      <img
                        src={votedUsers.profilePhoto ? votedUsers.profilePhoto : defaultAvatar}
                        alt="user"
                      />
                      <div className="user__details">
                        <h3>{votedUsers.userName}</h3>

                      </div>
                    </div>
                    <Divider />
                  </CardContent>
                </Grid>

              );
            } else {
              return (
                <CardContent ref={lastElementRef} key={index} sx={{ fontSize: 14 }} style={{ padding: "9px 0px 0px 0px" }} color="text.secondary" >

                  <div className="poll__user">
                    <img
                      src={votedUsers.profilePhoto ? votedUsers.profilePhoto : defaultAvatar}
                      alt="user"
                    />
                    <div className="user__details">
                      <h3>{votedUsers.userName}</h3>

                    </div>
                  </div>
                  <Divider />
                </CardContent>

              );
            }
          })}




          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "40px",
            }}
          >
            {isLoading && (
              <BallTriangle
                height="50"
                width="50"
                color="grey"
                ariaLabel="loading"
              />
            )}
          </div>


        </Card>
      </Box>


    </Modal>
  );
};
export default Votepagination;