import React, { useEffect } from 'react'
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import { useState } from 'react';
import { Button } from '@material-ui/core';
import { toast } from 'react-toastify';
import { getStorageItem } from '../../../utils/sessionStorage';
import axios from "axios";

export default function AppVersion() {
    const [oldversion, setOldversion] = useState(0);
    const [newVersion, setnewVersion] = useState(0);
    const [optional, setoptinal] = useState(true);
    const [mandatory, setMandatory] = useState(true);
    const accessToken = getStorageItem("token");

    const handleSubmit = () => {
        const getinfo = {
            method: "POST",
            url: global.config.ROOTURL.prod + "/auth/appVersion/createappVersion/",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
            data: {
                oldversion,
                newVersion,
                optional,
                mandatory
            },
        };
        if (oldversion || newVersion || mandatory || optional) {
            axios(getinfo)
                .then(({ data }) => {
                    toast.success('app version updated');
                    if (data) {
                        setMandatory(data.mandatory);
                        setoptinal(data.optional);
                        setOldversion(data.oldversion);
                        setnewVersion(data.newVersion)
                    }
                })
                .catch((error) => {
                    console.log(error)
                });
        }
    };

    const fetInformation = () => {
        const getinfo = {
            method: "GET",
            url: global.config.ROOTURL.prod + "/auth/appVersion/getappVersionData",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
        };
        axios(getinfo)
            .then(({ data }) => {
                if (data) {
                    setMandatory(data[0].mandatory);
                    setoptinal(data[0].optional);
                    setOldversion(data[0].oldversion);
                    setnewVersion(data[0].newVersion)
                }
            })
            .catch((error) => {
                console.log(error)
            });
    }
    useEffect(() => {
        fetInformation();
    }, [])
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            alignItems: 'flex-start',
            height: '429px'
        }}>
            <TextField id="outlined-basic" label="OldVersion" variant="outlined" value={oldversion} onChange={(e) => setOldversion(e.target.value)} />
            <TextField id="outlined-basic" label="NewVersion" variant="outlined" value={newVersion} onChange={(e) => setnewVersion(e.target.value)} />
            <label>
                <Checkbox
                    checked={optional}
                    color="primary"
                    onClick={(e) => setoptinal(!optional)}
                />
                Optional
            </label>
            <label>
                <Checkbox
                    checked={mandatory}
                    color="primary"
                    onClick={(e) => setMandatory(!mandatory)}
                />
                Mandatory
            </label>

            <Button style={{ backgroundColor: 'blue', color: 'white' }} onClick={handleSubmit}>Submit</Button>
        </div>
    )
}
