import React, { useState } from "react";
import avatar from "../../../assets/images/profile/default_avatar.png";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import ClearIcon from "@mui/icons-material/Clear";
import { Autocomplete, MenuItem } from "@mui/material";
import coverimage from "../../../assets/images/profile/camera.png";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useEffect } from "react";
import ProfileCrop from "./ProfileCrop";
import addicon from "../../../assets/images/profile/Addicon.png";
import removeicon from "../../../assets/images/profile/removeicon.png";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { getStorageItem } from "../../../utils/sessionStorage";
import defaultAvatar from "../../../assets/images/profile/default_avatar.png";

import axios from "axios";
// const options = ["Option 1", "Option 2"];

const genders = [
  {
    value: "male",
    label: "Male",
  },
  {
    value: "female",
    label: "Female",
  },
];
export default function FavoriteEditForm() {
  const [validator, setValidator] = useState(true);
  const [playersInput, setPlayersInput] = useState([1, 2]);
  const [PlayerSearchList, setPlayerSearchList] = useState([]);
  const [playerSelectValue, setPlayerSelectValue] = useState([]);
  const [inputValue, setInputValue] = useState([]);
  const [userInfo, setUserInfo] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const accessToken = getStorageItem("token");
  const localStorageUserId = getStorageItem("user_id");
  const navigate = useNavigate();
  const handleRemoveOption = (i) => {
    if (i > 0) {
      const newList = Array.from(playersInput);
      newList.pop();
      setPlayersInput(newList);
    }
  };
  const handleAddPlayers = (i) => {
    if (i < 4) {
      const newList = Array.from(playersInput);
      newList.push(i + 1);
      setPlayersInput(newList);
    }
  };

  const handleChangePlayerValue = (i, value) => {
    console.log(playerSelectValue)
    if (playerSelectValue.length - 1 < i) {
      const newList = playerSelectValue;
      newList.push(value);
      setPlayerSelectValue(newList);
    } else {
      const newList = playerSelectValue;
      newList[i] = value;
      setPlayerSelectValue(newList);
    }

  };

  const fetchUserInfo = () => {
    if (!localStorageUserId && !accessToken) return;
    const getUserInfo = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/auth/profile/",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      data: {
        userId: localStorageUserId,
      },
    };
    axios(getUserInfo)
      .then((response) => {
        console.log(response.data);
        setUserInfo({
          firstName: response.data.firstName ? response.data.firstName : "",
          lastName: response.data.lastName ? response.data.lastName : "",
          avatar: response.data.profilePhoto
            ? response.data.profilePhoto
            : defaultAvatar,
          gender: response.data.gender ? response.data.gender : "",
          country: response.data.country ? response.data.country : "",
          DOB: response.data.DOB ? response.data.DOB : "",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const checkValidation = () => {
    if (!userInfo) return;
    const { firstName, lastName, gender, location, DOB } = userInfo;
    const errors = {};
    setValidator(true);

    if (!firstName) {
      setValidator(false);
      errors.firstName = "First name is required";
    }
    if (!lastName) {
      setValidator(false);
      errors.lastName = "Last name is required";
    }
    if (!gender) {
      setValidator(false);
      errors.gender = "gender is required";
    }
    if (!location) {
      setValidator(false);
      errors.location = "country is required";
    }
    if (!DOB) {
      setValidator(false);
      errors.DOB = "DOB is required";
    }
    setFormErrors(errors);
  };

  const saveProfile = () => {

    if (!accessToken) return;
    const { firstName, aboutMe, lastName, gender, location, DOB } = userInfo;

    let profileData = new FormData();
    profileData.append("aboutMe", aboutMe);
    profileData.append("firstName", firstName);
    profileData.append("lastName", lastName);
    profileData.append("gender", gender);
    profileData.append("location", location);
    profileData.append("DOB", DOB);

    const saveProfileOptions = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/auth/update",
      data: profileData,
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + accessToken,
      },
    };
    axios(saveProfileOptions)
      .then(() => {
        navigate(`/profile/${localStorageUserId}`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const searchPlayer = (searchText) => {
    // const searchText = target?.value;
    if (!localStorageUserId) return;
    const getUserInfo = {
      method: "POST",
      url:
        global.config.ROOTURL.prod +
        `/favorite/player/search/1/${global.config.pagePerSize}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      data: {
        searchText,
      },
    };
    axios(getUserInfo)
      .then((response) => {
        console.log(response.data);
        if (Array.isArray(response.data)) {
          setPlayerSearchList(response.data);
        } else {
          setPlayerSearchList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = () => {
    if (validator) {
      saveProfile();
    }
  };

  useEffect(() => {
    fetchUserInfo();
  }, []);

  useEffect(() => {
    console.log(userInfo);
    checkValidation();
  }, [userInfo]);

  useEffect(() => {
    console.log({ playerSelectValue });
  }, [playerSelectValue]);

  return (
    <div>
      <div className="ProfileimageContainer">
        <img src={avatar} className="ProfileSetImage" alt="profile" />
        <img
          src={coverimage}
          onClick={() => navigate("/my-profile/edit")}
          className="childImageprofile"
          alt="cover"
        />
      </div>
      <div style={{ padding: "0px 32px" }}>
        {playersInput.map((data, index) => (
          <Autocomplete
            key={index}
            id="standard-basic"
            label={`Favourite Player ${index + 1}`}
            size="small"
            variant="outlined"
            fullWidth
            sx={{ marginBottom: 2 }}
            freeSolo
            value={playerSelectValue[index]}
            onChange={(event, newValue) => {
              handleChangePlayerValue(index, newValue);

              // let tmpValue = playerSelectValue;
              // tmpValue[index] = newValue;
              // setPlayerSelectValue(tmpValue);
              // setPlayerSearchList([]);
            }}
            // inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              searchPlayer(newInputValue);
              // let tmpValue = inputValue;
              // tmpValue[index] = newInputValue;
              // setInputValue(tmpValue);
            }}
            // id="controllable-states-demo"
            options={
              PlayerSearchList.length !== 0
                ? PlayerSearchList.map((player) =>
                  player.title ? player.title : ""
                )
                : [""]
            }
            // sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={`Favourite Player ${index + 1}`}
              // InputProps={{
              //   endAdornment: (
              //     <InputAdornment position="end">
              //       {index > 0 && (
              //         <ClearIcon
              //           onClick={() => {
              //             handleRemoveOption(index);
              //           }}
              //           style={{ cursor: "pointer" }}
              //         />
              //       )}
              //       {playersInput.length - 1 === index && (
              //         <FavoriteIcon
              //           style={{ cursor: "pointer" }}
              //           onClick={() => {
              //             handleAddPlayers(index);
              //           }}
              //         />
              //       )}
              //     </InputAdornment>
              //   ),
              //   }}
              />
            )}
          />
        ))}

        {/* {playersInput.map((i, index) => (
          <TextField
            key={index}
            id="standard-basic"
            label={`Favourite Player ${index + 1}`}
            size="small"
            variant="outlined"
            fullWidth
            select
            sx={{ marginBottom: 2 }}
            onChange={searchPlayer}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {index > 0 && (
                    <ClearIcon
                      onClick={() => {
                        handleRemoveOption(index);
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  )}
                  {playersInput.length - 1 === index && (
                    <FavoriteIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleAddPlayers(index);
                      }}
                    />
                  )}
                </InputAdornment>
              ),
            }}
          >
            {PlayerSearcList.length !== 0 ? (
              PlayerSearcList.map((option) => (
                <MenuItem key={option._id} value={option}>
                  {option.title}
                </MenuItem>
              ))
            ) : (
              <MenuItem value={""}>{"Please type to search"}</MenuItem>
            )}
          </TextField>
        ))} */}

        <TextField
          id="standard-basic"
          label="Favourite International Team "
          size="small"
          variant="outlined"
          fullWidth
          sx={{ marginBottom: 2 }}
        // onChange={(e) => setCompanyName(e.target.value)}
        // value={companyName}
        // onClick={(e) => { setlogocrop(false) }}
        // error={ValidationFirst && companyName === ""}
        />

        <TextField
          id="standard-basic"
          label="Favourite IPL Team "
          size="small"
          variant="outlined"
          fullWidth
          sx={{ marginBottom: 2 }}
        // onChange={(e) => setCompanyName(e.target.value)}
        // value={companyName}
        // onClick={(e) => { setlogocrop(false) }}
        // error={ValidationFirst && companyName === ""}
        />
        <TextField
          id="standard-basic"
          label="Favourite Women’s Cricketer"
          size="small"
          variant="outlined"
          fullWidth
          sx={{ marginBottom: 2 }}
        // onChange={(e) => setCompanyName(e.target.value)}
        // value={companyName}
        // onClick={(e) => { setlogocrop(false) }}
        // error={ValidationFirst && companyName === ""}
        />
        <TextField
          id="standard-basic"
          label="Favourite Cricketing Memory"
          size="small"
          variant="outlined"
          fullWidth
          sx={{ marginBottom: 2 }}
        // onChange={(e) => setCompanyName(e.target.value)}
        // value={companyName}
        // onClick={(e) => { setlogocrop(false) }}
        // error={ValidationFirst && companyName === ""}
        />
        <TextField
          id="standard-basic"
          label="Favourite Umpire"
          size="small"
          variant="outlined"
          fullWidth
          sx={{ marginBottom: 2 }}
        // onChange={(e) => setCompanyName(e.target.value)}
        // value={companyName}
        // onClick={(e) => { setlogocrop(false) }}
        // error={ValidationFirst && companyName === ""}
        />
        <TextField
          id="standard-basic"
          label="Favourite Commentator"
          size="small"
          variant="outlined"
          fullWidth
          sx={{ marginBottom: 2 }}
        // onChange={(e) => setCompanyName(e.target.value)}
        // value={companyName}
        // onClick={(e) => { setlogocrop(false) }}
        // error={ValidationFirst && companyName === ""}
        />
        <TextField
          id="standard-basic"
          label="Favourite Ground"
          size="small"
          variant="outlined"
          fullWidth
          sx={{ marginBottom: 2 }}
        // onChange={(e) => setCompanyName(e.target.value)}
        // value={companyName}
        // onClick={(e) => { setlogocrop(false) }}
        // error={ValidationFirst && companyName === ""}
        />
        <TextField
          id="standard-basic"
          label="Favourite Cricketing Skills"
          size="small"
          variant="outlined"
          fullWidth
          sx={{ marginBottom: 2 }}
        // onChange={(e) => setCompanyName(e.target.value)}
        // value={companyName}
        // onClick={(e) => { setlogocrop(false) }}
        // error={ValidationFirst && companyName === ""}
        />

        <div style={{ paddingBottom: "20px", textAlign: "center" }}>
          <Button className="Btn">Cancel</Button>
          <Button onClick={handleSubmit} className="Btn SaveBtn">
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}
