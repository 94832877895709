

import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';



const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    boxShadow: 24,
    p: 4,
};
const LoanInformaion = (props) => {
    const { openModel, handleCloseModel } = props;
    const [open, setOpen] = useState(openModel);
    const handleClose = () => {
        handleCloseModel(false);
        setOpen(false);
    };


    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <p>
                    You also have the option to borrow runs in order to redeem a deal. This deal will be reserved, and once you've earned enough runs to cover the borrowed amount, we will deliver the deal to you
                    </p>

                    <div>
                        <Button onClick={handleClose}>ok</Button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
};

export default LoanInformaion;
