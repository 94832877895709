import React from "react";
import { useEffect, useState, useRef, useCallback } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { getStorageItem } from "../../utils/sessionStorage";
import {
  Button,
  CardContent,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import Card from "@mui/material/Card";

import TextField from "@mui/material/TextField";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import "./index.scss";
// React-Toastifyimport
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CardHeader } from "@material-ui/core";
import Avatar from "@mui/material/Avatar";

const DefaultUserScroll = (props) => {
  const navigate = useNavigate();
  const accessToken = getStorageItem("token");
  const userId = getStorageItem("user_id");

  const [pageNumber, setPageNumber] = useState(1);
  const [totalPageNumber, setTotalPageNumber] = useState(0);

  const [defaultUsers, setDefaultUsers] = useState([]);
  const [newUserId, setNewUserId] = useState("");
  const [id, setId] = useState("");

  const getPreviousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const getNextPage = () => {
    if (pageNumber < totalPageNumber) {
      setPageNumber(pageNumber + 1);
    }
  };

  const addDefaultUser = () => {
    if (newUserId) {
      const data = {
        id: newUserId,
        defaultUser: true,
      };
      const options = {
        method: "POST",
        // url: global.config.ROOTURL.prod + `/carousel/getAll/${pageNumber}/6`,
        url: global.config.ROOTURL.prod + `/auth/setDefaultUser`,
        headers: {
          Authorization: "Bearer " + accessToken,
        },
        data,
      };

      axios(options)
        .then(({ data }) => {
          console.log(data);
          toast.success("Default User Added ");
          fetchData();
        })
        .catch((error) => {
          if (
            error?.response?.status === 401 ||
            error?.response?.status === 500
          ) {
            toast.error("Some error occured");
          }
        });
    }
  };

  const removeDefaultUser = () => {
    if (window.confirm("Remove default user?")) {
      if (!id) return;
      setAnchorEl(null);

      const data = {
        id: id,
        defaultUser: false,
      };
      const options = {
        method: "POST",
        // url: global.config.ROOTURL.prod + `/carousel/getAll/${pageNumber}/6`,
        url: global.config.ROOTURL.prod + `/auth/setDefaultUser`,
        headers: {
          Authorization: "Bearer " + accessToken,
        },
        data,
      };

      axios(options)
        .then(({ data }) => {
          console.log(data);
          toast.success("Default User Removed");
          fetchData();
        })
        .catch((error) => {
          if (
            error?.response?.status === 401 ||
            error?.response?.status === 500
          ) {
            toast.error("Some error occured");
          }
        });
    }
  };

  // const redirectUser = (_id) => {
  //   // Check if the user is already registered

  //   // Redirects User
  //   navigate(`/event-register/${_id}`);
  // };

  // Fetching length of data
  useEffect(() => {
    // Fetching all events
    if(!accessToken) return;
    const options = {
      method: "get",
      // url: global.config.ROOTURL.prod + `/carousel/getAll/${pageNumber}/6`,
      url: global.config.ROOTURL.prod + `/auth/getAlldefaultUsers`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };

    axios(options)
      .then(({ data }) => {
        console.log(data);
        setTotalPageNumber(Math.ceil(data.length / 6));
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          toast.error("Some error occured");
        }
      });
  }, []);

  // Ferching Page Data

  const fetchData = () => {
    if(!accessToken) return;
    const options = {
      method: "get",
      // url: global.config.ROOTURL.prod + `/carousel/getAll/${pageNumber}/6`,
      url: global.config.ROOTURL.prod + `/auth/getAlldefaultUsers`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };

    axios(options)
      .then(({ data }) => {
        console.log(data);
        setDefaultUsers([...data]);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          toast.error("Some error occured");
        }
      });
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const MoreOptions = ["Delete"];

  const handleMoreClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);

  const handleMoreClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    fetchData();
  }, [pageNumber]);

  return (
    <div>
      <Grid
        className="pagination"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          gap: "1rem",
        }}
      >
        {/* <Button variant="contained" onClick={getPreviousPage}>
          Prev
        </Button>
        <Typography variant="p">
          {pageNumber} of {totalPageNumber}
        </Typography> */}
        <TextField
          onChange={(e) => {
            setNewUserId(e.target.value);
          }}
          id="outlined-basic"
          label="User Id"
          variant="outlined"
        />

        <Button variant="contained" onClick={addDefaultUser}>
          Add
        </Button>
      </Grid>
      <div className="card-wrapper">
        {defaultUsers.map((user) => {
          return (
            <Card
              display="flex"
              className="card-main-default-user"
              key={user._id}
              onClick={() => {
                // navigate(`/admin/deal/${users._id}`);
                console.log(user);
                setId(user._id);
              }}
            >
              <CardHeader
                avatar={<Avatar alt={user.fullName} src={user.profilePhoto} />}
                action={
                  <IconButton
                    aria-label="more"
                    onClick={handleMoreClick}
                    aria-haspopup="true"
                    aria-controls="long-menu"
                  >
                    <MoreVertIcon />
                  </IconButton>
                }
                title={user.fullName}
                subheader={user.email}
              ></CardHeader>
              <CardContent className="content-default-user">
                <Menu
                  anchorEl={anchorEl}
                  keepMounted
                  onClose={handleMoreClose}
                  open={open}
                >
                  <MenuItem
                    p={2}
                    key={"delete"}
                    onClick={() => {
                      removeDefaultUser();
                    }}
                  >
                    <ListItemIcon>
                      <DeleteForeverIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Remove User</ListItemText>
                  </MenuItem>
                </Menu>
              </CardContent>
              {/* <DeleteForeverIcon /> */}
            </Card>
          );
        })}
      </div>
      <ToastContainer />
    </div>
  );
};

export default DefaultUserScroll;
