import React, { useRef, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import defaultAvatar from "../../../assets/images/profile/default_avatar.png";
import { useMediaQuery } from "@material-ui/core";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 881,
  //   bgcolor: 'background.paper',
  p: 4,
  maxWidth: "100%",
  maxHeight: "100%",
};

export default function ProfileCrop({ open, handleclose, handlecropImage, imageforcrop }) {
  const mobileView = useMediaQuery("(max-width:900px)");
  const [openmodal, setOpen] = useState(open);
  const [image, setImage] = useState(null);
  // console.log(mobileView);
  const [crop, setCrop] = useState({
    unit: "px",
    width: 150,
    height: 150,
    aspect: 1,
  });
  const [imageSrc, setImageSrc] = useState(null);
  const [cropimage, setcropimage] = useState(null);
  const [cropimageurl, setcropimageurl] = useState(null);
  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleImageSelect = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImageSrc(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const convertImageUrlToDataUrl = (imageUrl) => {
    return fetch(imageUrl)
      .then(response => response.blob())
      .then(blob => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      });
  }


  const handleImageEdit = () => {
    if (imageforcrop) {
      convertImageUrlToDataUrl(imageforcrop)
        .then((editimage) => {
          setImageSrc(editimage);
          setImage(editimage)
        })
    }
  }



  function dataURLtoFile(dataurl, filename) {
    const arr = dataurl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const handleClose = () => {
    setOpen(false);
    handleclose();
  };
  const handleSaveImage = () => {
    handlecropImage(cropimage, cropimageurl);
    handleClose();
  };

  const cropImageNow = async () => {
    
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    const base64Image = canvas.toDataURL("image/jpg");
    const convetedimage = dataURLtoFile(base64Image, "cropped-image.jpg");
    console.log(convetedimage);
    console.log({ base64Image });
    setcropimage(convetedimage);
    setcropimageurl(base64Image);
  };
  const handleImageLoaded = (cropimage) => {
   
    setImage(cropimage)
  }

  return (
    <div>
      <Modal
        open={openmodal}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{ textAlign: "center" }}>
            {imageSrc && (
              <ReactCrop
                circularCrop
                src={imageSrc}
                onImageLoaded={handleImageLoaded}
                crop={crop}
                onChange={(e) => setCrop(e)}
                onComplete={cropImageNow}
                className="image-crop"
              />


            )}
            <div>
              <div style={{ paddingBottom: "20px", paddingTop: "30px" }}>
                {!imageSrc && (
                  <div>
                    <div>
                      {imageforcrop
                        ?
                        <img src={imageforcrop} className="defaultimage" />
                        :
                        <img src={defaultAvatar} className="defaultimage" />
                      }

                    </div>
                    <input
                      type="file"
                      accept="image/*"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleImageSelect}
                    />
                    <Button className=" EditBtn" onClick={handleImageEdit}>
                      Edit
                    </Button>
                  </div>
                )}

                {imageSrc ? (
                  <Button className=" SaveBtn123" onClick={handleSaveImage}>
                    Save
                  </Button>
                ) : (
                  <Button className=" SaveBtn123" onClick={handleClick} >
                    Add
                  </Button>
                )}

                <Button className="Btn123 cancelBtn" onClick={handleClose}>
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}