import React from 'react'
import { Grid, Box, Typography} from "@mui/material";
import defaultAvatar from "../../../assets/images/profile/default_avatar.png";
import StarIcon from "../../../assets/images/icons/star.svg";
import UserOne from "../../../assets/images/user1.jpeg"
import UserTwo from "../../../assets/images/user2.jpeg"
import UserThree from "../../../assets/images/user3.jpeg"
import "./index.scss";


const Testimonny = () => {
    const users = [
      {
        name: "Somu Pradhan",
        content: `“ Who doesn't like saving money or earning free gift cards?? Love this app! ”`,
        avatar: UserOne,
      },
      {
        name: "Ansh Desai",
        content: `“ Who doesn't like saving money or earning free gift cards?? Love this app! ”`,
        avatar: UserTwo
      },
      {
        name: "Pranay Karwa",
        content: `“ Who doesn't like saving money or earning free gift cards?? Love this app! ”`,
        avatar: UserThree
      },
    ];
  
    const UserCard = (props) => {
      const { content, avatar, name} = props.user;
  
      return (
        <div className="user-card">
          {/* </div> */}
          {/* <Box>
            <Grid container spacing={1} sx={{ justifyContent: 'center' }}>
              {Array.from(Array(5).keys()).map(() => (
                <Grid item xs={2}>
                  <img src={StarIcon} alt=""/>
                </Grid>
              ))}
            </Grid>
          </Box> */}
          <div className="user-comment">{content ? content : ""}</div>
           {/* <div className="avatar"> */}
           <img
            className="avatar-image"
            src={avatar ? avatar : defaultAvatar}
            alt=""
          />
          <div className="user-name">{name ? name : ""}</div>
        </div>
      );
    };

  

  return (
    <Box className='container_block'>
        <Grid >
        <div className='circle_design'>
          </div>
         <div className='testinomial_text'>  What Our Users say about us</div>
      <Grid container spacing={2}>
          {users.map((user, index) => {
            return (
              <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
                <UserCard user={user} />
              </Grid>
            );
          })}
        </Grid>
        </Grid>
    </Box>
  )
}

export default Testimonny;
