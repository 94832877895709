import moment from "moment/moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./MatchItem.css";
import axios from "axios";
import { getAuthToken } from "../../utils/auth";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import PredictionDelete from './predictionDeleteModal'
import ToggleActive from './toggleActiveModal'
import DistributeReward from './DistributeReward'
import PredictionEdit from './create/edit-prediction'
const MatchItem = (props) => {
  const [data, setData] = useState(props.data)
  const [openDeletePredictionModal, setOpenDeletePredictionModal] = useState(false)
  const [openEditModal, setOpenEditModal] = useState(false)
  const [openActiveModal, setOpenActiveModal] = useState(false)
  const [openRewardModal, setOpenRewardModal] = useState(false)
  const accessToken = getAuthToken();
  const navigate = useNavigate();
  const handleOpenDeletePredictionModal = () => setOpenDeletePredictionModal(true);
  const handleOpenActiveModal = () => setOpenActiveModal(true);
  const handleOpenRewardModal = () => setOpenRewardModal(true);
  const handleCloseDeletePredictionModal = () => setOpenDeletePredictionModal(false);
  const handleCloseActiveModal = () => setOpenActiveModal(false);
  const handleCloseRewardModal = () => setOpenRewardModal(false);
  const handleOpenEditModal = () => setOpenEditModal(true)
  const handleCloseEditModal = () => setOpenEditModal(false)

  const toggleActive = (id) => {
    if (!accessToken && !id) return
    var toggle = {
      method: 'POST',
      url: global.config.ROOTURL.prod + `/contest/fantasy/toggleActive/${id}`,
      headers: {
        'Authorization': 'Bearer ' + accessToken,
      },

    };
    axios(toggle)
      .then(response => {
        if (response) {
          toast.success("MAtch deactivated ")
          handleCloseActiveModal()
          props.getAllMatches()

        }
      })
      .catch(error => {
        toast.error("error occured ")
        console.log(error)
      })

  }

  const distributeReward = (id) => {
    if (!accessToken && !id) return
    var toggle = {
      method: 'POST',
      url: global.config.ROOTURL.prod + `/contest/fantasy/updateRewardDistributedStatus/${id}`,
      headers: {
        'Authorization': 'Bearer ' + accessToken,
      },

    };
    axios(toggle)
      .then(response => {
        if (response) {
          toast.success("Rewards distributed ");
          handleCloseRewardModal();
          props.getAllMatches();

        }
      })
      .catch(error => {
        toast.error("error occured ")
        console.log(error)
      })

  }

  const deletePrediction = (id) => {
    if (!accessToken && !id) return
    var deleteDetails = {
      method: 'POST',
      url: global.config.ROOTURL.prod + `/contest/fantasy/deleteMatch/${id}`,
      headers: {
        'Authorization': 'Bearer ' + accessToken,
      },

    };
    axios(deleteDetails)
      .then(response => {
        if (response) {
          toast.success("MAtch deleted ")
          handleCloseDeletePredictionModal()
          props.getAllMatches()

        }
      })
      .catch(error => {
        toast.error("error occured ")
        console.log(error)
      })

  }
  console.log("daat", data)
  

  return (
    <div className="contest_admin_predction-wrapper">
      <div className="contest_admin_prediction-item">
        <div className="contest_admin_prediction-header">
          <div className="contest_admin_prediction-item-name">
            {data?.matchName}
          </div>
          <div>  <FontAwesomeIcon icon={faPenToSquare} style={
            { margin: "5px" }} onClick={handleOpenEditModal} />
            <img
              src="/svg/admin/delete.svg"
              alt="delete"
              className="contest_admin_prediction-item-delete" onClick={handleOpenDeletePredictionModal}
            /></div>
        </div>
        <div className="contest-admin-create-wrapper-form" style={{marginTop:"10px"}}>
          <div className="contest-admin-create-wrapper-form1" style={{marginBottom:"-4px"}}>
            <p className="contest_admin_prediction-sub-header--runs">
              Runs required- {data?.runsRequired}
            </p>
            <p className="contest_admin_prediction-sub-header--runs">
              Live- {data?.live==true?"True":"False"}
            </p>
            <p className="contest_admin_prediction-sub-header--runs">
            Rewards distributed- {data?.rewardsDistributed==true?"True":"False"}
            </p>
          </div>

          <div className="contest-admin-create-wrapper-form1" style={{marginTop:"-4px"}}>
            <p className="contest_admin_prediction-sub-header--runs">
            Rewards Given- {data?.rewardsGiven==true?"True":"False"}
            </p>
            <p className="contest_admin_prediction-sub-header--runs">
              Team1- {data?.team1}
            </p>
            <p className="contest_admin_prediction-sub-header--runs">
              Team2- {data?.team2}
            </p>
          </div>
          </div>
      </div>
      <div
        onClick={() => {
          navigate(`/admin/fantasy/add-players/${data._id}`, {
            state: { id: data._id, matchName: data.matchName},
          });
        }}
        className="contest_admin_prediction-sub-schedule-edit-button"
      >
        <p className="contest_admin_prediction-sub-schedule-button--text">
          Add Players
        </p>
      </div>
      <div
        onClick={() => {
          navigate(`/admin/fantasy/view-players/${data._id}`, {
            state: { id: data._id, matchName: data.matchName},
          });
        }}
        className="contest_admin_prediction-sub-schedule-edit-button"
      >
        <p className="contest_admin_prediction-sub-schedule-button--text">
          View Players
        </p>
      </div>
      <div
        onClick={handleOpenActiveModal}
        className="contest_admin_prediction-sub-schedule-edit-button"
      >
        <p className="contest_admin_prediction-sub-schedule-button--text">
          Toggle Live
        </p>
      </div>
      <div
        onClick={handleOpenRewardModal}
        className="contest_admin_prediction-sub-schedule-edit-button"
      >
        <p className="contest_admin_prediction-sub-schedule-button--text">
          Distribute Rewards
        </p>
      </div>
      
      {openDeletePredictionModal && <PredictionDelete handleOpen={handleOpenDeletePredictionModal}
        handleClose={handleCloseDeletePredictionModal} open={openDeletePredictionModal} predictionId={data?._id}
        handleDelete={deletePrediction} />

      }
      {openActiveModal && <ToggleActive handleOpen={handleOpenActiveModal}
        handleClose={handleCloseActiveModal} open={openActiveModal} predictionId={data?._id}
        handleDelete={toggleActive} />

      }
      {openRewardModal && <DistributeReward handleOpen={handleOpenRewardModal}
        handleClose={handleCloseRewardModal} open={openRewardModal} predictionId={data?._id}
        handleDelete={distributeReward} />

      }
      {openEditModal && <PredictionEdit handleOpen={handleOpenEditModal}
        handleClose={handleCloseEditModal} open={openEditModal} matchData={data} setData={setData}
      />}
    </div>

  );
};

export default MatchItem;
