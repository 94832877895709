import React, { useCallback, useEffect, useState } from "react";
import "./index.scss";
import { Box, useMediaQuery } from "@material-ui/core";
import PlayNowPopup from "./PredictionWeb/predictionWebPlayNow";
import PredictinResultPopup from "./PredictionWeb/predictionwebResult";
import PredictionReviewPopup from "./PredictionWeb/predictionwebReview";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { getStorageItem } from "../../utils/sessionStorage";
import PredictionData from "./PredictionData";
import { useDispatch } from "react-redux";
import { PredictionAction } from "../../redux/reducers/prediction";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import { BallTriangle } from "react-loader-spinner";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLessThan } from "@fortawesome/free-solid-svg-icons";
import { faGreaterThan } from "@fortawesome/free-solid-svg-icons";
export default function UpcomingPrediction() {
  const [openPlayNow, setOpenPlayNow] = useState(false);
  const [openResult, setOpenResult] = useState(false);
  const [openReview, setOpenReview] = useState(false);

  const [loading, setLoading] = useState(false);
  const [showNext, setShowNext] = useState(true);
  const [shouldLoadPage, setShouldLoadPage] = useState(false);
  const [items, setItems] = useState([]);
  const [current, setCurrent] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);

  const accessToken = getStorageItem("token");
  const mobileView = useMediaQuery("(max-width:769px)");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const scrollRef = React.useRef(null);

  //handle open in laptop
  const handleOpenSelected = (data, id) => {
    console.log("al pred list handleOpenSelected")
    dispatch(PredictionAction.setPredictionId({ payload: id }));
    if (data === "Play Now") {
      mobileView ? navigate(`/playNow`) : setOpenPlayNow(true);
    }
    if (data === "ALREADY PLAYED") {
      mobileView ? navigate(`/review`) : setOpenReview(true);
    }
    if (data === "Show Results") {
      mobileView ? navigate(`/results`) : setOpenResult(true);
    }
  };

  // handle close popup in laptop
  const handleClosePlayNow = (data) => setOpenPlayNow(data);
  const handleCloseResult = (data) => setOpenResult(data);
  const handleCloseReview = (data) => setOpenReview(data);
  const fetchMoreData = async () => {
    if (!accessToken) return;
    // Fetch additional data
    const req = {
      method: "GET",
      url:
        global.config.ROOTURL.prod +
        `/contest/prediction/getAllPredictions/${page}/3`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };

    axios(req)
      .then((response) => {

        if (response.data.length === 0) {
          // No more data to fetch
          setHasMore(false);

          if (!mobileView) {
            setShowNext(false)
            setLoading(false)
            setItems([])
          }
          setShouldLoadPage(true);
        }
        else if (response.data.length < 3) {
          if (!mobileView) {
            setShowNext(false)
            setHasMore(false);
            setLoading(false)
            setItems(response.data);
            console.log("ent")
          }
          else {

            setItems((prevItems) => prevItems.concat(response.data));
            setPage(prevpage => prevpage + 1)
          }

          setShouldLoadPage(true);
        } else {


          // Append new data to existing items
          if (!mobileView) {
            setLoading(false)
            setItems(response.data);
            setShowNext(true)
            setShouldLoadPage(true);
          }
          else {
            console.log("nbnbv")
            setPage(prevpage => prevpage + 1)
            if (page === 1 && items?.length) {
              const updatedItems = [...items, ...response.data]; // Assuming response.data is the new item
              const uniqueObjects = Array.from(new Set(updatedItems.map(obj => obj._id))).map(id => {
                return updatedItems.find(obj => obj._id === id);
              });
              setItems([])
              setItems(uniqueObjects);
            }
            else {


              setItems((prevItems) => prevItems.concat(response.data));

            }
            setShouldLoadPage(true);
          }
        }


      })
      .catch((error) => console.log(error));
  };
  const handleNext = () => {
    setLoading(true)
    setPage(prevPage => prevPage + 1);
  };

  const handleBefore = () => {
    if (page > 1) {
      setLoading(true)
      setPage(prevPages => prevPages - 1);
      setShowNext(true);
    }
  };


  useEffect(() => {
    console.log("mobileView", mobileView)
    if (mobileView) {
      window.scrollTo(0, 0);
      // setItems([]);
      fetchMoreData();
    };
    return () => {
      setOpenPlayNow(false);
      setOpenResult(false);
      setOpenReview(false);
      setShouldLoadPage(false);
      // setItems([]);
      setHasMore(true);
      setPage(1);
    }
  }, []);
  useEffect(() => {

    if (!mobileView) {
      fetchMoreData();
    }

  }, [page]);

  return (
    <>

      {shouldLoadPage && (
        <>
          {!mobileView && <h4 style={{ color: "#0C5BA0", fontWeight: "600", fontSize: "22px", marginTop: "10px" }}>
            Live Predictions
          </h4>}
          <div className={items?.length ? "predictionlist" : "predictionlist_none"}>
            {mobileView ? (
              <InfiniteScroll
                dataLength={items.length}
                next={fetchMoreData}
                hasMore={hasMore}
                loader={
                  <BallTriangle
                    height="100"
                    width="100"
                    color="grey"
                    ariaLabel="loading"
                  />
                }
              >
                {items?.length > 1 ?
                  items.map((i, index) => (
                    <div key={index}>
                      <PredictionData
                        data={i}
                        handleOpenSelcted={handleOpenSelected}
                        key={index}
                      />
                    </div>
                  )) :
                  items?.length === 0 && <div>No Live Prediction</div>}
              </InfiniteScroll>
            ) : (
              <> <div style={{ display: "flex", justifyContent: "space-between" }}>

                {page > 1 ?
                  <div className="before-arrow" onClick={() => handleBefore()}><FontAwesomeIcon icon={faLessThan} /></div>
                  : <div></div>}
                <div className="horizontal-scroll" ref={scrollRef}>

                  {
                    loading ?
                      <BallTriangle
                        height="100"
                        width="100"
                        color="grey"
                        ariaLabel="loading"
                      /> : items?.length ?
                        items?.map((i, index) => (

                          < PredictionData
                            data={i}
                            handleOpenSelcted={handleOpenSelected}
                            key={index}
                          />
                        )) :
                        page > 1 && items?.length === 0 ? <div style={{
                          height: "132px",
                          display: "flex",
                          alignItems: "center",
                          margin: "28px 0px 0px 0px",
                          justifyContent: "space-between"
                        }}>Go Back</div> : <></>}
                </div>
                {showNext ?
                  <div className="nxt-arrow" onClick={() => handleNext()}>
                    <FontAwesomeIcon icon={faGreaterThan} />
                  </div> :
                  <div></div>}
              </div>
                {page === 1 && items?.length === 0 ?
                  <div style={{
                    height: "132px",
                    display: "flex",
                    alignItems: "center",
                    margin: "28px 0px 0px 0px",
                    justifyContent: "center"
                  }}>No Live Prediction</div> : <></>
                }</>
            )}

            {openPlayNow && (
              <PlayNowPopup
                openPlayNow={openPlayNow}
                handleClosePlayNow={handleClosePlayNow}
              />
            )}
            {openResult && (
              <PredictinResultPopup
                openResult={openResult}
                handleCloseResult={handleCloseResult}
              />
            )}
            {openReview && (
              <PredictionReviewPopup
                openReview={openReview}
                handleCloseReview={handleCloseReview}
              />
            )}
          </div></>
      )}
    </>
  );
}
