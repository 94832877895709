import React from "react";
import Styled from "@emotion/styled";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import SignUpForm from './siginup-form.component';
import { InputAdornment, Button, Box } from "@mui/material";
import SignInSignUpPageHOC from '../signin-signup-page-hoc'
import { useNavigate } from "react-router-dom";
import { useState } from "react";



const RegisterHere = Styled.div(`
    width: 80%;
    max-width: 300px;
    margin: 38px auto 5px auto;
    padding: 10px 20px;
    border-radius: 20px;
    // background: white;
    font-size: 15px;
    color: black;
    text-align: center;
`);
const Button1 = Styled.div(
    `    width: 365px;
    height: 38px;
    font-size: 18px;
    border-radius: 20px;
    background: linear-gradient(270deg,#c8256c 3.05%,#f33130);
    color: white;
    margin-left: -54px;
    margin-top: 5px;
    padding:7px 6px;
    cursor: pointer;`
);


const InfoLabel = Styled.p(({ align }) => `
    font-size: 15px;
    color: black;
    margin: 0;
    margin-bottom: 15px;
    text-align:${align || 'left'}
`);


export default function SignUp(props) {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const navigate = useNavigate();
    const [openHeade, setHeder] = useState(false);
    const handleOpenHeader = (data) => {
        setHeder(data);
    }
    const handleLogin = () => {
        navigate("/login")
    }
    return (

        <SignInSignUpPageHOC matches={matches} props={props}>
            <>
                <SignUpForm breakPoint={matches} props={props}  />



            </>
        </SignInSignUpPageHOC>

    );
}

