import { Grid } from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import Loader from "../../commons/components/Loader";
import UserCard from "../followers/followers";

const InfiniteScroll = ({ fetchData, renderItem, itemKey, searchText }) => {
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  const observer = useRef();

  // useEffect(() => {
  //   setItems([]);
  //   setHasMore(true)
  // }, [searchText]);

  useEffect(() => {
    setItems([]);
    setPageNumber(1);
    if (searchText) {
      setHasMore(true);
    } else {
      setHasMore(false);
    }
  }, [searchText]);

  useEffect(() => {
    let currentObserver = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore && searchText) {
        fetchData(pageNumber, global.config.pagePerSize).then((newData) => {
          setPageNumber(pageNumber + 1);
          // console.log({ newData });
          setItems([...items, ...newData]);
          if (newData.length === 0) {
            setHasMore(false);
          }
        });
      }
    });
    if (observer.current) {
      observer.current.disconnect();
    }
    observer.current = currentObserver;
    let target = document.querySelector(".scrollable-content");
    if (target) {
      observer.current.observe(target);
    }
    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [fetchData, hasMore, items]);

  return (
    <div className="scrollable-content">
      <Grid container spacing={{ xs: 0, md: 2 }} className="search_page">
        {items.map((follower, index) => (
          <Grid item xs={12} sm={12} md={6} lg={6} key={index}>
            <UserCard following={follower} />
          </Grid>
        ))}
      </Grid>
      {<Loader isLoading={hasMore} />}
      {/* {hasMore && <div>Loading...</div>} */}
    </div>
  );
};

export default InfiniteScroll;

