import React from 'react'
import playstore from '../../assets/images/landigpage2/playstore.png'
import playsore2 from '../../assets/images/Events/playstore.png'
import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
export default function Footer() {
  const mobileView = useMediaQuery("(max-width:600px)");
  const navigate=  useNavigate()
  return (
    <Box sx={{  backgroundColor: {xs:'#F8F8F8' ,md:'#F0F0F0'} }}>
        <div className='lafooter'>
          <a href='https://play.google.com/store/apps/details?id=com.champ.huntapp' target="_blank">
           
                 <img src={playstore}/>
            
              </a>
            <Box sx={{paddingTop:{xs:'10px',md:'40px'}}}>
                <p>&#169; Copyright 2023 - CHAMPHUNT LLC</p>
            </Box>
        </div>
      
    </Box>
  )
}
