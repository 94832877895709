import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import pollimage from "../../assets/images/rewards/bars.png"
import tossball from "../../assets/images/rewards/tossball.png"
import axios from "axios";
import { getAuthToken } from '../../utils/auth';

// 
export default function PredictionData({ data, handleOpenSelcted }) {

    const accessToken = getAuthToken();
    const userId = localStorage.getItem('user_id');
    const [timeRemaining, setTimeRemaining] = useState(null);
    const [whichTimer, setWhichTimer] = useState("playnow");
    const [Status, setStatus] = useState("");
    const [updatebrowser, setupdateBrowser] = useState(false);
    const {
        predictionName,
        endDate,
        endTime,
        rewardRun,
        redeemRun,
        rewardsDistributed,
        startDate,
        startTime,
        _id,
        startDateAndTimeDiff,
        endDateAndTimeDiff

    } = data




    // this api work for check user status 


    const checkStatus = async () => {
        if (userId && _id) {

            const checkuserStatus = {
                method: "GET",
                url:
                    global.config.ROOTURL.prod +
                    `/contest/userPrediction/checkContestPlayedStatusByUserIdAndPredictionId/${userId}/${_id}`,
                headers: {
                    Authorization: "Bearer " + accessToken,
                },

            };
            axios(checkuserStatus)
                .then((response) => {
                    // console.log("status", response)
                    if (response.data === 'PLAYNOW') {

                        setStatus("Play Now")
                    }

                    if (response.data === 'ALREADYPLAYED') {
                        setStatus('ALREADY PLAYED')
                    }
                    if (response.data === 'SHOWRESULTS') {
                        setStatus('Show Results')
                    }
                    if (response.data === "EXPIRED") {
                        setStatus("Contest Expired")
                    }

                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    // this api work for user Elegibility 

    const checkeligibility = async (msg, predid, run) => {
        if (msg && predid && run) {

            const checkrun = {
                method: "POST",
                url:
                    global.config.ROOTURL.prod +
                    `/contest/userPrediction/checkForPredictionEligibility`,
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                data: {
                    entryRun: run
                },
            };
            axios(checkrun)
                .then((response) => {
                    if (response.data === 'Eligible to play') {

                        createuserPrediction(predid, run);
                        console.log("msg predid", msg, predid)
                        handleOpenSelcted(msg, predid)
                    } else {
                        toast.error(response.data)
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }


    const createuserPrediction = async (predid, run) => {
        if (predid && run) {

            const checkrun = {
                method: "POST",
                url:
                    global.config.ROOTURL.prod +
                    `/contest/userPrediction/createUserPrediction`,
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                data: {
                    deductedEntryRun: run,
                    predictionId: predid
                },
            };
            axios(checkrun)
                .then((response) => {

                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }




    // this function pass data to the parent camponent 
    const handleOpenSelcted1 = (data, id, run) => {
        if (Status === "Play Now") {
            if (id && data && run) {
                checkeligibility(data, id, run);
            }
        } else {
            handleOpenSelcted(data, id)
        }


    }



    const timer = (date) => {
        // const targetDate = new Date(date).getTime();

        const intervalId = setInterval(() => {
            // const now = new Date().getTime();
            // const date = targetDate - now;

            if (date < 0) {
                clearInterval(intervalId);
                setupdateBrowser(true)
                setTimeRemaining("Contest Ended");
            } else {
                date = date - 1000;
                const days = Math.floor(date / (1000 * 60 * 60 * 24));
                const hours = Math.floor(
                    (date % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
                );
                const minutes = Math.floor((date % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((date % (1000 * 60)) / 1000);
                let maindate = days;
                let mainhours = hours;
                let mainminte = minutes;
                let mainsecound = seconds;


                const countdownStr = `${maindate}:${mainhours}:${mainminte}:${mainsecound}`;


                setTimeRemaining(countdownStr);

            }


        }, 1000);
    }


    useEffect(() => {
        if (_id && userId) {
            checkStatus();
        }

        if (startDateAndTimeDiff > 0) {
            setWhichTimer("notstarted")
            timer(startDateAndTimeDiff)
        } else {
            setWhichTimer("playnow")
            timer(endDateAndTimeDiff)
        }
        return (() => {
            setTimeRemaining("null");
            setupdateBrowser(false);
            setStatus(false)
        })
    }, [])

    return (
        <div key={_id} className="predictionContainer">
            <img src={pollimage} className="PollImageinPrediction" />

            <div className="pridicictioncontent">
                <div>
                    {

                        whichTimer === 'playnow' ?
                            <div
                                className="fon10inPrediction paddingPridiction"
                                style={{ color: "#0C5BA0", textAlign: "end" }}
                            >
                                {timeRemaining === "Contest Ended" ? `${timeRemaining}` : `Ends In ${timeRemaining}`}
                            </div>
                            :
                            <>
                                {timeRemaining === "Contest Ended" ?
                                    < div
                                        className="fon10inPrediction paddingPridiction"
                                        style={{ color: "#0C5BA0", textAlign: "end", visibility: "hidden" }}
                                    >

                                        {timeRemaining}
                                    </div>
                                    :
                                    < div
                                        className="fon10inPrediction paddingPridiction"
                                        style={{ color: "#0C5BA0", textAlign: "end", visibility: "hidden" }}
                                    >

                                        <span>End In</span>
                                        <span>{timeRemaining}</span>
                                    </div>


                                }
                            </>
                    }
                </div>
                <div>
                    <p className="predicationName paddingPridiction">
                        {predictionName}
                    </p>
                </div>
                <div style={{ display: "flex" }} className="paddingPridiction">
                    <img src={tossball} alt="prediction" />
                    <p className="fon10inPrediction " style={{ paddingLeft: '5px' }}>{redeemRun} Runs to Play</p>
                </div>
                <div className="paddingPridiction">
                    {whichTimer === 'notstarted'
                        ?
                        <Button
                            className="PrdictionButtondisable"
                            disabled={true}

                        >
                            <span className='span1timer'> Starts in </span> <span className='span2timer'>{timeRemaining} </span>
                        </Button>
                        :
                        Status === "Contest Expired" ?
                            <Button
                                className="PrdictionButtondisable"
                                disabled={true}

                            >
                                {Status}
                            </Button>
                            :
                            <Button
                                className="PrdictionButton"

                                onClick={() => { handleOpenSelcted1(Status, _id, redeemRun) }}
                            >
                                {Status}

                            </Button>
                    }
                </div>
            </div>
        </div >
    )
}
