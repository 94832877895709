import { Box, Typography } from "@material-ui/core";
import React from "react";
import DefaultRunScroll from "../../components/default-run/defaultRunScroll"


const ManageDefaultRun = () => {
  return (
    <Box>
      <Typography variant="h4">Default Run</Typography>
      <Box marginTop={2}> <DefaultRunScroll/></Box>
    </Box>
  );
};

export default ManageDefaultRun;
