import React from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import gift from '../../assets/images/Events/gift.png'
import Bat from '../../assets/images/Events/bat.png'
import Ball from '../../assets/images/Events/ball.png'
import pad from '../../assets/images/Events/Padd.png'
export default function Third() {
    return (
        <Box sx={{ backgroundColor: { xs: 'none', md: '#F0F0F0' } }}>
            <Box className='paddingHeader2' >
                <Grid container>
                    <Grid item xs={12} md={6} >
                        <div><img src={gift} className="MoneyHead3" /> </div>
                    </Grid>
                    <Grid item xs={12} md={6} className="headerLet2">
                        <Box sx={{ paddingTop: { xs: 0, md: '43px' } }}>
                            <div style={{ display: 'flex' }}>
                                <p className='MoneyHead'>Runs = Rewards!</p>
                                {/* <img src={Ball} className='BIgBall' /> */}
                            </div>
                            <div>

                                <p className='MoneyHead2'>All the runs you have scored can be redeemed in our very own deals section. Some amazing deals out there for the fans and deals get restocked every weekend.</p>
                            </div>
                            <Box sx={{ textAlign: 'center', marginTop: '-35px', marginRight: '208px' ,visibility:{xs:'hidden',md:'visible'}}}>
                                {/* <img src={Ball} className='giftBall' />
                                <img src={Bat} className="giftBat" /> */}
                            </Box>
                        </Box>

                    </Grid>


                </Grid>
            </Box>
        </Box>
    )
}
