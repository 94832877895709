import { Box ,Typography} from "@material-ui/core";
import React from "react";
// import EventScroll from "../../components/find-all-events/eventScroll";
import AppliedUserList from "../../components/all-jobs-admin/applied-user-list-by-jobId"

const FindOneJobAdmin = () => {
  return (
    <Box>
        <Typography variant="h4">Applied User List</Typography>
      <Box marginTop={2}> <AppliedUserList/></Box>
    </Box>
  );
};

export default FindOneJobAdmin;