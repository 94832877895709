import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { getAuthToken } from "../../utils/auth";
import { clearStorage } from "../../utils/sessionStorage";
import SortIcon from '@mui/icons-material/Sort';
import { Button, useMediaQuery } from "@mui/material";
import SingleGift from "./SingleGift";
import FilterModal from './FilterModal'
import CustomPagination from './custompagination'
import './index.scss';
import LoanModel from "../deal-description/LoanModel";
import LoanInformaion from "./LoanInformaion";
import Coupons from "../userCoupons/coupons";


export default function Gift({ userRuns }) {
  const navigate = useNavigate();
  const [deals, setDeals] = useState([]);
  const [filterModal, setFilterModal] = useState(false);
  const mobileView = useMediaQuery("(max-width:769px)");
  const [filterName, setFilterName] = useState("");
  const handleClose = () => setFilterModal(!filterModal);
  const [dealsPerPage] = useState(8); // Number of deals to show per page
  const [currentPage, setCurrentPage] = useState(1);
  const [indexOfLastDeal, setIndexOfLastDeal] = useState(currentPage * dealsPerPage)
  const [indexOfFirstDeal, setIndexOfFirstDeal] = useState(indexOfLastDeal - dealsPerPage)
  const [paginatedDeals, setPaginatedDeals] = useState([]);
  const [LoanModel, setLaonModel] = useState(false);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleCloseModel = () => {
    setLaonModel(false);
  };

  const accessToken = getAuthToken();

  let getDealsOptions;
  if(accessToken) {
    getDealsOptions = {
      method: 'get',
      url: global.config.ROOTURL.prod + '/deal/getAllWeb',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken
      },
      json: true
    };
  }
  else {
    console.log(2);
    getDealsOptions = {
      method: 'get',
      url: global.config.ROOTURL.prod + '/deal/getAllWebWithoutLogin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      json: true
    };
  }

  useEffect(() => {
    axios(getDealsOptions)
      .then(response => {
        setDeals(response.data);

      })
      .catch(error => {
        if (error?.response?.status === 401) {
          clearStorage();
          navigate('/login');
        }
      })
  }, [])
  useEffect(() => {
    setCurrentPage(1);
  }, [filterName]);
  const paginatedDeals1 = useMemo(() => {
    const startIndex = (currentPage - 1) * dealsPerPage;
    const endIndex = startIndex + dealsPerPage;
    return deals.slice(startIndex, endIndex);
  }, [currentPage, deals]);
  return (
    <div className="MainDealContainer">
      <div
        style={{
          display: 'flex',
          width: '100%',
          padding: '0px 10px',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Button onClick={()=>setLaonModel(true)}>Borrow Runs ?</Button>
        <SortIcon className="sort-icon" onClick={handleClose} />


      </div>
      <div className="AllgiftContainer">
        <div
          className="giftContainer__inner"
        >
          {!mobileView && paginatedDeals1.map((deal, index) => {
            return (
              <div key={index} className="giftcardContainer">
                <SingleGift
                  key={index}
                  deal={deal}
                  userRuns={userRuns}
                />
              </div>
            );
          })}

          {mobileView && deals.map((deal, index) => {
            return (
              <div key={index} className="giftcardContainer">
                <SingleGift
                  key={index}
                  deal={deal}
                  userRuns={userRuns}
                />
              </div>
            );
          })}
        </div><div style={{ marginTop: "20px" }}>
          {!mobileView && deals?.length > 0 && <div className="custom-pagination"> <CustomPagination
            totalDeals={deals.length}
            dealsPerPage={dealsPerPage}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          /></div>}</div>
      </div>
      {filterModal && (
        <FilterModal
          setFilter={setFilterName}
          open={filterModal}
          handleClose={handleClose}
          setDeal={setDeals}
        />
      )}
      {LoanModel && <LoanInformaion openModel={LoanModel} handleCloseModel={handleCloseModel} />}
      
    </div>
    
  );
}
