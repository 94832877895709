import React from "react";
import { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { getStorageItem } from "../../../utils/sessionStorage";
import { Button, Grid, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import moment from "moment";
import scroller from '../../poll/votedListUseScroller'
import { BallTriangle } from 'react-loader-spinner';
import ActivateModal from './activateDealModal'
import "./deletedDeals.scss";
// React-Toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DealScroll = (props) => {
  const navigate = useNavigate();
  const accessToken = getStorageItem("token");
  const userId = getStorageItem("user_id");
  const [pageNum, setPageNum] = useState(1);
  const [activateDeal, setActivateDeal] = useState(false);
  const [active, setActive] = useState(false);
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [dealId, setDealId] = useState('');
  const handleActivateDeal=()=>setActivateDeal(!activateDeal);
  
  let pSize = 7;
  let req = {
    method: "GET",
    url:  global.config.ROOTURL.prod  + `/deal/getInactiveDeals/${pageNum}/${pSize}`, 
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  }
  
  const { isLoading, error, list, hasMore } = scroller(pageNum, req);
  const observer = useRef();
  const lastElementRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNum((prev) => prev + 1);
          console.log("hhg")
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, hasMore]
  );
  
  

  return (
        <div>
          <h2>Inactive deals</h2>
          <div className="card-wrapper">
              {list?.map((deal,index) => {
                return (
                  <Card ref={lastElementRef}
                    className="card-main"
                    key={deal._id}
                    onClick={()=>{handleActivateDeal();setDealId(deal._id)}}
                  
                  >
                    <CardMedia
                      component="img"
                      height="140px"
                      image={deal.dealImage}
                      alt={deal.name}
                    />
                    <CardContent className="content">
                      <Typography gutterBottom variant="h5" component="div">
                        {deal.name}
                      </Typography>
                      <Typography gutterBottom variant="subtitle1" component="div">
                        Redeem Run- {deal.redeemrun}
                      </Typography>
                      <Typography gutterBottom variant="subtitle2" component="div">
                        Deals Count left- {deal.count}
                      </Typography>
                      <Typography gutterBottom variant="subtitle2" component="div">
                      Deals Count set by admin- {deal.totalDeal}
                      </Typography>
                      <Typography gutterBottom variant="subtitle2" component="div">
                      app only- {deal.appOnly?"true":"false"}
                      </Typography>
                      <Typography gutterBottom variant="subtitle2" component="div">
                      locked deal- {deal.isLocked?"true":"false"}
                      </Typography>
                      <Typography gutterBottom variant="subtitle2" component="div">
                      redeemableOnce- {deal.redeemableOnce?"true":"false"}
                      </Typography>
                     { deal.isLocked&&<Typography gutterBottom variant="subtitle2" component="div">
                      condition to unlock deal- {deal.conditionForUnlock}
                      </Typography>}
                      <Typography variant="body2" color="text.secondary">
                        <div
                          style={{ marginTop: "15px" }}
                          className="event-description"
                          dangerouslySetInnerHTML={{
                            __html: deal.desc,
                          }}
                        />
                      </Typography>
                      <hr style={{ marginTop: "10px", marginBottom: "10x" }} />
                      <Typography gutterBottom variant="subtitle2" component="div">
                        Start Time- {moment(deal.startDateAndTime).format("LLL")}
                      </Typography>
                      <Typography gutterBottom variant="subtitle2" component="div">
                        End Time- {moment(deal.endDateAndTime).format("LLL")}
                      </Typography>

                    </CardContent>
                  </Card>
                );
              })}
          </div>
          

         
          {isLoading && ( <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "40px",
            }}
          >
              <BallTriangle
                height="50"
                width="50"
                color="grey"
                ariaLabel="loading"
              />
            
          </div>)}
          
       {activateDeal &&
       <ActivateModal dealId={dealId} open={activateDeal} handleClose={handleActivateDeal} setPageNum={setPageNum}/>}
          <ToastContainer />
        </div>
    
  );
};
export default DealScroll;
