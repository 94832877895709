import React from "react";
import "./index.css";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import PaginationRounded from "../PaginationRounded";

const index = () => {
  return (
    <div className="contest-admin-update">
      <div className="contest-admin-update-wrapper">
        <div className="contest-admin-update-wrapper-main">
          <div className="contest-admin-create-wrapper-header">
            <p className="contest-admin-create-wrapper-header--text">Name</p>
            <p className="contest-admin-create-wrapper-header--delete-text">
              Delete
            </p>
          </div>
          <div className="contest-admin-create-wrapper-form">
            <div className="contest-create-name-wrapper">
              <input
                type="text"
                className="contest-create-name"
                placeholder="Prediction 1"
              />
              <p className="contest-create-name-extra">Max 24 letters</p>
            </div>
            <div className="contest-create-row2-wrapper">
              <div className="contest-create-row2-block1">
                <p className="admin-contest-creation-general-text">Start</p>
                <input type="date" className="contest-create-start-date" />
                <input type="time" className="contest-create-start-time" />
              </div>
              <div className="contest-create-row2-block2">
                <p className="admin-contest-creation-general-text">
                  Runs for Entry
                </p>
                <input type="text" className="contest-create-start-input" />
              </div>
            </div>
            <div className="contest-create-row3-wrapper">
              <div className="contest-create-row3-block1">
                <p className="admin-contest-creation-general-text">End</p>
                <input type="date" className="contest-create-start-date" />
                <input type="time" className="contest-create-start-time" />
              </div>
              <div className="contest-create-row3-block2">
                <p className="admin-contest-creation-general-text">Reward</p>
                <input type="text" className="contest-create-start-input" />
              </div>
            </div>
            <div className="contest-create-row4-wrapper">
              <p className="admin-contest-creation-general-text">Q1</p>
              <input type="text" className="contest-create-start-input-full" />
            </div>
            {[
              [...Array(4)].map((question, index) => {
                return (
                  <div className="contest-create-row5-wrapper" key={index}>
                    <p className="admin-contest-creation-general-text">
                      A{index + 1}
                    </p>
                    <div className="contest-create-radio-button-wrapper">
                      <label
                        className="contest-create-radio-label"
                        htmlFor={`option-${index + 1}`}
                      >
                        Option {index + 1}
                      </label>
                      <input
                        type="radio"
                        className="contest-create-start-input-radio"
                        name="Options"
                        id={`option-${index + 1}`}
                      />
                    </div>
                  </div>
                );
              }),
            ]}
            <div className="contest-create-add-more">
              <div className="contest-create-add-more--main">
                <AddCircleOutlineIcon className="contest-create-add-more-icons" />
                <RemoveCircleOutlineIcon className="contest-create-add-more-icons" />
              </div>
            </div>
            <div className="contest-create-pagination-rounded-wrapper">
              <PaginationRounded />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default index;
