import React, { useEffect, useState } from "react";
import CheckAnswer from "./CheckAnswer";
import axios from "axios";
import { getAuthToken } from "../../../../utils/auth";
import VectorRed from "../../../../assets/images/rewards/wronganswer.png";
import VectorGreen from "../../../../assets/images/rewards/rightansswerball.png";
import ArrowImg from "../../../../assets/images/rewards/backArrow.png";
import Image from "../../../../assets/images/rewards/dealBall.png";
import "./Resultindex.scss";
import { useMediaQuery } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getStorageItem } from "../../../../utils/sessionStorage";

export default function PredictionResult(props) {
  const mobileView = useMediaQuery("(max-width:900px)");
  const userId = localStorage.getItem("user_id");
  const predictionid = useSelector((state) => state.prediction.prediction);
  const [userRuns, setUserRuns] = useState(0);
  const [openResult, setOpenResult] = useState(false);
  const [totalRightNoQuestion, setTotalRightNoQuestion] = useState(0);
  const [totalNoOfQuestion, settotalnoofQuestion] = useState(0);
  const [rewarderRun, setRewarderRun] = useState(0);
  const accessToken = getStorageItem('token');
  const navigate = useNavigate();
  const handlePredictionPage = () => {
    mobileView ? navigate("/prediction") : props?.handleclosePopup();
  };

  const handleOpenResult = () => {
    setOpenResult(true);
  };

  const getPredictionResult = () => {
    if (!(userId && predictionid)) return;
    const getTotalRun = {
      method: "POST",
      url:
        global.config.ROOTURL.prod +
        "/contest/userPredictionQuestion/getPredictionResult",
      headers: {
        Authorization: "Bearer " + accessToken,
      },
      data: {
        userId: userId,
        predictionId: predictionid,
      },
    };
    axios(getTotalRun)
      .then((response) => {
        if (response.data) {
          console.log(response.data);
          console.log("iam response", response.data.totatlNoOfQuestion);
          setTotalRightNoQuestion(response.data.totalRightNoQuestion);
          settotalnoofQuestion(response.data.totatlNoOfQuestion);
          setRewarderRun(response.data.rewarderRun);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getRuns = () => {
    if (!accessToken) return;
    if (!userId) return console.error("userId required");

    const getTotalRun = {
      method: "GET",
      url: global.config.ROOTURL.prod + "/pitch/getTotalRun/" + userId,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };
    axios(getTotalRun)
      .then((response) => {
        setUserRuns(response.data);
      })
      .catch((error) => {
        console.log("4")
        console.log(error);
      });
  };
  useEffect(() => {
    getRuns();
    getPredictionResult();
    return () => {
      setUserRuns(0);
    };
  }, [openResult]);
  return (
    <div className="PlayNow_container">
      <div className="pridiction_Main">
        {!openResult && (
          <>
            <div className="Predict_nav">
              <img
                src={ArrowImg}
                alt="arrowbtn"
                onClick={handlePredictionPage}
              />
            </div>

            <div className="predict_run">
              <div className="prdict_img">
                <img src={Image} alt="ball-logo" />
                <p style={{ paddingLeft: "24px" }}>{userRuns}</p>
              </div>
            </div>

            <div className="second_mainCont">
              <div className="pridiction_content">
                <p>Prediction</p>
                <h1>{totalRightNoQuestion + "/" + totalNoOfQuestion}</h1>

                <p className="paragraph_second">
                  {" "}
                  congrats,
                  <br />
                  You earned {rewarderRun} runs!
                </p>

                <div className="circle_img">
                  <img src={VectorGreen} alt="dot" />
                  <img src={VectorRed} alt="dot" />
                  <img src={VectorGreen} alt="dot" />
                  <img src={VectorRed} alt="dot" />
                  <img src={VectorGreen} alt="dot" />
                  <img src={VectorRed} alt="dot" />
                </div>
              </div>

              <div className="predict_btn_main">
                <button className="btn1" onClick={handleOpenResult}>
                  View Answers
                </button>
                <button className="btn2" onClick={handlePredictionPage}>
                  Close
                </button>
              </div>
            </div>
          </>
        )}

        {openResult && (
          <CheckAnswer
            userRuns={userRuns}
            handleclosePopup={props?.handleclosePopup}
          />
        )}
      </div>
    </div>
  );
}
