import React, { useEffect } from "react";
import Styled from "@emotion/styled";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link } from '@mui/material';


import SignInForm from './siginin-form.component';

import SignInSignUpPageHOC from '../signin-signup-page-hoc'
import GoogleSignup from "../signup/GoogleSignup";

const RegisterHere = Styled.div(`
    width: 80%;
    max-width: 300px;
    margin: 20px auto 5px auto;
    padding: 10px 20px;
    border-radius: 20px;
    background: white;
    font-size: 15px;
    color: black;
    text-align: center;
`);





export default function SignIn() {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    return (
        <SignInSignUpPageHOC matches={matches}>
            <>
               
                <SignInForm breakPoint={matches} />
              
            </>
        </SignInSignUpPageHOC>
    );
}