import React from "react";
import { Link } from "react-router-dom";
import { CardContent, Grid, Divider } from "@mui/material";
import new2ball from "../../assets/images/icons/2runNewBall.png";
import new4ball from "../../assets/images/icons/4runBall.png";
import new6ball from "../../assets/images/icons/6runnewBall.png";
import './runPopupModal.scss'
const UserCard = ({ votedUsers, index, lastElementRef, defaultAvatar,type }) => {
  if (votedUsers.length === index + 1) {
    return (
      <Grid ref={lastElementRef}>
        <CardContent
          key={index}
          sx={{ fontSize: 14 }}
          style={{ padding: "9px 0px 0px 0px" }}
          color="text.secondary"
        >
       <Link
                to={`/profile/${votedUsers?.userId}`}
                style={{ textDecoration: "none" }}
              > <div className="user-run-details">
            <div  >
                <img className=" run-user-profile ball-space-left1 "
              src={votedUsers?.userProfilePhoto ? votedUsers?.userProfilePhoto : defaultAvatar}
              alt="user"
            /></div>
            <div className="user-run-username">
              {votedUsers?.userName}
            </div>
           {type===1&& <div className="ball-space-right1">
                {votedUsers?.run===6&&
                <img className="ball-design" src={new6ball} alt="" ></img>}
                {votedUsers?.run===4&&<img className="ball-design" src={new4ball} alt="" ></img>}
                {votedUsers?.run===2&& <img className="ball-design" src={new2ball} alt=""></img>}
            </div>}
          </div>
          </Link>
          {/* <div>you are yet to receive {type===6?6:type===4?4:type===2?2:""} runs</div> */}
          <Divider />
        </CardContent>
      </Grid>
    );
  } else {
    return (
      <CardContent
        ref={lastElementRef}
        key={index}
        sx={{ fontSize: 14 }}
        style={{ padding: "9px 0px 0px 0px" }}
        color="text.secondary"
      >
             <Link
                to={`/profile/${votedUsers?.userId}`}
                style={{ textDecoration: "none" }}
              >  <div className="user-run-details">
            <div  >
                <img className=" run-user-profile ball-space-left1 "
              src={votedUsers?.userProfilePhoto ? votedUsers?.userProfilePhoto : defaultAvatar}
              alt="user"
            /></div>
            <div className="user-run-username">
              {votedUsers?.userName}
            </div>
           {type===1&& <div className="ball-space-right1">
                {votedUsers?.run===6&&
                <img className="ball-design" src={new6ball} alt="" ></img>}
                {votedUsers?.run===4&&<img className="ball-design" src={new4ball} alt="" ></img>}
                {votedUsers?.run===2&& <img className="ball-design" src={new2ball} alt=""></img>}
            </div>}
          </div></Link>
        <Divider />
      </CardContent>
    );
  }
};

export default UserCard;
