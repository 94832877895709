import * as React from "react";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CardMedia from '@mui/material/CardMedia';
import {
    useMediaQuery, Grid,
    Button, Box, Card,
    CardActions,
    Typography, Link
} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PostedJob from "../posted-job";
import axios from "axios";
import { getStorageItem } from "../../utils/sessionStorage";
import config from '../../config';
import parse from 'html-react-parser';
import {faCalendarDays} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import { faMoneyBill,faCode } from '@fortawesome/free-solid-svg-icons';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { faBookmark } from '@fortawesome/free-solid-svg-icons';
import suitcase from "../../assets/images/icons/suitcase.png";
const userID = getStorageItem("user_id");
const accessToken = getStorageItem("token");

const baseURL = config.ROOTURL.prod;

const FindOneEvent = (props) => {
    const navigate = useNavigate();
    const navigateToEvents = () => {
        navigate('/admin/find-all-events');
    };
    const { id } = useParams();
    const [events, setEvents] = useState("");
    const [error, setError] = useState(null);
    const editFunCall=(data)=>{
        let eventId=data;
        navigate(`/admin/edit-event-admin/${eventId}`);
    }
    const deleteFunCall = (data) => {
        console.log("This is delete function call:");
        console.log("this is event ID:", data);
        axios.delete(`${baseURL}/events/delete/${data}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
        }).then((response) => {
            console.log("this is post response:", response)
            if (response) {
                toast.success("event deleted successfully", { theme: "colored" });
                console.log("event deleted successfully");
                setTimeout((e) => {
                    navigateToEvents();
                }, 2000)
            }
            else {
                toast.error("Some error occured", { theme: "colored" });
                console.log("error occured:");
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    useEffect(() => {
        axios.get(`${baseURL}/events/getEventById/${id}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            }
        }).then((response) => {

            // handlejobDescription(response);
            console.log("this is base url:", `${baseURL}`);
            console.log("this is response data:", response)
            setEvents(response.data);
            // console.log("this is post:", events)
        }).catch(error => {
            setError(error);
            console.log("this is error:", error)
        });

    }, [id]);


    if (error) return `Error: ${error.message}`;
    if (!events) return "No post!"
    const theme = createTheme(
        {
            typography: {
                fontFamily: 'poppins, Arial',
            },
        }
    );
 
    return (

        <Grid
        container
        alignItems="center"
      > <Grid item xs={12}>
              <Card xs={{ maxWidth: 400 }}  >
         <CardMedia
              component="img"
              height="140"
              image={events.eventBanner}
              alt="bannerImage"
            />
            <CardContent>
              <Typography ><h2><strong><center> {events.eventTitle}</center></strong></h2>
      </Typography>
              <Typography >
              <FontAwesomeIcon icon={faCalendarDays} />  <strong>From:</strong>{events.startDate} {events.startTime} 
              
             </Typography>
             <Typography >
              <FontAwesomeIcon icon={faCalendarDays} />  <strong>To:</strong>{events.endDate} {events.endTime} 
              
             </Typography>
             <Typography >
              <FontAwesomeIcon icon={faLocationDot} />  {events.eventLocation} 
              
             </Typography>
             <Typography >
              <FontAwesomeIcon icon={faMoneyBill} />  {events.registrationFee} 
              
             </Typography>
             <Typography >
              <FontAwesomeIcon icon={faCode} />  {events.eventNameCode} 
              
             </Typography>
             <Typography ><p><strong>Description:</strong><p dangerouslySetInnerHTML={{ __html: events.eventdescription }} /></p>
              
             </Typography>
          </CardContent>
          
          <CardActions > <Button variant="contained"  sx={{ display: 'flex', flexDirection: 'row', alignItems: "left", ml: 'auto',backgroundColor:'#0c5ba0',color:'white' }} onClick={navigateToEvents}> Back</Button>
          {(events.userId === userID) && <Button variant="contained" color="primary" sx={{ display: 'flex', flexDirection: 'row', alignItems: "left", ml: 'auto' }} onClick={() => editFunCall(events._id)}> Edit</Button>
}
{(events.userId === userID) && <Button variant="contained" color="primary" sx={{ display: 'flex', flexDirection: 'row', alignItems: "left", ml: 'auto' }} onClick={() => deleteFunCall(events._id)}> Delete</Button>
}</CardActions>
          </Card>
          </Grid>    
      </Grid>
      )
        }
export default FindOneEvent;