import moment from "moment/moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./PredictionItem.css";
import axios from "axios";
import { getAuthToken } from "../../../utils/auth";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import PredictionDelete from './predictionDeleteModal'
import PlayerEdit from './editPlayer'

const ViewPlayerItem = (props) => {
  const [data, setData] = useState(props.data)
  const details=props.details;
  const [openDeletePredictionModal, setOpenDeletePredictionModal] = useState(false)
  const [openEditModal, setOpenEditModal] = useState(false)
  const accessToken = getAuthToken();
  const navigate = useNavigate();
  const handleOpenDeletePredictionModal = () => setOpenDeletePredictionModal(true);
  const handleCloseDeletePredictionModal = () => setOpenDeletePredictionModal(false);
  const handleOpenEditModal = () => setOpenEditModal(true)
  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    props.getSelectedPlayers();
  }
  const deletePrediction = () => {
    if (!accessToken && !props.id) return
    const pdata={
      id: props.id,
      fantasyid: props.fantasyId
    }
    var deleteDetails = {
      method: 'POST',
      url: global.config.ROOTURL.prod + `/contest/fantasyMatch/deleteMatchPlayer`,
      headers: {
        'Authorization': 'Bearer ' + accessToken,
      },
      data: pdata,

    };
    axios(deleteDetails)
      .then(response => {
        if (response) {
          toast.success("Player deleted ");
          handleCloseDeletePredictionModal();
          props.getSelectedPlayers();

        }
      })
      .catch(error => {
        toast.error("error occured ")
        console.log(error)
      })

  }
  console.log("daat", data)
  

  return (
    <div className="contest_admin_predction-wrapper">
      <div className="contest_admin_prediction-item" style={{height:"250px"}}>
        <div className="contest_admin_prediction-header">
          <div className="contest_admin_prediction-item-name">
            {data?.playerName}
          </div>
          <div>  <FontAwesomeIcon icon={faPenToSquare} style={
            { margin: "5px",marginRight:"10px",height:"22px" }} onClick={handleOpenEditModal} />
            <img
              src="/svg/admin/delete.svg"
              alt="delete"
              className="contest_admin_prediction-item-delete" onClick={handleOpenDeletePredictionModal}
            /></div>
        </div>
        <div className="contest-admin-create-wrapper-form" style={{marginTop:"10px"}}>
          <div className="contest-admin-create-wrapper-form1">
            <p className="contest_admin_prediction-sub-header--runs">
              Role- {data?.role}
            </p>
            <p className="contest_admin_prediction-sub-header--runs">
              Country- {data?.country}
            </p>
            <p className="contest_admin_prediction-sub-header--runs">
              Team- {data?.team}
            </p>
          </div>

          <div className="contest-admin-create-wrapper-form1">
            <p className="contest_admin_prediction-sub-header--runs">
              basePrice- {details?.basePrice}
            </p>
            <p className="contest_admin_prediction-sub-header--runs">
              fours- {details?.fours}
            </p>
            <p className="contest_admin_prediction-sub-header--runs">
              sixes- {details?.sixes}
            </p>
          </div>
          <div className="contest-admin-create-wrapper-form1">
            <p className="contest_admin_prediction-sub-header--runs">
              runsScored- {details?.runsScored}
            </p>
            <p className="contest_admin_prediction-sub-header--runs">
              wickets- {details?.wickets}
            </p>
            <p className="contest_admin_prediction-sub-header--runs">
              catches- {details?.catches}
            </p>
          </div>
          <div className="contest-admin-create-wrapper-form1">
            <p className="contest_admin_prediction-sub-header--runs">
              Twos- {details?.twos}
            </p>
            <p className="contest_admin_prediction-sub-header--runs">
              Ones- {details?.ones}
            </p>
            <p className="contest_admin_prediction-sub-header--runs">
              Dot balls- {details?.dots}
            </p>
          </div>
          <div className="contest-admin-create-wrapper-form1">
          <p className="contest_admin_prediction-sub-header--runs">
              Rewards- {details?.rewardPoints}
            </p>
          </div>
        </div>
        
      </div>
      
      {openDeletePredictionModal && <PredictionDelete handleOpen={handleOpenDeletePredictionModal}
        handleClose={handleCloseDeletePredictionModal} open={openDeletePredictionModal}
        handleDelete={deletePrediction} />
      }
      {openEditModal && <PlayerEdit handleOpen={handleOpenDeletePredictionModal}
        handleClose={handleCloseEditModal} open={openEditModal} playerData={props.details} setData={setData}
      />}
    </div>

  );
};

export default ViewPlayerItem;
