import React from 'react'
import { Grid, Box, Typography } from "@mui/material";
import "./index.scss";
import { FaFacebookF, FaTwitter } from "react-icons/fa";
import { TfiYoutube } from "react-icons/tfi";


const FooterBlock = () => {
    return (

        <Box className='footerRows'>
            <div className='footerSection'>
                <div className='content_footer'>
                    <Typography variant='h6'>Home</Typography>
                    <Typography variant='h6'>Experience</Typography>
                    <Typography variant='h6'>News</Typography>
                    <Typography variant='h6'>About us</Typography>
                    <Typography variant='h6'>Jobs</Typography>
                    <Typography variant='h6'>Contact</Typography>
                </div>

                <div className='social_media_icons'>
                    <div>
                        <FaFacebookF className='icons' />
                    </div>
                    <div>
                        <FaTwitter className='icons' />
                    </div>
                    <div>
                        <TfiYoutube className="icons" />
                    </div>
                    </div>
            </div>

            <p>© Copyright 2022 - lorem Media</p>
        </Box>
    );
}

export default FooterBlock;

