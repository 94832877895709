import Deals from "../../components/deals";
import Profile from "../../components/profile";
import Header from "../../components/header";
import Activities from "../../components/activities/activities";
import coverimage from "../../assets/images/profile/camera.png";
import backArrow from "../../assets/images/icons/back-button.png";
import analyticsicon from "../../assets/images/icons/analytics-icon.png";
import defaultcoverImage from "../../assets/images/profile/seventh.png";
import "./index.scss";
import { useContext, useEffect, useState } from "react";
import AppContext from "../../context/AppContext";
import { useDispatch } from "react-redux";
import { PathAction } from "../../redux/reducers/globalPath";
import { useNavigate, useParams } from "react-router-dom";
import { clearStorage, getStorageItem } from "../../utils/sessionStorage";
import axios from "axios";
import { toast } from "react-toastify";
import { Button } from "@mui/material";

const OwnProfileDetailsPage = ({ Params = getStorageItem("user_id") }) => {
  const [image, setImage] = useState(null);
  const [ownProfile, setownProfile] = useState(false);
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const { setShowAdd, setShowFooter } = appContext;
  const accessToken = getStorageItem("token");
  const localStorageUserId = getStorageItem("user_id");
  const params = useParams();
  const [showAddRuns, setShowAddRuns] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [profileRunsInputValue, setProfileRunsInputValue] = useState(0);
  let [userId, setUserId] = useState(params.uid);

  const handlecoverPhoto = (data) => {
    if (data === "") {
      setImage(defaultcoverImage);
    } else {
      setImage(data);
    }
  };

  const path = window.location.pathname;
  const dispatch = useDispatch();
  dispatch(PathAction.handlePath({ payload: path }));

  const profileVisitApi = async () => {
    if (!accessToken && !localStorageUserId && !params.uid) return;
    try {
      const req = {
        method: "POST",
        url: global.config.ROOTURL.prod + "/userengagement/profilevisit/create",
        headers: {
          Authorization: "Bearer " + accessToken,
        },
        data: { profileUserId: params.uid, visitedUserId: localStorageUserId },
      };

      axios(req)
        .then((response) => {
          if (response.data) {
            console.log(response.data);
          }
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    if (params.uid === localStorageUserId) {
      setownProfile(true);
    }
    profileVisitApi();
    setShowAdd(true);
    setShowFooter(true);

    return () => {
      setShowAdd(false);
      setShowFooter(false);
    };
  }, []);
  useEffect(() => {
    if (params.uid === localStorageUserId) {
      setownProfile(true);
    }
  }, [params.uid]);
  const fetchUserInfo = () => {
    if (!accessToken && !userId) return;
    if (!localStorageUserId) return;
    const getUserInfo = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/auth/profile/",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      data: {
        userId: userId,
      },
    };
    const getMyTrueRole = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/auth/profile/",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      data: {
        userId: localStorageUserId,
      },
    };
    axios(getMyTrueRole)
      .then((res) => {
        if (res?.data?.role === "SuperAdmin" || res?.data?.role === "Admin") {
          // If my real role is Admin or superAdmin only in that condition perform this action
          setIsAdmin(true);
          axios(getUserInfo)
            .then((response) => {
              if (localStorageUserId) {
                if (localStorageUserId === userId) {
                  setShowAddRuns(false);
                } else {
                  setShowAddRuns(true);
                }
              }
            })
            .catch((error) => {
              if (error?.response?.status === 401) {
                clearStorage();
                navigate("/login");
              }
            });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          clearStorage();
          navigate("/login");
        }
      });
  };
  const handleAddRunsToUserProfile = () => {
    if (!profileRunsInputValue || profileRunsInputValue === 0) {
      toast.error("Cannot add 0 runs to user id");
    } else {
      const getUserInfo = {
        method: "POST",
        url: global.config.ROOTURL.prod + "/auth/given-runs/",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
        data: {
          userId: userId,
          givenRun: profileRunsInputValue,
        },
      };
      axios(getUserInfo)
        .then((response) => {
          toast.success(response.data);
          setProfileRunsInputValue(0);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  useEffect(() => {
    fetchUserInfo();
  }, [userId]);

  const BackButton = () => {
    const navigate = useNavigate();
  }
  return (
    <div className="Profile_component">
      {isAdmin && showAddRuns && (
        <div className="add_runs_modal__profile">
          <input
            type="number"
            placeholder="Add Runs"
            className="profile_runs__input"
            value={profileRunsInputValue}
            onChange={(e) => {
              setProfileRunsInputValue(e.target.value);
            }}
          />
          <button
            className="profile_runs__button"
            onClick={handleAddRunsToUserProfile}
          >
            Add
          </button>
        </div>
      )}

      {/* Mobile view edit analytics bar */}
      <div className="mobile_profileinfo">
        <img src="\..\assets\images\profile\back.svg" />

        {ownProfile && (
          <div className="mobile_view_check">
            <div className="own_profile_text">
              <p>
                <a onClick={() => navigate(-1)} ><img className="profile_backimg" src={backArrow} style={{height: '18px', width:'18px',marginRight:'10px'}}/>
                Profile
                </a>
              </p>
            </div>

            <div className="mobile_profile_analytics">
              <img
                src={analyticsicon}
                style={{ marginLeft: "24px", height: '30px', width:'30px'}}
                onClick={() => {
                  navigate("/analytics", { state: { id: Params } });
                }}
              />

              <Button
                className="mobile_profile_editButton"
                style={{ marginLeft: "24px" }}
                onClick={() => {
                  navigate(`/profile_edit`);
                }}
              >
                {" "}
                Edit
              </Button>
            </div>
          </div>
        )}

        <div className="bannerHeight">
          {image && (
            <div className="bg" style={{ backgroundImage: `url(${image})` }} />
          )}
        </div>

        {ownProfile && (
        <img
          src={coverimage}
          onClick={() => navigate("/banner")}
          className="Cameraimage"
        />
      )}
      <Profile handlecoverPhoto={handlecoverPhoto} Params={params.uid} />
      </div>


    </div>
  );
};

export default OwnProfileDetailsPage;
