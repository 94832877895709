import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";


const CartModal = ({ isOpen, onClose, onConfirm }) => {
  const [promoCode, setPromoCode] = useState("");

  const handleConfirm = () => {
    onConfirm(promoCode);
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "white",
          width: 300,
          height: "228px",
          padding: 2,
          borderRadius: "8px",
          textAlign: "center",
        }}
      >
        <CloseIcon
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            cursor: "pointer",
            color: "#0C5BA0"
          }}
          onClick={onClose}
        />
        <Typography
          id="modal-title"
          style={{ fontSize: "10px", lineHeight: "4rem", fontWeight: "500" }}
        >
          Add Promocode here to get amazing discount
        </Typography>
        <TextField
          style={{width: "234px",  border: "1px solid #9FCEF7"}}
          className="Box1"
          label= "Add Promo code here"
          variant="outlined"
          fullWidth
          value={promoCode}
          onChange={(e) => setPromoCode(e.target.value)}

        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleConfirm}
          style={{ marginTop: "10px", width: "234px", borderRadius: "6px", height: "40px" }}
        >
          Confirm
        </Button>
      </Box>
    </Modal>
  );
};
export default CartModal;
