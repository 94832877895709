import axios from "axios";

const auth_ms_url = global.config.ROOTURL.prod;
const accessToken = localStorage.getItem("token");
const headers = {
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + accessToken,
  },
};


const getjobByid = async (id) => {
    let response = await axios.get(
      auth_ms_url + `/job/getByjobIdForPreview/${id}`,
      headers
    );
    return response;
  };
const getAllJobPostedBasedOnWorkPlaceType = async (data) => {
    let response = await axios.post(
      auth_ms_url + "/job/getAllJobPostedBasedOnWorkPlaceType",
      data,
      headers
    );
    return response;
  };


export {getjobByid,getAllJobPostedBasedOnWorkPlaceType};