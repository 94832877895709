import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  inviteData: null,
  inviteIsLoading: false,
  inviteError: null,
}

export const inviteSlice = createSlice({
  name: 'invite',
  initialState,
  reducers: {
    fetchInviteData: (state) => ({
      ...state,
      inviteIsLoading: !initialState.inviteIsLoading,
    }),
    setInviteData: (state, action) => ({
      ...initialState,
      ...state,
      inviteData: action.payload || initialState.data,
      inviteIsLoading: initialState.inviteIsLoading,
    }),
    setInviteLoading: (state, action) => ({
      ...initialState,
      ...state,
      inviteIsLoading: (typeof action.payload === 'undefined') ? initialState.inviteIsLoading : action.payload,
    }),
    setInviteError: (state, action) => ({
      ...initialState,
      ...state,
      inviteError: action.payload || initialState.inviteError,
    }),
    resetInvite: (state) => ({
      ...initialState,
    }),
  },
})

// Action creators are generated for each case reducer function
export const {
  fetchInviteData, setInviteLoading, setInviteData, resetInvite, setInviteError,
} = inviteSlice.actions

export default inviteSlice.reducer
