import React, { useState, useEffect } from "react";
import axios from "axios";
import redBalls from "../../assets/images/icons/red-ball.png";
import pitchIcon from "../../assets/images/icons/pitch_icon.png";
import pollIcon from "../../assets/images/icons/poll_icon.png";
import predictionIcon from "../../assets/images/icons/prediction_icon.png";
import dealIcon from "../../assets/images/icons/deal_icon.png";
import pdfIcon from "../../assets/images/icons/icons_pdf.png";
import excelIcon from "../../assets/images/icons/icons_excel.png";
import "./index.scss";
import InfiniteScroll from "react-infinite-scroll-component";
import { BallTriangle } from "react-loader-spinner";
import moment from "moment";

const RunsTransactionTable = ({ activeItem, sortItem, dateFilter, runStartDate, runEndDate }) => {
  // const [runsTransaction, setRunsTransaction] = useState([]);
  // const [runDeals, setRunDeals] = useState([]);
  // const [runPoll, setRunPoll] = useState([]);
  // const [runPredictionDeduct, setRunPredictionDeduct] = useState([])
  // const [runPredicitonReward, setRunPredictionReward] = useState([])
  const [updatedRunData, setUpdatedRunData] = useState(0)
  const [newArray, setNewArray] = useState([])
  const accessToken = localStorage.getItem("token");
  const userId = localStorage.getItem("user_id");
  const pitchComment = 'Total Runs on Pitch ';
  const dealComment = 'Run Debited on Deals ';
  const pollComment = 'Run Credited for Poll ';
  const predictionReward = 'Run Credited for Prediction ';
  const predictionDeduct = 'Run Debited for Prediction ';

  const [userRuns, setUserRuns] = useState(0)
  const [newFilter, setNewFilter] = useState([])
  const [shouldLoadPage, setShouldLoadPage] = useState(false);
  const [items, setItems] = useState([]);
  const pagePerSize=2;
  const [pageNumber,setPageNumber]=useState(1)
  const headers = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
  };

  const getRuns = () => {
    if (!userId) return console.error("_userId required");

    const getTotalRun = {
      method: "GET",
      url: global.config.ROOTURL.prod + "/pitch/getTotalRun/" + userId,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };
    axios(getTotalRun)
      .then((response) => {
        setUserRuns(response.data);
      })
      .catch((error) => {

        console.log(error);
      });
  };

  // fetching according to date
  const fetchRunsDateWise = async () => {
    // const now = new Date()
    // let startDate;
    // if (dateFilter === 'This Week') {
    //   startDate = new Date(now - 1000 * 60 * 60 * 24 * 7)
    // } else if (dateFilter === 'Last Week') {
    //   startDate = new Date(now - 1000 * 60 * 60 * 24 * 7)
    // } else if (dateFilter === 'Last Month') {
    //   startDate = new Date(now - 1000 * 60 * 60 * 24 * 30)
    // } else if (dateFilter === 'Last 3 Months') {
    //   startDate = new Date(now - 1000 * 60 * 60 * 24 * 90)
    // } else if (dateFilter === 'Last Years') {
    //   startDate = new Date(now - 1000 * 60 * 60 * 24 * 365)
    // }
    setNewArray([])
    console.log(runStartDate)
    console.log("END",runEndDate)
    // const formattedStartDate = startDate.toISOString()
    // const formattedEndDate = now.toISOString()
    setUpdatedRunData(0)
    fetchDataAndCombine(runStartDate, runEndDate).then((combinedData) => setNewArray(combinedData))
  }

  // fetching pitch runs
  const fetchPitchRuns = async (startDate, endDate) => {
    try {
      const response = await axios.get(
        `http://localhost:3002/pitch/userPitchForRunTransaction/${userId}/${startDate}/${endDate}?pageNo=${pageNumber}&pagePerSize=${pagePerSize}`,
        headers
      );
      let resData = response.data;
      console.log("hey whatsappp",resData)
      return resData
    } catch (err) {
      console.log(err);
    }
  };

  // fetching deal runs
  const fetchDealRuns = async (startDate, endDate) => {
    try {
      let data = [];
      const response = await axios.get(
        `http://localhost:3002/dealredeemdetails/getRedeemDetailsByUserId/${userId}/${startDate}/${endDate}?pageNo=${pageNumber}&pagePerSize=${pagePerSize}`,
        headers
      );
      const runDealsData = response.data;
      runDealsData.map((datass) => {
        data.push(datass);
      });
      return runDealsData

    } catch (err) {
      console.log(err);
    }
  };

  // fetching poll runs
  const fetchPollRuns = async (startDate, endDate) => {
    try {
      console.log("POLLL NUMBER HURRAYAFSDFNSKJDHASKJDBASJKFBASKJFASF",pageNumber)
      const response = await axios.get(
        `http://localhost:3002/poll/getPollByUserName/${userId}/${startDate}/${endDate}?pageNo=${pageNumber}&pagePerSize=${pagePerSize}`,
        headers
      );
      const pollData = response.data;
      return pollData
      
    } catch (err) {
      console.log(err);
    }
  };

  // fetching prediction runs
  const fetchPredictionDeduct = async (startDate, endDate) => {
    try {
      const deductResponse = await axios.get(
        `http://localhost:3014/userpredictionquestion/gettotaldeduct/${userId}/${startDate}/${endDate}`,
        headers
      );
      const data=(deductResponse.data);
      console.log("predition headers", data)
      return data
    } catch (err) {
      console.log(err);
    }
  };

  const fetchPredictionReward = async (startDate,endDate) => {
    try {
     
      const rewardResponse = await axios.get(
        `http://localhost:3014/userpredictionquestion/gettotalreward/${userId}/${startDate}/${endDate}`,
        headers
      );
      const data=(rewardResponse.data);
      console.log("predition response", data)
      return data
    } catch (err) {
      console.log(err);
    }
  }
  // data is shown as per time
  const fetchDataAndCombine = async (startDate, endDate) => {
    try {
      const [pitchRunsData, dealRunsData, pollData,predictionDeduct,predictionReward] = await Promise.all([
        fetchPitchRuns(startDate, endDate),
        fetchDealRuns(startDate, endDate),
        fetchPollRuns(startDate, endDate),
        fetchPredictionDeduct(startDate, endDate),
        fetchPredictionReward(startDate, endDate)
      ]);

      let combinedData = [...pitchRunsData, ...dealRunsData, ...pollData, ...predictionReward, ...predictionDeduct];    // Combining all data into a single array

      combinedData.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
      let updatedRun = [...combinedData].reduce((acc, cur) => {
        if (cur.run) {
          return acc + cur.run;
        } else if (cur.redeemedRun) {
          return acc + cur.redeemedRun;
        } else if(cur.rewarded){
          return acc+cur.rewarded
        } else if(cur.deductedEntryRun){
          return acc+cur.deductedEntryRun
        }else {
          return acc + 30; // Default value
        }
      }, 0);
      setUpdatedRunData(updatedRun)
      setNewFilter([...combinedData])
      console.log('COMDINED DATA RUNS', updatedRunData)
      console.log("COMBINED DATA LENGTH", combinedData);
      return combinedData;
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  // filter as per option
  const filteroutRunData = () => {
    let filterArray = [...newArray]
    let updatedRun;
    if (activeItem === "Pitch") {
      filterArray = [...filterArray].filter((data) => data.run)

    } else if (activeItem === "Deals") {
      filterArray = [...filterArray].filter((data) => data.redeemedRun)

    } else if (activeItem === "Polls") {
      filterArray = [...filterArray].filter((data) => data.question)
    }
    else if(activeItem === 'Predictions'){
      filterArray = [...filterArray].filter((data)=> data.deductedEntryRun || data.rewarded)
    }

    if (sortItem === 'Credited') {
      filterArray = [...filterArray].filter((data) => (data.run || data.question || data.rewarded) ? true : false)

    } else if (sortItem === 'Debited') {
      filterArray = [...filterArray].filter((data) => (data.redeemedRun || data.deductedEntryRun) ? true : false)
    }
    updatedRun = [...filterArray].reduce((acc, cur) => {
      if (cur.run) {
        return acc + cur.run;
      } else if (cur.redeemedRun) {
        return acc + cur.redeemedRun;
      } else if(cur.rewarded){
        return acc+cur.rewarded
      } else if(cur.deductedEntryRun){
        return acc+cur.deductedEntryRun
      }
      else {
        return acc + 30; // Default value
      }
    }, 0);
    console.log(filterArray.length)
    setUpdatedRunData(updatedRun)
    setNewFilter([...filterArray])
  }

  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    filteroutRunData()
  }, [activeItem, sortItem])

  // filter as per date
  useEffect(() => {
    fetchRunsDateWise();
  }, [dateFilter]);

  const fetchMoreData = () => {
    console.log("HELLO INFINTITE SCROLL")
    //setPageNumber((prev)=>prev+=1)
    //fetchDataAndCombine(runStartDate, runEndDate).then((combinedData) => setNewArray([...newArray,combinedData]))
  }

  return (
    <>
      {/* <InfiniteScroll
        dataLength={newFilter.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={
          <BallTriangle
            height="100"
            width="100"
            color="grey"
            ariaLabel="loading"
          />
        }
      > */}
        <div className="runstransactiontable_container">
          <div className="runstransactiontable_header">
            <div className="runstransactiontable_title">
              <p className="table_day">Today</p>
            </div>
            <div className="runstransactiontable_total">
              <img className="runsball_img" src={redBalls} />
              <p className="table_totalruns">{updatedRunData}</p>
              <p className="table_totalruns">Runs</p>
            </div>
          </div>
          {newFilter.map((data, index) => (
            <div key={index} className="runs_transactions_sheet" >
              <div className="transactions_info">
                <div className="transaction_img">
                  <img className="rt_img_type" src={dealIcon} />
                </div>

                <div className="transactions_userinfo">
                  <p className="transaction_username">{data.question ? pollComment : data.redeemedRun ? dealComment : data.deductedEntryRun ? predictionDeduct : data.rewarded ? predictionReward:pitchComment}{moment(data.createdDate).format('ll')} | {moment(data.createdDate).format('LT')}</p>
                  <p className={`transaction_type`} style={{ color: `${(data.redeemedRun || data.deductedEntryRun) ? '#F37121' : ''}` }}>
                    {data.redeemedRun ? 'Debited' : 'Credited'}
                  </p>
                </div>
              </div>

              <div className="rt_transaction_runs">
                <img className="transactionball_img" src={redBalls} />
                <p className="transaction_totalruns">{data.redeemedRun ? data.redeemedRun : data.run ? data.run : data.deductedEntryRun ? data.deductedEntryRun : data.rewarded ? data.rewarded:30}</p>
                <p className="transaction_totalruns">Runs</p>
              </div>
            </div>
          ))}
        </div>
        {/* </InfiniteScroll> */}
      {/* {hasMore && (
            <div style={{ textAlign: "center", margin: "20px 0" }}>
              <BallTriangle
                height="100"
                width="100"
                color="grey"
                ariaLabel="loading"
              />
            </div>
          )} */}
      {/* <div className="runstransactiontable_footer">
        <div className="rt_data_download">
          <div>
            <img className="rt_pdf_icon" src={pdfIcon} />
          </div>
          <div>
            <img className="rt_excel_icon" src={excelIcon} />
          </div>
        </div>
      </div> */}
    </>
  );
};

export default RunsTransactionTable;
