
import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import { getStorageItem } from '../../utils/sessionStorage';
import axios from "axios";


const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    boxShadow: 24,
    p: 4,
};
const LoanModel = (props) => {
    const { openModel, handleCloseModel, LoanAmount,handleReddemLoanDeal } = props;
    const [open, setOpen] = useState(openModel);
    const accessToken = getStorageItem("token");
    const userId = getStorageItem("user_id");
    const email = getStorageItem("user_email");
    const name = getStorageItem("full_name");
    const handleClose = () => {
        handleCloseModel(false);
        setOpen(false);
    };

    const handleConfirm = () => {
        if (!userId || !accessToken ) return;
        const getUserInfo = {
          method: "POST",
          url: global.config.ROOTURL.prod + "/auth/profile/",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
          data: {
            userId: userId,
          },
        };
        axios(getUserInfo)
          .then((response) => {
            handleReddemLoanDeal(name, email, response?.data?.mobileNo,LoanAmount)
            handleClose();
    
          }
          )
          .catch((error) => {
            console.log(error);
          })
    
      }

    useEffect(() => {
    }, [props])

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <p>
                    You also have the option to borrow runs in order to redeem a deal. This deal will be reserved, and once you've earned enough runs to cover the borrowed amount, we will deliver the deal to you
                    </p>

                    <div>
                        <Button onClick={handleConfirm}>Confirm</Button>
                        <Button onClick={handleClose}>Cancel</Button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
};

export default LoanModel;
