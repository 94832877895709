
import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { Alert } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import LogoWithTitle from '../logo.component';
import Button from '@mui/material/Button';
import "./verification.scss";


const VerficationLogin = ({ state }) => {
    const [verificationText1, setVerificationText1] = useState('');
    const [verificationText2, setVerificationText2] = useState('');
    const [verificationText3, setVerificationText3] = useState('');
    const [verificationText4, setVerificationText4] = useState('');
    const [disableRegisterBtn, setDisableRegisterBtn] = useState(true);
    const [timer, setTimer] = useState(0);
    const [disableResend, setdisableResend] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();

    const inputReference1 = useRef(null);
    const inputReference2 = useRef(null);
    const inputReference3 = useRef(null);
    const inputReference4 = useRef(null);
    useEffect(() => {


        const startTime = () => {
            setTimer(30);
            const myInterval = setInterval(() => {
                setTimer((prevCounter) => prevCounter - 1);

            }, 1000);
            setTimeout(() => {
                setdisableResend(true);
                clearInterval(myInterval)
            }, 30000)

        }
        if (!disableResend) {

            startTime();
        }
    }, [disableResend])


    useEffect(() => {
        const verifyOTP = async (isAllTextField) => {
            if (!isAllTextField) {

                const sendOTPRes = await axios.post(`
                ${global.config.ROOTURL.prod}/auth/verifyOTP`,
                    {
                        email: state.email, otp:
                            parseInt(`${verificationText1.trim()}${verificationText2.trim()}${verificationText3.trim()}${verificationText4.trim()}`)
                    }
                );

                if (sendOTPRes.data) {
                    setDisableRegisterBtn(false);

                } else {
                    setDisableRegisterBtn(true);
                    setErrorMessage("OTP is wrong!");
                    inputReference1.current.focus();

                }
            }

        }
        verifyOTP(!(
            verificationText1.trim().length === 1
            && verificationText2.trim().length === 1
            && verificationText3.trim().length === 1
            && verificationText4.trim().length === 1
        ));

    }, [state.email, verificationText1, verificationText2, verificationText3, verificationText4]);

    const loginSubmit = (otp) => {
        if (!state.email && !otp) return
        const option = {
            email: state.email,
            otp: otp
        };

        var loginOptions = {
            method: "POST",
            url: global.config.ROOTURL.prod + "/auth/login-with-Otp",

            data: JSON.stringify(option),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            json: true,
        };

        axios(loginOptions)
            .then((response) => {

                if (response.data.data.match) {
                    localStorage.setItem("token", response.data.data.token);
                    localStorage.setItem("user_id", response.data.data.payload._id);
                    localStorage.setItem("user_email", response.data.data.payload.email);
                    localStorage.setItem("first_name", response.data.data.payload.firstName);
                    localStorage.setItem("last_name", response.data.data.payload.lastName);
                    localStorage.setItem("full_name", response.data.data.payload.firstName + " " + response.data.data.payload.lastName);
                    localStorage.setItem("avatar", response.data.data.payload.profilePhoto);
                    navigate('/pitch')
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const onRegisterClick = async () => {
        let data = {};
        let option = JSON.parse(JSON.stringify(state));
        option.otp = parseInt(`${verificationText1.trim()}${verificationText2.trim()}${verificationText3.trim()}${verificationText4.trim()}`);
        loginSubmit(option.otp);

    }

    const resendOTP = async (e) => {
        setdisableResend(false)
        e.preventDefault();
        axios.post(`
        ${global.config.ROOTURL.prod}/auth/reSendOTP`,
            { email: state.email, captchaToken: state.captchaToken }
        );
        setErrorMessage('resend')
        return false;
    }


    return (
        <div>


            <div className="banner1">
                <div className="form1">
                    <div className="heading">
                        <div>
                            <LogoWithTitle fontSize={"120"} />
                        </div>
                        {errorMessage.length > 0 && <Alert severity="error">
                            {errorMessage}
                        </Alert>}
                        <h1 className="head">Verification Code</h1>
                        <div><p className="p1">We sent you code on given Email Id to</p>
                            <p>verify your account</p>
                        </div>
                    </div>
                    <div className="gmail">
                        <p>{state.email}</p>
                    </div>
                    <div className="userinput">
                        <input
                            ref={inputReference1}
                            className={`inputFiled ${verificationText1.length === 1 ? '' : 'required'}`}
                            type="text" pattern="[0-9]*" inputmode="numeric"
                            maxLength="1"
                            onChange={(e) => {
                                setVerificationText1(e.target.value);
                                inputReference2.current.focus();
                            }}
                            autoFocus={true}
                        />
                        <input
                            ref={inputReference2}
                            className={`inputFiled ${verificationText2.length === 1 ? '' : 'required'}`}
                            type="text" pattern="[0-9]*" inputmode="numeric"
                            maxLength="1"
                            onChange={(e) => {
                                setVerificationText2(e.target.value);
                                inputReference3.current.focus();
                            }}

                        />
                        <input
                            ref={inputReference3}
                            className={`inputFiled ${verificationText3.length === 1 ? '' : 'required'}`}
                            type="text" pattern="[0-9]*" inputmode="numeric"
                            maxLength="1"
                            onChange={(e) => {
                                setVerificationText3(e.target.value);
                                inputReference4.current.focus();
                            }}

                        />
                        <input
                            ref={inputReference4}
                            className={`inputFiled ${verificationText4.length === 1 ? '' : 'required'}`}
                            type="text" pattern="[0-9]*" inputmode="numeric"
                            maxLength="1"
                            onChange={(e) => {
                                setVerificationText4(e.target.value);



                            }}

                        />
                    </div>
                    <div className="time">
                        <p className="count1"><span style={{ color: 'blue' }}>{timer}</span> Seconds </p>
                    </div>

                    <div className="b1">
                        <button
                            className={`registerBtn ${disableRegisterBtn ? 'disabled' : ''}`}
                            onClick={onRegisterClick}
                            disabled={disableRegisterBtn}>Login </button>
                    </div>
                    <p className="s1">
                        Didn’t Receive The Code ? <Button disabled={!disableResend} onClick={resendOTP}>Resend  </Button></p>


                </div>
            </div>
        </div>






    );
};
export default VerficationLogin