"use client";
import React, { useEffect, useState } from "react";
import "./index.scss";

const Gallery = ({ images }) => {
  const [mainImage, setMainImage] = useState(images?images[images.length-1]:"")
  useEffect(() => {
    if (images && images.length > 0) {
      setMainImage(images[images.length-1]);
    }
  }, [images]);

  return (
    <div className="gallery-wrapper">
      <div className="gallery--multiple">
        {images.map((image, index) => {
          return <img key={index} src={image} alt={image} className="small-sized" onClick={() => {setMainImage(image)}} />;
        })}
      </div>
      <div className="gallery--single">
        <img src={mainImage} alt={mainImage} className="big-sized" />
      </div>
    </div>
  );
};

export default Gallery;
