import React from "react";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import "./AddBox.css";
import AddIcon from "@mui/icons-material/Add";

const AddBox = () => {
  return (
    <div className="contest_admin_add-wrapper">
      <Button
        variant="text"
        startIcon={<AddIcon />}
        className="contest_admin_add--button"
      >
        Add
      </Button>
    </div>
  );
};

export default AddBox;
