import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import PaginationRounded from "../PaginationRounded";
import { toast, ToastContainer } from "react-toastify";
import { getStorageItem } from "../../../utils/sessionStorage";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
const userID = getStorageItem("user_id");
const accessToken = getStorageItem("token");

const Index = () => {
  const { state } = useLocation();
  const { answer, options, question, predictionId, _id } = state;
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState(answer ? answer : null);

  const handleChange = (option) => {
    console.log(option);
    setSelectedOption(option);
  };

  const navigateBack = () => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      navigate(`contest/view-questions/${predictionId}`, { replace: true }); // the current entry in the history stack will be replaced with the new one with { replace: true }
    }
  };

  const submitAnswer = () => {
    if (!accessToken) return
    if (!selectedOption) return toast.error("option not selected");

    const option = {
      method: "POST",
      url:
        global.config.ROOTURL.prod + "/contest/predictionQuestion/updateResult",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
        role: "Admin",
      },
      data: {
        predictionId,
        questionId: _id,
        answer: selectedOption,
      },
    };
    axios(option)
      .then(({ data }) => {
        toast.success("Answer updated successfully");
        navigateBack();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div>
      <ToastContainer />
      <div className="contest-admin-create">
        <div className="contest-admin-create-wrapper">
          <div className="contest-admin-create-wrapper-main">
            <div className="contest-admin-create-wrapper-header">
              <p className="contest-admin-create-wrapper-header--text">
                {state?.predictionName || ""}
              </p>
              <p
                onClick={() => {
                  navigateBack();
                }}
                className="contest-admin-create-wrapper-header--delete-text"
              >
                back
              </p>
            </div>
            <div className="contest-admin-create-wrapper-form">
              <div className="contest-create-row4-wrapper">
                <p className="admin-contest-creation-general-text">Q</p>
                <input
                  type="text"
                  className="contest-create-start-input-full"
                  value={question}
                />
              </div>
              {options &&
                options.length &&
                options.map((option, index) => (
                  <div className="contest-create-row5-wrapper" key={option._id}>
                    <p className="admin-contest-creation-general-text">
                      option{index + 1}
                    </p>
                    <div className="contest-create-radio-button-wrapper">
                      <label
                        className="contest-create-radio-label"
                        htmlFor={`option-${index + 1}`}
                      >
                        {option.text}
                      </label>
                      <input
                        type="radio"
                        className="contest-create-start-input-radio"
                        name="Options"
                        id={`option-${index + 1}`}
                        defaultChecked={answer && answer._id === option._id}
                        onChange={(e) => {
                          handleChange(option);
                        }}
                      />
                    </div>
                  </div>
                ))}

              <button
                onClick={submitAnswer}
                className="contest-preediction-admin-creation-submit-button"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
