import React, { useEffect } from 'react'
import './index.scss'
import CartItem from '../cart/CartItem'
import { Button } from '@material-ui/core'
import { useMediaQuery } from '@mui/material';
import { useState } from 'react';
import dummyimage from '../../assets/images/marketplace/dummyImage.png'
import pen from '../../assets/images/marketplace/edit.png'
import OrderMOdel from './OrderMOdel';
import { useParams } from 'react-router-dom';
import { getStorageItem } from "../../utils/sessionStorage";
import axios from 'axios';
import moment from 'moment';
export default function OrderReceived(params) {
  const mobileView = useMediaQuery("(max-width:900px)");
  const [isOrder, setOder] = useState(true);
  const [model, setmodel] = useState(false);
  const [order, setOrder] = useState([]);
  const [product, setProduct] = useState([]);
  const [info, setInfo] = useState({});
  // Access Token
  const accessToken = getStorageItem("token");
  const { id } = useParams();
  let userId = "";
  if(localStorage.getItem("user_id")) {
    userId = localStorage.getItem("user_id");
  }
  const handleclose = () => {
    setmodel(false);
  };
  const handleOpen = () => {
    setmodel(true);
  };

  // Function to fetch product
  const fetchProduct = async (data) => {
    try {
      const response = await fetch(
        global.config.ROOTURL.prod +
          `/marketplace/product/getSingleProductByProductId/${data.productId}`,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/json",
          },
        }
      );
      const product = await response.json();
      setProduct(product);
      if (data) {
        
        setInfo({
          productInfo: product,
          orderInfo: data,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchOrder = async (id) => {
    try {
      const response = await fetch(
        global.config.ROOTURL.prod +
          `/marketplace/buyer/singlePurchasedProduct/${id}`,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setOrder(data);
      await fetchProduct(data);
    } catch (err) {
      console.log(err.message);
    }
  };
  useEffect(() => {
    fetchOrder(id);
  }, [id]);
  return (
    <div className="MainCartContainer123">
      <div className="marketFirstChild">
        {/* above part  */}
        <div className="marketCartAbovePart1234">
          {!mobileView && <p className="CartHeading">Order Received</p>}
          {/* cart item  */}
          <div className="OrderContainer123">
            {(info?.productInfo && info?.orderInfo) && (
              <CartItem isCart={false} product={info} />
            )}
          </div>
        </div>
        {/* lower part  */}
        <div className="orderlowerPart">
          <div className="orderLower1part">
            <div className="firstchild">
              <p className="font500">Order Placed by</p>
              <p className="font300">{moment(order?.createdDate).calendar()}</p>
            </div>
            <div className="imagechild">
              <img src={dummyimage} alt="dummy" />
              <p className="font300" style={{ paddingLeft: "10px" }}>
                {order?.buyerName}
              </p>
            </div>
          </div>

          {/* next one */}
          <div className="orderLower1part">
            <div className="firstchild">
              <p className="font500">Address</p>
            </div>
            <div className="secoundChild">
              <div className="font400">
                <p>Full Name</p>
                <p>Address Line 1</p>
                <p>Main Street Address Line 2</p>
                <p>City</p>
                <p>State</p>
                <p>Postal Code</p>
              </div>
              <div className="font300">
                <p>{order?.buyerName}</p>
                <p>{order?.addressLine1}</p>
                <p>{order?.street}</p>
                <p>{order?.city}</p>
                <p>{order?.state}</p>
                <p>{order?.postalCode}</p>
              </div>
            </div>
          </div>
          {product?.sellerId === userId && (
            <div className="orderlastMain">
              <p style={{ marginBottom: "10px" }} className="font500">
                Order Status
              </p>
              <div
                className="lastordercontainer font500"
                style={{ color: "#0C5BA0" }}
              >
                <p className="orderplaced">{order?.currentState}</p>
                <div className="editcontainer">
                  <img src={pen} alt="pen" />
                  <p onClick={handleOpen}>Edit</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {model && <OrderMOdel Open={model} handleclose={handleclose} />}
    </div>
  );
}
