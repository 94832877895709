import axios from "axios";

const auth_ms_url = global.config.ROOTURL.prod;
const accessToken = localStorage.getItem("token");
const headers = {
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
    },
};

const getNestedCommentCount = async (jobId, commentId) => {
    let response = await axios.get(auth_ms_url + `/job/sharejob/comment/getNestedCommentsCount/${jobId}/${commentId}`, headers);
    return response;
};

const getNestedCommentCountPitch = async (pitchId, commentId) => {
    let response = await axios.get(auth_ms_url + `/pitch/comment/getNestedCommentCount/${pitchId}/${commentId}`, headers);
    return response;
};


export {
    getNestedCommentCount, getNestedCommentCountPitch
};