import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import cross from '../../assets/images/marketplace/cross.png'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 365,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    backgroundColor:'white',
    padding: "12px",
    borderRadius: "10px"

  };
export default function OrderMOdel({ Open, handleclose }) {

    const [open, setOpen] = React.useState(Open);
    const handleClose = () => {
        handleclose(false);
        setOpen(false);
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div style={style}>
                <div className='Modelcontainer'>
                    <img src={cross} onClick={handleClose} />
                </div>
                <div className='ButtonModelContainer'>
                    <Button className='modelButton'>Order Placed</Button>
                    <Button className='modelButton'>Shiped</Button>
                    <Button className='modelButton'>Declared</Button>
                    <Button className='modelButton'>Canceled</Button>
                </div>
            </div>
        </Modal>
    )
}

