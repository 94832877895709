import React from 'react';
import './index.scss';
import Carousel from '../../components/ads/Carousel';

const Advertismets = () => {
  return (
    <div className='earn-rewards__wraper'>
      {/* Video carousel section */}
      <div className='video-banner' style={{backgroundImage:`url('https://assets-global.website-files.com/60859154db9558fc04fc06fe/65a1518eb99447a79a387788_advertising-in-games-cover.png')`}}>
        <h1 className='video-banner__title'>Epic Showdown: Intense clash between rivals</h1>
        <p className='video-banner__description'>Witness the thriling battle as two cricket giants face off for supermacy on the pitch. Brace yourself for heart-stopping moments, jaw dropping catches, and boundry-blasting shorts in the heart-stakes encounter.</p>
      </div>
      {/* Admin ads section */}
      <Carousel />
      {/* User ads section */}
    </div>
  )
}

export default Advertismets