import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import { Modal, Box } from "@mui/material";
import { toast } from "react-toastify";
import { getStorageItem } from "../../../utils/sessionStorage";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
const userID = getStorageItem("user_id");
const accessToken = getStorageItem("token");
const moment = require("moment");

const Index = (props) => {
  const [fours, setfours] = useState(0);
  const [basePrice, setbasePrice] = useState(0);
  const [sixes, setsixes]= useState(0);
  const [dots, setdots]= useState(0);
  const [ones, setones]= useState(0);
  const [twos, settwos]= useState(0);
  const [wickets, setwickets]=useState(0);
  const [runsScored, setruns]=useState(0);
  const [catches, setcatches]=useState(0);
  
  const handleEditPlayer = async () => {
    if (!accessToken) return
    
    const data1 = {
      id: props?.playerData?._id,
      fantasyId: props?.playerData?.fantasyId,
      basePrice: basePrice ? basePrice:props?.playerData?.basePrice,
      playerDetails: props?.playerData?.playerDetails,
      fours: fours ? fours : props?.playerData?.fours,
      sixes: sixes ? sixes : props?.playerData?.sixes,
      runsScored: runsScored ? runsScored : props?.playerData?.runsScored,
      catches: catches ? catches : props?.playerData?.catches,
      wickets: wickets ? wickets : props?.playerData?.wickets,
      ones: ones ? ones : props?.playerData?.ones,
      twos: twos ? twos : props?.playerData?.twos,
      dots: dots ? dots : props?.playerData?.dots,
      
    };
    const options = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/contest/fantasyMatch/updatePlayerScore",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
        role: "Admin",
      },
      data: data1,
    };
    axios(options)
      .then(() => {
        toast.success(" Player Edited successfully");
        props.handleClose();
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          console.log(error);
        }
      });
  };
  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <div className="contest-admin-create">
          <div className="contest-admin-create-wrapper">
            <div className="contest-admin-create-wrapper-main">
              <Box style={{ float: "right" }}>
                <IconButton onClick={props.handleClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <div className="contest-admin-create-wrapper-header">
                <p className="contest-admin-create-wrapper-header--text">Edit Player Stats</p>
              </div>
              
              <div className="contest-admin-create-wrapper-form">
              <div className="contest-create-name-wrapper">
                  Base Price-
                  <input
                    type="text"
                    className="contest-create-name"
                    placeholder="Price"
                    defaultValue={props?.playerData?.basePrice}
                    onChange={(event) => {
                      setbasePrice(event.target.value);
                    }}
                    required
                  />
                  </div>
                <div className="contest-create-name-wrapper">
                  Fours- 
                  <input
                    type="text"
                    className="contest-create-name"
                    placeholder="Fours"
                    defaultValue={props?.playerData?.fours}
                    onChange={(event) => {
                      setfours(event.target.value);
                    }}
                    required
                  />
                  </div>
                  <div className="contest-create-name-wrapper">
                  Sixes- 
                  <input
                    type="text"
                    className="contest-create-name"
                    placeholder="Sixes"
                    defaultValue={props?.playerData?.sixes}
                    onChange={(event) => {
                      setsixes(event.target.value);
                    }}
                    required
                  />

                  </div>
                  <div className="contest-create-name-wrapper">
                  Dots-  
                  <input
                    type="text"
                    className="contest-create-name"
                    placeholder="Dot balls"
                    defaultValue={props?.playerData?.dots}
                    onChange={(event) => {
                      setdots(event.target.value);
                    }}
                    required
                  />

                  </div>
                  <div className="contest-create-name-wrapper">
                  Singles-  
                  <input
                    type="text"
                    className="contest-create-name"
                    placeholder="Singles"
                    defaultValue={props?.playerData?.ones}
                    onChange={(event) => {
                      setones(event.target.value);
                    }}
                    required
                  />

                  </div>
                  <div className="contest-create-name-wrapper">
                  Twos-  
                  <input
                    type="text"
                    className="contest-create-name"
                    placeholder="Doubles"
                    defaultValue={props?.playerData?.twos}
                    onChange={(event) => {
                      settwos(event.target.value);
                    }}
                    required
                  />

                  </div>
                  
                  <div className="contest-create-name-wrapper">
                  Wickets-  
                  <input
                    type="text"
                    className="contest-create-name"
                    placeholder="Wickets"
                    defaultValue={props?.playerData?.wickets}
                    onChange={(event) => {
                      setwickets(event.target.value);
                    }}
                    required
                  />
                  </div>
                  <div className="contest-create-name-wrapper">
                  Catches- 
                  <input
                    type="text"
                    className="contest-create-name"
                    placeholder="Catches"
                    defaultValue={props?.playerData?.catches}
                    onChange={(event) => {
                      setcatches(event.target.value);
                    }}
                    required
                  />
                  </div>
                  <div className="contest-create-name-wrapper">
                  Runs Scored-  
                  <input
                    type="text"
                    className="contest-create-name"
                    placeholder="Runs"
                    defaultValue={props?.playerData?.runsScored}
                    onChange={(event) => {
                      setruns(event.target.value);
                    }}
                    required
                  />
                  
                 
                </div>
                
                
                <button
                  onClick={handleEditPlayer}
                  className="contest-preediction-admin-creation-submit-button"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Index;
