import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import Alert from "../alerts";
import Hero from "../hero";
import Posts from "../posts";
import MyCarousel from "../carousel";
import Story from "../stories";
import Polls from "../poll/poll";
import "./index.scss";
import { useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";
import axios from "axios";
import Post from "../posts/post";
import ActiveuserRunModel from "./ActiveuserRunModel";
const Feeds = (props) => {
  const mobileView = useMediaQuery("(max-width:899px)");
  const { pitchCreatedProps } = props;
  const [openModel, setopenModel] = useState(false);
  const [userData, setuserData] = useState({});
  const [pitchCreated, setPitchcreated] = useState(false);
  const [pinpoll, setPinPoll] = useState([]);
  const accessToken = localStorage.getItem("token");
  const userId = localStorage.getItem("user_id");
  const handleClose = () => {
    setopenModel(false);
  };
  const getPerDayBonusRuns = () => {
    if (!userId) return;
    var rejectdetails = {
      method: "POST",
      url: global.config.ROOTURL.prod + `/auth/activeuser/createuserEntry`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      data: {
        userId,
      },
    };
    axios(rejectdetails)
      .then((res) => {
        console.log("res.data", res);
        if (res?.data?.message !== "") {
          const newdata = res.data;
          setuserData(newdata);
          setopenModel(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchPInPoll = () => {
    if (!accessToken) return;
    const options = {
      method: "get",
      // url: global.config.ROOTURL.prod + `/carousel/getAll/${pageNumber}/6`,
      url: global.config.ROOTURL.prod + `/poll/getAllPinPoll`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };

    axios(options)
      .then(({ data }) => {
        setPinPoll([...data]);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          console.log(error);
        }
      });
  };

  useEffect(() => {
    fetchPInPoll();
    getPerDayBonusRuns();
    // setPitchcreated(!pitchCreated);
    window.scrollTo(0, 0);
  }, [pitchCreated]);

  return (
    <div className="component feeds">
      <div>
        <MyCarousel />
      </div>
      <div>{<Story />}</div>
      <div
        style={{
          paddingTop: "15px",
          paddingBottom: "15px",
          display: `${mobileView ? "none" : ""}`,
        }}
      >
        <Alert
          hprops={() => {
            setPitchcreated(!pitchCreated);
          }}
        />
      </div>
      {pinpoll.map((post, index) => (
        <div key={index} style={{ width: "100%" }}>
          <Polls key={index} post={post} postquestion={post?.question} />
        </div>
      ))}
      <Posts
        filter={"all_pitches"}
        // pitchCreatedProps={pitchCreated}
        hprops={() => {
          setPitchcreated(!pitchCreated);
        }}
      />
      {openModel && (
        <ActiveuserRunModel
          data={userData}
          Open={openModel}
          handleCloseModel={handleClose}
        />
      )}
    </div>
  );
};

export default Feeds;
