import React from "react";
import "./Filter.css";

const Filter = () => {
  return (
    <ul className="admin_contest-filter-main">
      {["Active", "Schedule", "Closed", "Ended", "All"].map((status, index) => {
        return (
          <li className="admin_contest-filter-main-text" key={index}>
            {status}
          </li>
        );
      })}
    </ul>
  );
};

export default Filter;
