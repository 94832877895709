import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Post from "./post";
import "./index.scss";
import { getStorageItem } from "../../utils/sessionStorage";
import PostPage from "./post-page";
import { Box } from "@mui/system";
import { useMediaQuery } from "@material-ui/core";
import PostPopup from "./PostPopup";

const PitchPage = (props) => {
  const navigate = useNavigate();
  const [posts, setPosts] = useState(null);
  const accessToken = getStorageItem("token");
  const { pid, type } = props;
  const [isPostOpen, setIsPostOpen] = useState(false);
  const [popupData, setpopupData] = useState(false);
  const [newSharedDetail, setNewSharedDetail] = useState([]);
  const [sharedisable, setSharedisable] = useState(true);
  const ActiveUser = localStorage.getItem("user_id");
  const [shareOriginalPost, setoriginalPost] = useState(null);
  const [propsData, setPropsData] = useState({ post: {} });



  const fetchPitches = () => {
    if (!pid && !accessToken) return;
    axios({
      method: "get",
      url: global.config.ROOTURL.prod + "/pitch/pitchById/" + pid,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((response) => {
        setPosts(response.data);
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          console.log(error);
        } else if (error?.response?.status === 401) {
          navigate("/login");
        }
      });
  };
  const style = {
    position: "absolute",
    top: "57%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40vw",
    bgcolor: "background.paper",
    // border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 3,
  };

  const checkShare = async () => {
    const sharedata = posts?.sharedPost;
    const MainUserId = ActiveUser;
    // issue is here
    if (sharedata) {
      const ans = sharedata.filter((i) => i === MainUserId);
      if (ans.length > 0) {
        setSharedisable(false);
      } else {
        setSharedisable(true);
      }
    }
  };

  const handlePopup = () => {
    setIsPostOpen(!isPostOpen);
    setpopupData(true);
  };
  const handleclose = () => {
    setpopupData(false);
  };

  useEffect(() => {
    setPropsData({ post: posts })
    if (posts?.sharedDetail) {
      setoriginalPost(posts?.sharedDetail?.originalPitchId);
    } else {
      setoriginalPost(posts?._id);
    }
    checkShare();
    if (posts?.sharedDetail !== undefined) {
      setNewSharedDetail(posts?.sharedDetail);
    }
    return () => {
      setNewSharedDetail([]);
      setoriginalPost(null);
      setoriginalPost(null);
      setSharedisable(true);
    };
  }, [posts]);


  useEffect(() => {
    fetchPitches();
    handlePopup();
  }, [pid]);
  const mobileView = useMediaQuery("(max-width:600px)");


  return (
    <>
      <Box sx={!mobileView && style} className="component posts pitch-page">
        {posts && (
          <div>
            <div>
              Ad
            </div>
            <PostPopup
              type={"notificationpopup"}
              postdata={posts}
              props={propsData}
              open={isPostOpen}
              setOpen={setIsPostOpen}
              handleclose={handleclose}
              sharedetail={newSharedDetail}
              sharedisable={sharedisable}
              originalpost={shareOriginalPost}
            />
          </div>
        )}
      </Box>
    </>
  );
};

export default PitchPage;
