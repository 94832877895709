import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Grid } from "@mui/material";
import "./rules.scss";
import { Helmet } from "react-helmet";
import runImg from "../../assets/images/rules/run.png";
import dealImg from "../../assets/images/rules/deal.png";
import spamImg from "../../assets/images/rules/spam.png";
import bgImg from "../../assets/images/rules/champhunt.png";
// import character from "../../assets/images/rules/Character.png";
import character from "./img/bg_policy.png";
import { useState } from "react";

const NewRefundPage = () => {
  const [expandedSection, setExpandedSection] = useState(null);

  const handleAccordianChange = (panel) => (event, isExpanded) => {
    setExpandedSection(isExpanded ? panel : null);
  };
  return (
    <div
      className="rule-container"
      style={{ backgroundColor: expandedSection ? "#fafafa" : "#07365f" }}
    >
      <Helmet>
        <title>Champhunt | Rules</title>
      </Helmet>
      <img className="bg" src={bgImg} alt="background" />
      <div className="inner-container">
        <Grid item xs={12} className="inner2-container">
          <img className="character" src={character} alt="character" />
          <Typography className="headding">REFUND POLICIES</Typography>
        </Grid>
        <Grid item xs={12} className="rules-accordian">
          <Accordion className="accordian-list">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              {" "}
              <Typography className="accordian-heading">
                Return Policies
              </Typography>
            </AccordionSummary>
            <Grid item xs={12} className="horizontal"></Grid>
            <AccordionDetails className="accordian-details">
              <Typography>
                <p>
                  If you are not satisfied with your purchase, you can return it
                  within 15 days of receipt.
                </p>
                <h3 style={{ color: "#0C5BA0" }}>Conditions</h3>
                <ul type="a" style={{ paddingLeft: "23px" }}>
                  <li>
                    Items must be unused, in original packaging, and with proof
                    of purchase.
                  </li>
                </ul>

                <h3 style={{ color: "#0C5BA0" }}>How to Return</h3>
                <ol type="1" style={{ paddingLeft: "43px" }}>
                  <li style={{ color: "#C1510B" }}>Contact Us </li>
                  <ul type="a" style={{ paddingLeft: "23px" }}>
                    <li>
                      Email:{" "}
                      <a href="mailto:support@champhunt.com">
                        support@champhunt.com
                      </a>
                    </li>
                  </ul>

                  <li style={{ color: "#C1510B" }}>Ship the Item</li>
                  <ul type="a" style={{ paddingLeft: "23px" }}>
                    <li>
                      Return shipping costs are the customer's responsibility
                      unless the return is due to our error.
                    </li>
                    <li>Shipping instructions will be emailed</li>
                  </ul>
                </ol>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion className="accordian-list">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
              style={{ marginTop: "20px" }}
            >
              <Typography className="accordian-heading">
                Shipping Policies
              </Typography>
            </AccordionSummary>
            <Grid item xs={12} className="horizontal"></Grid>
            <AccordionDetails className="accordian-details">
              <Typography>
                <ul type="1" style={{ paddingLeft: "23px" }}>
                  <li>
                    Processed within 7-10 business days after receiving the
                    item.
                  </li>
                  <li>
                    Refunds will be issued to the original payment method.
                  </li>
                  <li>
                    It will take 5 business days to reflect refunds in
                    respective accounts.
                  </li>
                </ul>
                <p>
                  For questions, contact us at{" "}
                  <a href="mailto:support@champhunt.com">
                    support@champhunt.com
                  </a>
                </p>
                <p>Thank you for shopping with us!</p>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion className="accordian-list">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
              style={{ marginTop: "20px" }}
            >
              <Typography className="accordian-heading">
                Refund Policies
              </Typography>
            </AccordionSummary>
            <Grid item xs={12} className="horizontal"></Grid>
            <AccordionDetails className="accordian-details">
              <Typography>
                <h3 style={{ color: "#0C5BA0" }}>Processing Time</h3>
                <ul type="a" style={{ paddingLeft: "23px" }}>
                  <li>Orders processed in 1-2 business days.</li>
                </ul>
                <h3 style={{ color: "#0C5BA0" }}>Shipping Options</h3>
                <ul type="a" style={{ paddingLeft: "23px" }}>
                  <li>
                    <span style={{ fontWeight: "bold" }}> Standard : </span>{" "}
                    Products will be shipped in 5-7 business days.
                  </li>
                </ul>
                <h3 style={{ color: "#0C5BA0" }}>Tracking</h3>
                <ul type="a" style={{ paddingLeft: "23px" }}>
                  <li>Tracking info will be emailed after shipping.</li>
                </ul>
                <h3 style={{ color: "#0C5BA0" }}>Contact Us</h3>
                <ul type="a" style={{ paddingLeft: "23px" }}>
                  <li>
                    Email:{" "}
                    <a href="mailto:support@champhunt.com">
                      support@champhunt.com
                    </a>
                  </li>
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </div>
    </div>
  );
};

export default NewRefundPage;
