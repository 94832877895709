import React, { useEffect, useState } from "react";
import "./marketplace.scss";
import { getStorageItem } from "../utils/sessionStorage";
import { useNavigate } from "react-router-dom";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useContext } from "react";
import AppContext from "../context/AppContext";
import { PathAction } from "../redux/reducers/globalPath";
import { useDispatch } from "react-redux";
import { CartContext } from "../context/CartContext";
import { toast } from "react-toastify";

// User Information
const userId = getStorageItem("user_id");
const userName = getStorageItem("full_name");
const userAvatar = getStorageItem("avatar");
const userEmail = getStorageItem("user_email");

// Access Token
const accessToken = getStorageItem("token");

// Function to calculate final product price after discount
// const calculateDiscount = (price, discountPercent) => {
//   return Math.round(price - price * (discountPercent / 100));
// };

const Card = ({ product, index, type, handleModal, verifiedSellerChecked }) => {
  const navigate = useNavigate();
  const path = window.location.pathname;
  const dispatch = useDispatch();
  dispatch(PathAction.handlePath({ payload: path }));
  const appContext = useContext(AppContext);
  const { setShowFooter } = appContext;
  const [cart, setCart] = useContext(CartContext);
  const [wishListed, setWishListed] = useState(false);
  const [isProductPresent, setIsProductPresent] = useState(false);
  const [sellerInfo, setSellerInfo] = useState(null);
  const handleCheckIfProductIsWishLitedOrNot = async () => {
    if (!accessToken && !userId) return
    try {
      const response = await fetch(
        global.config.ROOTURL.prod +
        `/marketplace/product/checkIfProductIsWishListedByUserId`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: userId,
            productId: product?._id,
          }),
        }
      );
      const res = await response.json();
      if (res === true) {
        setWishListed(true);
      } else {
        setWishListed(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleGetSellerInformation = async () => {
    try {
      const response = await fetch(
        global.config.ROOTURL.prod +
        `/marketplace/seller/findInformationOfSingleSellerById/${product?.sellerId}`,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/json",
          },
        }
      );
      const res = await response.json();
      setSellerInfo(res);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleGetSellerInformation();
  }, []);

  useEffect(() => {
    handleCheckIfProductIsWishLitedOrNot();
  }, []);

  useEffect(() => {
    // Login to check if the product exists in cart or not
    if (cart.length > 0) {
      const productFound = cart.find((item) => {
        return item?.productInfo?._id === product._id;
      });
      if (productFound) {
        setIsProductPresent(true);
      }
    }
  }, [cart]);

  const handleStarClick = async (_id) => {
    // handle the star icon click here
    if (!_id && !userId) return
    try {
      const response = await fetch(
        global.config.ROOTURL.prod +
        "/marketplace/product/addProductToWishList",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            userId: userId,
            productId: product?._id,
          }),
        }
      );
      const res = await response.json();
      console.log(res);
      setWishListed(true);
    } catch (err) {
      console.log(err);
    }
  };
  const handleStarUnselect = async (_id) => {
    // handle the star icon click here
    if (!accessToken && !userId) return
    try {
      const response = await fetch(
        global.config.ROOTURL.prod +
        `/marketplace/product/removeProductFromWishList`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: userId,
            productId: product?._id,
          }),
        }
      );
      const res = await response.json();
      setWishListed(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleAddToCart = async (product) => {
    if (!product) return;
    const productSellerInfo = {
      productInfo: product,
      sellerInfo: sellerInfo,
    };
    productSellerInfo.productInfo.quantity = 1;
    setCart([...cart, productSellerInfo]);
    localStorage.setItem("cart", JSON.stringify([...cart, productSellerInfo]));
    toast.success(`${product?.title} added to cart`);
  };

  useEffect(() => {
    // getRuns();
    setShowFooter(true);
    return () => {
      setShowFooter(false);
    };
  }, []);
  return type === "editable" ? (
    <div
      key={product?._id}
      className={
        verifiedSellerChecked && !sellerInfo?.isSellerVerified
          ? `hideCard`
          : `card`
      }
      onClick={() => {
        navigate(`/marketplace/single/${product?._id}`);
      }}
    >
      <img
        src="/marketplace/delete.svg"
        alt="delete-product"
        className="delete__product"
        onClick={(event) => {
          event.stopPropagation();
          handleModal(product?._id);
        }}
      />
      <img
        src="/marketplace/edit.svg"
        alt="edit-product"
        className="edit__product"
        onClick={(event) => {
          event.stopPropagation();
          navigate(`/marketplace/edit/${product?._id}`);
        }}
      />
      <div style={{ height: "134px" }}>
        <img
          src={
            product?.images[0] ||
            "https://images.unsplash.com/photo-1447752875215-b2761acb3c5d?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&ixid=eyJhcHBfaWQiOjE0NTg5fQ"
          }
          alt="popular-product"
          className="card__image"
        />
      </div>
      <div className="card__box" style={{ height: "100%" }}>
        <div className="title__box--flex">
          {product?.title?.length > 20 ? (
            <h3>{product?.title.substring(0, 20)}...</h3>
          ) : (
            <h3>{product?.title}</h3>
          )}
          <div className="discount_price">
            <p>
              {product?.discount !== 0
                ? `₹${product?.discount} off`
                : "No Discount"}
            </p>
          </div>
        </div>
        <div
          style={{
            fontSize: "10px",
            fontWeight: "400",
            fontFamily: "Poppins",
            paddingBottom: "15px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p>Original price</p>
            <p
              style={{
                textDecoration: product?.discount !== 0 && "line-through",
                color: product?.discount !== 0 && "#B1B1B1",
              }}
            >
              {" "}
              ₹{product?.price}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <p className="seller__username">{sellerInfo?.sellerName}</p>
              {sellerInfo?.isSellerVerified && (
                <VerifiedIcon style={{ color: "#FEBC3C" }} />
              )}
            </div>
            {product?.discount !== 0 && (
              <p> ₹{product?.price - product?.discount}</p>
            )}
          </div>
        </div>

        <div className="cart__botton">
          <ShoppingCartOutlinedIcon
            style={{
              color: "#0C5BA0",
              width: "0.8em",
              position: "absolute",
              bottom: "2",
            }}
          />
          <p className="cart__runs">{product?.runs} Runs</p>
        </div>
      </div>
    </div>
  ) : (
    <div
      key={product?._id}
      className={
        verifiedSellerChecked && !sellerInfo?.isSellerVerified
          ? `hideCard`
          : `card`
      }
      onClick={() => {
        navigate(`/marketplace/single/${product?._id}`);
      }}
    >
      {!wishListed ? (
        <a
          className="star-icon"
          onClick={(event) => {
            event.stopPropagation();
            handleStarClick(product?._id);
          }}
        >
          <img
            src="/marketplace/star-nofill.svg"
            alt="star-nofill"
            className="star"
          />
        </a>
      ) : (
        <a
          className="star-icon"
          onClick={(event) => {
            event.stopPropagation();
            handleStarUnselect(product?._id);
          }}
        >
          <svg
            className="star"
            width="22"
            height="16"
            viewBox="0 0 22 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.1274 1.64942C12.9476 1.52448 12.8359 1.32177 12.8359 1.10287C12.8359 0.746184 12.5468 0.457031 12.1901 0.457031H9.79584C9.43563 0.457031 9.14363 0.749033 9.14363 1.10924C9.14363 1.32901 9.03222 1.53283 8.85202 1.65864C8.44456 1.94313 7.99238 2.29879 7.70773 2.64453C7.38397 3.03778 7.14173 3.59473 6.9742 4.08316C6.8253 4.51732 6.43138 4.83203 5.9724 4.83203H4.52825C2.48904 4.83203 0.835938 6.51096 0.835938 8.58203V11.707C0.835938 13.7781 2.48904 15.457 4.52825 15.457H17.1436C19.1828 15.457 20.8359 13.7781 20.8359 11.707V8.58203C20.8359 6.51096 19.1828 4.83203 17.1436 4.83203H15.9816C15.5308 4.83203 15.141 4.52859 14.9904 4.10372C14.8082 3.58961 14.5468 2.99387 14.2115 2.58666C13.935 2.25082 13.5123 1.917 13.1274 1.64942ZM12.8017 3.04416C12.6312 2.78515 12.3338 2.64453 12.0237 2.64453H9.91973C9.62069 2.64453 9.33364 2.77566 9.15796 3.01765C8.98833 3.25131 8.82511 3.50874 8.73337 3.73828C8.72667 3.75505 8.72015 3.77185 8.71381 3.78867C8.50981 4.32995 8.99015 4.83203 9.56859 4.83203H12.2584C12.8773 4.83203 13.3661 4.2663 13.1445 3.68836C13.1383 3.67213 13.132 3.65597 13.1255 3.6399C13.0469 3.44323 12.9279 3.23588 12.8017 3.04416Z"
              fill="#0C5BA0"
              stroke="#0C5BA0"
              strokeWidth="0.6"
            />
          </svg>
        </a>
      )}
      <div className="discount_price">
        <p>{product?.discount > 0 ? `₹${product?.discount} off` : ""}</p>
      </div>

      <div style={{ height: "134px" }}>
        <img
          src={
            product?.images[0] ||
            "https://images.unsplash.com/photo-1447752875215-b2761acb3c5d?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&ixid=eyJhcHBfaWQiOjE0NTg5fQ"
          }
          alt="popular-product"
          className="card__image"
        />
      </div>
      <div className="card__box" style={{ height: "100%" }}>
        {product?.title?.length > 20 ? (
          <h3>{product?.title.substring(0, 20)}...</h3>
        ) : (
          <h3>{product?.title}</h3>
        )}
        <div
          style={{
            fontSize: "10px",
            fontWeight: "400",
            fontFamily: "Poppins",
            paddingBottom: "15px",
          }}
        >
          {/* <p style={{ textAlign: 'end' }}>₹ {product?.discount}</p> */}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p>Original price</p>
            <p
              style={{
                textDecoration: product?.discount !== 0 && "line-through",
                color: product?.discount !== 0 && "#B1B1B1",
              }}
            >
              {" "}
              ₹{product?.price}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <p className="seller__username">{sellerInfo?.sellerName}</p>
              {sellerInfo?.isSellerVerified && (
                <VerifiedIcon style={{ color: "#FEBC3C" }} />
              )}
            </div>
            {product?.discount !== 0 && (
              <p> ₹{product?.price - product?.discount}</p>
            )}
          </div>
        </div>

        {isProductPresent ? (
          product?.stock > 0 ? (
            <div
              className="cart__botton"
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <ShoppingCartOutlinedIcon
                style={{
                  color: "#EAEAEA",
                  width: "0.8em",
                  position: "absolute",
                  bottom: "2",
                }}
              />
              <p className="cart__runs" style={{ color: "#EAEAEA" }}>
                {product?.runs} Runs
              </p>
            </div>
          ) : (
            <div
              className="cart__botton cart__button--oos"
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <p className="cart__runs" style={{ color: "#EAEAEA" }}>
                {product?.runs} Runs
              </p>
            </div>
          )
        ) : product?.stock > 0 ? (
          <div
            className="cart__botton"
            onClick={(event) => {
              event.stopPropagation();
              handleAddToCart(product);
            }}
          >
            <ShoppingCartOutlinedIcon
              style={{
                color: "#0C5BA0",
                width: "0.8em",
                position: "absolute",
                bottom: "2",
              }}
            />
            <p className="cart__runs">{product?.runs} Runs</p>
          </div>
        ) : (
          <div
            className="cart__botton cart__button--oos"
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <p className="cart__runs">Out of Stock</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Card;
