import { Box, Typography } from "@material-ui/core";
import React from "react";
import CoverPhoto from "./CoverPhoto";


export default function CoverPhotoCreate() {
  return (
    <Box>
      <Typography variant="h4">Create new carousel</Typography>
      {/* <Typography variant="p">
        Want to create a new event. Register an event here in few minutes.
      </Typography> */}
      <Box marginTop={2}>
        <CoverPhoto/>
      </Box>
    </Box>
  )
}
