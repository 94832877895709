import React, { useState, useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import { BallTriangle } from 'react-loader-spinner';
import axios from "axios";
import { getStorageItem } from '../../utils/sessionStorage';
import { Button } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import './SearchUser.scss'
export default function SearchUser() {
    const [searchText, setSearchText] = useState("");
    const accessToken = getStorageItem('token');
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);
    const [items, setItems] = useState([]);
    const navigate = useNavigate();

    const handleuserChat = (id) => {
        navigate(`/profile/chat/${id}`)
    }
    const handleSearch = () => {
        fetchMoreData();
    }
    const fetchMoreData = async () => {

        if (searchText === '') return;
        const req = {
            method: "POST",
            url: global.config.ROOTURL.prod + `/chat/Searchuser`,
            headers: {
                Authorization: "Bearer " + accessToken,
            },
            data: {
                searchText: searchText,
                pageNumber: page,
                pagePerSize: global.pagePerSize,
            },
        };

        axios(req).then(response => {

            if (response.data.length === 0) {
                setHasMore(false);
            } else {
                setItems(prevItems => prevItems.concat(response.data));
                setPage(prevPage => prevPage + 1);

                if (response.data.length === 10) {
                    setHasMore(true);
                }
            }

        })
            .catch(error => console.log(error));

    };
    return (
        <>

            <div>
                <div>
                    <input type='text' value={searchText} onChange={(e) => setSearchText(e.target.value)} />

                    {searchText.length >= 3 && <Button onClick={handleSearch}>Search</Button>}

                </div>

                <InfiniteScroll
                    dataLength={items.length}
                    next={fetchMoreData}
                    hasMore={hasMore}
                >
                    {items.map((data, index) => (
                        <div key={index} onClick={() => handleuserChat(data._id)} className='MainSearchconatiner'>
                            <img src={data.profilePhoto} className='searchImage' />
                            <p className='para'>{data.firstName + " " + data.lastName}</p>
                        </div>
                    ))}

                </InfiniteScroll>
            </div>


        </>
    )
}
