import React from "react";
import { useEffect, useState, useRef, useCallback } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import SortIcon from '@mui/icons-material/Sort';
import { getStorageItem } from "../../utils/sessionStorage";
import { Button, Grid, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea, CardActions } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import InfiniteScroll from 'react-infinite-scroll-component';
import { BallTriangle } from 'react-loader-spinner';
import "./index.scss";
// React-Toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FilterModal from "../NewDeals/FilterModal";

const DealScroll = (props) => {
  const navigate = useNavigate();
  const accessToken = getStorageItem("token");
  const userId = getStorageItem("user_id");
  const [shouldLoadPage, setShouldLoadPage] = useState(false);

  const [filterModal, setFilterModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPageNumber, setTotalPageNumber] = useState(0);
  const [filterName, setFilterName] = useState("");
  const [sortName, setSortName] = useState("");
  const [deals, setDeals] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const handleClose = () => setFilterModal(!filterModal);


  const fetchMoreData = async () => {
    if (!accessToken) return;
    let url;
    if (filterName) {
      url = global.config.ROOTURL.prod + `/deal/findByCategoryInAdmin/${filterName}/${page}/${global.config.pagePerSize}`;
    } else if (sortName) {
      url = global.config.ROOTURL.prod + `/deal/sortDealsInAdmin/${sortName}/${page}/${global.config.pagePerSize}`;
    } else {
      url = global.config.ROOTURL.prod + `/deal/getAllDeals/${page}/${global.config.pagePerSize}`
    }
    // Fetch additional data
    const req = {
      method: "GET",
      url: url,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };

    axios(req).then(response => {

      if (response.data.length === 0) {
        setHasMore(false);
      } else {
        setDeals(prevItems => prevItems.concat(response.data));
        setPage(prevPage => prevPage + 1);
        setShouldLoadPage(true);
        if (response.data.length === 10) {
          setHasMore(true);
        }
      }

    })
      .catch(error => console.log(error));

  };
  useEffect(() => {
    window.scrollTo(0, 0);
    setDeals([]);
    fetchMoreData();
  }, []);
  useEffect(() => {
    if (filterName && filterName !== '') {
      setSortName("");
      setPage(1)
      setDeals([]);
      fetchMoreData();
    }
  }, [filterName])

  useEffect(() => {
    if (sortName && sortName !== '') {
      setFilterName("")
      setDeals([]);
      setPage(1)
      fetchMoreData();
    }
  }, [sortName])
  return (
    <>
      {shouldLoadPage &&
        <div>
          <div className="card-wrapper">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                padding: "10px",
              }}
            >
              <SortIcon className="sort-icon" onClick={() => handleClose()} />
            </div>
            <InfiniteScroll
              dataLength={deals.length}
              next={fetchMoreData}
              hasMore={hasMore}
              loader={<BallTriangle
                height="100"
                width="100"
                color="grey"
                ariaLabel="loading"

              />}
            >
              {deals.map((deal, index) => {
                return (
                  <Card
                    className="card-main"
                    key={deal._id}
                    onClick={() => {
                      navigate(`/admin/deal/${deal._id}`);

                    }}

                  >
                    <CardMedia
                      component="img"
                      height="140px"
                      image={deal.dealImage}
                      alt={deal.name}
                    />
                    <CardContent className="content">
                      <Typography gutterBottom variant="h5" component="div">
                        {deal.name}
                      </Typography>
                      <Typography gutterBottom variant="subtitle1" component="div">
                        Redeem Run- {deal.redeemrun}
                      </Typography>
                      <Typography gutterBottom variant="subtitle2" component="div">
                        Deals Count left- {deal.count}
                      </Typography>
                      <Typography gutterBottom variant="subtitle2" component="div">
                        Deals Count set by admin- {deal.totalDeal}
                      </Typography>
                      <Typography gutterBottom variant="subtitle2" component="div">
                        app only- {deal.appOnly ? "true" : "false"}
                      </Typography>
                      <Typography gutterBottom variant="subtitle2" component="div">
                        redeemableOnce- {deal.redeemableOnce ? "true" : "false"}
                      </Typography>
                      <Typography gutterBottom variant="subtitle2" component="div">
                        locked deal- {deal.isLocked ? "true" : "false"}
                      </Typography>

                      <Typography gutterBottom variant="subtitle2" component="div">
                        category- {deal?.categories}
                      </Typography>
                      <Typography gutterBottom variant="subtitle2" component="div">
                        Target Users- {deal?.SubscribedUser ? "Subscribers" : "Users"}
                      </Typography>
                      <Typography gutterBottom variant="subtitle2" component="div">
                        Runs Back- {deal?.runsBack ? deal.runsBack : 0}
                      </Typography>
                      <Typography gutterBottom variant="subtitle2" component="div">
                        discount percent- {deal?.discount}
                      </Typography>
                      <Typography gutterBottom variant="subtitle2" component="div">
                        discount startTime- {deal?.discountStartDateAndTime ? deal?.discountStartDateAndTime : ""}
                      </Typography>
                      <Typography gutterBottom variant="subtitle2" component="div">
                        discount endTime- {deal?.discountEndDateAndTime ? deal?.discountEndDateAndTime : ""}
                      </Typography>
                      {deal.isLocked && <Typography gutterBottom variant="subtitle2" component="div">
                        condition to unlock deal- 
                        <li>post --{deal.conditionForUnlock.post} </li>
                        <li>comment -- {deal.conditionForUnlock.comment}</li>
                        <li>highlight -- {deal.conditionForUnlock.highlight}</li>
                      </Typography>}
                      <Typography variant="body2" color="text.secondary">
                        <div
                          style={{ marginTop: "15px" }}
                          className="event-description"
                          dangerouslySetInnerHTML={{
                            __html: deal.desc,
                          }}
                        />
                      </Typography>
                      <hr style={{ marginTop: "10px", marginBottom: "10x" }} />
                      <Typography gutterBottom variant="subtitle2" component="div">
                        Start Time- {deal?.startDateAndTime}
                      </Typography>
                      <Typography gutterBottom variant="subtitle2" component="div">
                        End Time- {deal.endDateAndTime}
                      </Typography>

                    </CardContent>
                  </Card>
                );
              })}
              {filterModal && (
                <FilterModal
                  setFilter={setFilterName}
                  setPage={setPage}
                  setSort={setSortName}
                  open={filterModal}
                  handleClose={handleClose}
                  // setDeal={setDeals}
                  type={"admin"}
                />
              )}
            </InfiniteScroll>
          </div>
          <ToastContainer />
        </div>
      }
    </>
  );
};

export default DealScroll;
