import React from "react";
import "./ProductCard.scss";

const ProductCard = ({ productData }) => {
  return (
    <div className="product-card-main-admin">
      <h1 className="product-card-title">Title: {productData?.title}</h1>
      <h2 className="product-card-category">
        Category: {productData?.category}
      </h2>
      <p className="product-card-contactPhone">
        Contact Phone: {productData?.contactPhone || "Not provided"}
      </p>
      <p className="product-card-contactWhatsapp">
        Contact Whatsapp: {productData?.contactWhatsapp || "Not provided"}
      </p>
      <p className="product-card-contactEmail">
        Contact Email: {productData?.contactEmail || "Not provided"}
      </p>
      <p className="product-card-stock">Stock: {productData?.stock}</p>
      {productData?.images?.map((image, index) => {
        return (
          <img
            key={index}
            src={image}
            alt="product"
            className="product-card-productImage"
          />
        );
      })}
      <p className="product-card-price">Price: {productData?.price}</p>
      <p className="product-card-discount">Discount: {productData?.discount}</p>
      <p className="product-card-count">
        Wishlisted Count: {productData?.wishlistedCount}
      </p>
      <p className="product-card-sellerRuns">
        Check Seller Runs: {productData?.checkSellerRuns}
      </p>
    </div>
  );
};

export default ProductCard;
