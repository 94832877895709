import React from "react";
import NavbarSection from "./firstSection";
import SecondBlock from "./secondSection";
import ThirdBlock from "./thirdSection";
import  CarouselBlock from "./fourthSection";
import CarouselItems from "./fourthSection";
import FifthSection from "./fifthSection";
import Testimonny from "./testimonny";
import FooterBlock from "./footerSection";



const CampaignLandingPage = () => {
    return(
      <>
       <NavbarSection />
       <SecondBlock />
       <ThirdBlock />
       <CarouselItems />
       <FifthSection />
       <Testimonny />
       <FooterBlock />
       </>
 );
}

export default CampaignLandingPage;

