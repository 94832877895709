import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import "./UnlockModal.scss"

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 361,
  bgcolor: 'background.paper',
  border: '1px solid #CBCBCB',
  borderRadius: '6px',
  boxShadow: 24,
  p: 4,
};

export default function UnlockModal(props) {
  return (
    <div className='deal_unlock'>
      <Modal
        open={props?.open}
        onClose={props?.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography style={{ textAlign: "center" }} id="modal-modal-title" variant="h6" component="h2">
            Condition to Unlock:
          </Typography>
          <Typography style={{ textAlign: "center" }} id="modal-modal-description" sx={{ mt: 2 }}>
            You need to create
            {props?.count.post > 0 && (
              ` ${props?.count.post}  post/s `
            )}
            {props?.count.highlight > 0 && (
              ` ${props?.count.highlight} highlight/s`
            )}
            {props?.count.comment > 0 && (
              ` ${props?.count.comment} comment/s`
            )}
          &nbsp;  to unlock the deal
          </Typography>

          <div style={{ textAlign: "center" }} className="back_btn">
            <Button style={{ width: "124px", height: "32px", border: "1px solid #0c5ba0", marginTop: "20px" }} onClick={props?.handleClose}>Back</Button>
          </div>
        </Box>
      </Modal>
    </div>

  );
}