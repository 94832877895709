import React from "react";
import { useEffect, useState, useRef, useCallback } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import useScroller from "../../commons/useScroller_JSON_ARG";
import Manage from "./index";
import { BallTriangle } from "react-loader-spinner";
import { getStorageItem } from "../../utils/sessionStorage";
import { Button, Grid, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea, CardActions } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import EventIcon from "@mui/icons-material/Event";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import "./index.scss";
// React-Toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CarouselScroll = (props) => {
  const navigate = useNavigate();
  const accessToken = getStorageItem("token");
  const userId = getStorageItem("user_id");
  const [type, setType] = useState(props?.type);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPageNumber, setTotalPageNumber] = useState(0);

  const [carousels, setCarousels] = useState([]);

  const getPreviousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const getNextPage = () => {
    if (pageNumber < totalPageNumber) {
      setPageNumber(pageNumber + 1);
    }
  };

  const getAllCarousels = async (pageNumber, nPerPage, type) => {
    try {
      const options = {
        method: "get",
        url: global.config.ROOTURL.prod + `/carousel/getAll/${type}/${pageNumber}/${nPerPage}`,
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      };
      const response = await axios(options);
      return response.data;
    } catch (error) {
      console.error("Error fetching carousels:", error);
      throw error;
    }
  };


  useEffect(() => {
    if (!accessToken) return;
    getAllCarousels(pageNumber, 6, type)
      .then((data) => {
        setCarousels(data);
        setTotalPageNumber(Math.ceil(data.length / 6) + 1);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          toast.error("Some error occurred");
        }
      });
  }, [pageNumber]);

  return (
    <div>
      <Grid
        className="pagination"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          gap: "1rem",
          // marginTop: "-30px"
        }}
      >
        <Button variant="contained" onClick={getPreviousPage}>
          Prev
        </Button>
        <Typography variant="p">
          {pageNumber} of {totalPageNumber}
        </Typography>
        <Button variant="contained" onClick={getNextPage}>
          Next
        </Button>
      </Grid>
      <div className="card-wrapper">
        {carousels.map((carousel) => {
          return (
            <Card
              className="card-main"
              key={carousel._id}
              onClick={() => {
                navigate(`/admin/carousel/${carousel._id}`);
              }}
            >
              <CardMedia
                component="img"
                height="140px"
                image={carousel.name}
                // image={carousel.imageUrl}
                alt={carousel.message}
              />
              <CardContent className="content">
                <Typography gutterBottom variant="h5" component="div">
                  {carousel.message}
                </Typography>
                <Typography gutterBottom variant="subtitle1" component="div">
                  Link- {carousel.link}
                </Typography>
                <Typography gutterBottom variant="subtitle2" component="div">
                  Priority- {carousel.priority}
                </Typography>

                <Typography variant="body2" color="text.secondary">
                  <div
                    className="event-description"
                    dangerouslySetInnerHTML={{
                      __html: moment(carousel.modifiedDate).format("LLL"),
                    }}
                  />
                </Typography>
              </CardContent>
            </Card>
          );
        })}
      </div>
      <ToastContainer />
    </div>
  );
};
export default CarouselScroll;
