import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Logo from "../../assets/images/header/logo.png";
// import { NavLink, useNavigate } from "react-router-dom";
import popupimage from "../../assets/images/deals/popupImage.png"
import './Popup.scss'
import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { clearStorage, setStorageItem } from '../../utils/sessionStorage';

// in props we need  a open state and  close function and here we pass false to back function  

export default function Popup(props) {
  const [open, setOpen] = React.useState(props.Open);
  const navigate = useNavigate();
  const handleClose = () => {
    props.handleClose(false)
  };
  const mobileView = useMediaQuery("(max-width:600px)");
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>
          <Box sx={!mobileView ? style : mobilestyle}>
            <div className="lineHeight"></div>
            <Box className="logobox">
              <img src={Logo} />
            </Box>
            <Box sx={{ display: "flex" }}>
              <Box>
                <img src={popupimage} className="image" alt="" />
              </Box>
              <Box className="flex">
                <div className="signContent">
                  {props.message ? props.message : "Sign In to start Engaging"}
                </div>
                <Button
                  variant="contained"
                  className="loginButton_"
                  onClick={() => {
                    clearStorage();
                    props.linkAddress !== "" &&
                      props.linkType === "eventRedirect" &&
                      setStorageItem("eventRedirect", props.linkAddress);
                    navigate("/login");
                  }}
                >
                  Log In
                </Button>
                <Button
                  variant="text"
                  className="signupButton_"
                  onClick={() => {
                    clearStorage();
                    props.linkAddress !== "" &&
                      props.linkType === "eventRedirect" &&
                      setStorageItem("eventRedirect", props.linkAddress);
                    navigate("/signup");
                  }}
                >
                  Sign Up
                </Button>
              </Box>
            </Box>
          </Box>
        </div>
      </Modal>
    </div>
  );
}












const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',

  bgcolor: 'background.paper',
  border: '2px solid #0C5BA0',
  boxShadow: 24,
  p: '5px',
  height: '280px',
  width: '440px',
  borderRadius: '22px'
};
const mobilestyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #0C5BA0',
  boxShadow: 24,
  p: '5px',
  height: '280px',
  width: '350px',
  borderRadius: '22px'
};
// const image = {
//   height: '212px',
//   width: '212px'

// };
// const logobox = {
//   height: '106px',
//   width: '79px',
//   backgroundColor: '#0C5BA0',
//   borderRadius: '28px',
//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center',
//   marginTop: '-93px',
//    marginLeft: '24px !important'
// };
// const lineHeight = {
//   backgroundColor: 'white',
//     height: '226px',
//     width: '4px',
//     marginTop: '-187px',
//     marginLeft: '61px',
// };
// const signContent = {
//   color: '#E53C96',
//   fontWeight: '600',
//   textAlign: 'center',
//   paddingBottom: '12px',
// }
// const flex = {
//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center',
//   flexDirection: 'column',

// }