import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import { Modal, Box } from "@mui/material";
import { toast } from "react-toastify";
import { getStorageItem } from "../../../utils/sessionStorage";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
const userID = getStorageItem("user_id");
const accessToken = getStorageItem("token");
const moment = require("moment");

const Index = (props) => {
  const [name, setName] = useState("");
  const startDayRef = useRef();
  const startTimeRef = useRef();
  const endDayRef = useRef();
  const endTimeRef = useRef();
  const handleEditPrediction = async () => {
    if (!accessToken) return
    const startDayRefValue = startDayRef?.current?.value;
    const startTimeRefValue = startTimeRef?.current?.value;
    const endDayRefValue = endDayRef?.current?.value;
    const endTimeRefValue = endTimeRef?.current?.value;
    const data1 = {
      id: props?.predictionData?._id,
      predictionName: name ? name : props?.predictionData?.predictionName,
      startTime: startTimeRefValue,
      startDate: moment(startDayRefValue).format("YYYY-MM-DD"),
      endTime: endTimeRefValue,
      endDate: moment(endDayRefValue).format("YYYY-MM-DD"),
    };
    const options = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/contest/prediction/updatePrediction",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
        role: "Admin",
      },
      data: data1,
    };
    axios(options)
      .then(({ data }) => {
        toast.success(" Prediction Edited successfully");
        props.setData(data)
        props.handleClose();
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          console.log(error);
        }
      });
  };
  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <div className="contest-admin-create">
          <div className="contest-admin-create-wrapper">
            <div className="contest-admin-create-wrapper-main">
              <Box style={{ float: "right" }}>
                <IconButton onClick={props.handleClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <div className="contest-admin-create-wrapper-header">
                <p className="contest-admin-create-wrapper-header--text">Edit Prediction</p>
              </div>
              <div className="contest-admin-create-wrapper-form">
                <div className="contest-create-name-wrapper">
                  <input
                    maxLength={17}
                    type="text"
                    className="contest-create-name"
                    placeholder="Prediction name"
                    defaultValue={props?.predictionData?.predictionName}
                    onChange={(event) => {
                      setName(event.target.value);
                    }}
                    required
                  />
                  <p className="contest-create-name-extra">Max 17 letters</p>
                  <p className="contest-create-name-count-indicator">
                    {name.length < 17 ? 17 - name.length : 0}
                  </p>
                </div>
                <div className="contest-create-row2-wrapper">
                  <div className="contest-create-row2-block1">
                    <p className="admin-contest-creation-general-text">Start</p>
                    <input
                      type="date"
                      className="contest-create-start-date"
                      defaultValue={moment(props?.predictionData?.startDate).format("YYYY-MM-DD")}
                      ref={startDayRef}
                      required
                    />
                    <input
                      type="time"
                      className="contest-create-start-time"
                      defaultValue={props?.predictionData?.startTime}
                      ref={startTimeRef}
                      required
                    />
                  </div>

                </div>
                <div className="contest-create-row3-wrapper">
                  <div className="contest-create-row3-block1">
                    <p className="admin-contest-creation-general-text">End</p>
                    <input
                      type="date"
                      className="contest-create-start-date"
                      defaultValue={moment(props?.predictionData?.endDate).format("YYYY-MM-DD")}
                      ref={endDayRef}
                      required
                    />
                    <input
                      type="time"
                      className="contest-create-start-time"
                      ref={endTimeRef}
                      defaultValue={props?.predictionData?.endTime}
                      required
                    />
                  </div>

                </div>
                <button
                  onClick={handleEditPrediction}
                  className="contest-preediction-admin-creation-submit-button"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Index;
