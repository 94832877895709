import React, { useState, useEffect } from 'react';
import "./ProgressBar.scss";

const StreakProgressBarComponent = ({ borderColor, backgroundColor, status,
  currentStage,
  totalStages,
  progressLabelLineColor

}) => {
  const [progressWidth, setProgressWidth] = useState(0);
  const [isBroken, setIsBroken] = useState(status === "breaked");
  
  useEffect(() => {
    const currentProgress = (currentStage / totalStages) * 100;
    setProgressWidth(currentProgress); 
  }, [currentStage, totalStages]);


  return (
    <div className="progress-container">
      {/* <div className="progress-bar" style={{ width: `${progressWidth}%`,  backgroundColor: backgroundColor  }}></div> */}
      <div className="progress-labels">
        {Array.from({ length: totalStages}, (_, index) => (
          <div key={index} className="progress-labeled" style={{ borderColor: borderColor }}>
            {index + 1 < (currentStage + 1) ? (
              <span className="tick" style={{color: "red"}} >&#10003;</span>
            ) : (
              index + 1 === currentStage + 1 && <span className="cross" style={{ backgroundColor: backgroundColor }} ></span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default StreakProgressBarComponent;
