import React from "react";
import { useEffect, useState, useRef, useCallback } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import useScroller from "../../commons/useScroller_JSON_ARG";
import Manage from "./index";
import { getStorageItem } from "../../utils/sessionStorage";
import { Button, Grid, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea, CardActions } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import EventIcon from "@mui/icons-material/Event";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import "./index.scss";
import InfiniteScroll from 'react-infinite-scroll-component';
import { BallTriangle } from 'react-loader-spinner';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UpdateDealScroll = (props) => {
  const navigate = useNavigate();
  const accessToken = getStorageItem("token");
  const userId = getStorageItem("user_id");
  const [shouldLoadPage, setShouldLoadPage] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [deals, setDeals] = useState([]);

  const DateTime = (dateAndTime) => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      timeZone: 'Asia/Kolkata'
    };
    const formattedDate = new Date(dateAndTime).toLocaleString('en-US', options);
    return formattedDate;
  }



  const fetchMoreData = async () => {
    // Fetch additional data
    if (!accessToken) return
    const req = {
      method: "GET",
      url: global.config.ROOTURL.prod + `/deal/getAllDeals/${page}/${global.config.pagePerSize}`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };

    axios(req).then(response => {

      if (response.data.length === 0) {

        // No more data to fetch
        setHasMore(false);
      } else {

        // Append new data to existing items
        setDeals(prevItems => prevItems.concat(response.data));
        setPage(prevPage => prevPage + 1);
        setShouldLoadPage(true);
      }
    })
      .catch(error => console.log(error));
  };


  useEffect(() => {
    window.scrollTo(0, 0);
    setDeals([]);
    fetchMoreData();

  }, []);



  return (
    <div>
      {shouldLoadPage
        && <>

          <div className="card-wrapper">
            <InfiniteScroll
              dataLength={deals.length}
              next={fetchMoreData}
              hasMore={hasMore}
              loader={<BallTriangle
                height="100"
                width="100"
                color="grey"
                ariaLabel="loading"

              />}
            >
              {deals.map((deal) => {
                return (
                  <Card
                    className="card-main"
                    key={deal._id}
                    onClick={() => {
                      navigate(`/admin/edit-deal-admin/${deal._id}`);
                    }}
                  >
                    <CardMedia
                      component="img"
                      height="140px"
                      image={deal.dealImage}
                      alt={deal.name}
                    />
                    <CardContent className="content">
                      <Typography gutterBottom variant="h5" component="div">
                        {deal.name}
                      </Typography>
                      <Typography gutterBottom variant="subtitle1" component="div">
                        Redeem Run- {deal.redeemrun}
                      </Typography>
                      <Typography gutterBottom variant="subtitle2" component="div">
                        Deals Count left- {deal.count}
                      </Typography>
                      <Typography gutterBottom variant="subtitle2" component="div">
                        Deals Count set by admin- {deal.totalDeal}
                      </Typography>

                      <Typography gutterBottom variant="subtitle2" component="div">
                        app only- {deal.appOnly ? "true" : "false"}
                      </Typography>
                      <Typography gutterBottom variant="subtitle2" component="div">
                        redeemableOnce- {deal.redeemableOnce ? "true" : "false"}
                      </Typography>
                      <Typography gutterBottom variant="subtitle2" component="div">
                        locked deal- {deal.isLocked ? "true" : "false"}
                      </Typography>

                      <Typography gutterBottom variant="subtitle2" component="div">
                        category- {deal?.categories}
                      </Typography>
                      <Typography gutterBottom variant="subtitle2" component="div">
                        discount percent- {deal?.discount}
                      </Typography>
                      <Typography gutterBottom variant="subtitle2" component="div">
                        Priority No- {deal?.priorityNo}
                      </Typography>
                      <Typography gutterBottom variant="subtitle2" component="div">
                        discount startTime- {deal?.discountStartDateAndTime ? DateTime(deal.discountStartDateAndTime) : ""}
                      </Typography>
                      <Typography gutterBottom variant="subtitle2" component="div">
                        discount endTime- {deal?.discountEndDateAndTime ? DateTime(deal.discountEndDateAndTime) : ""}
                      </Typography>


                      <Typography variant="body2" color="text.secondary">
                        <div
                          style={{ marginTop: "15px" }}
                          className="event-description"
                          dangerouslySetInnerHTML={{
                            __html: deal.desc,
                          }}
                        />
                      </Typography>
                      <hr style={{ marginTop: "10px", marginBottom: "10x" }} />
                      <Typography gutterBottom variant="subtitle2" component="div">
                        Start Time- {DateTime(deal.startDateAndTime)}
                      </Typography>
                      <Typography gutterBottom variant="subtitle2" component="div">
                        End Time- {DateTime(deal.endDateAndTime)}
                      </Typography>

                    </CardContent>
                  </Card>
                );
              })}
            </InfiniteScroll>
          </div>
          <ToastContainer />
        </>
      }
    </div>
  );
};

export default UpdateDealScroll;
