import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ProgressBar from "./ProgressBar";
import DailyProgressBar from "./DailyProgressBar";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "334px",
  maxWidth: 400,
  backgroundColor: "white",
  border: "none",
  boxShadow: 24,
  padding: "32px 15px 32px 40px",
  borderRadius: "20px",
  fontSize: "16px",
  color: "#333",
  fontFamily: "Arial, sans-serif",
  fontWeight: "500",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};

const buttonStyle = {
  maxWidth: "300px",
  width: "100%",
  backgroundColor: "#0C5BA0",
  color: "white",
  marginTop: "20px",
  height: "45px",
  fontSize: "16px",
};

const DailyLoginCompleteModal = ({
  open,
  onClose,
  status,
  currentStage,
  totalStages,
}) => {
  const handleStartFromDay1 = () => {
    onClose();
  };

  // const handleClose = () => {
  //   setOpen(false);
  //   handleCloseModel();
  // };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
    >
      <Box sx={style}>
        <IconButton
          style={{
            position: "absolute",
            top: "8px",
            right: "8px",
            color: "#0C5BA0",
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        <h3
          style={{
            marginBottom: "20px",
            textAlign: "center",
            color: "#0C5BA0",
            fontSize: "20px",
            fontWeight: "700",
          }}
        >
          Daily Login
        </h3>
        <h4
          style={{
            color: "#FFB000",
            textAlign: "center",
            fontSize: "32px",
            fontWeight: "900",
          }}
        >
          Congrats!
        </h4>
        <p
          style={{
            color: "#0C5BA0",
            fontSize: "20px",
            fontWeight: "700",
            textAlign: "center",
          }}
        >
          Daily Login Challenge
          <br />
          Complete
        </p>
        {totalStages === 7 && (
          <DailyProgressBar
            currentStage={currentStage}
            totalStages={totalStages}
          />
        )}
        <Button variant="contained" style={buttonStyle} onClick={onClose}>
          Home
        </Button>
      </Box>
    </Modal>
  );
};

export default DailyLoginCompleteModal;
