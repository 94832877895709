import React, { useState } from "react";
import "./index.scss";

const DropdownComponentFilter = ({ runsType, activeItem, setActiveItem }) => {
  const handleClick = (item) => {
    setActiveItem(item);
  };

  return (
    <div className="rt-dropdown-filter">
      <ul className="rt-filters">
        {runsType.map((item, index) => (
          <li
            className={ item === activeItem ? "active" : "" }
            key={index}
            onClick={ () => handleClick(item) }
          >
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DropdownComponentFilter ;
