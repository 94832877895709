import axios from "axios";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import "./index.css";
import config from "../../config";
import { getStorageItem } from "../../utils/sessionStorage";
import { ToastContainer, toast } from "react-toastify";
const baseURL = config.ROOTURL.prod;

const ExpandedComponent = ({ data }) => {
  return <pre>{JSON.stringify(data, null, 2)}</pre>;
};

const RefferedToday = () => {
  const [data, setData] = useState([]);
  const accessToken = getStorageItem("token");
  const userId = getStorageItem("user_id");
  const columns = [
    {
      name: "User Id",
      selector: (row) => row.userId,
    },
    {
      name: "User First Name",
      selector: (row) => row.userFirstName,
      sortable: true,
    },
    {
      name: "User Last Name",
      selector: (row) => row.userLastName,
      sortable: true,
    },
    {
      name: "User Email",
      selector: (row) => row.userEmail,
      sortable: true,
    },
    {
      name: "User Profile Photo",
      selector: (row) => row.userProfilePhoto,
    },
    {
      name: "Referral User Id",
      selector: (row) => row.referralUserId,
    },
    {
      name: "Refferal User First Name",
      selector: (row) => row.referralUserFirstName,
      sortable: true,
    },
    {
      name: "Refferal User Last Name",
      selector: (row) => row.referralUserLastName,
      sortable: true,
    },
    {
      name: "Refferal User Email",
      selector: (row) => row.referralUserEmail,
      sortable: true,
    },
    {
      name: "Refferal User Profile Photo",
      selector: (row) => row.referralUserProfilePhoto,
    },
    {
      name: "Created Date",
      selector: (row) => row.referralUserCreatedDate,
      sortable: true,
    },
    {
      name: "Share Mode",
      selector: (row) => row.shareMode,
    },
  ];
  useEffect(() => {
    axios
      .get(`${baseURL}/invite/getToday`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((response) => {
        console.log(response);
        const users = response.data.filter((item) => item.referralUserId);
        console.log(users);
        setData(users);
      })
      .catch((error) => {
        toast.error(error);
      });
  }, []);
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setRows(data);
      setPending(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  const [filterText, setFilterText] = useState("");

  const filteredData = data?.filter((item) => {
    return (
      item.userFirstName &&
      item.userFirstName.toLowerCase().includes(filterText.toLowerCase())
    );
    // .map((user) => {
    //   return {
    //     _id: user.referralUserId,
    //     name: user.referralUserFirstName + " " + user.referralUserLastName,
    //     createdDate: user.createdDate
    //   }
    // })
  });

  return (
    <div className="refer">
      <div className="refer__wrapper">
        <div>
          <input
            type="text"
            className="refer__input"
            value={filterText}
            onChange={(e) => {
              setFilterText(e.target.value);
            }}
          />
          <button
            className="refer__clearButton"
            onClick={() => {
              setFilterText("");
            }}
          >
            Clear
          </button>
        </div>
        <div>
          <h2 className="refers__count">
            <span className="refers__total">Total: </span>
            {filteredData.length}
          </h2>
        </div>
      </div>
      <DataTable
        title="Reffered Users"
        columns={columns}
        data={filteredData}
        selectableRows
        expandableRows
        expandableRowsComponent={ExpandedComponent}
        pagination
        // progressComponent={<CustomLoader />}
        // customStyles={customStyles}
        progressPending={pending}
        highlightOnHover
        striped
      />
    </div>
  );
};

export default RefferedToday;
