import React, { useState, useEffect, useContext } from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header";
import axios from "axios";
import { getStorageItem } from "../../utils/sessionStorage";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { PathAction } from "../../redux/reducers/globalPath";
import AppContext from "../../context/AppContext";
// Mui
import { Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";

// Custom CSS
import "./index.scss";
// import { Link, useParams } from "react-router-dom";
import Post from "../../components/posts/post";
import Events from "../../components/events";
import { Helmet } from "react-helmet";
import Treanding from "./Treanding";
import moment from "moment";

function Index() {
  const accessToken = getStorageItem("token");
  const userId = getStorageItem("user_id");
  const userName = getStorageItem("user_name");
  const avatar = getStorageItem("avatar");
  const [shouldLoadPage, setShouldLoadPage] = useState(false);
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const navigate = useNavigate();
  const id = searchParams.get("id");

  const tag = searchParams.get("hashtag");

  const [trendingHashtags, setTrendingHashtags] = useState();

  const [pitches, setPitches] = useState([]);

  const [posts, setPosts] = useState([]);

  const [name, setName] = useState("");

  const [postCount, setPostCount] = useState("");

  const [activeButtonFirst, setActiveButtonFirst] = useState(false);

  const [activeButtonSecond, setActiveButtonSecond] = useState(true);

  const [allTime, setAllTime] = useState(true);

  const [thisWeekPosts, setThisWeekPosts] = useState([]);

  const handleButtonClick1 = () => {
    setActiveButtonFirst(true);
    setActiveButtonSecond(false);
  };

  const handleButtonClick2 = () => {
    setActiveButtonFirst(false);
    setActiveButtonSecond(true);
  };

  const findTrendingHashtags = async () => {
    if (!accessToken) return
    let creationOptions = {
      method: "GET",
      url:
        global.config.ROOTURL.prod +
        `/hash-profile-tag/getTopTrandingHashTag/1/10`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    await axios(creationOptions)
      .then((response) => {
        setTrendingHashtags(response.data.data);
      })
      .catch((error) => {
        console.log("Creation error" + error);
        if (error?.response?.status == 401) {
          console.log(error);
        }
      });
  };

  const fetchThisWeekPosts = (posts) => {
    const currentDate = moment();
    const oneWeekAgo = currentDate.subtract(7, "days");
    const filteredPosts = posts.filter((post) => {
      return moment(post.createdDate).isAfter(oneWeekAgo);
    });
    setThisWeekPosts(filteredPosts);
  };

  const fetchPosts = async (data) => {

    setPitches(data.data);
    var ids = [];
    data.data.forEach((element) => {
      ids.push(element.pitchId);
    });
    if (!accessToken && !ids) return
    let creationOptions = {
      method: "POST",
      url: global.config.ROOTURL.prod + `/pitch/multiplePitchByIds`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      data: {
        pitchIds: ids,
      },
    };
    await axios(creationOptions)
      .then((response) => {
        fetchThisWeekPosts(response.data);
        setPosts(response.data);
      })
      .catch((error) => {
        console.log("Creation error" + error);
        if (error?.response?.status == 401) {
          console.log(error);
        }
      });
  };

  async function searchAllPitchesByHashTagId(id) {
    if (!accessToken && !id) return
    let creationOptions = {
      method: "GET",
      url:
        global.config.ROOTURL.prod +
        `/hash-profile-tag/postHashTag/getPostByHashTagId/${id}/1/10`,
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
    };
    await axios(creationOptions)
      .then(({ data }) => {
        fetchPosts(data);
      })
      .catch((error) => {
        console.log("Creation error" + error);
        if (error?.response?.status == 401) {
          console.log(error);
        }
      });
  }

  const findHashTagById = async () => {
    const reqId = id;
    // console.log(reqId);
    if (!accessToken && !id) return
    let creationOptions = {
      method: "GET",
      url:
        global.config.ROOTURL.prod +
        `/hash-profile-tag/getByHashTagId/${reqId}`,
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
    };
    await axios(creationOptions)
      .then(({ data }) => {
        setName(data.data[0].hashTagText);
        setPostCount(data.data[0].totalUsedPitchCount);
      })
      .catch((error) => {
        console.log("Creation error" + error);
        if (error?.response?.status == 401) {
          console.log(error);
        }
      });
  };

  const findHashTagByTag = async () => {
    if (!accessToken && !tag) return
    const text = tag;
    console.log(text);
    let creationOptions = {
      method: "GET",
      url:
        global.config.ROOTURL.prod +
        `/hash-profile-tag/postHashTag/getPostByHashTagText/${text}/1/5`,
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
    };
    await axios(creationOptions)
      .then(({ data }) => {
        setName(tag);
        setPostCount(data.data.length);
        fetchPosts(data);
      })
      .catch((error) => {
        console.log("Creation error" + error);
        if (error?.response?.status == 401) {
          console.log(error);
        }
      });
  };

  function removeHash(query) {
    // Check if the query string is a string and has a length greater than 1
    if (typeof query === "string" && query.length > 1) {
      // Remove the # character from the beginning of the string
      return query.replace(/^#/, "");
    }
    // Return the original query string if it's not a string or has a length of 1 or less
    return query;
  }
  const path = window.location.pathname;
  const dispatch = useDispatch();
  dispatch(PathAction.handlePath({ payload: path }));

  const appContext = useContext(AppContext);
  console.log(appContext)
  const { setShowFooter } = appContext;

  useEffect(() => {
    setPitches([]);
    setShowFooter(true);
    setPosts([]);
    if (id) {
      findHashTagById();
    } else if (tag) {
      findHashTagByTag(tag);
    }
    findTrendingHashtags();
    if (id) {
      searchAllPitchesByHashTagId(id);
    }
  }, []);

  return (
    <>
      <div>
        <Helmet>
          <title>Champhunt | Hashtags</title>
        </Helmet>


        <Box className="hashtag-wrapper">
          <Box
            sx={{
              borderBottom: "1px solid #ada5a5",
              display: { xs: "block", md: "none" },
            }}
          >
            <Box className="visible123">Trending Hastags</Box>

            <Box className="trending-hash-tags-mobile-box">
              <Box
                display="flex"
                justifyContent="space-around"
                alignItems="center"
                gap={2}
                paddingX={2}
                height={50}
              >
                {trendingHashtags?.map((hashtag, index) => {
                  return (
                    <Box key={hashtag._id} className="trendin-hash-tags-mobile">
                      <span className="number-trending-mobile">
                        {index + 1}
                      </span>
                      <a
                        className="anchor-mobile"
                        onClick={() => {
                          console.log({ hashtag })
                          navigate(`/hashtags?id=${hashtag._id}`)
                        }}
                      >
                        #{hashtag.hashTagText}
                      </a>
                      <span className="fire-icon-mobile">&#128293;</span>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Box>
          <Box className="hashtag-wrapper__header marginforbutton">
            <Typography className="header__heading ">
              #{name} <br />
              <Box
                sx={{
                  visibility: { xs: "visible", md: "hidden" },
                  color: "#545454",
                }}
              >
                {allTime ? postCount : thisWeekPosts.length} Posts
              </Box>
            </Typography>
            <Box
              className="header__buttons"
              sx={{ width: { xs: "200px", md: "300px" } }}
            >
              <Box
                className={
                  activeButtonFirst ? "active-button" : "header__buttons--top"
                }
                onClick={() => {
                  handleButtonClick1();
                  setAllTime(false);
                }}
              >
                This Week
              </Box>
              <Box
                className={
                  activeButtonSecond
                    ? "active-button"
                    : "header__buttons--recent"
                }
                onClick={() => {
                  handleButtonClick2();
                  setAllTime(true);
                }}
              >
                All Time
              </Box>
            </Box>
          </Box>
          <Box className="hashtag-wrapper__content">
            <Box className="treandingvisible">
              <Treanding />
            </Box>
            <Box className="content-box--2 marginclass">
              <span className="main-hashtag-name-total-posts  mobilvisible">
                #{name}{" "}
                <span className="main-hashtag-posts-number">
                  {allTime ? postCount : thisWeekPosts?.length} Posts
                </span>
              </span>
              {allTime &&
                posts?.map((element, index) => {
                  return (
                    <Paper
                      className="component posts"
                      key={index}
                      style={{
                        marginBottom: "20px",
                        borderRadius: "12px",
                        boxShadow: "none",
                      }}
                    >
                      <Post
                        post={element}
                        key={index}
                        hprops={() => {
                          setShouldLoadPage(!shouldLoadPage);
                        }}
                      />
                    </Paper>
                  );
                })}
              {!allTime &&
                thisWeekPosts.map((element, index) => {
                  console.log("dffs", index);
                  console.log(element);
                  console.log(allTime);
                  return (
                    <Paper
                      className="component posts"
                      key={index}
                      style={{
                        marginBottom: "20px",
                        borderRadius: "12px",
                        boxShadow: "none",
                      }}
                    >
                      <Post
                        post={element}
                        key={index}
                        hprops={() => {
                          setShouldLoadPage(!shouldLoadPage);
                        }}
                      />
                    </Paper>
                  );
                })}
            </Box>
            <Box className="content-box--3">
              {/* <h2>Fixtures/ Results</h2> */}
              <Events />
            </Box>
          </Box>
        </Box>
        <Footer />
      </div>
    </>
  );
}

export default Index;
