import axios from "axios";
import moment from "moment/moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getStorageItem } from "../../../utils/sessionStorage";
import CheckIcon from "@mui/icons-material/Check";
import "../PredictionItem.css";
const accessToken = getStorageItem("token");

const QuestionItem = ({ data, predictionId }) => {
  const navigate = useNavigate();
  console.log(data);

  const deleteQuestion = (predictionId, id) => {
    if (!predictionId || !id || !accessToken) return toast.error("something went wrong");

    const option = {
      method: "POST",
      url:
        global.config.ROOTURL.prod +
        "/contest/predictionQuestion/deletePredictionQuestion",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
        role: "Admin",
      },
      data: {
        predictionId: predictionId,
        questionId: id,
      },
    };
    axios(option)
      .then(({ data }) => {
        toast.success("Question deleted successfully");
        navigate(0);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="contest_admin_predction-wrapper">
      <div className="contest_admin_predction_view_question-item">
        <div className="contest_admin_predction_view_question-header">
          <div className="contest_admin_predction_view_question-item-name">
            {data?.question}
          </div>
          <img
            onClick={() => {
              if (window.confirm("Are you sure to delete this record?"))
                deleteQuestion(predictionId, data._id);
            }}
            src="/svg/admin/delete.svg"
            alt="delete"
            className="contest_admin_predction_view_question-item-delete"
          />
        </div>
        <div className="">
          {data.options &&
            data.options.map((option, index) => (
              <div>
                <div className="">
                  {index + 1}. {option.text}{" "}
                  {data.answer && data.answer?._id === option._id ? (
                    <CheckIcon style={{ color: "blue", height: "15px" }} />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>

      <div
        onClick={() => {
          navigate(`/admin/contest/edit-answer/${data._id}`, {
            state: data,
          });
        }}
        className="contest_admin_predction_view_question-sub-schedule-edit-button"
      >
        <p className="contest_admin_predction_view_question-sub-schedule-button--text">
          Edit Answer
        </p>
      </div>
    </div>
  );
};

export default QuestionItem;
