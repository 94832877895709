import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ProgressBarComponent from "./ProgressBar";
import DailyLoginCompleteModal from "./DailyLoginCompleteModal";
import StreakProgressBarComponent from "./StreakProgressBar";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "334px",
  maxWidth: 400,
  backgroundColor: "white",
  border: "none",
  boxShadow: 24,
  padding: "19px",
  borderRadius: "20px",
  fontSize: "16px",
  color: "#333",
  fontFamily: "Arial, sans-serif",
  fontWeight: "500",
};

const StreakOverModal = ({
  open,
  onClose,
  currentStage,
  totalStages,
  status,
}) => {
  const [dailyLoginModalOpen, setDailyLoginModalOpen] = useState(false);
  const handleStartFromDay1 = () => {
    setDailyLoginModalOpen(true);
    // onClose();
  };

  const handleDailyLoginModalClose = () => {
    setDailyLoginModalOpen(false);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
      >
        <Box sx={style}>
          <IconButton
            style={{
              position: "absolute",
              top: "8px",
              right: "8px",
              color: "#0C5BA0",
            }}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
          <h3
            style={{
              marginBottom: "20px",
              textAlign: "center",
              color: "#0C5BA0",
              fontSize: "20px",
              fontWeight: "700",
            }}
          >
            Daily Login
          </h3>
          <h4
            style={{
              color: "#E31E1E",
              textAlign: "center",
              fontSize: "32px",
              fontWeight: "900",
              lineHeight: "35px",
            }}
          >
            Streak <br />
            over!
          </h4>
          <StreakProgressBarComponent
            status={status}
            borderColor="red"
            backgroundColor="red"
            currentStage={currentStage}
            totalStages={totalStages}
          />
          {/* Start From Day 1 Button */}
          <Button
            variant="contained"
            style={{
              backgroundColor: "#0C5BA0",
              color: "white",
              marginTop: "-8px",
              marginLeft: "7px",
              width: "95%",
              height: "45px",
            }}
            onClick={handleStartFromDay1}
          >
            Start From Day 1
          </Button>
        </Box>
      </Modal>
      {dailyLoginModalOpen && (
        <DailyLoginCompleteModal
          open={dailyLoginModalOpen}
          onClose={handleDailyLoginModalClose}
        />
      )}
    </>
  );
};

export default StreakOverModal;
