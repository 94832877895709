import React, { useEffect } from 'react';
import './index.scss';
import gameIcon from "../../assets/images/book-cricket/wicket2.png";
import bookCricketBg from "../../assets/images/book-cricket/book-cricket.png";
import Button from "@mui/material/Button";
import RunsModal from './runs-modal';

const BookCricketHome = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const toolbarStyle = {
    backgroundImage: `url(${bookCricketBg})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // height: '100vh', 
  };

  return (
    <div className="book-cricket-home">
      <div className="toolbar">
        <img className='book-cricket' src={bookCricketBg} alt="No Image" />
        <div className="group-1000004947">
          <div className='inner-group'>
            <h2>Rules</h2>
            <div>
              <div className='p-container'>
                <img className='game-icon' src={gameIcon} alt='Icon' />
                <p>
                  Players must have a minimum of 200 runs to be eligible.
                </p>
              </div>
              <div className='p-container'>
                <img className='game-icon' src={gameIcon} alt='Icon' />
                <p>
                  Runs are scored while flipping through the pages of the book.
                </p>
              </div>
              <div className='p-container'>
                <img className='game-icon' src={gameIcon} alt='Icon' />
                <p>
                  Players can get out a maximum of three times during the game.
                </p>
              </div>
              <div className='p-container'>
                <img className='game-icon' src={gameIcon} alt='Icon' />
                <p>
                  The player with the highest total runs at the end of the game is
                  declared the winner.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="button_group">
        <div className="btn--primary">
          <h3>Play against random players</h3>
          <Button onClick={handleOpen}>Play Now</Button>
        </div>
      </div>
      {/* Modal */}
      <RunsModal open={open} handleClose={handleClose} />
    </div>
  );
}
export default BookCricketHome