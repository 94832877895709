import { div, Button } from "@material-ui/core";
import React from "react";
import Toss from "./Toss";
import { useNavigate } from "react-router-dom";
import tossKaBoss from "../../assets/images/rewards/TossKaBoss.png";
import fantsyLeage from "../../assets/images/rewards/fantsyLeage.png";
import bookCricket from "../../assets/images/rewards/bookCricket.png";
import prediction from "../../assets/images/rewards/prediction.png";
import challenges from "../../assets/images/rewards/challenges.png";

export default function Contest() {
  const navigate = useNavigate();
  const handleOpenCoinToss = () => {
    navigate("/coin");
  };
  const handleOpenprediction = () => {
    navigate("/prediction");
  };
  const handleOpenChallenge = () => {
    navigate("/challenge");
  };
  const handleOpenBookCricket = () => {
    navigate("/games/book-cricket");
  };
  const handleOpenFantasy = () => {
    navigate("/fantasy-league");
  };
  return (
    <div className="contestContainer">
      <div className="mobileGrid">
        <div className="contestmaincontainer">
          <div style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
            <img src={tossKaBoss} alt="" className="contentimage" />
          </div>
          <div className="constestContent">
            <div className="innerContent">
              <p className="TossFontStyle contestpadding">Toss Ka Boss</p>
              <p className="giftFont contestpadding" style={{ color: "#95999D" }}>
                win 2X more
              </p>
            </div>
            <div className="contestpadding">
              <Button
                className="TossFontStyle contestButton"
                onClick={handleOpenCoinToss}
              >
                Play Now
              </Button>
            </div>
          </div>
        </div>
        <div className="contestmaincontainer">
          <div style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
            <img src={fantsyLeage} alt="" className="contentimage" />
          </div>
          <div className="constestContent">
            <div className="innerContent">
              <p className="TossFontStyle contestpadding">Fantasy League</p>
              <p className="giftFont contestpadding" style={{ color: "#95999D" }}>
                Play with fav. players
              </p>
            </div>
            <div className="contestpadding">
              <Button
                className="TossFontStyle contestButton"
                onClick={handleOpenFantasy}
              >
                Play Now
              </Button>
            </div>
          </div>
        </div>
        <div className="contestmaincontainer">
          <div style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
            <img src={bookCricket} alt="" className="contentimage" />
          </div>
          <div className="constestContent">
            <div className="innerContent">
              <p className="TossFontStyle contestpadding">Book Cricket</p>
              <p className="giftFont contestpadding" style={{ color: "#95999D" }}>
                Eearn 2000 runs
              </p>
            </div>
            <div className="contestpadding">
              <Button
                className="TossFontStyle contestButton"
                onClick={handleOpenBookCricket}
              >
                Play Now
              </Button>
            </div>
          </div>
        </div>
        <div className="contestmaincontainer">
          <div style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
            <img src={prediction} alt="" className="contentimage" />
          </div>
          <div className="constestContent">
            <div className="innerContent">
              <p className="TossFontStyle contestpadding">Predictions</p>
              <p className="giftFont contestpadding" style={{ color: "#95999D" }}>
                Predict & Earn
              </p>
            </div>
            <div className="contestpadding">
              <Button
                className="TossFontStyle contestButton"
                onClick={handleOpenprediction}
              >
                Play Now
              </Button>
            </div>
          </div>
        </div>
        <div className="contestmaincontainer">
          <div style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
            <img src={challenges} alt="" className="contentimage" />
          </div>
          <div className="constestContent">
            <div className="innerContent">
              <p className="TossFontStyle contestpadding">Challenges</p>
              <p className="giftFont contestpadding" style={{ color: "#95999D" }}>
                Challenge & Win
              </p>
            </div>
            <div className="contestpadding">
              <Button
                className="TossFontStyle contestButton"
                onClick={handleOpenChallenge}
              >
                Play Now
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
