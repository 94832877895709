import {
  Grid,
  TextField,
  Button,
  Box,
  FormControl,
  InputAdornment,
  Paper, Select, InputLabel, MenuItem,
  Input
} from "@mui/material";
import React, { useState, useRef, useMemo } from "react";
import "./index.css";
import axios from "axios";
import { getStorageItem } from "../../utils/sessionStorage";
import Compressor from "compressorjs";
import moment from "moment/moment";
// jodit-react
import JoditEditor from "jodit-react";
// React-Toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Deal from "../all-deals/deal";

const DealCreationForm = () => {
  const accessToken = getStorageItem("token");
  const [banner, setBanner] = useState("/banner.png");
  const [formContent, setFormContent] = useState({
    image: "",
  });
  const [status, setStatus] = useState(true);
  const [redeemableOnce, setRedeemableOnce] = useState(false);
  const [RunDeal, setRunDeal] = useState(false);
  const [targetUser, setTargetUser] = useState("")
  const [runsBack, setRunsBack] = useState()
  const [RunDealAmount, setRunDealAmount] = useState(0);
  const [isLocked, setIsLocked] = useState(false);
  const [category, setCategory] = useState("All");
  const [appOnly, setAppOnly] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [conditionForUnlock, setConditionForUnlock] = useState({
    post: 0,
    comment: 0,
    highlight: 0
  });
  const [RunConditionCount, setRunCondition] = useState({
    lowerCount: 0,
    upperCount: Infinity
  });
  // Image Wrapper Styles
  const styles = {
    paperContainer: {
      height: 300,
      width: "100%",
      backgroundImage: `url(${banner})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    },
  };

  const editor = useRef(null);
  const [content, setContent] = useState("");

  const nameRef = useRef();
  const runsRef = useRef();
  const countRef = useRef();
  const codeRef = useRef();

  const bannerRef = useRef();
  const startDateRef = useRef();
  const endDateRef = useRef();
  const startTimeRef = useRef();
  const endTimeRef = useRef();
  const discountStartDateRef = useRef();
  const discountEndDateRef = useRef();
  const discountStartTimeRef = useRef();
  const discountEndTimeRef = useRef();
  const emailRef = useRef();
  const priorityRef = useRef();
  const handleSubmit = () => {

    const dealImage = banner;
    const name = nameRef.current.value;
    const desc = content;
    const count = countRef.current.value;
    const redeemrun = runsRef.current.value;
    const encrptedcode = codeRef.current.value;
    const startDate = startDateRef?.current?.value;
    const endDate = endDateRef?.current?.value;
    const startTime = startTimeRef?.current?.value;
    const endTime = endTimeRef?.current?.value;
    const discountStartDate = discountStartDateRef?.current?.value;
    const discountEndDate = discountEndDateRef?.current?.value;
    const discountStartTime = discountStartTimeRef?.current?.value;
    const discountEndTime = discountEndTimeRef?.current?.value;
    const priorityNo = priorityRef?.current?.value;
    if (
      name === "" ||
      count === "" ||
      redeemrun === "" ||
      encrptedcode === "" ||
      startDate === "" ||
      endDate === "" || startTime === "" || endTime === "" || emailRef?.current?.value === "" || priorityNo === ''
    ) {
      toast.error("Error: Data incomplete");
      return;
    }
    const formData = new FormData();
    formData.append("uploader", image);
    formData.append("name", name);
    formData.append("desc", desc);
    formData.append("count", count);
    formData.append("redeemrun", redeemrun);
    formData.append("encrptedcode", encrptedcode);
    formData.append("startTime", startTime);
    formData.append("endTime", endTime);
    formData.append("startDate", moment(startDate).format("YYYY-MM-DD"));
    formData.append("endDate", moment(endDate).format("YYYY-MM-DD"));
    formData.append("appOnly", appOnly);
    formData.append("isLocked", isLocked);
    formData.append("conditionForUnlock", JSON.stringify(conditionForUnlock));
    // formData.append("conditionCount", conditionCount);
    formData.append("redeemableOnce", redeemableOnce);
    formData.append("runDeal", RunDeal);
    formData.append("runDealAmount", RunDealAmount);
    formData.append("partnerEmailId", emailRef?.current?.value);
    formData.append("discount", discount);
    formData.append("category", category);
    formData.append("discountStartTime", discount > 0 ? discountStartTime : "");
    formData.append("discountEndTime", discount > 0 ? discountEndTime : "");
    formData.append("priorityNo", priorityNo);
    formData.append("discountStartDate", discount > 0 ? moment(discountStartDate).format("YYYY-MM-DD") : "");
    formData.append("discountEndDate", discount > 0 ? moment(discountEndDate).format("YYYY-MM-DD") : "");
    formData.append("runcondition", JSON.stringify(RunConditionCount));
    formData.append("targetUser", targetUser);
    formData.append("runsBack", runsBack);
    // Object.keys(data).forEach(key => {
    //   console.log(key, typeof(data[key]), data[key]);
    // });
    // Axios Post request
    const createNewEvent = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/deal/create",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      data: formData,
    };
    if (formData) {
      axios(createNewEvent)
        .then((response) => {
          if (response?.status === 200 && response) {
            toast.success("New Event created successfuly");
            bannerRef.current.value = "";
            nameRef.current.value = "";
            setContent("");
            countRef.current.value = "";
            runsRef.current.value = "";
            codeRef.current.value = "";
            startDateRef.current.value = "";
            // startTimeRef.current.value = "";
            endDateRef.current.value = "";
            setBanner("");
          }
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            error.message
              ? toast.error("Error: " + error.message)
              : toast.error("Error: " + error);
          }
          error.message
            ? toast.error("Error: " + error.message)
            : toast.error("Error: " + error);
        });
    }
  };
  
  const [image, setImage] = useState(null);
  

  const onImageChange = (event) => {
    const { name, value } = event.target;
    if (name === "image") {
      if (event.target.files[0].type.includes("image")) {
        const file = event.target.files[0];
        console.log({ file });
        // const formData = new FormData();
        //...not using compress object to pass service the issue is that after compress object is blob so in service multer is not able to parse it
        new Compressor(file, {
          quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
          success: (compressedResult) => {
            if (compressedResult.size > 5000000) {
              setImage(compressedResult);
              setStatus(true);
            } else {
              // formData.append("uploader", file);
              console.log("This is upload img function call:");
              setImage(compressedResult);
              
              setStatus(false);
            }

            if (event.target.files && event.target.files[0]) {
              setBanner(URL.createObjectURL(event.target.files[0]));
            }
          },
        });
      }
    }
  };

  return (
    <Grid container columnSpacing={2}>
      <Grid item sm={6} xs={12}>
        <Box component="form" noValidate autoComplete="off">
          <Grid container columnSpacing={1}>
            {/* Title */}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  required
                  id="title"
                  label="Title"
                  name="title"
                  autoFocus
                  margin="normal"
                  inputRef={nameRef}
                />
              </FormControl>
            </Grid>
            {/* Location */}
            <Grid item xs={12} lg={4}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-adornment-amount"
                  label="Runs Required"
                  name="redeemRuns"
                  autoFocus
                  type="number"
                  min="0"
                  margin="normal"
                  required
                  inputRef={runsRef}
                />
              </FormControl>
            </Grid>
            {/* partner mailid */}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  required
                  id="mailid"
                  label="email"
                  name="email"
                  autoFocus
                  margin="normal"
                  inputRef={emailRef}
                />
              </FormControl>
            </Grid>

            {/* Price */}
            <Grid item xs={12} lg={4}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-adornment-amount"
                  // startAdornment={
                  //   <InputAdornment position="start">INR</InputAdornment>
                  // }
                  label="Count"
                  margin="normal"
                  type="number"
                  min="0"
                  required
                  inputRef={countRef}
                />
              </FormControl>
            </Grid>
            {/* Code */}
            <Grid item xs={12} lg={4}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-adornment-amount"
                  // startAdornment={
                  //   <InputAdornment position="start">Event Code</InputAdornment>
                  // }
                  label="Code"
                  margin="normal"
                  required
                  inputRef={codeRef}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={4}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-adornment-amount"
                  label="RunCondition lowerCount"
                  margin="normal"
                  type="number"
                  value={RunConditionCount.lowerCount}
                  onChange={(e) => {
                    setRunCondition((prevState) => { return { ...prevState, lowerCount: parseInt(e.target.value, 10) } });
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={4}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-adornment-amount"
                  label="RunCondition upperCount"
                  margin="normal"
                  type="number"
                  value={RunConditionCount.upperCount}
                  onChange={(e) => {
                    setRunCondition((prevState) => { return { ...prevState, upperCount: parseInt(e.target.value, 10) } });
                  }}
                />
              </FormControl>
            </Grid>
            {/* Start Date */}
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  id="start-date"
                  label="Start date"
                  type="date"
                  // defaultValue="2023-01-01"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={startDateRef}
                  margin="normal"
                  required
                />
              </FormControl>
            </Grid>
            {/* End Date */}
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  id="end-date"
                  label="End date"
                  type="date"
                  // defaultValue="2023-01-01"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={endDateRef}
                  margin="normal"
                  required
                />
              </FormControl>
            </Grid>
            {/* Start Time */}
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  id="start-time"
                  label="Start time"
                  type="time"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={startTimeRef}
                  margin="normal"
                  required
                />
              </FormControl>
            </Grid>
            {/* End Time */}
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  id="end-time"
                  label="End time"
                  type="time"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={endTimeRef}
                  margin="normal"
                  required
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  id="start-date"
                  label="Priority No"
                  type="number"
                  // defaultValue="2023-01-01"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={priorityRef}
                  margin="normal"
                  required
                />
              </FormControl>
            </Grid>
            {/* app only */}

            <Grid item xs={12} lg={4}>
              <FormControl fullWidth>
                <InputLabel id="event-code-label">App Only</InputLabel>
                <Select
                  labelId="event-code-label"
                  id="event-code-select"
                  value={appOnly}
                  onChange={(e) => setAppOnly(e.target.value)}
                  label="App only"
                >
                  <MenuItem value={false}>False</MenuItem>
                  <MenuItem value={true}>True</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} lg={4}>
              <FormControl fullWidth>
                <InputLabel id="category-label">Category</InputLabel>
                <Select
                  labelId="category-label"
                  id="category-select"
                  value={category}
                  onChange={(e) => {
                    setCategory(e.target.value);
                  }}
                  label="Category"
                >
                  <MenuItem value="All" >All</MenuItem>
                  <MenuItem value="E-Commerce">E-Commerce</MenuItem>
                  <MenuItem value="FoodAndBeverage">Food And Beverage</MenuItem>
                  <MenuItem value="Exclusive">Exclusive</MenuItem>
                  <MenuItem value="TimeLimit">TimeLimit</MenuItem>
                  <MenuItem value="Offers">Offers</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {/* redeem  once */}

            <Grid item xs={12} lg={4}>
              <FormControl fullWidth>
                <InputLabel id="event-code-label">redeem Once</InputLabel>
                <Select
                  labelId="redeem-once"
                  id="redeem-once-select"
                  value={redeemableOnce}
                  onChange={(e) => setRedeemableOnce(e.target.value)}
                  label="redeemable once"
                >
                  <MenuItem value={false}>False</MenuItem>
                  <MenuItem value={true}>True</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {/* lock or unlock */}
            <Grid item xs={12} lg={4}>
              <FormControl fullWidth>
                <InputLabel id="event-code-label">lock deal</InputLabel>
                <Select
                  labelId="event-code-label"
                  id="event-code-select"
                  value={isLocked}
                  onChange={(e) => {
                    setIsLocked(e.target.value);
                  }}
                  label="is locked"
                >
                  <MenuItem value={true} >True</MenuItem>
                  <MenuItem value={false}>False</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {/* conditions to lock */}
            {isLocked && <Grid item xs={12} lg={4}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-adornment-amount"
                  label=" post condition count"
                  type='number'
                  margin="normal"
                  value={conditionForUnlock.post}
                  onChange={(e) => {
                    setConditionForUnlock((prevState) => { return { ...prevState, post: parseInt(e.target.value, 10) } });
                  }}
                // multiline
                />
              </FormControl>
            </Grid>}
            {isLocked && <Grid item xs={12} lg={4}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-adornment-amount"
                  label=" comment condition count"
                  type='number'
                  margin="normal"
                  value={conditionForUnlock.comment}
                  onChange={(e) => {
                    setConditionForUnlock((prevState) => { return { ...prevState, comment: parseInt(e.target.value, 10) } });
                  }}
                // multiline
                />
              </FormControl>
            </Grid>}
            {(isLocked && appOnly) && <Grid item xs={12} lg={4}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-adornment-amount"
                  label=" highlight condition count"
                  type='number'
                  margin="normal"
                  value={conditionForUnlock.highlight}
                  onChange={(e) => {
                    setConditionForUnlock((prevState) => { return { ...prevState, highlight: parseInt(e.target.value, 10) } });
                  }}
                // multiline
                />
              </FormControl>
            </Grid>}

            <Grid item xs={12} lg={4}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-adornment-amount"
                  label="Discount Percentage"
                  name="discount"
                  autoFocus
                  type="number"
                  min="0" margin="normal"
                  value={discount}
                  onChange={(e) => {
                    setDiscount(e.target.value);
                  }}
                />
              </FormControl>
            </Grid>
            {/*0Discount Start Date */}
            {discount > 0 &&
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <TextField
                    id="discount-start-date"
                    label="Discount Start date"
                    type="date"
                    // defaultValue="2023-01-01"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputRef={discountStartDateRef}
                    margin="normal"
                    required
                  />
                </FormControl>
              </Grid>}
            {/*0Discount End Date */}
            {discount > 0 && <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  id="discount-end-date"
                  label="Discount End date"
                  type="date"
                  // defaultValue="2023-01-01"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={discountEndDateRef}
                  margin="normal"
                  required
                />
              </FormControl>
            </Grid>}
            {/*0Discount Start Time */}
            {discount > 0 && <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  id="discount-start-time"
                  label="Discount Start time"
                  type="time"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={discountStartTimeRef}
                  margin="normal"
                  required
                />
              </FormControl>
            </Grid>}
            {/*0Discount End Time */}
            {discount > 0 &&
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <TextField
                    id="discount-end-time"
                    label="Discount End time"
                    type="time"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputRef={discountEndTimeRef}
                    margin="normal"
                  />
                </FormControl>
              </Grid>
            }
            <Grid item xs={12} lg={4}>
              <FormControl fullWidth>
                <InputLabel id="event-code-label">Run Deal </InputLabel>
                <Select
                  labelId="Run Deal"
                  id="redeem-once-select"
                  value={RunDeal}
                  onChange={(e) => setRunDeal(e.target.value)}
                  label="Run Deal"
                >
                  <MenuItem value={false}>False</MenuItem>
                  <MenuItem value={true}>True</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} lg={4}>
              <FormControl fullWidth>
                <InputLabel id="event-code-label">Target User</InputLabel>
                <Select
                  labelId="Target User"
                  id="target-user-select"
                  value={targetUser}
                  onChange={(e) => setTargetUser(e.target.value)}
                  label="Target User"
                >
                  <MenuItem value={"subscriber"}>Subscriber</MenuItem>
                  <MenuItem value={"users"}>Users</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Runs Back */}

              <Grid item xs={12} lg={4}>
              <FormControl fullWidth>
                <InputLabel id="event-code-label">Runs Back</InputLabel>
                <Input
                  type="number"
                  id="outlined-adornment-amount"
                  value={runsBack}
                  name="runsBack"
                  onChange={(e) => {
                    setRunsBack(e.target.value)
                  }}
                />
              </FormControl>
            </Grid>
            
            
            {RunDeal && <Grid item xs={12} lg={4}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-adornment-amount"
                  label="Run Deal Reward"
                  type='number'
                  margin="normal"
                  value={RunDealAmount}
                  onChange={(e) => { setRunDealAmount(e.target.value) }}
                // multiline
                />
              </FormControl>
            </Grid>}
            {/* Banner */}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  type="file"
                  autoFocus
                  margin="normal"
                  name="image"
                  accept="image/*"
                  onChange={onImageChange}
                  inputRef={bannerRef}
                  required
                />
                {/* <input id="bannerImage" type="file" onChange={onChangePicture}  ref={bannerRef}/> */}
              </FormControl>
            </Grid>
            {/* Description */}
            <Grid item xs={12}>
              <FormControl fullWidth>
                {/* <TextField
                  label="Description"
                  fullWidth
                  multiline
                  rows={5}
                  margin="normal"
                  inputRef={descriptionRef}
                /> */}
                <JoditEditor
                  ref={editor}
                  value={content}
                  tabIndex={1} // tabIndex of textarea
                  onBlur={(content) => setContent(content)} // preferred to use only this option to update the content for performance reasons
                  onChange={(content) => { }}
                  fullWidth
                  multiline
                  rows={5}
                  className="editor-main"
                  margin="normal"
                />
              </FormControl>
            </Grid>
            {/* Button */}
            <Grid item xs={12}>
              <FormControl fullWidth></FormControl>
              <Button
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                margin="normal"
                onClick={handleSubmit}
                fullWidth
              >
                Create Event
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item sm={6} xs={12}>
        <Paper component="img" style={styles.paperContainer}></Paper>
      </Grid>
      <ToastContainer />
    </Grid>
  );
};

export default DealCreationForm;
