import React, { useEffect, useState } from "react";
import "./index.scss";
import { useMediaQuery, Box, Tabs, Tab, AppBar } from "@material-ui/core";
import dealBall from "../../assets/images/rewards/dealBall.png";
import { getAuthToken } from "../../utils/auth";
import AllPredictionList from "./AllPredictionList";
import PredictionCarousel from "./PredictionCarousel";
import Run from "../posts/run";
import { useDispatch } from "react-redux";
import { PathAction } from "../../redux/reducers/globalPath";
import AppContext from "../../context/AppContext";
import { useContext } from "react";
import UserPredictionHistory from "./UserPredictionHistory";
import UpcomingPrediction from "./UpcomingPrediction";

export default function Prediction() {
  const mobileView = useMediaQuery("(max-width:600px)");
  const path = window.location.pathname;
  const dispatch = useDispatch();
  dispatch(PathAction.handlePath({ payload: path }));

  const appContext = useContext(AppContext);
  const { setShowFooter } = appContext;

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {

    setShowFooter(true);
    return () => {
      setShowFooter(false);
    };
  }, []);

  return (
    <div className="RewadsMain">
      {mobileView && (
        <Box className="MobileRuns">
          <img src={dealBall} />
          <p className="mobilepara">
            <Run isRunValueNeeded={true} />
          </p>
        </Box>
      )}
      <PredictionCarousel />
      {mobileView ? (
        <div>
          <AppBar position="static" style={{ color: "black", backgroundColor: "white", marginBottom: "15px" }}>
            <Tabs value={tabValue} onChange={handleTabChange} TabIndicatorProps={{ style: { backgroundColor: tabValue === 0 ? "#57F51E" : tabValue === 1 ? "#0B518E" : "#494D50" } }}>

              <Tab label="Live" style={{
                backgroundColor: tabValue === 0 && "#EDFEE7", color: tabValue === 0 && "#2C9207", width: "33.33%",
                borderBottom: tabValue === 0 && "4px solid #57F51E"
              }} />
              <Tab label="Upcoming" style={{
                backgroundColor: tabValue === 1 && "#E7F3FD",
                color: tabValue === 1 && "#0C5BA0", width: "33.33%",
                borderBottom: tabValue === 1 && "4px solid #0B518E"
              }} />
              <Tab label="History" style={{
                backgroundColor: tabValue === 2 && "#F2F2F3",
                color: tabValue === 0 && "#737373", width: "33.33%",
                borderBottom: tabValue === 2 && "4px solid #494D50"
              }} />
            </Tabs>


          </AppBar>
          {tabValue === 0 && <AllPredictionList />}
          {tabValue === 1 && <UpcomingPrediction />}
          {tabValue === 2 && <UserPredictionHistory />}
        </div>
      ) : (
        <div >
          <AllPredictionList />
          <UpcomingPrediction />
          <UserPredictionHistory />
        </div>
      )
      }


    </div >
  );
}
