import React from 'react';
import Carousel from 'react-material-ui-carousel'
import { Paper, Button } from '@mui/material'
import testopic1 from "../../assets/images/usplandingpage/testopic1.png";
import testopic2 from "../../assets/images/usplandingpage/testopic2.png";
import testopic3 from "../../assets/images/usplandingpage/testopic3.png";

export default function Examples(props)
{
    var itemsData = [{
        name: 'Pranshu Pal', 
        comment: 'I never thought winning a Smartwatch would be this easy. All it took was signing up on Champhunt.',
        img: testopic1,
       
    }, {
        name: 'Manjeet Raj',
        comment: 'I have won a Fire-Boltt smart watch and many more rewards by signing up on champhunt and being active on the platform.',
        img: testopic2,
        
    }, {
        name: 'Ansh Desai',
        comment: 'I have won a giveaway as well as multiple rewards from Champhunt. I am a regular on the website and constantly winning rewards on the matchday contests.',
        img: testopic3,
    },]

    return (
        <Carousel >
            {
                itemsData.map( (item, i) => <Item key={i} item={item} /> )
            }
        </Carousel>
    )
}

function Item(props)
{
    return (
        <div className='flex2_usp' style={{ paddingLeft: 34, paddingRight: 32, paddingTop: 39 }}>
      
            <div className='comment_usp'>
                <div style={{ paddingTop: 10 }}>
                    <img src={props.item.img} style={{ borderRadius:'50%',height:'68px'}}/>
                </div>
                <div style={{ paddingTop: 10 }}>
                    <p className='font20ex_usp' > {props.item.name}</p>
                </div>
                {/* <div style={{ paddingTop: 10 }}>
                    {props.item.star.map((i)=>(

                    <img src={star} />
                    ))}
                  {props.item.name==='Ansh Desai' &&  <div style={{display:'inline-block',height:'14px',overflow:'hidden',width:'8px'}}>
                                <img src={star} />
                                 </div>}
                </div> */}
                <div style={{ padding: 10 }}>
                    <p>{props.item.comment}</p>
                </div>
            </div>

    

    </div>
    )
}
