import React, { useState, useEffect } from 'react';
import { Typography, Box, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField, Button, Avatar, List, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify'
import closeIcon from "../../assets/images/Events/Dismiss-circle.png";
import whatsappIcon from "../../assets/images/Events/whatsapp.png";

const RequestDialog = ({ open, onClose, team, whatsAppUrl }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [teamMembers, setTeamMembers] = useState([])
  const userId = localStorage.getItem('user_id');
  const [iterator, setIterator] = useState({})

  // accept request fn
  const approveRequest = async (player, playerStatus, teamStatus, index) => {
    try {
      const response = await axios.post(global.config.ROOTURL.prod + `/events/team/teamMemberApproved/${player._id}?playerStatus=${playerStatus}&teamStatus=${teamStatus}&playerName=${player.playerName}`);
      if (response.data) {
        if (response.data === "Player already added to Another Team") {
          toast.error("Player already added to Another Team")
        }
        else {
          setIterator({ index, status: playerStatus })
          if (response.data === "accepted") {
            toast.success(`New Player added to ${player.teamName} `)
          } else {
            toast.success('Player Rejected ')
          }
        }

      }
    } catch (error) {
      console.log(error);
    }

    ;
  };

  useEffect(() => {
    if (iterator) {
      setTeamMembers((prevTeamMembers) =>
        prevTeamMembers
          .map((member, i) => {
            if (i === iterator.index) {
              return { ...member, team_status: iterator.status };
            }
            return member;
          })
          .filter(member => member.team_status !== 'rejected')
      );
    }
  }, [iterator]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(global.config.ROOTURL.prod + `/events/team/show-team/${team.teamName}`);
        if (response.data) {
          setTeamMembers(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  // reject btn fn
  const handleRejectClick = (member, index) => {
    setSelectedMember(member);
    setSelectedIndex(index);
    setOpenDialog(true);
  };

  // reject btn confirmation
  const handleConfirmReject = () => {
    approveRequest(selectedMember, "rejected", false, selectedIndex);
    setOpenDialog(false);
  };

  return (
    <Dialog open={open} fullWidth maxWidth="sm">

      {/* header */}
      <IconButton
        onClick={onClose}
        aria-label="close"
        sx={{ justifyContent: "flex-end !important", borderRadius: "0 !important" }}
      >
        <img src={closeIcon} alt="Close" style={{ width: 24, height: 24 }} />
      </IconButton>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
          padding: "10px", 
        }}
      >
        <DialogTitle
          sx={{ display: "flex", alignItems: "center" }}
        >
          Team Members
          <Typography color="primary" sx={{ paddingLeft: "10px" }}>
            {teamMembers.length} PLAYERS
          </Typography>
        </DialogTitle>

        {/* whatsapp share icon */}
        <a
          href={whatsAppUrl}
          data-action="share/whatsapp/share"
          target="_blank"
          style={{
            textDecoration: "none",
            display: "flex",
            alignItems: "center",
            marginRight: "30px",
            borderRadius: "20px",
            paddingRight: "8px",
            color: "black",
            marginTop: "10px",
          }}
        >
          <img
            style={{ width: "30px", height: "30px", marginRight: "5px" }}
            src={whatsappIcon}
          />
          WhatsApp
        </a>
      </Box>

      <DialogContent>
        <TextField
          margin="dense"
          label="Search user name"
          fullWidth
          sx={{ marginBottom: "20px" }}
        />

        {/* players list */}
        <List>
          {teamMembers.map((member, index) => (
            <ListItem
              key={index}
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" }, 
                alignItems: { xs: "flex-start", sm: "center" }, 
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
                <ListItemAvatar>
                  <Avatar alt={member.playerName} src="path_to_member_image.jpg" />
                </ListItemAvatar>
                <ListItemText
                  primary={member.playerName}
                  secondary={`Roll no: ${member.rollNo}`}
                  sx={{ marginLeft: "10px" }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: { xs: "space-between", sm: "flex-end" },
                  width: { xs: "100%", sm: "auto" },
                  marginTop: { xs: "10px", sm: "0" },
                }}
              >
                {member.team_status === "pending" ? (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        approveRequest(member, "accepted", true, index)
                      }
                      sx={{ marginBottom: "5px", width: { xs: "45%", sm: "auto" } }}
                    >
                      Accept
                    </Button>
                    <Button
                      sx={{
                        marginLeft: { xs: "5%", sm: "4px" },
                        width: { xs: "45%", sm: "auto" },
                        marginBottom: "5px"
                      }}
                      variant="contained"
                      color="error"
                      onClick={() => handleRejectClick(member, index)}
                    >
                      Reject
                    </Button>
                  </>
                ) : (
                  <Button variant="outlined" disabled sx={{ width: "100%" }}>
                    Added to team
                  </Button>
                )}
              </Box>
            </ListItem>
          ))}
        </List>
      </DialogContent>

      {/* reject confirmation */}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      >
        <DialogTitle>Confirm Rejection</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to reject {selectedMember?.playerName}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmReject} color="error">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog >
  );
};

export default RequestDialog;
