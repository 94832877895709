import React, { useEffect, useState } from "react";
import ProfileCard from "./ProfileCard";
import InfiniteScroll from "react-infinite-scroll-component";
import { getStorageItem } from "../../utils/sessionStorage";
import axios from "axios";
import "./ReportUsers.scss"; // Make sure to create this CSS file

const ReportUsers = () => {
  const [reportedUsers, setReportedUsers] = useState([]);
  const accessToken = getStorageItem("token");
  const [groups, setGroups] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState("");
  const [isAdmin, setIsAdmin] = useState(true);

  const getGroups = () => {
    if (!accessToken) return;

    const options = {
      method: "get",
      url: global.config.ROOTURL.prod + `/groupchat/getAllGroup`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };

    axios(options)
      .then(({ data }) => {
        setGroups(data);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          console.log("Unauthorized:", error);
        } else {
          console.log("Error fetching groups:", error);
        }
      });
  };

  const getReportedUsers = (groupId) => {
    if (!accessToken || !groupId) return;

    const options = {
      method: "get",
      url:
        global.config.ROOTURL.prod + `/groupchat/getReportedUsers/${groupId}`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };

    axios(options)
      .then(({ data }) => {
        setReportedUsers(data);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          console.log("Unauthorized:", error);
        } else {
          console.log("Error fetching reported users:", error);
        }
      });
  };

  const checkAdminStatus = () => {
    if (!accessToken) return;

    const options = {
      method: "get",
      url: global.config.ROOTURL.prod + `/user/isAdmin`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };

    axios(options)
      .then(({ data }) => {
        setIsAdmin(data.isAdmin);
      })
      .catch((error) => {
        console.log("Error checking admin status:", error);
      });
  };

  useEffect(() => {
    getGroups();
    // checkAdminStatus();
  }, []);

  useEffect(() => {
    if (selectedGroupId) {
      getReportedUsers(selectedGroupId);
    }
  }, [selectedGroupId]);

  const handleGroupSelect = (groupId) => {
    setSelectedGroupId(groupId);
  };

  return (
    <div className="report-users-container">
      <h1 className="report-users-title">Reported Users</h1>

      {isAdmin ? (
        <div className="content-wrapper">
          <div className="groups-list">
            <h2>Groups</h2>
            {groups.map((group) => (
              <div
                key={group._id}
                className={`group-item ${
                  selectedGroupId === group._id ? "selected" : ""
                }`}
                onClick={() => handleGroupSelect(group._id)}
              >
                <img
                  src={group.groupProfile}
                  alt={group.name}
                  className="group-image"
                />
                <p className="group-name">{group.name}</p>
              </div>
            ))}
          </div>

          <div className="reported-users-list">
            <h2>Reported Users</h2>
            {selectedGroupId ? (
              reportedUsers.length > 0 ? (
                <InfiniteScroll
                  dataLength={reportedUsers.length}
                  // next={fetchMoreData}
                  // hasMore={hasMore}
                  className="reported-users-scroll"
                >
                  {reportedUsers.map((user, index) => (
                    <div key={index} className="reported-user-card">
                      <ProfileCard data={user} />
                    </div>
                  ))}
                </InfiniteScroll>
              ) : (
                <p>No reported users in this group.</p>
              )
            ) : (
              <p>Select a group to view reported users.</p>
            )}
          </div>
        </div>
      ) : (
        <p>You do not have permission to view this page.</p>
      )}
    </div>
  );
};

export default ReportUsers;
