import React from 'react'
import commntpic from '../../assets/images/landigpage2/commentpic.png'
import star from '../../assets/images/landigpage2/star.png'
import Carouselll from "../../components/Landingpage2/Carouselll"
import no1 from '../../assets/images/Events/Raj.png'
import no2 from '../../assets/images/Events/no22.jpg'
import no3 from '../../assets/images/user2.jpeg'
import { Box, useMediaQuery } from '@mui/material';
const dummmy = [{
    name: 'RajAishwary Singh',
    comment: 'I have scored more than 4 million runs in the last 3 months playing various Matchday contest in the last 4 months, which has led me to earn thousands of Rupees. I look forward to playing many more contests on the website and app. ',
    img: no1, 
    star:[1,1,1,1]
}, {
    name: 'Valred Rodrigues',
    comment: 'I have redeemed a MI jersey and an India Jersey just by participating in the matchday contests. I look forward to winning much more things in the forthcoming series.',
    img: no2,
    star:[1,1,1,1,1]
}, {
    name: 'Ansh Desai',
    comment: 'I enjoy the thrill of winning on matchdays by predicting the outcome of scenarios. It elevates my game experience and gets me some extra bucks as a college student. I love Champhunt.',
    img: no3,
    star:[1,1,1,1]
},]
export default function Five() {
    const mobileView = useMediaQuery("(max-width:600px)");
    return (
        <Box sx={{ height: 529, backgroundColor: { xs: '#F8F8F8', md: '#F0F0F0' } }}>
            <div style={{ paddingTop: 80 }}>

                <div style={{ textAlign: 'center' }}>
                    <p className='font36'>What Our Users say about us</p>
                </div>
                {
                    mobileView
                        ?
                        <Carouselll />

                        :

                        <div className='flexbox2' style={{ paddingLeft: 70, paddingRight: 70, paddingTop: 40 }}>
                            {dummmy.map((i,index) => (
                                <div className='commentbox' key={index}>
                                    <div style={{ paddingTop: 10 }}>
                                        <img src={i.img} style={{ borderRadius: '50%', height: '85px' }} />
                                    </div>
                                    <div style={{ paddingTop: 10 }}>
                                        <p className='font20ex' > {i.name}</p>
                                    </div>
                                    <div style={{ paddingTop: 10}}>
                                        {i.star.map((i) => (

                                            <img src={star} />
                                        ))}
                                        {i.name === 'Ansh Desai' && <div className='startmanage'>
                                            <img src={star} />
                                        </div>}
                                    </div>
                                    <div style={{ padding: 10, lineHeight: '25px' }}>
                                        <p>{i.comment}</p>
                                    </div>
                                </div>

                            ))}

                        </div>
                }


            </div>
        </Box>
    )
}

