import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import axios from "axios";
import { getStorageItem } from "../../../utils/sessionStorage";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "20px 10px",
    backgroundColor: "#f8f8f8",
    borderRadius: "10px",
    boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
    overflow: "hidden",
    transition: "transform 0.3s ease-in-out",
    "&:hover": {
      transform: "scale(1.02)",
    },
  },
  media: {
    height: 300,
    backgroundSize: "cover",
    backgroundPosition: "center",
    cursor: "pointer",
  },
  content: {
    padding: theme.spacing(2),
  },
}));

const AdsSection = () => {
  const classes = useStyles();
  const [ads, setAds] = useState([]);
  const [currentAdIndex, setCurrentAdIndex] = useState(0);
  const accessToken = getStorageItem("token");

  useEffect(() => {
    getAllAds();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentAdIndex((prevIndex) => (prevIndex + 1) % ads.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [ads]);

  const getAllAds = () => {
    if (!accessToken) return;
    const options = {
      method: "get",
      url: global.config.ROOTURL.prod + `/groupchat/getAllAds`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };
    axios(options)
      .then(({ data }) => {
        setAds(data);
      })
      .catch((error) => {
        console.log("Error fetching ads:", error);
      });
  };

  const handleAdClick = () => {
    if (ads.length > 0) {
      window.open(ads[currentAdIndex].redirectUrl, "_blank");
    }
  };

  if (ads.length === 0) {
    return null; // Don't render anything if there are no ads
  }

  const currentAd = ads[currentAdIndex];

  return (
    <Card className={classes.root}>
      <CardMedia
        className={classes.media}
        image={currentAd.image}
        title={currentAd.title}
        onClick={handleAdClick}
      />
    </Card>
  );
};

export default AdsSection;
