import React, { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import BonusRunImage from "../../assets/images/bonusRunimage.png";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import StreakOverModal from "./StreakOverModal";
import ProgressBarComponent from "./ProgressBar";
import DailyLoginCompleteModal from "./DailyLoginCompleteModal";
import BonusRunsCoverImage from "../../assets/images/bonusRun-cover.png";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useNavigate } from "react-router-dom";

const style = (isMobile) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: isMobile ? "549px" : "433px",
  maxWidth: 400,
  backgroundColor: "#FFFFFF",
  border: "none",
  boxShadow: 24,
  padding: "17px",
  borderRadius: "20px",
  fontSize: "16px",
  color: "#333",
  fontFamily: "Arial, sans-serif",
  fontWeight: "500",
  backgroundRepeat: "no-repeat",
});

const generateBoxStyle = (backgroundColor, isActive) => ({
  width: "57px",
  height: "75px",
  position: "relative",
  borderRadius: "10px",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor,
  boxShadow: "0px 4px 8px rgba(108, 108, 108, 0.5)",
  marginBottom: "20px",
  zIndex: isActive ? "2" : "1",
});

const generateBoxStyle1 = (
  backgroundColor,
  isActive,
  isMobile,
  continuousActive
) => ({
  width: isMobile ? "32%" : "19%",
  height: isMobile ? "310px" : "194px",
  borderRadius: "10px",
  backgroundColor,
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  boxShadow: "0px 4px 8px rgba(108, 108, 108, 0.5)",
  zIndex: isActive ? "2" : "1",
});

const imageStyle = {
  width: "37px",
  height: "35px",
  marginBottom: "10px",
};
const imageStyle1 = {
  width: "74px",
  height: "67px",
  marginBottom: "10px",
};

const Card = (props) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 769);
  const cardStyle = generateBoxStyle(props?.backgroundColor);
  const cardStyle1 = generateBoxStyle1(props?.backgroundColor, false, isMobile);
  const styleBackgroundImage = {
    ...cardStyle,
    backgroundImage: props.activeToday ? `url(${BonusRunsCoverImage})` : "none",
  };

  return (
    <>
      {props.continuousActive === 7 ? (
        <img
          src={BonusRunsCoverImage}
          alt="bonus-runs-cover"
          style={{
            width: "117px",
            height: "117px",
            position: "absolute",
            left: "-15px",
            top: "4rem",
          }}
        />
      ) : null}
      <div
        style={props.continuousActive === 7 ? cardStyle1 : styleBackgroundImage}
      >
        <div style={{ position: "relative" }}>
          {props?.indexLess ? (
            <div
              style={{
                position: "absolute",
                width: props.continuousActive === 7 ? "71px" : "57px",
                height: props.continuousActive === 7 ? "195px" : "75px",
                background: "rgba(204, 204, 204, 0.5)",
                borderRadius: "10px",
                right: props.continuousActive === 7 ? "-36px" : "-29px",
                bottom: props.continuousActive === 7 ? "-164px" : "",
              }}
            >
              <CheckCircleOutlineIcon
                style={{
                  color: "#6DEC58",
                  position: "absolute",
                  top: "17px",
                  left: "5px",
                }}
              />
            </div>
          ) : null}
        </div>
        <img
          src={BonusRunImage}
          alt="login-runs"
          style={props.continuousActive === 7 ? imageStyle1 : imageStyle}
        />
        {props.continuousActive === 7 ? (
          <hr style={{ width: "70px", color: "#E9E9E9", height: "1px" }} />
        ) : (
          <hr style={{ width: "57px", color: "#E9E9E9", height: "1px" }} />
        )}
        <p
          className={props.continuousActive === 7 ? "bonus_run1" : "bonus_run"}
        >
          {props?.runsgiven}
        </p>
      </div>
      <p
        className={props.continuousActive === 7 ? "dailys_day1" : "dailys_day"}
      >
        Day {props?.day}
      </p>
    </>
  );
};
export default function ActiveuserRunModel({ data, Open, handleCloseModel }) {
  const [open, setOpen] = useState(false);
  const [messageData, setMessageData] = useState({});
  const [dailyLoginModalOpen, setDailyLoginModalOpen] = useState(false);
  const [currentStage, setCurrentStage] = useState(1);
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
    handleCloseModel();
  };

  const isMobile = window.innerWidth <= 769;

  const data1 = [
    { backgroundColor: "#F8F8F8" },
    { backgroundColor: "#F8F8F8" },
    { backgroundColor: "#F8F8F8" },
    { backgroundColor: "#F8F8F8" },
    { backgroundColor: "#F8F8F8" },
    { backgroundColor: "#F8F8F8" },
  ];

  const handleClaimReward = () => {
    setDailyLoginModalOpen(true);
  };

  React.useEffect(() => {
    setOpen(Open);
    setMessageData(data);
    if (data.message === "continuous" && data.continuousActive) {
      setCurrentStage(data.continuousActive);
    }
  }, []);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style(isMobile)}>
            <IconButton
              style={{
                position: "absolute",
                top: "8px",
                right: "8px",
                color: "#0C5BA0",
              }}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
            <h3
              style={{
                marginBottom: "39px",
                textAlign: "center",
                color: "#0C5BA0",
              }}
            >
              Daily Login
            </h3>
            {messageData.message === "continuous" &&
            messageData.continuousActive ? (
              <>
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      rowGap: "26px",
                      width: "77%",
                    }}
                  >
                    {data1?.slice(0, 7).map((item, index) =>
                      index + 1 > messageData?.continuousActive ? (
                        <Card
                          day={index + 1}
                          backgroundColor={item?.backgroundColor}
                          runsgiven={messageData?.runsgiven}
                          continuousActive={messageData?.continuousActive}
                        />
                      ) : index + 1 > messageData?.continuousActive ? (
                        <Card
                          day={index + 1}
                          backgroundColor={item?.backgroundColor}
                          runsgiven={messageData?.runsgiven}
                          continuousActive={messageData?.continuousActive}
                        />
                      ) : index + 1 < messageData?.continuousActive ? (
                        <Card
                          day={index + 1}
                          indexLess={true}
                          backgroundColor={item?.backgroundColor}
                          runsgiven={messageData?.runsgiven}
                          continuousActive={messageData?.continuousActive}
                        />
                      ) : index + 1 === messageData?.continuousActive ? (
                        <Card
                          // activeToday={true}
                          day={index + 1}
                          backgroundColor={item?.backgroundColor}
                          runsgiven={messageData?.runsgiven}
                          continuousActive={messageData?.continuousActive}
                        />
                      ) : (
                        <></>
                      )
                    )}
                  </div>
                  <Card
                    day={data1.length + 1}
                    backgroundColor={"#F8F8F8"}
                    runsgiven={messageData?.runsgiven}
                    continuousActive={7}
                  />
                </div>
                <div style={{ position: "relative", bottom: "5rem" }}>
                  <ProgressBarComponent
                    currentStage={messageData?.continuousActive}
                    totalStages={7}
                    status={messageData.message}
                  />
                </div>
                <Button
                  onClick={handleClose}
                  style={{
                    backgroundColor: "#0C5BA0",
                    color: "white",
                    position: "relative",
                    left: "16px",
                    bottom: "5.3rem",
                    width: "90%",
                    height: "43px",
                  }}
                  // onClick={handleClaimReward}
                >
                  Claim Reward
                </Button>
              </>
            ) : (
              <div style={{ position: "relative", bottom: "7rem" }}>
                <DailyLoginCompleteModal
                  currentStage={messageData?.continuousActive}
                  totalStages={7}
                  status={messageData?.message}
                />
              </div>
            )}
            {messageData.message === "breaked" && (
              <div>
                <StreakOverModal
                  currentStage={messageData?.continuousActive}
                  totalStages={7}
                  status={messageData?.message}
                />
              </div>
            )}
          </Box>
        </Fade>
      </Modal>
      {dailyLoginModalOpen && (
        <StreakOverModal
          open={dailyLoginModalOpen}
          onClose={() => setDailyLoginModalOpen(false)}
          currentStage={messageData?.continuousActive}
          totalStages={7}
          status={messageData?.message}
        />
      )}
    </div>
  );
}
