import React, { useState, useEffect, useRef } from "react";
import "./index.scss";
import champLogo from "../../assets/images/icons/storyIcon.png";
import axios from "axios";
import ViewStories from "./ViewStories";
import { IoCloudUploadOutline } from "react-icons/io5";

import ViewPersonalStory from "./ViewPersonalStory";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { getStorageItem } from "../../utils/sessionStorage";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxWidth: "90%",
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
  maxHeight: "90vh",
  overflow: "auto",
};

const Story = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openModalView, setOpenModalView] = useState(false);
  const [stories, setStories] = useState([]);
  const [videoURL, setVideoURL] = useState(null);
  const fileInputRef = useRef(null);
  const [media, setMedia] = useState([]);
  const [videoIndex, setVideoIndex] = useState(0); // Track current video index
  const [fileData1, setFileData1] = useState();
  const [myStories, setMyStories] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [mediaURL, setMediaURL] = useState(null); // URL for uploaded media
  const [mediaType, setMediaType] = useState(null); // Type of media: 'video' or 'image'
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = useState(false);
  const avatar = getStorageItem("avatar");

  const handleClickPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  // For circular slide
  const handleClick = (index) => {
    setVideoIndex(index); // Set the current video index
    setOpenModal(true);
  };

  const handleClickView = (index) => {
    // setVideoIndex(index); // Set the current video index
    setOpenModalView(true);
  };

  const accessToken = getStorageItem("token");
  const getStoriesAndUserDetails = () => {
    if (!accessToken) return;

    const options = {
      method: "get",
      url: global.config.ROOTURL.prod + "/story/getAllStories",
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };

    axios(options)
      .then(({ data }) => {
        setStories([...data]);
        setMedia([...data[0].stories.map((story) => story.media)]);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          console.log("Unauthorized:", error);
        } else {
          console.log("Error fetching stories:", error);
        }
      });
  };

  const getMyStory = () => {
    if (!accessToken) return;

    const options = {
      method: "get",
      url: global.config.ROOTURL.prod + `/story/getStory`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };

    axios(options)
      .then(({ data }) => {
        setMyStories(data);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          console.log("Unauthorized:", error);
        } else {
          console.log("Error fetching stories:", error);
        }
      });
  };

  useEffect(() => {
    getMyStory();
    getStoriesAndUserDetails();
  }, []);

  const handleAfterStoryDelete = () => {
    try {
      getMyStory();
    } catch (error) {
      console.log(error);
    }
  };

  const addNewStory = async () => {
    const file = fileData1;
    let dataURL = videoURL;
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("uploader", file, file.name);

      const uploadResponse = await axios.post(
        global.config.ROOTURL.prod + `/upload-file`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("uploadResponse", uploadResponse);
      if (uploadResponse.data && uploadResponse.data.length > 0) {
        dataURL = uploadResponse.data[0].location;
      } else {
        console.error("Error occurred: No data returned from upload.");
        return;
      }

      const options = {
        method: "post",
        url: global.config.ROOTURL.prod + "/story/create",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        data: { media: dataURL },
      };

      await axios(options);
      setFileData1(null);
      setVideoURL(null);
      setMediaURL(null);
      setMediaType(null);

      // Reset file input
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }

      handleClose();
      getMyStory();
      setLoading(false);
      // setFileData1("")
      // setVideoURL("")
      // setMediaURL(null)

      // handleClose()
      // getMyStory()
      // setLoading(false)
    } catch (error) {
      setLoading(false);
      console.error("Error uploading video:", error);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const fileURL = URL.createObjectURL(file);
    setFileData1(file); // Save file data if needed for upload
    setMediaURL(fileURL); // Set URL for display

    // Check file type to determine media type
    if (file.type.startsWith("video")) {
      setMediaType("video");
    } else if (file.type.startsWith("image")) {
      setMediaType("image");
    } else {
      // Handle unsupported file types or other logic
      console.error("Unsupported file type");
      return;
    }
  };

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? "simple-popover" : undefined;

  return (
    <div className="story-main-container">
      <div className="stories-slider">
        <div>
          <div>
            <Button aria-describedby={id} onClick={handleClickPopover}>
              <div className="add-story">
                <img
                  className="add-story-logo"
                  src={avatar || champLogo}
                  alt="Add Story"
                  style={{ borderRadius: "50%", width: "65px", height: "65px" }}
                />
                <p className="add-story-name">Add Story</p>
              </div>
            </Button>
            <Popover
              id={id}
              open={openPopover}
              anchorEl={anchorEl}
              onClose={handleClosePopover}
              anchorOrigin={{
                vertical: "center",
                horizontal: "left",
              }}
            >
              <Box sx={{ p: 1 }}>
                <Typography
                  sx={{ p: 1, cursor: "pointer" }}
                  onClick={handleClickView}
                >
                  View Story
                </Typography>

                <Typography
                  sx={{ p: 1, cursor: "pointer" }}
                  onClick={handleOpen}
                >
                  Add Story
                </Typography>
                <Modal
                  keepMounted
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="keep-mounted-modal-title"
                  aria-describedby="keep-mounted-modal-description"
                >
                  <Box sx={style}>
                    <Typography
                      id="keep-mounted-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      Add Story
                    </Typography>
                    <div className="add-story">
                      {mediaType === "video" ? (
                        <div>
                          <video
                            src={mediaURL}
                            controls
                            style={{ width: "100%", height: "auto" }}
                            autoPlay
                            loop
                          />
                          <div>
                            {loading ? (
                              <p>Uploading ... </p>
                            ) : (
                              <Button
                                style={{
                                  color: "#ffffff",
                                  backgroundColor: "black",
                                  width: "100%",
                                }}
                                onClick={addNewStory}
                              >
                                Add Story
                              </Button>
                            )}
                          </div>
                        </div>
                      ) : mediaType === "image" ? (
                        <div>
                          <img
                            src={mediaURL}
                            style={{ width: "100%", height: "auto" }}
                            alt="Uploaded Image"
                          />
                          <div>
                            <Button
                              style={{
                                color: "#ffffff",
                                backgroundColor: "black",
                                width: "100%",
                              }}
                              onClick={addNewStory}
                            >
                              Add Story
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                            backgroundColor: "rgba(40, 90, 155, 0.05)",
                            borderRadius: "16px",
                            padding: "40px",
                            transition: "all 0.3s ease",
                            "&:hover": {
                              backgroundColor: "rgba(40, 90, 155, 0.1)",
                            },
                          }}
                        >
                          <IoCloudUploadOutline
                            size={100}
                            color="#285a9b"
                            style={{ marginBottom: "20px" }}
                          />
                          <Typography
                            variant="h6"
                            sx={{
                              color: "#285a9b",
                              fontWeight: "bold",
                              marginBottom: "20px",
                              textAlign: "center",
                            }}
                          >
                            Upload Your Story
                          </Typography>
                          <input
                            type="file"
                            ref={fileInputRef}
                            onChange={handleFileChange}
                            accept="image/*,video/*"
                            style={{ display: "none" }}
                            id="file-upload"
                          />
                          <label htmlFor="file-upload">
                            <Button
                              variant="outlined"
                              component="span"
                              startIcon={<IoCloudUploadOutline />}
                              sx={{
                                color: "#285a9b",
                                borderColor: "#285a9b",
                                "&:hover": {
                                  backgroundColor: "rgba(40, 90, 155, 0.1)",
                                  borderColor: "#285a9b",
                                },
                              }}
                            >
                              Choose File
                            </Button>
                          </label>
                          <Typography
                            variant="body2"
                            sx={{
                              color: "#666",
                              marginTop: "10px",
                              textAlign: "center",
                            }}
                          >
                            Drag and drop a file here or click to select
                          </Typography>
                        </Box>
                      )}
                    </div>
                  </Box>
                </Modal>
              </Box>
            </Popover>
          </div>
        </div>

        {stories.map((story, index) => (
          <div
            key={story.id}
            className="following-stories"
            onClick={() => handleClick(index)}
          >
            <div className="following-profile-container">
              <img
                className="following-profile"
                src={story.user.profilePhoto || champLogo}
                alt={story.user?.firstName || "User"}
              />
            </div>
            <p className="following-name">{`${story.user?.firstName} `}</p>
          </div>
        ))}
      </div>
      {openModal && (
        <ViewStories
          openModal={openModal}
          setOpenModal={setOpenModal}
          videoIndex={videoIndex}
          storyData={stories}
        />
      )}

      {openModalView && (
        <ViewPersonalStory
          handleOpenStory={handleOpen}
          openModalView={openModalView}
          setOpenModalView={setOpenModalView}
          myStories={myStories}
          handleAfterStoryDelete={handleAfterStoryDelete}
        />
      )}
    </div>
  );
};

export default Story;
