import React, { useEffect, useState } from "react";
import "./coupon.scss";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import { toast } from 'react-toastify';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const ActiveCoupons = () => {
  const [coupons, setCoupons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState(null);

  const handleDelete = async (_id) => {
    try {
      const response = await fetch(
        global.config.ROOTURL.prod + `/coupons/deactivateCoupon/${_id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem('token')}`, // Add authorization header if needed
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to deactivate coupon");
      }

      setCoupons(coupons.filter((coupon) => coupon._id !== _id));
      toast.success('Coupon deactivated');
      closeModal();
    } catch (error) {
      toast.error('Failed to deactivate coupon');
    }
  };

  const openModal = (coupon) => {
    setSelectedCoupon(coupon);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedCoupon(null);
  };

  useEffect(() => {
    const fetchCoupons = async () => {
      try {
        const response = await fetch(global.config.ROOTURL.prod + `/coupons/getActiveCoupons`, {
          headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`, // Add authorization header if needed
          },
        });
        if (!response.ok) {
          throw new Error("Failed to fetch coupons");
        }
        const data = await response.json();
        setCoupons(data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchCoupons();
  }, []);

  const handleUpdateCoupon = async (id, updatedCoupon) => {
    try {
      const response = await fetch(
        global.config.ROOTURL.prod + `/coupons/updateCoupon/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem('token')}`, 
          },
          body: JSON.stringify(updatedCoupon),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update coupon");
      }

      const updatedCoupons = coupons.map((coupon) =>
        coupon._id === id ? { ...coupon, ...updatedCoupon } : coupon
      );

      setCoupons(updatedCoupons);
    } catch (error) {
      console.error(error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <h2>Active Coupons</h2>
      <div className="coupon-list">
        {coupons.map((coupon) => (
          <CouponCard
            key={coupon._id}
            coupon={coupon}
            onUpdate={handleUpdateCoupon}
            openModal={openModal}
          />
        ))}
      </div>

      {selectedCoupon && (
        <Dialog
          open={isModalOpen}
          onClose={closeModal}
        >
          <DialogTitle>Confirm Deactivation</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to deactivate the following coupon?
            </DialogContentText>
            <p><strong>Description:</strong> {selectedCoupon.description}</p>
            <p><strong>Discount:</strong> {selectedCoupon.discount} %</p>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleDelete(selectedCoupon._id)} color="primary">
              Deactivate
            </Button>
            <Button onClick={closeModal} color="primary" autoFocus>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

const CouponCard = ({ coupon, onUpdate, openModal }) => {
  const { _id, discount, description,type } = coupon;
  const [isEditing, setIsEditing] = useState(false);
  const [editedDiscount, setEditedDiscount] = useState(discount);
  const [editedType, setEditedType] = useState(type);
  const [editedDescription, setEditedDescription] = useState(description);

  const handleUpdate = async () => {
    const updatedCoupon = {
      discount: editedDiscount,
      description: editedDescription,
      type: editedType,
    };

    try {
      await onUpdate(_id, updatedCoupon);
      setIsEditing(false);
    } catch (error) {
      console.error("Failed to update coupon:", error);
    }
  };

  return (
    <div className="coupon-card">
      {isEditing ? (
        <>
          <input
            type="number"
            value={editedDiscount}
            onChange={(e) => setEditedDiscount(e.target.value)}
          />
          <input
            type="text"
            value={editedDescription}
            onChange={(e) => setEditedDescription(e.target.value)}
          />
          <select
            id="ad-creation-type"
            type="text"
            className="ad-creation__form-input"
            value={editedType}
            onChange={(e) => setEditedType(e.target.value)}
            required
          >
            <option value="" disabled>Select Type</option>
            <option value="Subscription">Subscription</option>
            <option value="Marketplace">Marketplace</option>
          </select>
          <button onClick={handleUpdate}>Save</button>
          <button onClick={() => setIsEditing(false)}>Cancel</button>
        </>
      ) : (
        <>
          <p>Discount: {discount} %</p>
          <p>Description: {description}</p>
          <p>Type: {type}</p>
          <button
            onClick={() => setIsEditing(true)}
            style={{ marginTop: "10px" }}
          >
            Update
          </button>
          <Button
            variant="outlined"
            onClick={() => openModal(coupon)}
          >
            Deactivate
          </Button>
        </>
      )}
    </div>
  );
};

export default ActiveCoupons;
