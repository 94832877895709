import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  notificationData: null,
  notificationIsLoading: false,
  notificationError: null,
}

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    fetchNotificationData: (state) => ({
      ...state,
      notificationIsLoading: !initialState.notificationIsLoading,
    }),
    setNotificationData: (state, action) => ({
      ...initialState,
      ...state,
      notificationData: action.payload || initialState.data,
      notificationIsLoading: initialState.notificationIsLoading,
    }),
    setNotificationLoading: (state, action) => ({
      ...initialState,
      ...state,
      notificationIsLoading: (typeof action.payload === 'undefined') ? initialState.notificationIsLoading : action.payload,
    }),
    setNotificationError: (state, action) => ({
      ...initialState,
      ...state,
      notificationError: action.payload || initialState.notificationError,
    }),
    resetNotification: (state) => ({
      ...initialState,
    }),
  },
})

// Action creators are generated for each case reducer function
export const {
  fetchNotificationData, setNotificationLoading, setNotificationData, resetNotification, setNotificationError,
} = notificationSlice.actions

export default notificationSlice.reducer
