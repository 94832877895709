
import coverimage from '../../../assets/images/profile/camera.png'
import "./ProfileEdit.scss";
import { useContext, useEffect, useState } from "react";
import AppContext from "../../../context/AppContext";
import { useDispatch } from "react-redux";
import { PathAction } from "../../../redux/reducers/globalPath";
import { useNavigate, useParams } from "react-router-dom";
import FavoriteEditForm from './FavoriteEditForm';
import { getStorageItem } from '../../../utils/sessionStorage';
import axios from "axios";


const FavoriteEdit = () => {


  const params = useParams();
  const [image, setImage] = useState(null);
  const navigate = useNavigate();
  const accessToken = getStorageItem("token");


  const appContext = useContext(AppContext);
  const { setShowAdd, setShowFooter } = appContext

  const path = window.location.pathname;
  const dispatch = useDispatch();


  dispatch(PathAction.handlePath({ payload: path }))

  const handlecoverPhoto = () => {

    // if (!params.id) return
    // const getUserInfo = {
    //   method: "GET",
    //   url: global.config.ROOTURL.prod + `/coverphoto/getById/${params.id}`,
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //     Authorization: "Bearer " + accessToken,
    //   },

    // };
    // axios(getUserInfo)
    //   .then((response) => {

    //     setImage(response.data.image);
    //   })
    //   .catch((error) => {
    //     console.log(error)
    //   });
  }


  useEffect(() => {
    handlecoverPhoto()
    setShowAdd(true)
    setShowFooter(true)
    return () => {
      setShowAdd(false)
      setShowFooter(false)
    }
  }, [])
  return (
    <div className="Profile_component">
      <div className="bannerHeight">
        {image && <div className="bg" style={{ backgroundImage: `url(${image})` }} />}
      </div>
      <img src={coverimage} onClick={() => navigate('/banner')} className="Cameraimage" />
      <FavoriteEditForm />
    </div>
  );
};

export default FavoriteEdit;