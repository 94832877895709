
import coverimage from '../../../assets/images/profile/camera.png'
import defaultcoverImage from '../../../assets/images/profile/seventh.png'
import "./ProfileEdit.scss";
import { useContext, useEffect, useState } from "react";
import AppContext from "../../../context/AppContext";
import { useDispatch } from "react-redux";
import { PathAction } from "../../../redux/reducers/globalPath";
import { useNavigate, useParams } from "react-router-dom";
import ProfileEditForm from './ProfileEditForm';
import { getStorageItem } from '../../../utils/sessionStorage';
import axios from "axios";


const ProfileEdit = () => {


  const params = useParams();
  const [image, setImage] = useState(null);
  const navigate = useNavigate();
  const accessToken = getStorageItem("token");
const coverid=getStorageItem('coverPhotoid');

  const appContext = useContext(AppContext);
  const { setShowAdd, setShowFooter } = appContext

  const path = window.location.pathname;
  const dispatch = useDispatch();


  dispatch(PathAction.handlePath({ payload: path }))

  const handlecoverPhoto = () => {
    if (!coverid){
      setImage(defaultcoverImage);
    }else{
    const getUserInfo = {
      method: "GET",
      url: global.config.ROOTURL.prod + `/coverphoto/getById/${coverid}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },

    };
    axios(getUserInfo)
      .then((response) => {

        setImage(response.data.image);
      })
      .catch((error) => {
        console.log(error)
      });
  }
  }


  useEffect(() => {
    handlecoverPhoto()
    setShowAdd(true)
    setShowFooter(true)
    return () => {
      setShowAdd(false)
      setShowFooter(false)
    }
  }, [])
  return (
    <div className="Profile_component">
      <div className="bannerHeight">
        {image && <div className="bg" style={{ backgroundImage: `url(${image})` }} />}
      </div>
      <img src={coverimage} onClick={() => navigate('/banner')} className="Cameraimage" />
      <ProfileEditForm />
    </div>
  );
};

export default ProfileEdit;