import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { getStorageItem } from "../../utils/sessionStorage";
import { Button, Grid, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Box } from "@mui/system";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import "./index.scss";
// React-Toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
const JobScroll = (props) => {
  const navigate = useNavigate();
  const accessToken = getStorageItem("token");
  const userId = getStorageItem("user_id");

  const [pageNumber, setPageNumber] = useState(1);
  const [totalPageNumber, setTotalPageNumber] = useState(0);

  const [jobs, setJobs] = useState([]);

  const getPreviousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const getNextPage = () => {
    if (pageNumber < totalPageNumber) {
      setPageNumber(pageNumber + 1);
    }
  };

  // const redirectUser = (_id) => {
  //   // Check if the user is already registered

  //   // Redirects User
  //   navigate(`/event-register/${_id}`);
  // };

  // Fetching length of data
  useEffect(() => {
    // Fetching all events
    if(!accessToken) return;
    const options = {
      method: "get",
      url: global.config.ROOTURL.prod + `/job/getAll`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };

    axios(options)
      .then((response) => {
        console.log(response.data.data);
        console.log("response.data.data.length",response.data.data.length)
        setTotalPageNumber(Math.ceil(response.data.data.length / 6));
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          toast.error("Some error occured");
        }
      });
  }, []);

  // Fetching Page Data
  useEffect(() => {
    if(!accessToken) return;
    const options = {
      method: "get",
      url: global.config.ROOTURL.prod + `/job/getAllWithPagination/${pageNumber}/6`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };

    axios(options)
      .then((response) => {
        console.log(response.data.data);
        setJobs([...response.data.data]);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          toast.error("Some error occured");
        }
      });
  }, [pageNumber]);
  return (
    <div>
      <Grid
        className="pagination"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          gap: "1rem",
        }}
      >
        <Button variant="contained" 
        // style={{backgroundColor:'#0c5ba0',color:'white'}} 
        onClick={getPreviousPage}>
          Prev
        </Button>
        <Typography variant="p">
          {pageNumber} of {totalPageNumber}
        </Typography>
        <Button variant="contained"
          // style={{backgroundColor:'#0c5ba0',color:'white'}} 
         onClick={getNextPage}>
          Next
        </Button>
      </Grid>
      <div className="card-wrapper">
       
             <Grid item xs={12} > 
          
             <Paper className="container">
       <Table>
         <TableHead>
           <TableRow>
             <TableCell style={{fontWeight:"bolder"}}>sNo.</TableCell>
            
             <TableCell style={{fontWeight:"bolder"}}>UserName</TableCell>
             <TableCell style={{fontWeight:"bolder"}}>companyName</TableCell>
             <TableCell style={{fontWeight:"bolder"}}>jobTitle</TableCell>
             <TableCell style={{fontWeight:"bolder"}}>userApplied</TableCell>
             {/* <TableCell style={{fontWeight:"bolder"}}>email</TableCell>
             <TableCell style={{fontWeight:"bolder"}}>contactNo</TableCell>
             <TableCell style={{fontWeight:"bolder"}}>resume</TableCell> */}
           </TableRow>
         </TableHead>
         <TableBody>
           {jobs.map((job,index)=> (
             <TableRow key={index}  onClick={() => {
                  navigate(`/admin/job/${job._id}`);
                }}>
               <TableCell component="th" scope="row">
                 {index+1}
               </TableCell>
               
               <TableCell>{job.userName}</TableCell>
               <TableCell>{job.companyName}</TableCell>
               <TableCell>{job.jobTitle}</TableCell>
               <TableCell>42</TableCell>
             
             </TableRow>
           ))}
         </TableBody>
       </Table>
     </Paper>
           </Grid>
      </div>
      <ToastContainer />
    </div>
  );
};

export default JobScroll;









// existing code that i changed in line number 118 



   // return (
          //   <Card className="card-main" key={job._id} onClick={() => {
          //     navigate(`/admin/job/${job._id}`);
          //   }}>
          //     <CardMedia
          //       component="img"
          //       height="140px"
          //       image={job.companyLogo}
          //       alt={job.jobTitle}
          //     />
          //     <CardContent className="content">
          //       <Typography gutterBottom variant="h5" component="div">
          //         {job.jobTitle}
          //       </Typography>
          //       <Typography variant="body2" color="text.secondary">
              
                
          //         {job.jobDescription.length>200? <div className="event-description"
          //           dangerouslySetInnerHTML={{
          //             __html: job.jobDescription.substring(0, 200) + "...",
          //           }}
          //         />:<div className="event-description"
          //         dangerouslySetInnerHTML={{
          //           __html: job.jobDescription,
          //         }}
          //       />}
          //       </Typography>
          //       <hr style={{ marginTop: "10px", marginBottom: "10x" }} />
          //       <Box
          //         sx={{
          //           display: "flex",
          //           flexDirection: "column",
          //           gap: "5px",
          //           marginTop: "10px",
          //         }}
          //       >
          //         <Box
          //           sx={{
          //             display: "flex",
          //             alignItems: "center",
          //             justifyContent: "space-between",
          //           }}
          //         >
          //           {/* <Box>
          //             <EventIcon />
          //           </Box>
          //           <Box>
          //             <p>
          //               {event.startDate} at {event.startTime}
          //             </p>
          //           </Box> */}
          //         </Box>
          //         <Box
          //           sx={{
          //             display: "flex",
          //             alignItems: "center",
          //             justifyContent: "space-between",
          //           }}
          //         >
          //         </Box>
          //         {/* <span>{event.endTime}</span> */}
          //         <Box
          //           sx={{
          //             display: "flex",
          //             alignItems: "center",
          //             justifyContent: "space-between",
          //           }}
          //         >
          //           <LocationOnIcon />
          //           <p>{job.jobLocation}</p>
          //         </Box>
          //       </Box>
          //     </CardContent>
          //   </Card>
          // );