// FantassyModal.js

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import FantassyGif from "../../assets/images/fantasy.gif";
import useMediaQuery from "@mui/material/useMediaQuery";
import PlayerCard from "./PlayerCard";


export default function FantassyModal({ open, onClose}) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [buttonClick, setButtonClick] = useState(false);
  const navigate = useNavigate();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "90%" : 400,
    height: "433px",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "12px",
  };
  
  const gifStyle = {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    alignItem: "center",
    margin: "auto",
  };
  const handleGotItClick = () => {
    onClose();
    setButtonClick(true);
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <img src={FantassyGif} alt="Fantassy Gif" style={gifStyle} />
        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ textAlign: "center" }}>
        Congratulations!
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2, textAlign: "center" }}>
         You have successfully purchased the player card!
        </Typography>
        <Button
          onClick={handleGotItClick}
          sx={{
            mt: 6,
            width: isMobile ? "100%" : "326px",
            backgroundColor: "#0C5BA0",
            color: "white",
            textAlign: "center",
            "&:hover": {
              backgroundColor: "#0C5BA0",
              color: "white",
            },
          }}
        >
          Got it
        </Button>
      </Box>
    </Modal>
    
  );
}
