import React, { useState, useEffect } from 'react';
import "./ProgressBar.scss";

const DailyProgressBar = ({ borderColor, backgroundColor, status,
  currentStage,
  totalStages

}) => {
  const [progressWidth, setProgressWidth] = useState(0);
 
  
  useEffect(() => {
    const currentProgress = (currentStage / totalStages) * 100;
    setProgressWidth(currentProgress); 
  }, [currentStage, totalStages]);


  return (
    <div className="progress-container">
      <div className="progress-bar" style={{ width: `${progressWidth}%`,  backgroundColor: "yellow", marginTop: "0px"  }}></div>
      <div className="progress-labels">
        {Array.from({ length: totalStages}, (_, index) => (
          <div key={index} className="progress-label-dailylogin" style={{ borderColor: "yellow" }}>
              <span className="tick" style={{color: "yellow"}} >&#10003;</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default  DailyProgressBar;
