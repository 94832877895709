
import * as React from "react";
import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
    useMediaQuery,
    Card,
    Box,
    Grid,
    Link,
    Container,
    main,
    Button,
    ThemeProvider,
    createTheme,
    Avatar,
} from "@mui/material";

import axios from "axios";
import { getStorageItem } from "../../utils/sessionStorage";
import config from "../../config";
import "./index.scss";
import SingleJob from "./SingleJob";


const baseURL = config.ROOTURL.prod;

const userId = getStorageItem("user_id");
const accessToken = getStorageItem("token");

const pagePerSize = config.pagePerSize;
const PostedJob = (props) => {
    const navigate = useNavigate();
    const mobileView = useMediaQuery("(max-width:959px)");
    const [post, setPost] = useState([]);
    const [error, setError] = useState(null);
   
     

        const fetchData = async () => {

            const postedJob = axios.get(`${baseURL}/job/getAll`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + accessToken,
                }
            });

            let pageNumber = 1;
            const savedJob = axios.get(`${baseURL}/job/getSavedjobsByUserId/${userId}/${pageNumber}/${20}`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + accessToken,
                }
            });
            const results = await Promise.all([postedJob, savedJob])
            // console.log(results);
            const postedJobData = results[0].data.data;
            const savedJobData = results[1].data.data;
            const savedJobDataJobIds = savedJobData.map(s => (s.jobId))
            const updatePostedJobData = postedJobData.map(d => {
                // console.log("hey i am active")
                if (savedJobDataJobIds.includes(d._id.toString())) {
                    d.isSaved = true;
                }
                return d;
            })
            // console.log(updatePostedJobData,"updatedfdafja;t")
           

            setPost(updatePostedJobData)

        }

        useEffect(() => {

            fetchData();
        }, []);
        if (error) return `Error: ${error.message}`;
        if (!post) return "No post!"
      
       
            

        return post.map((data, index) => {
            // {console.log("post poste",post)}
            return (
               <SingleJob data={data} key={index} />


            );
        })
    };
    export default PostedJob;