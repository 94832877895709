// import React from 'react'
import * as React from 'react';
import { styled } from '@mui/material/styles';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Grid, Typography, Card, CardContent } from '@material-ui/core';
import Button from "@mui/material/Button";
import { CSVLink, CSVDownload } from "react-csv";
import './Dashboard.scss';
import {
    getLoginCountApi, getUserCountApi, getPitchCountApi, getRedeemedRunApi, getPollCountApi,
    getAllRunsCreated, GetAllUsers, GetAllUsersDetail, getAllUserCount, getAllUsersRuns, getOnlineUsers, noOfUsersVisitingDeals, totalHitsForDealsToday,
    downloadDealVisiterDetails, downloadRunsGivenByUserDetails, thisMonthUserCreationCount, thisYearUserCreationCount, thisWeekUserCreationCount, todayUserCreationCount,
    totalRunsGivenByAllUsersToday, pitchCreationCount, totalRunsGivenToAllUsersToday, totalRunsGivenToAllUsersThisYear,
    totalRunsGivenToAllUsersThisMonth, totalUsersWhoCreatedPitchApi,
    totalRunsGivenToAllUsersThisWeek, redeemedRunsToday, redeemedRunsThisYear, redeemedRunsThisMonth, redeemedRunsThisWeek,
    pollCreationCountToday, pollCreationCountThisYear, pollCreationCountThisMonth, pollCreationCountThisWeek, dealVisitToday, dealVisitThisWeek, dealVisitThisMonth, dealVisitThisYear
} from './DashboardSevics';




const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const headers = [
    { label: "User Id", key: "userId" },
    { label: "User Name", key: "userName" },
    { label: "dealVisitCount", key: "dealVisitCount" },
    { label: "Mobile", key: "mobileNo" },
    { label: "Email", key: "email" },
];

const headers1 = [
    { label: "User Id", key: "userId" },
    { label: "User Name", key: "userName" },
    { label: "totalRunsGiven", key: "totalRunsGiven" },
    { label: "Mobile", key: "mobileNo" },
    { label: "Email", key: "email" },
];
export default function Dashboard() {
    const [Users, setUsers] = useState([]);
    const [TotalUsers, setTotalUsers] = useState(0);
    const [TodayUsers, setTodayUsers] = useState(0);
    const [ThisWeekUsers, setThisWeekUsers] = useState(0);
    const [ThisYearUsers, setThisYearUsers] = useState(0);
    const [ThisMonthUsers, setThisMonthUsers] = useState(0);
    const [userSinceIpl, setUserSinceIpl] = useState(0);
    const [TotalRun, setTotalRun] = useState(0);
    const [TodayRun, setTodayRun] = useState(0);
    const [ThisWeekRun, setThisWeekRun] = useState(0);
    const [ThisYearRun, setThisYearRun] = useState(0);
    const [ThisMonthRun, setThisMonthRun] = useState(0);
    //redeemed runs
    const [TotalRedeemedRuns, setTotalRedeemedRuns] = useState(0);
    const [TodayRedeemedRuns, setTodayRedeemedRuns] = useState("");
    const [ThisWeekRedeemedRuns, setThisWeekRedeemedRuns] = useState("");
    const [ThisYearRedeemedRuns, setThisYearRedeemedRuns] = useState("");
    const [ThisMonthRedeemedRuns, setThisMonthRedeemedRuns] = useState("");
    //poll created details
    const [TodayCreatedPolls, setTodayCreatedPolls] = useState(0);
    const [ThisWeekCreatedPolls, setThisWeekCreatedPolls] = useState(0);
    const [ThisYearCreatedPolls, setThisYearCreatedPolls] = useState(0);
    const [ThisMonthCreatedPolls, setThisMonthCreatedPolls] = useState(0);
    // deal visits
    const [TodayDealVisits, setTodayDealVisits] = useState(0);
    const [ThisWeekDealVisits, setThisWeekDealVisits] = useState(0);
    const [ThisYearDealVisits, setThisYearDealVisits] = useState(0);
    const [ThisMonthDealVisits, setThisMonthDealVisits] = useState(0);
    //
    const [Online, setOnline] = useState([]);
    const [Pitches, setPitches] = useState([]);
    const [Runs, Setruns] = useState([]);
    const [TotalRuns, SeTotaltruns] = useState('');
    const [dealsVisitCount, setDealsVisitCount] = useState(0);
    const [totalHits, setTotalHits] = useState(0);
    const [pitchCreation, setPitchCreation] = useState('');
    const [userCreation, setUserCreation] = useState('');
    const [runCreation, setRunCreation] = useState('');
    const [rows, setRows] = useState([]);
    const [rows1, setRows1] = useState([]);
    const [totalRunsGivenByUsers, setTotalRunsGivenByUsers] = useState(0);
    const [todayLoginCount, setTodayLoginCount] = useState(0);
    const [thisMonthLoginCount, setThisMonthLoginCount] = useState(0);
    const [thisYearLoginCount, setThisYearLoginCount] = useState(0);
    const [thisWeekLoginCount, setThisWeekLoginCount] = useState(0);
    const [loginCountSinceIpl, setLoginCountSinceIpl] = useState(0);
    const [usersWhoCreatedPitchToday, setusersWhoCreatedPitchToday] = useState(0);
    const [usersWhoCreatedPitchThisWeek, setusersWhoCreatedPitchThisWeek] = useState(0);
    const [usersWhoCreatedPitchThisMonth, setusersWhoCreatedPitchThisMonth] = useState(0);
    const [usersWhoCreatedPitchThisYear, setusersWhoCreatedPitchThisYear] = useState(0);
    const [usersWhoCreatedPitchSinceIpl, setusersWhoCreatedPitchSinceIpl] = useState(0);

    const [redeemedRunSinceIpl, setRedeemedRunSinceIpl] = useState("");
    const [pollCreatedSinceIpl, setPollCreatedSinceIpl] = useState(0);
    const [pitchSinceIpl, setPitchSinceIpl] = useState(0);
    //no of users who created deal
    const usersWhoCreatedPitchTodayCall = () => {
        const now = new Date();
        const twentyFourHoursAgo = new Date(now - 24 * 60 * 60 * 1000);

        // Format the start and end dates as required by your API
        const formattedStartDate = twentyFourHoursAgo.toISOString();
        const formattedEndDate = now.toISOString();
        totalUsersWhoCreatedPitchApi(formattedStartDate, formattedEndDate)
            .then((data) => {

                setusersWhoCreatedPitchToday(data.data)
            }).catch((error) => {
                console.log(error)
            })
    }
    const thisMonthUsersWhoCreatedPitchCall = () => {

        const now = new Date();
        const thirtyDaysAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);

        const formattedStartDate = thirtyDaysAgo.toISOString();
        const formattedEndDate = now.toISOString();
        totalUsersWhoCreatedPitchApi(formattedStartDate, formattedEndDate)
            .then((data) => {

                setusersWhoCreatedPitchThisMonth(data.data)
            }).catch((error) => {
                console.log(error)
            })
    }

    const thisYearUsersWhoCreatedPitchCall = () => {

        const now = new Date();
        const currentYear = now.getFullYear();
        const startOfYear = new Date(currentYear, 0, 1);
        const endOfYear = new Date(currentYear, 11, 31);

        const formattedStartDate = startOfYear.toISOString();
        const formattedEndDate = endOfYear.toISOString();
        totalUsersWhoCreatedPitchApi(formattedStartDate, formattedEndDate)
            .then((data) => {
                setusersWhoCreatedPitchThisYear(data.data)
            }).catch((error) => {
                console.log(error)
            })
    }

    const thisWeekUsersWhoCreatedPitchCall = () => {

        const now = new Date();
        const sevenDaysAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);

        const formattedStartDate = sevenDaysAgo.toISOString();
        const formattedEndDate = now.toISOString();
        totalUsersWhoCreatedPitchApi(formattedStartDate, formattedEndDate)
            .then((data) => {

                setusersWhoCreatedPitchThisWeek(data.data)
            }).catch((error) => {
                console.log(error)
            })
    }
    const totalUsersWhoCreatedPitchSinceIplCall = () => {
        const now = new Date();
        const startDate = new Date(2023, 2, 31); // Months are zero-based, so March is represented as 2
        const formattedStartDate = startDate.toISOString();
        const formattedEndDate = now.toISOString();
        totalUsersWhoCreatedPitchApi(formattedStartDate, formattedEndDate)
            .then((data) => {

                setusersWhoCreatedPitchSinceIpl(data.data)
            }).catch((error) => {
                console.log(error)
            })

    }
    //login
    const todayLoginCountCall = () => {
        const now = new Date();
        const twentyFourHoursAgo = new Date(now - 24 * 60 * 60 * 1000);

        // Format the start and end dates as required by your API
        const formattedStartDate = twentyFourHoursAgo.toISOString();
        const formattedEndDate = now.toISOString();
        getLoginCountApi(formattedStartDate, formattedEndDate)
            .then((data) => {

                setTodayLoginCount(data.data)
            }).catch((error) => {
                console.log(error)
            })
    }
    const thisMonthLoginCountCall = () => {

        const now = new Date();
        const thirtyDaysAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);

        const formattedStartDate = thirtyDaysAgo.toISOString();
        const formattedEndDate = now.toISOString();
        getLoginCountApi(formattedStartDate, formattedEndDate)
            .then((data) => {

                setThisMonthLoginCount(data.data)
            }).catch((error) => {
                console.log(error)
            })
    }

    const thisYearLoginCountCall = () => {

        const now = new Date();
        const currentYear = now.getFullYear();
        const startOfYear = new Date(currentYear, 0, 1);
        const endOfYear = new Date(currentYear, 11, 31);

        const formattedStartDate = startOfYear.toISOString();
        const formattedEndDate = endOfYear.toISOString();
        getLoginCountApi(formattedStartDate, formattedEndDate)
            .then((data) => {
                setThisYearLoginCount(data.data)
            }).catch((error) => {
                console.log(error)
            })
    }

    const thisWeekLoginCountCall = () => {

        const now = new Date();
        const sevenDaysAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);

        const formattedStartDate = sevenDaysAgo.toISOString();
        const formattedEndDate = now.toISOString();
        getLoginCountApi(formattedStartDate, formattedEndDate)
            .then((data) => {

                setThisWeekLoginCount(data.data)
            }).catch((error) => {
                console.log(error)
            })
    }
    const loginCountSinceIplCall = () => {
        const now = new Date();
        const startDate = new Date(2023, 2, 31); // Months are zero-based, so March is represented as 2
        const formattedStartDate = startDate.toISOString();
        const formattedEndDate = now.toISOString();
        getLoginCountApi(formattedStartDate, formattedEndDate)
            .then((data) => {

                setLoginCountSinceIpl(data.data)
            }).catch((error) => {
                console.log(error)
            })

    }
    const userCountSinceIplCall = () => {
        const now = new Date();
        const startDate = new Date(2023, 2, 31); // Months are zero-based, so March is represented as 2
        const formattedStartDate = startDate.toISOString();
        const formattedEndDate = now.toISOString();
        getUserCountApi(formattedStartDate, formattedEndDate)
            .then((data) => {

                setUserSinceIpl(data.data)
            }).catch((error) => {
                console.log(error)
            })

    }
    const pitchSinceIplCall = () => {
        const now = new Date();
        const startDate = new Date(2023, 2, 31); // Months are zero-based, so March is represented as 2
        const formattedStartDate = startDate.toISOString();
        const formattedEndDate = now.toISOString();
        getPitchCountApi(formattedStartDate, formattedEndDate)
            .then((data) => {

                setPitchSinceIpl(data.data)
            }).catch((error) => {
                console.log(error)
            })

    }
    const pollSinceIplCall = () => {
        const now = new Date();
        const startDate = new Date(2023, 2, 31); // Months are zero-based, so March is represented as 2
        const formattedStartDate = startDate.toISOString();
        const formattedEndDate = now.toISOString();
        getPollCountApi(formattedStartDate, formattedEndDate)
            .then((data) => {

                setPollCreatedSinceIpl(data.data)
            }).catch((error) => {
                console.log(error)
            })

    }
    const redeemedRunSinceIplCall = () => {
        const now = new Date();
        const startDate = new Date(2023, 2, 31); // Months are zero-based, so March is represented as 2
        const formattedStartDate = startDate.toISOString();
        const formattedEndDate = now.toISOString();
        getRedeemedRunApi(formattedStartDate, formattedEndDate)
            .then((data) => {

                setRedeemedRunSinceIpl(data.data)
            }).catch((error) => {
                console.log(error)
            })

    }
    useEffect(() => {
        usersWhoCreatedPitchTodayCall()
        thisMonthUsersWhoCreatedPitchCall()
        thisYearUsersWhoCreatedPitchCall()
        totalUsersWhoCreatedPitchSinceIplCall()
        thisWeekUsersWhoCreatedPitchCall()
        pollSinceIplCall()
        redeemedRunSinceIplCall()
        userCountSinceIplCall();
        todayLoginCountCall();

        thisYearLoginCountCall();

        thisMonthLoginCountCall();

        thisWeekLoginCountCall();

        loginCountSinceIplCall();
        pitchSinceIplCall()
        GetAllUsersDetail()
            .then((data) => {

                setUsers(data.data)
            }).catch((error) => {
                console.log(error)
            })

        getOnlineUsers()
            .then((data) => {
                console.log(data)
                setOnline(data.data)
            }).catch((error) => {
                console.log(error)
            })

        pitchCreationCount().then((data) => {
            console.log("pitchCreationCount", data.data)
            setPitchCreation(data.data)
        }).catch((error) => {
            console.log(error)
        })
        thisYearUserCreationCount().then((data) => {
            console.log("thisYearUserCreationCount", data.data)
            setThisYearUsers(data.data)
        }).catch((error) => {
            console.log(error)
        })
        thisWeekUserCreationCount().then((data) => {
            console.log("thisWeekUserCreationCount", data.data)
            setThisWeekUsers(data.data)
        }).catch((error) => {
            console.log(error)
        })
        thisMonthUserCreationCount().then((data) => {
            console.log("thisMonthUserCreationCount", data.data)
            setThisMonthUsers(data.data)
        }).catch((error) => {
            console.log(error)
        })
        todayUserCreationCount().then((data) => {
            console.log("todayUserCreationCount", data.data)
            setTodayUsers(data.data)
        }).catch((error) => {
            console.log(error)
        })
        getAllUserCount().then((data) => {
            console.log("totalRunDetails", data.data)
            setTotalUsers(data.data)
        }).catch((error) => {
            console.log(error)
        })
        totalRunsGivenToAllUsersToday().then((data) => {
            console.log("totalRunsGivenToAllUsersToday", data.data)
            setTodayRun(data.data)
        }).catch((error) => {
            console.log(error)
        })
        totalRunsGivenToAllUsersThisYear().then((data) => {
            console.log("totalRunsGivenToAllUsersThisYear", data.data)
            setThisYearRun(data.data)
        }).catch((error) => {
            console.log(error)
        })
        totalRunsGivenToAllUsersThisMonth().then((data) => {
            console.log("totalRunsGivenToAllUsersThisMonth", data.data)
            setThisMonthRun(data.data)
        }).catch((error) => {
            console.log(error)
        })
        totalRunsGivenToAllUsersThisWeek().then((data) => {
            console.log("totalRunsGivenToAllUsersThisWeek", data.data)
            setThisWeekRun(data.data)
        }).catch((error) => {
            console.log(error)
        })
        redeemedRunsToday().then((data) => {
            console.log("redeemedRunsToday", data.data)
            setTodayRedeemedRuns(data.data)
        }).catch((error) => {
            console.log(error)
        })
        redeemedRunsThisYear().then((data) => {
            console.log("redeemedRunsThisYear", data.data)
            setThisYearRedeemedRuns(data.data)
        }).catch((error) => {
            console.log(error)
        })
        redeemedRunsThisMonth().then((data) => {
            console.log("redeemedRunsThisMonth", data.data)
            setThisMonthRedeemedRuns(data.data)
        }).catch((error) => {
            console.log(error)
        })
        redeemedRunsThisWeek().then((data) => {
            console.log("redeemedRunsThisWeek", data.data)
            setThisWeekRedeemedRuns(data.data)
        }).catch((error) => {
            console.log(error)
        })
        pollCreationCountToday().then((data) => {
            console.log("pollCreationCountToday", data.data)
            setTodayCreatedPolls(data.data)
        }).catch((error) => {
            console.log(error)
        })
        pollCreationCountThisWeek().then((data) => {
            console.log("pollCreationCountThisWeek", data.data)
            setThisWeekCreatedPolls(data.data)
        }).catch((error) => {
            console.log(error)
        })
        pollCreationCountThisMonth().then((data) => {
            console.log("pollCreationCountThisMonth", data.data)
            setThisMonthCreatedPolls(data.data)
        }).catch((error) => {
            console.log(error)
        })
        pollCreationCountThisYear().then((data) => {
            console.log("pollCreationCountThisYear", data.data)
            setThisYearCreatedPolls(data.data)
        }).catch((error) => {
            console.log(error)
        })
        dealVisitToday().then((data) => {
            console.log("dealVisitToday", data.data)
            setTodayDealVisits(data.data)
        }).catch((error) => {
            console.log(error)
        })
        dealVisitThisWeek().then((data) => {
            console.log("dealVisitThisWeek", data.data)
            setThisWeekDealVisits(data.data)
        }).catch((error) => {
            console.log(error)
        })
        dealVisitThisMonth().then((data) => {
            console.log("dealVisitThisMonth", data.data)
            setThisMonthDealVisits(data.data)
        }).catch((error) => {
            console.log(error)
        })
        dealVisitThisYear().then((data) => {
            console.log("dealVisitThisYear", data.data)
            setThisYearDealVisits(data.data)
        }).catch((error) => {
            console.log(error)
        })
    }, [])

    const Card = styled(Box)(({ theme }) => ({
        padding: theme.spacing(3),
        borderRadius: theme.shape.borderRadius,
        display: 'flex',
        flexDirection: 'column',
        //alignItems: 'center',
        //justifyContent: 'center',
        color: 'black',
        //backgroundColor: theme.palette.primary.main,
        background: 'white',
        boxShadow: theme.shadows[3],
        marginBottom:'6px',
        minWidth: 200,
        minHeight: 120,
        '&:nth-of-type(2)': {
            backgroundColor: theme.palette.secondary.main,
            background: 'linear-gradient(135deg, #7b4397 0%, #dc2430 100%)',
        },
        '&:nth-of-type(3)': {
            backgroundColor: theme.palette.info.main,
            background: 'linear-gradient(135deg, #43cea2 0%, #185a9d 100%)',
        },
        '&:nth-of-type(4)': {
            backgroundColor: theme.palette.warning.main,
            background: 'linear-gradient(135deg, #f7971e 0%, #ffd200 100%)',
        },
    }));

    const data = [
        { title: 'Total Revenue', value: '$75,500', change: '+10%', icon: '💰' },
        { title: 'Total Users', value: '76,272', change: '+15%', icon: '👤' },
        { title: 'Redeemed runs & deals', value: '2,24,500', change: '-25%', icon: '🏃' },
        { title: 'Total Product (Marketplace)', value: '247', change: '0%', icon: '🛍️' },
    ];

    const StyledCard = styled(Card)(({ theme }) => ({
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[3],
        borderRadius: theme.shape.borderRadius,
      }));

    return (
        <Box sx={{ flexGrow: 1 }}>
            <div>
                <div><h1 style={{ color: 'blue' }}>Welcome Admin</h1></div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                    <p>Users and activity metrics</p>
                    <p><input type='date' />Select Dates</p>
                </div>
            </div>
            <Grid container spacing={2}>
                {data.map((item, index) => (
                    <Grid item xs={12} sm={6} md={3} key={index}>
                        <Card>
                            <Typography variant="h5" component="div">
                                {item.icon}
                            </Typography>
                            <Typography variant="p" component="div">
                                {item.title}
                            </Typography>
                            <Typography variant="h6" component="div">
                                {item.value}
                            </Typography>
                            <Typography variant="body2" component="div">
                                {item.change}
                            </Typography>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <Box>
                <Grid container spacing={2}>

                    {/* First Column */}
                    <Grid item xs={12} md={8}>
                        <Grid container spacing={2}>

                            {/* First Row - User Creation */}
                            <Grid item xs={12}>
                                <StyledCard >
                                    <CardContent>
                                        <Typography variant="h6">User Creation</Typography>
                                        {/* Insert User Creation Chart */}
                                    </CardContent>
                                </StyledCard>
                            </Grid>

                            {/* Second Row - Runs & Deals */}
                            <Grid item xs={12}>
                                <StyledCard>
                                    <CardContent>
                                        <Typography variant="h6">Runs & Deals</Typography>
                                        {/* Insert Runs & Deals Chart */}
                                    </CardContent>
                                </StyledCard>
                            </Grid>

                            {/* Third Row - Top Product and Top Category */}
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <StyledCard>
                                            <CardContent>
                                                <Typography variant="h6">Top Product</Typography>
                                                {/* Insert Top Product List */}
                                            </CardContent>
                                        </StyledCard>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <StyledCard>
                                            <CardContent>
                                                <Typography variant="h6">Top Category</Typography>
                                                {/* Insert Top Category List */}
                                            </CardContent>
                                        </StyledCard>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>

                    {/* Second Column */}
                    <Grid item xs={12} md={4}>
                        <Grid container spacing={2}>

                            {/* First Row - Users Creating Pitches */}
                            <Grid item xs={12}>
                                <StyledCard>
                                    <CardContent>
                                        <Typography variant="h6">Users Creating Pitches</Typography>
                                        {/* Insert Donut Chart */}
                                    </CardContent>
                                </StyledCard>
                            </Grid>

                            {/* Second Row - User Growth */}
                            <Grid item xs={12}>
                                <StyledCard>
                                    <CardContent>
                                        <Typography variant="h6">User Growth</Typography>
                                        {/* Insert User Growth Chart */}
                                    </CardContent>
                                </StyledCard>
                            </Grid>

                        </Grid>
                    </Grid>

                </Grid>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Item>
                        <p className='padding_m'>Total Created Users <br /> {TotalUsers.totalCount} </p>
                        <p className='padding_m'>Weekly Created Users<br /> {ThisWeekUsers}  </p>

                        <p className='padding_m'> Users Created Today<br /> {TodayUsers}  </p>
                        <p className='padding_m'>Monthly Created Users<br /> {ThisMonthUsers}  </p>
                        <p className='padding_m'>Yearly Created Users<br /> {ThisYearUsers}  </p>
                        <p className='padding_m'>Users Created since Ipl<br /> {userSinceIpl}  </p>
                    </Item>
                </Grid>
                {/* <Grid item xs={6}> 
                    <Item>
                        <p className='padding_m'>Today Created Runs<br /> {TodayRun} </p>
                        <p className='padding_m'>Weekly Created Runs<br /> {ThisWeekRun} </p>
                        <p className='padding_m'>Monthly Created Runs<br /> {ThisMonthRun} </p>
                        <p className='padding_m'>Yearly Created Runs<br /> {ThisYearRun} </p></Item>
                </Grid>  */}
                <Grid item xs={6}>
                    <Item>

                        <p className='padding_m'>Today Created pitches<br /> {pitchCreation.today} </p>
                        <p className='padding_m'>Weekly Created pitches<br /> {pitchCreation.thisWeek} </p>
                        <p className='padding_m'>Monthly Created pitches<br /> {pitchCreation.thisMonth} </p>
                        <p className='padding_m'>This Year Created pitches<br /> {pitchCreation.thisYear} </p>
                        <p className='padding_m'>pitches Created since ipl<br /> {pitchSinceIpl} </p>
                    </Item>
                </Grid>
                {/* //redeem Runs */}
                <Grid item xs={6}>
                    <Item>
                        {/* <p className='padding_m'>Total Redeemed Runs<br /> {TotalRedeemedRuns} </p> */}
                        <p className='padding_m'>Today Redeemed Runs && no. of deals<br /> {TodayRedeemedRuns.redeemedRuns} &&{TodayRedeemedRuns.noOfDeals} </p>
                        <p className='padding_m'>Weekly Redeemed Runs&& no. of deals<br /> {ThisWeekRedeemedRuns.redeemedRuns}&&{ThisWeekRedeemedRuns.noOfDeals}</p>
                        <p className='padding_m'>MonthlyRedeemed Runs&& no. of deals<br /> {ThisMonthRedeemedRuns.redeemedRuns}&&{ThisMonthRedeemedRuns.noOfDeals} </p>
                        <p className='padding_m'>This Year Redeemed Runs&& no. of deals<br /> {ThisYearRedeemedRuns.redeemedRuns}&&{ThisYearRedeemedRuns.noOfDeals} </p>
                        <p className='padding_m'> Redeemed Runs since IPL&& no. of deals<br />{redeemedRunSinceIpl.redeemedRuns} &&
                            {redeemedRunSinceIpl.noOfDeals} </p>
                    </Item>
                </Grid>
                <Grid item xs={6}>
                    <Item>
                        {/* <p className='padding_m'>Total Redeemed Runs<br /> {TotalRedeemedRuns} </p> */}
                        <p className='padding_m'>Today Created Polls<br /> {TodayCreatedPolls} </p>
                        <p className='padding_m'>Weekly  Created Polls<br /> {ThisWeekCreatedPolls}</p>
                        <p className='padding_m'>Monthly  Created Polls<br /> {ThisMonthCreatedPolls} </p>
                        <p className='padding_m'>This Year Created Polls<br /> {ThisYearCreatedPolls} </p>
                        <p className='padding_m'> Polls created sonce IPL<br /> {pollCreatedSinceIpl} </p>
                    </Item>
                </Grid>
                <Grid item xs={6}>
                    <Item>
                        <p className='padding_m'>Total number of users who have created pitch <br /> </p>
                        <p className='padding_m'> today<br /> {usersWhoCreatedPitchToday} </p>
                        <p className='padding_m'>this week<br /> {usersWhoCreatedPitchThisWeek} </p>
                        <p className='padding_m'>this month<br /> {usersWhoCreatedPitchThisMonth} </p>
                        <p className='padding_m'>this year<br /> {usersWhoCreatedPitchThisYear} </p>
                        <p className='padding_m'>since ipl<br /> {usersWhoCreatedPitchSinceIpl} </p>
                    </Item>
                </Grid>

                <Grid item xs={6}>
                    <Item>

                        <p className='padding_m'>No of Users<br /> </p>
                        <p className='padding_m'>Today's Login Count<br /> {todayLoginCount} </p>
                        <p className='padding_m'>This Week's Login Count<br /> {thisWeekLoginCount} </p>
                        <p className='padding_m'>This Month's Login Count<br /> {thisMonthLoginCount} </p>
                        <p className='padding_m'>This Year's Login Count<br /> {thisYearLoginCount} </p>
                        <p className='padding_m'> Login Count since Ipl<br /> {loginCountSinceIpl} </p>
                    </Item>
                </Grid>
                {/* <Grid item xs={6}>
                    <Item>
                        <p className='padding_m'>Today online<br /> {Online[0]?.today} </p>
                        <p className='padding_m'>weekly online<br /> {Online[1]?.week} </p>
                        <p className='padding_m'>Monthly online<br /> {Online[2]?.month} </p>
                    </Item>
                </Grid>
                

                <Grid item xs={6}>
                    <Item>

                        <p className='padding_m'>Today's Total Deal Page Visits<br /> {TodayDealVisits} </p>
                        <p className='padding_m'>This Week's Total Deal Page Visits<br /> {ThisWeekDealVisits} </p>
                        <p className='padding_m'>This Month's Total Deal Page Visits<br /> {ThisMonthDealVisits} </p>
                        <p className='padding_m'>This Year's Total Deal Page Visits<br /> {ThisYearDealVisits} </p>
                        </Item>
                </Grid> */}
                {/* <Grid item xs={6}>
                    <Item>

                        <p className='padding_m'>Total Runs given by users Today<br /> {totalRunsGivenByUsers} </p>
                        <p className='padding_m'>List of Users With Total Runs Given By Them<br />  <CSVLink
                            style={{ textDecoration: "none" }}
                            data={rows1}
                            headers={headers1}
                            filename={`totalRunsGivenByUser.csv`}
                        >
                            <Button marginTop={2} variant="contained">
                                Download
                            </Button>
                        </CSVLink>  </p></Item>
                </Grid> */}
            </Grid>
        </Box>
    )
}
