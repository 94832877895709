import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Button, Typography, Box } from '@mui/material';
import Lottie from 'react-lottie';
import animationData from '../../assets/images/Events/success.json';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from "axios";

const TeamDetail = ({ open, onClose }) => {
    const userId = localStorage.getItem('user_id');
    const [teamName, setTeamName] = useState('');
    const [teamCaptain, setTeamCaptain] = useState('');
    const [rollNo, setRollNo] = useState('');
    const [successOpen, setSuccessOpen] = useState(false);
    const navigate = useNavigate();
    const userName = localStorage.getItem('full_name')


    const handleCreate = async () => {
        if(!teamName || !teamCaptain || !rollNo){
            toast.error('Field Required')
            return;
        }
        const teamData = {
            teamName: teamName,
            userId: userId,
            teamCaptain: userName,
            rollNo: rollNo,
            playerName: teamCaptain
        }

        var createTeam = {
            method: "POST",
            url: global.config.ROOTURL.prod + "/events/team",
            data: teamData,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            json: true,
        };

        await axios(createTeam)
            .then((response) => {
                const status = response.data
                if (status === "TeamName Exists") {
                    toast.error(status);
                } else if (status === "Player with Roll No Already a part of team") {
                    toast.error("Roll No Exist");
                } else {
                    setSuccessOpen(true);
                    setTimeout(() => {
                        navigate('/AllTeams');
                    }, 3000);
                }
            })
            .catch((error) => {
                console.log(error)
            });
    };

    const handleSuccessClose = () => {
        setSuccessOpen(false);
        onClose();
    };

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    }

    return (
        <Dialog open={open} onClose={onClose} sx={{ borderRadius: '15px' }} >
            <DialogTitle sx={{ fontSize: '22px', fontWeight: '600' }}>Create New Team</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ paddingBottom: '20px' }}>
                    Please fill in the details to create your team.
                </DialogContentText>

                <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>Team Name *</Typography>
                <TextField
                    autoFocus
                    margin="dense"
                    id="teamName"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={teamName}
                    onChange={(e) => setTeamName(e.target.value)}
                    sx={{ paddingBottom: '10px' }}
                    required
                />

                <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>Your Name *</Typography>
                <TextField
                    margin="dense"
                    id="teamCaptain"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={teamCaptain}
                    onChange={(e) => setTeamCaptain(e.target.value)}
                    sx={{ paddingBottom: '10px' }}
                    required
                />

                <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>Enter your Roll no *</Typography>
                <TextField
                    margin="dense"
                    id="rollNo"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={rollNo}
                    onChange={(e) => setRollNo(e.target.value)}
                    sx={{ paddingBottom: '10px' }}
                    required
                />
            </DialogContent>

            <DialogActions>
                <Button variant="contained" color="success" onClick={onClose}>Cancel</Button>
                <Button variant="contained" onClick={handleCreate}>Create</Button>
            </DialogActions>

            <Dialog open={successOpen} onClose={handleSuccessClose} sx={{ textAlign: 'center' }}>
                <Box>
                    <Lottie
                        options={defaultOptions}
                        height={200}
                        width={200}
                    />
                </Box>

                <DialogTitle sx={{ color: '#0C5BA0', fontSize: '22px' }}>You're All Set to Play</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ color: '#62666A', fontSize: '16px' }}>
                        Your team is ready to hit the pitch
                    </DialogContentText>

                    <DialogContentText sx={{ color: '#2C9207', fontSize: '20px', fontStyle: 'italic' }}>
                        Best Of Luck! Enjoy the game, play fair and have fun!
                    </DialogContentText>
                </DialogContent>

                {/* <DialogActions>
                    <Button variant="contained" color="primary" onClick={handleClick}>View Team</Button>
                </DialogActions> */}

            </Dialog>

        </Dialog >
    );
};

export default TeamDetail;
