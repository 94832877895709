import * as React from "react";
import axios from "axios";
import Modal from "@mui/material/Modal";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect, useState, useRef } from "react";
import {
  IconButton, Typography, InputLabel, Select, FormControl, Box, Button, Grid, TextField
} from "@mui/material";
import moment from "moment/moment";
import CloseIcon from "@mui/icons-material/Close";
import { ToastContainer, toast } from "react-toastify";
import { getStorageItem } from '../../../utils/sessionStorage'
const styleDesktop = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
};

const styleMobile = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
};

export default function ActivateDealsModal(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const [style, setStyle] = useState();
  const [active, setActive] = useState(false);
  const { open, handleClose, setPageNum, dealId } = props;
  const accessToken = getStorageItem("token");
  const startTimeRef = useRef();
  const endTimeRef = useRef();
  const startDateRef = useRef();
  const endDateRef = useRef();
  const handleSubmit = (event) => {
    const startDate = startDateRef?.current?.value;
    const endDate = endDateRef?.current?.value;
    const startTime = startTimeRef?.current?.value;
    const endTime = endTimeRef?.current?.value;
    let data = {
      active, dealId,
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"), startTime, endTime
    };
    if (dealId === "" ||
      startDate === "" ||
      endDate === "" || startTime === "" || endTime === ""
    ) {
      toast.error("Error: Data incomplete");
      return;
    }
    var saveEventOptions = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/deal/updateDeal",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      data: data,
    };
    axios(saveEventOptions)
      .then((response) => {
        setPageNum(1);
        handleClose();
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  useEffect(() => {
    if (!matches) {
      setStyle(styleMobile);
    } else {
      setStyle(styleDesktop);
    }
  }, [matches]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

          <IconButton
            aria-label="close"
            onClick={handleClose}
            style={{
              position: "absolute",
              right: "0%",
              top: "0%",
              color: "black !important",
              backgroundColor: "white !important",
              border: "none !important",
            }}
          >
            <CloseIcon />
          </IconButton>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="active">Active:</InputLabel>
                <Select
                  native
                  id="active"
                  name="active"
                  value={active}
                  onChange={(e) => setActive(e.target.value === 'true')}
                  label="Active"
                >
                  <option value={true}>True</option>
                  <option value={false}>False</option>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>startDate and startTime</Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                type="date"
                id="startDate"
                name="startDate"
                inputRef={startDateRef}
                // label="Start Date"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                type="time"
                id="startTime"
                name="startTime"
                inputRef={startTimeRef}
                // label="Start Time"
                variant="outlined"
                required
              />
            </Grid>

            <Grid item xs={12}>endDate and endTime</Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                type="date"
                id="endDate"
                name="endDate"
                inputRef={endDateRef}
                //   label="End Date"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                type="time"
                id="endTime"
                name="endTime"
                inputRef={endTimeRef}
                //   label="End Time"
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12}>
              <Button variant="contained" fullWidth onClick={handleSubmit}>
                Submit
              </Button>
            </Grid>
          </Grid>

        </Box>
      </Modal>
    </div>
  );
}
