import React from "react";
import { Button } from '@mui/material';
import { Grid, Typography, Box} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SignUp from '../../signin-signup/signup/signup.component';
import circle5 from "./circle5.png";
import "./index.scss";



const SecondBlock=()=>{
  const navigate = useNavigate();
  
    return(
       
        <Box className="secondblock">
         <div className="circle_first">
          
        </div>
        {/* <img className ="bgrimages" src={circle5} alt="images" /> */}
        <Grid container spacing={2} sx={{ flexDirection: { xs: 'column-reverse', md: 'row' },justifyContent:'space-between' }}>
      
        {/* <Typography variant="h3">Want to be a Cricket Influencer?</Typography>
        <Typography>
        <p>Join Champhunt today and become an influencer<br />
        from Day 1. Create posts, Score Runs and redeem the <br />
        runs to win exciting deals and rewards without<br />
        waiting for getting thousands of followers.</p>
       </Typography>
        </Grid>
       <Grid item xs={12} >
        <Button  variant="contained">SIGN UP NOW</Button>*/}
        <Grid item xs={12} sm={12} md={6}>
                          <div className="first_circle">
                         
                          </div>
                         
                        <Typography variant='body2' className='seconSection_text' sx={{ fontSize: { xs: "30px", md: "50px" } }}>
                        <p> Want to be a Cricket Influencer?</p> 
                        </Typography>
                        <p className='secondPage_para'>
                        Join Champhunt today and become an influencer<br />
                        from Day 1. Create posts, Score Runs and redeem the <br />
                        runs to win exciting deals and rewards without<br />
                         waiting for getting thousands of followers.
                        </p>
                        <button variant="contained" className='secondSignup-buttn' onClick={() => navigate('/signup')}>SIGN UP NOW</button>

                    </Grid>
                      
                    <Grid item xs={12} sm={12} md={4} id="start-now">
                   
                    <div className="small_circle">
                    </div>
                   
                  
                    
                    <div className="shadow">
                        <Box className="firstSection_Signup" sx={{ width: { xs: "100%", md: "400px" }, maxWidth: '400px' }}>
                            <SignUp custClassName="landingPageSignUp"/>
                        </Box>
                        </div> 
                      

                    </Grid>
                  
      </Grid> 
   
    </Box>
      
    
 );

    
}
export default SecondBlock;


