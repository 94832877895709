import axios from "axios";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import "./index.css";
import config from "../../config";
import { getStorageItem } from "../../utils/sessionStorage";
import { ToastContainer, toast } from "react-toastify";
const baseURL = config.ROOTURL.prod;

// const data = [
//   {
//     id1: 123,
//     reffering_name: "Shubham Kakkar",
//     id2: 246,
//     reffered_name: "John Doe",
//     date: "16-01-2023",
//   },
//   {
//     id1: 124,
//     reffering_name: "Emily Smith",
//     id2: 247,
//     reffered_name: "Jessica Brown",
//     date: "17-01-2023",
//   },
//   {
//     id1: 125,
//     reffering_name: "Michael Johnson",
//     id2: 248,
//     reffered_name: "Amanda Davis",
//     date: "18-01-2023",
//   },
//   {
//     id1: 126,
//     reffering_name: "Matthew Rodriguez",
//     id2: 249,
//     reffered_name: "Ashley Miller",
//     date: "19-01-2023",
//   },
//   {
//     id1: 127,
//     reffering_name: "Jacob Garcia",
//     id2: 250,
//     reffered_name: "Samantha Moore",
//     date: "20-01-2023",
//   },
//   {
//     id1: 128,
//     reffering_name: "Isabella Martinez",
//     id2: 251,
//     reffered_name: "Taylor Thompson",
//     date: "21-01-2023",
//   },
//   {
//     id1: 129,
//     reffering_name: "Ethan Hernandez",
//     id2: 252,
//     reffered_name: "Emily White",
//     date: "22-01-2023",
//   },
//   {
//     id1: 130,
//     reffering_name: "Madison Gonzalez",
//     id2: 253,
//     reffered_name: "Madison Lopes",
//     date: "23-01-2023",
//   },
//   {
//     id1: 131,
//     reffering_name: "Aiden Perez",
//     id2: 254,
//     reffered_name: "Abigail Green",
//     date: "24-01-2023",
//   },
//   {
//     id1: 132,
//     reffering_name: "Jackson Turner",
//     id2: 255,
//     reffered_name: "Gabriel Martin",
//     date: "25-01-2023",
//   },
//   {
//     id1: 133,
//     reffering_name: "Aiden Lewis",
//     id2: 256,
//     reffered_name: "Aria Carter",
//     date: "26-01-2023",
//   },
// ];

// data provides access to your row data
const ExpandedComponent = ({ data }) => {
  return <pre>{JSON.stringify(data, null, 2)}</pre>;
};

const Index = () => {
  const [data, setData] = useState([]);
  const accessToken = getStorageItem("token");
  const userId = getStorageItem("user_id");
  const columns = [
    {
      name: "User Id",
      selector: (row) => row.userId,
    },
    {
      name: "User First Name",
      selector: (row) => row.userFirstName,
      sortable: true,
    },
    {
      name: "User Last Name",
      selector: (row) => row.userLastName,
      sortable: true,
    },
    {
      name: "User Email",
      selector: (row) => row.userEmail,
      sortable: true,
    },
    {
      name: "User Profile Photo",
      selector: (row) => row.userProfilePhoto,
    },
    {
      name: "Referral User Id",
      selector: (row) => row.referralUserId,
    },
    {
      name: "Refferal User First Name",
      selector: (row) => row.referralUserFirstName,
      sortable: true,
    },
    {
      name: "Refferal User Last Name",
      selector: (row) => row.referralUserLastName,
      sortable: true,
    },
    {
      name: "Refferal User Email",
      selector: (row) => row.referralUserEmail,
      sortable: true,
    },
    {
      name: "Refferal User Profile Photo",
      selector: (row) => row.referralUserProfilePhoto,
    },
    {
      name: "Created Date",
      selector: (row) => row.referralUserCreatedDate,
      sortable: true,
    },
    {
      name: "Share Mode",
      selector: (row) => row.shareMode,
    },
  ];
  useEffect(() => {
    axios
      .get(`${baseURL}/invite/getAll`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((response) => {
        const users = response.data.filter((item) => item.referralUserId);
        setData(users);
      })
      .catch((error) => {
        toast.error(error);
      });
  }, []);
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setRows(data);
      setPending(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  const [filterText, setFilterText] = useState("");

  const filteredData = data?.filter((item) => {
    return (
      item.userFirstName &&
      item.userFirstName.toLowerCase().includes(filterText.toLowerCase())
    );
    // .map((user) => {
    //   return {
    //     _id: user.referralUserId,
    //     name: user.referralUserFirstName + " " + user.referralUserLastName,
    //     createdDate: user.createdDate
    //   }
    // })
  });

  return (
    <div className="refer">
      <div className="refer__wrapper">
        <div>
          <input
            type="text"
            className="refer__input"
            value={filterText}
            onChange={(e) => {
              setFilterText(e.target.value);
            }}
          />
          <button
            className="refer__clearButton"
            onClick={() => {
              setFilterText("");
            }}
          >
            Clear
          </button>
        </div>
        <div>
          <h2 className="refers__count"><span className="refers__total">Total: </span>{filteredData.length}</h2>
        </div>
      </div>
      <div className="refer__table">
      <DataTable
        title="Reffered Users"
        columns={columns}
        data={filteredData}
        selectableRows
        expandableRows
        expandableRowsComponent={ExpandedComponent}
        pagination
        // paginationResetDefaultPage={resetPaginationToggle}
        // progressComponent={<CustomLoader />}
        // customStyles={customStyles}
        subHeader
        // subHeaderComponent={subHeaderComponentMemo}
        progressPending={pending}
        highlightOnHover
        striped
      />
      </div>
    </div>
  );
};

export default Index;
