import { useMediaQuery } from '@material-ui/core';
import React from 'react';
import './Challenge.scss';

export default function ChallengeRules() {
    const mobileView = useMediaQuery("(max-width:900px)");

    return (

        <div className='ChallengeRulePageMainContainer'>
            <div>
                <h2 style={{ textAlign: "center", color: "white" }}>Rules</h2>
                <p>1. Start by joining fun challenges created by the us! Pick your runs (at least 500 deducted) on the challenge page's popup.</p>
                <p>2. Accept challenges on the feed page if you have enough runs. Just choose the options not picked by the creator for added excitement!</p>
                <p>3.  Winners get runs calculated by adding both players' bet runs and subtracting 10%.</p>
                <p>4. Remember, no taking the same challenge twice, and make sure you've got at least 500 runs to join the fun!</p>
            </div>
        </div>

    );
}
