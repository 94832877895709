import React from 'react';
import gifImage from "../../assets/images/giftbox-web.gif";
import gifImageMobile from "../../assets/images/mobile_giftbox.gif";
import { useMediaQuery } from 'react-responsive';

import "./DealCongratesPage.scss";

import { AiOutlineCloseCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";


const DealCongratesPage = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const navigate = useNavigate();

  return (
    <div className="deal_animation">
      {isMobile ? (
        <img src={gifImageMobile} alt="GIF Video" />
      ) : (
        <img src={gifImage} alt="GIF Video" />
      )}
      <AiOutlineCloseCircle
        className="close__icon-deals-coin"
        onClick={() => {
          navigate("/deals");
        }}
      />
    </div>

  )
}

export default DealCongratesPage
