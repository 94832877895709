import React, { createContext, useEffect, useState } from "react";
import { getStorageItem } from "../utils/sessionStorage";
import { io } from "socket.io-client";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import './SocketContext.scss';

const SocketContext = createContext();

const SocketProvider = ({ children }) => {
  const accessToken = getStorageItem("token");
  const [socket, setSocket] = useState(null);
  const [isVerified, setIsVerified] = useState(false);
  const [isYourTurn, setIsYourTurn] = useState(true);
  const userId = getStorageItem("user_id");
  const navigate = useNavigate();

  // State to manage the custom modal
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalCallback, setModalCallback] = useState(null);
  const [timer, setTimer] = useState(60);

  const connect = () => {
    try {
      const socketInstance = io(global.config.ROOTURL.prod, {
        auth: {
          token: "Bearer " + accessToken,
        }, transports: ["websocket", "polling"],
      });
      socketInstance.on("connect", () => {
        setSocket(socketInstance);
      });
    } catch (err) {
      console.error("Error occurred while connecting with socket", err);
    }
  };

  useEffect(() => {
    connect();
  }, [accessToken, userId]);

  const getTotalRuns = async () => {
    if (!accessToken || !userId) {
      return 0;
    }

    try {
      const getTotalRun = {
        method: "GET",
        url: global.config.ROOTURL.prod + "/pitch/getTotalRun/" + userId,
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      };

      const response = await axios(getTotalRun);
      return response?.data || 0;
    } catch (error) {
      console.error(
        "Error occurred while checking user eligibility to play",
        error
      );
      return 0;
    }
  };

  // Function to start the countdown timer
  const startTimer = () => {
    const intervalId = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    // Stop the timer after 60 seconds
    setTimeout(() => {
      clearInterval(intervalId);
      hideModal();
    }, 60000);
  };

  // Function to show the custom modal
  const showModalWithCallback = (message, callback) => {
    setModalMessage(message);
    setModalCallback(() => callback);
    setShowModal(true);
    setTimer(60); // Reset the timer when showing the modal
    startTimer(); // Start the countdown timer
  };

  // Function to hide the custom modal
  const hideModal = () => {
    setShowModal(false);
    setModalMessage("");
    setModalCallback(null);
  };

  // Function to handle user confirmation in the custom modal
  const handleModalConfirm = async () => {
    hideModal();
    if (modalCallback) {
      await modalCallback();
    }
  };

  // Function to handle user cancellation in the custom modal
  const handleModalCancel = () => {
    hideModal();
  };

  useEffect(() => {
    const handleInviteRequest = async (data) => {
      if (userId !== data?.friendId) {
        return;
      }

      showModalWithCallback(data?.message, async () => {
        const runs = await getTotalRuns();
        if (runs > 200) {
          socket.emit(
            "invite response",
            {
              inviteId: data?.invite?._id,
              response: true,
            },
            (err, res) => {
              if (err) {
                console.log(err);
                toast.error(err);
              } else {
                console.log(res);
              }
            }
          );
        } else {
          alert("You don't have sufficient runs to play");
        }
      });
    };

    socket?.on("invite-request", handleInviteRequest);
    socket?.on("redirect-to", (data) => {
      if (data?.userId === userId) {
        navigate(data?.path);
      }
    });

    return () => {
      socket?.off("invite-request", handleInviteRequest);
      socket?.off("invite-request");
      socket?.off("redirect-to");
    };
  }, [getTotalRuns, navigate, socket]);

  return (
    <SocketContext.Provider
      value={[socket, setSocket, isVerified, setIsVerified]}
    >
      {children}

      {/* Custom Modal */}
      {showModal && (
        <div className="custom-modal">
          <div className="modal-content">
            <div className="timer">{timer}s</div>
            <p>{modalMessage}</p>
            <button onClick={handleModalConfirm}>Confirm</button>
            <button onClick={handleModalCancel}>Cancel</button>
          </div>
        </div>
      )}
    </SocketContext.Provider>
  );
};

export { SocketContext, SocketProvider };
