import React, { useEffect, useState } from "react";
import "./index.css";
import PredictionItem from "../PredictionItem";
import PaginationBox from "../PaginationBox";
import AddBox from "../AddBox";
import { Button, Stack } from "@mui/material";
import Filter from "../Filter";
import { getStorageItem } from "../../../utils/sessionStorage";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import QuestionItem from "./QuestionItem";
const accessToken = getStorageItem("token");
// const userId = getStorageItem("user_id");

const Index = () => {
  const { state } = useLocation();
  const { id } = state;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [questions, setQuestions] = useState([]);
  // const [pageNumber, setPageNumber] = useState(1);
  const findQuestionsByPredictionId = async () => {
    if (!accessToken) return
    if (!id) return toast.error("something went wrong");
    const options = {
      method: "GET",
      url:
        global.config.ROOTURL.prod +
        `/contest/predictionQuestion/findQuestionsByPredictionId/${id}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    axios(options)
      .then(({ data }) => {
        console.log(data);
        setQuestions(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status == 401) {
        }
      });
  };
  useEffect(() => {
    findQuestionsByPredictionId();
  }, []);
  return (
    <div className="contest_admin-wrapper">
      <div className="contest_admin_head">
        <h3 className="contest_admin_head-content--left">
          {state.predictionName}
        </h3>
        {/* <h3 className="contest_admin_head-content--right">
          <p className="contest_admin_head-content--text">Filter</p>
          <img
            src="/svg/admin/filter.svg"
            alt="filter"
            className="contest_admin_head-content--filter"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setShowFilter(!showFilter);
            }}
          />
        </h3> */}
      </div>
      {/* {showFilter && (
        <div className="contest_admin-filter">
          <Filter />
        </div>
      )} */}
      <div className="contest_admin_prediction-list">
        {!isLoading && !questions.length && (
          <>
            <div>No Questions</div>
            <div>
              <button
                onClick={() => {
                  navigate(`../contest/add-question/${id}`, {
                    replace: true,
                    state: {
                      id: id,
                      predictionName: state.predictionName,
                    },
                  });
                }}
                className="contest-preediction-admin-add-new-button"
              >
                Add Question
              </button>
            </div>
          </>
        )}

        {questions &&
          questions?.map((question, index) => {
            return (
              <QuestionItem
                key={index}
                predictionId={id}
                data={question}
                length={questions?.length}
              />
            );
          })}
      </div>
    </div>
  );
};

export default Index;
