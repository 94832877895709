import { Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import LoanModel from './LoanModel';
import axios from "axios";
import { getStorageItem } from '../../utils/sessionStorage';
export default function LoanDescription(props) {
    const { totalRun, dealRun, discount, reedemLoanDeal } = props;

    const [insufficientRun, setInsufficientRun] = useState(0);
    const [LoanAmount, setLoanAmount] = useState(0);
    const [openModel, setOpenModel] = useState(false);
    const accessToken = getStorageItem("token");
    const handleclose = () => {
        setOpenModel(false);
    }
    const handleReddemLoanDeal = (n, e, mobileNo, Loan) => {
        reedemLoanDeal(n, e, mobileNo, Loan);
    }
    const CheckLoanAmount = () => {

        if (!accessToken) return;
        const options = {
            method: "get",
            url: global.config.ROOTURL.prod + `/defaultRun/getRunsByName/LOAN_PERCENTAGE`,
            headers: {
                Authorization: "Bearer " + accessToken,
            },
        };

        axios(options)
            .then((response) => {
                let realRuntoAvialDeal = dealRun * ((100 - discount) / 100)
                let difference = realRuntoAvialDeal - totalRun;
                setInsufficientRun(difference)
                let percentage = Math.floor(realRuntoAvialDeal * (response.data.run / 100));

                if (percentage >= difference) {
                    setLoanAmount(difference)
                }
            })
            .catch((error) => {
                console.log(error)
            });
    }

    useEffect(() => {
        CheckLoanAmount();
    }, [])

    return (
        <div>
            <p style={{ color: 'red' }}>You need {insufficientRun} More Runs to Avail This Deal</p>
            {LoanAmount > 0 && <Button onClick={() => setOpenModel(true)}>Borrow Runs ?</Button>}

            {openModel && <LoanModel openModel={openModel} handleCloseModel={handleclose} LoanAmount={LoanAmount} handleReddemLoanDeal={handleReddemLoanDeal} />}
        </div>
    )
}
