import React, { useEffect, useState } from "react";
import { getStorageItem } from "../../utils/sessionStorage";
import axios from "axios";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import sucessAnimation from "../../assets/images/subscription/Successful.json"
import {useNavigate } from "react-router-dom";
import Lottie from "react-lottie";
import "./index.scss";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Payment = () => {
  const accessToken = getStorageItem("token");
  const userId = getStorageItem("user_id");
  const [transactionId, setTransactionId] = useState("");
  const [open, setOpen] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(""); // SUCCESS or another status

  // Function to fetch transaction ID
  const getTransactionId = () => {
    if (!userId) return;
    const config = {
      method: "GET",
      url: global.config.ROOTURL.prod + `/pay/transaction/${userId}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };

    axios(config)
      .then((response) => {
        setTransactionId(response.data.merchantTransactionId);
      })
      .catch((error) => {
        console.log("Error fetching transaction ID:", error);
      });
  };
  console.log("transactionId:", transactionId);
  // Function to fetch payment status based on transactionId
  const getStatus = () => {
    if (!transactionId) return;
    const config = {
      method: "GET",
      url: global.config.ROOTURL.prod + `/pay/payment/status/${transactionId}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };

    axios(config)
      .then((response) => {
        if (response.data.data.responseCode === "SUCCESS") {
          setPaymentStatus("SUCCESS");
        } else {
          setPaymentStatus("NOT SUCCESS");
        }
        handleOpen(); // Open modal based on status
      })
      .catch((error) => {
        console.log("Error fetching payment status:", error);
      });
  };

  const navigate = useNavigate();
  const navigateTopitch = () => {
    navigate('/pitch');
  };
  useEffect(() => {
    getTransactionId(); // Fetch transaction ID on component mount
  }, []);

  useEffect(() => {
    if (transactionId) {
      getStatus(); // Fetch payment status when transactionId changes
    }
  }, [transactionId]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      {/* Button to manually open the modal for testing */}
      <Button onClick={getStatus}>Check Payment Status</Button>

      {/* Modal component */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box>
          {/* <Typography id="modal-modal-title" variant="h6" component="h2">
            Payment Status
          </Typography> */}
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {paymentStatus === "SUCCESS" ? (
              <div className="modal-content-subs">
                <div className="modal-icon-subs">
                <div>
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: sucessAnimation,
                  }}
                  width="205px"
                  height="145px"
                />
              </div>
                </div>
                <p style={{fontSize: "20px", fontWeight: "600"}}>Plan Activated Successfully</p>
                <p style={{fontSize: "12px"}}>Your transaction has been completed successfully: {transactionId}</p>
                <p>
                  <strong style={{color: "green"}}>
                    Welcome to the VIP experience of the cricketing world!
                  </strong>
                </p>
                <button className="modal-button-subs" onClick={navigateTopitch}>
                  Go to pitch page
                </button>
              </div>
            ) : (
              <div>
                Payment Not Successful for Transaction ID: {transactionId}
              </div>
            )}
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default Payment;
{/* <div>Payment Successful for Transaction ID: {transactionId}</div> */}

