import { useContext, useEffect, useState } from "react";

import Feeds from "../../components/feeds";
// import Footer from "../../components/footer";
import Footer from "../../components/footer/Footer.js";
import Header from "../../components/header";
import Suggestions from "../../components/suggestions";
import Events from "../../components/events";
import PostContext from "../../context/post";
import RegistrationBonus from "../../components/registration-bonus";
import "./index.scss";
import { Grid } from "@mui/material";
import SideBar from "../../assets/images/side-bar.png";
import { useMediaQuery } from "@mui/material";
import AppContext from "../../context/AppContext";
import { useDispatch } from "react-redux";
import { PathAction } from "../../redux/reducers/globalPath";
import LeaderBoard from "../../components/leader-board";
import { Helmet } from "react-helmet";
import Treanding from "../hashtags/Treanding";
import LiveScore from "../../components/live-score";
import { getStorageItem, setStorageItem } from "../../utils/sessionStorage";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const PitchesPage = () => {
  const accessToken = getStorageItem("token");
  const [showForm, setShowForm] = useState(false);
  const [isPitch, setIsPitch] = useState(true);
  const [pitchCreated, setPitchcreated] = useState(false);
  const [url1, setURL] = useState("");
  const pitchURL = "/pitch";
  const path = window.location.pathname;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(PathAction.handlePath({ payload: path }));
    const eventRedirect = getStorageItem("eventRedirect");
    if (eventRedirect && eventRedirect !== "") {
      navigate(eventRedirect);
    }
  }, []);

  const appContext = useContext(AppContext);
  const { setOnlyLogo } = appContext;
  setOnlyLogo(false);

  const handlePostClick = () => {
    // console.log(url);
    if (pitchURL === url1) {
      setShowForm(!showForm);
    }
  };

  const handleCancelPost = () => {
    setShowForm(false);
  };
  const setpitchselection = () => {
    if (!accessToken) return
    const shared = {
      method: "GET",
      url: global.config.ROOTURL.prod + "/auth/getPitchSelectionType",
      headers: {
        Authorization: "Bearer " + accessToken,
        'Content-Type': 'application/json'
      },

    }
    axios(shared)
      .then((response) => {
        // console.log("setpitchselection", response)
        setStorageItem('pitchSelectionType', response.data.pitchSelectionType)
        setPitchcreated(true);
        // localStorage.setItem('hey', 'rsigi')
      })

      .catch((error) => {
        if (error?.response?.status == 401) {
          console.log(error.response.status)
        }
      });
  }
  useEffect(() => {
    setpitchselection();
    return () => {
      setOnlyLogo(false);
    }
  }, [])
  const mobileView = useMediaQuery("(max-width:959px)");
  const handlehprops = () => {
    setPitchcreated(!pitchCreated);
  }
  const handlesetUrl = (data) => {
    setURL(data)
  }

  return (
    <div>
      <Helmet>
        <title>Champhunt | Pitch</title>
      </Helmet>

      <PostContext.Provider
        value={{
          showForm,
          toggleShowForm: handleCancelPost,
        }}
      >
        <div className="page pitch">
          <main className="pitch-main">
            <Grid container spacing={mobileView ? 0 : 2} >
              <Grid
                item
                sm={12}
                md={3}
                lg={3}
                sx={{ display: `${mobileView ? "none" : ""}` }}
              >
                <img alt="" src={SideBar} style={{ width: "100%" }} />
                {/* <LiveScore/> */}

                <Grid style={{ position: 'relative', top: '9px' }}>
                  <Treanding />
                </Grid>


                <LeaderBoard />
              </Grid>
              <Grid
                item
                sm={12}
                md={5}
                lg={6}
                sx={{ padding: `${mobileView ? "0" : ""}` }}
              >

                <Feeds pitchCreatedProps={pitchCreated} />
              </Grid>
              <Grid item sm={12} md={4} lg={3}>
                <Suggestions />

              </Grid>
            </Grid>
            <RegistrationBonus />
          </main>
          <Footer
            
          />
        </div>
      </PostContext.Provider>
      
    </div>
  );
};

export default PitchesPage;
