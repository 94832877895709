import moment from "moment/moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./ChallengeItem.css";
import axios from "axios";
import { getAuthToken } from "../../utils/auth";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import ChallengeDelete from './challengeDeleteModal'
import ChallengeEdit from './Update'
const ChallengeItem = (props) => {
  const [data, setData] = useState(props.data)
  const [openDeleteChallengeModal, setOpenDeleteChallengeModal] = useState(false)
  const [openEditModal, setOpenEditModal] = useState(false)

  const [challengeEnded, setChallengeEnded] = useState(data?.endChallenge ? true : false);
  const [distributionStatus, setDistributionStatus] =
    useState(data?.rewardsDistributed ? true : false)
  const accessToken = getAuthToken();
  const navigate = useNavigate();
  const handleOpenDeleteChallengeModal = () => setOpenDeleteChallengeModal(true);
  const handleCloseDeleteChallengeModal = () => setOpenDeleteChallengeModal(false);
  const handleOpenEditModal = () => {
    navigate('/admin/challenge/update', { state: { data } });
  }
  const handleCloseEditModal = () => setOpenEditModal(false)
  const deleteChallenge = (id) => {
    if (!accessToken && !id) return
    var deleteDetails = {
      method: 'POST',
      url: global.config.ROOTURL.prod + `/contest/challenge/deleteChallenge/${id}`,
      headers: {
        'Authorization': 'Bearer ' + accessToken,
      },

    };
    axios(deleteDetails)
      .then(response => {
        if (response) {
          toast.success("challenge deleted ")
          handleCloseDeleteChallengeModal()
          props.getAllChallenges()

        }
      })
      .catch(error => {
        toast.error("error occured ")
        console.log(error)
      })

  }
  console.log("daat", data)
  const handleRewardDistrubution = () => {
    if (accessToken && data?._id) {

      var getReferral = {
        method: 'POST',
        url: global.config.ROOTURL.prod + `/contest/challenge/updateRewardDistributedStatus/${data?._id}`,
        headers: {
          'Authorization': 'Bearer ' + accessToken,
        },
        data: {

        },

      };
      axios(getReferral)
        .then(response => {
          toast.success("challenge reward distribution has started")
          setDistributionStatus(true)
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
  const endChallenge = () => {
    if (accessToken && data?._id) {

      var getReferral = {
        method: 'POST',
        url: global.config.ROOTURL.prod + `/contest/challenge/endChallenge/${data?._id}`,
        headers: {
          'Authorization': 'Bearer ' + accessToken,
        },


      };
      axios(getReferral)
        .then(response => {
          if (response.data.modifiedCount === 1) {
            setChallengeEnded(true)
            toast.success("challenge ended")
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
  return (
    <div className="contest_admin_predction-wrapper">
      <div className="contest_admin_prediction-item">
        <div className="contest_admin_prediction-header">
          <div className="contest_admin_prediction-item-name">
            {data?.challengeQuestion}
          </div>
          <div>  <FontAwesomeIcon icon={faPenToSquare} style={
            { margin: "5px" }} onClick={handleOpenEditModal} />
            <img
              src="/svg/admin/delete.svg"
              alt="delete"
              className="contest_admin_prediction-item-delete" onClick={handleOpenDeleteChallengeModal}
            /></div>
        </div>
        <div className="contest_admin_prediction-sub-header">
          <div className="contest_admin_prediction-sub-header--runs">
            <img
              src="/svg/admin/run-small.svg"
              alt="runs"
              className="contest_admin_prediction-sub-header--runs-svg"
            />
            <p className="contest_admin_prediction-sub-header--runs-entry">
              {/* Entry {data?.redeemRun} */}
            </p>
          </div>
          <div>
            <p className="contest_admin_prediction-sub-header--reward">
              {/* Reward {data?.rewardRun} */}
            </p>
          </div>
          <div>
            <p className="contest_admin_prediction-sub-header--played">
              {/* Played 200 */}
            </p>
          </div>
        </div>
        <div className="contest_admin_prediction-sub-schedule">
          <div className="contest_admin_prediction-sub-schedule-status">
            {data.active && (
              <p className="contest_admin_prediction-sub-schedule-status--text">
                Active
              </p>
            )}
          </div>
          <div className="contest_admin_prediction-sub-schedule-timing">
            <p className="contest_admin_prediction-sub-schedule-timing--text">
              Closes at {data.endDate}, {data.endTime}
            </p>
          </div>
        </div>
      </div>
      {/* <div
        onClick={() => {
          navigate(`/ admin / contest / add - question / ${ data._id }`, {
            state: { id: data._id, predictionName: data.predictionName },
          });
        }}
        className="contest_admin_prediction-sub-schedule-edit-button"
      >
        <p className="contest_admin_prediction-sub-schedule-button--text">
          Add Question
        </p>
      </div> */}
      <div
        onClick={() => {
          navigate(`/admin/challenge/viewUpdateChallenge/${data._id}`, { state: { data } });
        }}
        className="contest_admin_prediction-sub-schedule-edit-button" style={{ cursor: 'pointer' }}
      >
        <p className="contest_admin_prediction-sub-schedule-button--text">
          View Answer & update Answer
        </p>
      </div>
      <div
        // onClick={handleRewardDistrubution}
        className="contest_admin_prediction-sub-schedule-edit-button"
      >
        <p className="contest_admin_prediction-sub-schedule-button--text">
          Answer:"{data.correctOption ? `${data.correctOption}` : "yet to be updated"}"
        </p>
      </div>
      <div
        onClick={!distributionStatus && handleRewardDistrubution}
        className="contest_admin_prediction-sub-schedule-edit-button"
        style={{ cursor: 'pointer' }}
      >
        <p className="contest_admin_prediction-sub-schedule-button--text">
          {distributionStatus ? 'rewards distributed' : 'distribute rewards'}
        </p>
      </div>
      <div
        onClick={!challengeEnded && endChallenge}
        className="contest_admin_prediction-sub-schedule-edit-button"
        style={{ cursor: 'pointer' }}
      >
        <p className="contest_admin_prediction-sub-schedule-button--text">
          {challengeEnded ? 'Challenge Ended' : 'End Challenge'}
        </p>
      </div>
      {/* <div className="contest_admin_prediction-sub-schedule-button">
        <p className="contest_admin_prediction-sub-schedule-button--text">
          Edit
        </p>
      </div> */}
      {openDeleteChallengeModal && <ChallengeDelete handleOpen={handleOpenDeleteChallengeModal}
        handleClose={handleCloseDeleteChallengeModal} open={openDeleteChallengeModal} challengeId={data?._id}
        handleDelete={deleteChallenge} />

      }
      {openEditModal && <ChallengeEdit handleOpen={handleOpenDeleteChallengeModal}
        handleClose={handleCloseEditModal} open={openEditModal} predictionData={data} setData={setData}
      />}
    </div>

  );
};

export default ChallengeItem;
