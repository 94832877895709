import Styled from "@emotion/styled";
import LogoWithTitle from "../../components/signin-signup/logo.component";
import { Helmet } from "react-helmet";
import "./index.scss";

const HeaderLabel = Styled.h1(
  ({ align }) => `
    font-size: 20px;
    color: black;
    margin: auto 0 auto 0;
    text-align:${align || "left"};
    font-weight:500;
    line-height: 30px;
`
);

const AboutsUs = () => {
  return (
    <div>
      <Helmet>
        <title>Champhunt | AboutUs</title>
      </Helmet>

      <div className="page aboutus">
        <div className="main-content">
          <main className="aboutus-main">
            <div className="component aboutus-alert">
              <div className="aboutus-text">
                <div style={{ display: "flex" }}>
                  <LogoWithTitle align={"flex-start"} />
                  <HeaderLabel align={""}>Champhunt</HeaderLabel>
                </div>
                <p>
                  Champ Hunt is your home for all things cricket. We are the
                  virtual adda of cricket lovers where they can come, interact
                  with other fans, share their opinions, have discussions,
                  analyze games and much more. What separates Champ Hunt from
                  other cricket portals on the internet is that Champ Hunt is a
                  reward based platform. Every fan who registers to the platform
                  earns runs for every single activity they do. Users can also
                  give runs of their liking to other user's posts and that gets
                  added to their runs earned.
                  <br></br>
                  <br></br>
                  You can use these runs to redeem exclusive deals from our
                  partners and get rewarded for your love of cricket!
                  <br></br> <br></br>
                  If you are a stakeholder from the cricketing ecosystem, feel
                  free to reach out to us at{" "}
                  <strong>partnerships@champhunt.com</strong>
                </p>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default AboutsUs;
