import React, { useEffect, useRef, useState } from "react";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";
import { Box } from "@mui/material";
import jobExperience from "../../assets/images/icons/jobExperienceimg.png";
import jobSalarylogo from "../../assets/images/icons/jobsalaryimg.png";
import jobCompanylogo from "../../assets/images/icons/jobcompanypic.png";
import CardContent from "@mui/material/CardContent";
import { useNavigate } from "react-router-dom";
import defaultAvatar from "../../assets/images/profile/default_avatar.png";
import { clearStorage, getStorageItem } from "../../utils/sessionStorage";
import JobDescription from "../../components/job-description";
import TrendingBadge from "../../assets/images/posts/trending_badge.svg";
import DropdownMenu from "./../dropdown-menu/dropdownMenu";
import ScoreRuns from "../runs-dropdown/dropdownMenu";
import ChampButton from "../../commons/form/button";
import Runs from "../../assets/images/icons/post-action.png";
import runs from "../../assets/images/icons/newBall.png";
import newcomment from "../../assets/images/icons/emptycomment.png";
import emptyreshare from "../../assets/images/icons/emptyReshare.png";
import newreshare from "../../assets/images/icons/newreshare.png";
import newshare from "../../assets/images/icons/emptyShare.png";
import { MentionsInput, Mention } from "react-mentions";
import axios from "axios";
import new2ball from "../../assets/images/icons/2runNewBall.png";
import new4ball from "../../assets/images/icons/4runBall.png";
import new6ball from "../../assets/images/icons/6runnewBall.png";
import "./JobMain.scss";
import ShareJobPopup from "./ShareJobPopup";
import JobPopup from "../posted-job/JobPopup";
import ShareToAppsPopupPage from "../posts/shareToOtherApps";
import RunPopupModal from '../posts/runPopup'
import { PathAction } from "../../redux/reducers/globalPath";
import { useDispatch } from "react-redux";
import fireBaseFuntion from "../../firebase";

const JobMain = (props) => {
  const jobRef = useRef(null);


  const { onEnterViewport } = props

  // const postObj = props.post;
  const [openRunModal, setOpenRunModal] = useState(false);
  // const [postObj, setJobData] = useState(props.post);
  const [postObj, setPostObj] = useState(props.post);
  const [ownPost, setOwnPost] = useState(false);
  const [showRuns, setShowRuns] = useState(false);
  const [runsGiven, setRunsGiven] = useState(false);
  const [runsScored, setRunsScored] = useState();
  const [runsImage, setRunsImage] = useState(Runs);
  const [runsGivenValue, setRunsGivenValue] = useState(0);
  const [isPostOpen, setIsPostOpen] = useState(false);
  const [popupData, setpopupData] = useState(false);
  const [sharedisable, setSharedisable] = useState(true);
  const [isShareopen, setShareOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [sharing, setSharing] = useState(false);
  const [url, setUrl] = useState("");
  const [shareToOtherApps, setShareToOtherApps] = useState(false);
  const [sharedBody, setSharedBody] = useState("");
  const avatar = getStorageItem("avatar");
  const [shareOriginalPost, setoriginalPost] = useState(null);
  const [newSharedDetail, setNewSharedDetail] = useState([]);
  const navigate = useNavigate();
  const accessToken = getStorageItem("token");
  const userId = getStorageItem("user_id");
  const [comments, setComments] = useState();
  const [showComments, setShowComments] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [jobData, setJobData] = useState(props.data);
  const dispatch = useDispatch();
  const handleCloseShareToOtherAppsPopup = () => { setShareToOtherApps(false) };
  const handleOpenRunModal = () => {
    setOpenRunModal(true)
  }
  const handleCloseRunModal = () => {
    setOpenRunModal(false)
  }
  const [updateRun, setUpdateRun] = useState(
    props?.post?.postRunCount ? props?.post?.postRunCount : 0
  );

  const navigateToJobDescription = (id) => {

    navigate(`/job-description/${id}`);
  };

  const handlePopup = () => {
    setIsPostOpen(!isPostOpen);
    setpopupData(true);
  };

  const handleShareOpen = () => {
    // setShareOpen(true);
    // setOpen(true);
  };

  const [updateCommentCount, setUpdateCommentedCount] = useState(
    props?.post?.postCommentCount ? props?.post?.postCommentCount : 0
  );
  const handleclose = () => {
    setpopupData(false);
  };

  const updateRuninShareJob = (data) => {
    // console.log("run", data)
    setUpdateRun(updateRun + data);
    setRunsGivenValue(data);
  };

  const updateCommentinShareJob = () => {
    setUpdateCommentedCount(updateCommentCount + 1);
    // setRunsGivenValue(data);
  };

  const handleClose = () => {
    setIsOpen(false);

  }

  const [updateRepostCount, setUpdateRepostCount] = useState(
    props?.post?.sharedPost ? props?.post?.sharedPost.length : 0
  );

  function replaceHashtags(description) {
    // Use a regular expression to match hashtags that are not already anchor tags
    const regex = /(?!<a[^>]*>)(#(\w+))(?![^<]*<\/a>)/g;
    // Replace hashtags with anchor tags
    if (description) return description?.replace(regex, '<a class="hashtagged" href="/hashtags?hashtag=$2">$1</a>');
  }
  const realTimePitchUpdate = () => {
    if (userId) {
      const { onValue, starCountRef } = fireBaseFuntion(
        "user/shareJobDetail/" + postObj?.feed_id
      );

      onValue(starCountRef, (snapShot) => {
        const data = snapShot.val();
        if (data) {
          setUpdateRun(data?.shareJobRunCount);
          setUpdateCommentedCount(data?.shareJobCommentCount);
          setUpdateRepostCount(data?.sharedPost?.length);
        }
      });
    }
  };
  useEffect(() => {
    if (props?.post?.shareJobCommentCount) {
      setUpdateCommentedCount(props?.post?.shareJobCommentCount);
    }
    if (props?.post?.shareJobRunCount) {
      setUpdateRun(props?.post?.shareJobRunCount);
    }
  }, [props?.post?.shareJobCommentCount, props?.post?.shareJobRunCount]);

  const handleShareToOtherAppsPopup = () => {
    let shareUrl = `${global.config.share_url}/job-preview/${postObj.feed_id}`;
    setUrl(shareUrl);
    setShareToOtherApps(true);
  };

  const getSharedBody = (event) => {
    setSharedBody(event.target.value);
  };

  const closeModal = () => {
    setSharing(false);
  };

  const sharePost = () => {
    if (sharedisable && shareOriginalPost) {

      const shared = {
        method: "POST",
        url: global.config.ROOTURL.prod + "/pitch/shared",
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
        },
        data: {
          comment: sharedBody ? sharedBody : "",
          pitchId: shareOriginalPost,
        },
      };
      axios(shared)
        .then((response) => {
          dispatch(PathAction.handleCreatedObject({ payload: response.data }));
          closeModal();
          navigate("/pitch");

        })
        .catch((error) => {
          setShowRuns(!showRuns);
          if (error?.response?.status === 401) {
            clearStorage();
            navigate("/login");
          }
        });
    }
  };
  useEffect(() => {
    if (runsGivenValue === 2) {
      setRunsImage(new2ball);
    } else if (runsGivenValue === 4) {
      setRunsImage(new4ball);
    } else if (runsGivenValue === 6) {
      setRunsImage(new6ball);
    }
    return () => {
      setRunsImage(runs);
    };

  }, [runsGivenValue, runsGiven, updateRun]);

  const tagStyles = {};
  const MentionStyle = {
    control: {
      backgroundColor: "#fff",
      fontSize: 14,
      fontWeight: "normal",
    },

    "&multiLine": {
      input: {
        padding: 9,
        border: "1px solid silver",
        "&focused": {
          border: "1px solid black",
        },
      },
    },

    suggestions: {
      marginTop: 50,
      list: {
        fontSize: 14,
        backgroundColor: "#FFFFFF",
        boxShadow: "0px 5px 15px rgba(68, 68, 79, 0.1)",
        borderRadius: 8,
        minWidth: 400,
      },
      item: {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 300,
        fontSize: 14,
        lineHeight: 2,
        padding: "5px 15px",
        borderBottom: "1px solid rgba(0,0,0,0.15)",
        "&focused": {
          backgroundColor: "#d2effa",
        },
      },
    },
  };
  const getUserMentions = async (str, callback) => {
    if (!str) return;
    const nameSearch = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/auth/mention-name-search",
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
      data: {
        searchText: str,
        pageNumber: 1,
        pagePerSize: 5,
      },
    };
    axios(nameSearch)
      .then(({ data }) => {
        return data.map((data) => {
          return {
            id: data._id,
            display: "@" + data.firstName + " " + data.lastName,
            photo: data.profilePhoto,
            email: data.email,
            followersCount: data.followersCount,
            totalRun: data.totalRun,
            following: data.youFollow,
          };
        });
      })
      .then(callback);
  };

  const renderSuggestions = (entry) => {
    return (
      <div className="suggestions1">
        <div className="suggestions1__image">
          <img
            src={
              entry.photo
                ? entry.photo
                : "https://www.umass.edu/philosophy/sites/default/files/store/img/cfm/user_23.png"
            }
            alt="user-profile"
          />
          {entry.following && (
            <div className="following__mark">
              <svg
                width="9"
                height="10"
                viewBox="0 0 9 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.4362 1.11473C4.18418 1.11473 3.93462 1.16437 3.70178 1.26081C3.46894 1.35726 3.25738 1.49862 3.07917 1.67683C2.90096 1.85503 2.7596 2.0666 2.66316 2.29944C2.56671 2.53228 2.51707 2.78183 2.51707 3.03385C2.51707 3.28588 2.56671 3.53543 2.66316 3.76827C2.7596 4.00111 2.90096 4.21267 3.07917 4.39088C3.25738 4.56909 3.46894 4.71045 3.70178 4.8069C3.93462 4.90334 4.18418 4.95298 4.4362 4.95298C4.94518 4.95298 5.43332 4.75079 5.79323 4.39088C6.15313 4.03098 6.35533 3.54284 6.35533 3.03385C6.35533 2.52487 6.15313 2.03673 5.79323 1.67683C5.43332 1.31692 4.94518 1.11473 4.4362 1.11473ZM1.96875 3.03385C1.96875 2.37945 2.22871 1.75184 2.69145 1.28911C3.15419 0.826369 3.78179 0.566406 4.4362 0.566406C5.09061 0.566406 5.71821 0.826369 6.18095 1.28911C6.64368 1.75184 6.90365 2.37945 6.90365 3.03385C6.90365 3.68826 6.64368 4.31587 6.18095 4.7786C5.71821 5.24134 5.09061 5.5013 4.4362 5.5013C3.78179 5.5013 3.15419 5.24134 2.69145 4.7786C2.22871 4.31587 1.96875 3.68826 1.96875 3.03385Z"
                  fill="white"
                  stroke="white"
                  stroke-width="0.5"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0.87207 8.51673C0.87207 7.57147 1.24757 6.66493 1.91597 5.99653C2.58437 5.32814 3.49091 4.95264 4.43616 4.95264C5.38142 4.95264 6.28796 5.32814 6.95636 5.99653C7.62475 6.66493 8.00026 7.57147 8.00026 8.51673V8.67026C8.00026 8.94607 7.80725 9.18404 7.53747 9.24051L6.57736 9.44284C5.61396 9.64572 4.62862 9.70988 3.65151 9.63585C3.57979 9.62933 3.51352 9.59484 3.46702 9.53986C3.42052 9.48487 3.39752 9.41379 3.40299 9.34198C3.40846 9.27017 3.44197 9.2034 3.49627 9.1561C3.55057 9.1088 3.62131 9.08476 3.69319 9.08918C4.61875 9.15936 5.552 9.0985 6.46441 8.90604L7.42452 8.70425C7.43226 8.70273 7.43923 8.69855 7.44423 8.69245C7.44923 8.68635 7.45196 8.6787 7.45193 8.67081V8.51673C7.45193 7.7169 7.1342 6.94982 6.56863 6.38426C6.00307 5.81869 5.23599 5.50096 4.43616 5.50096C3.63633 5.50096 2.86926 5.81869 2.30369 6.38426C1.73812 6.94982 1.42039 7.7169 1.42039 8.51673V8.67026C1.42039 8.68671 1.43136 8.70042 1.44781 8.70425L2.40792 8.90604C2.44317 8.91345 2.47661 8.92774 2.50633 8.94808C2.53606 8.96842 2.56149 8.99442 2.58117 9.02459C2.60085 9.05475 2.61439 9.0885 2.62103 9.12391C2.62766 9.15931 2.62726 9.19567 2.61985 9.23092C2.61243 9.26616 2.59814 9.29961 2.5778 9.32933C2.55746 9.35906 2.53147 9.38449 2.5013 9.40417C2.47113 9.42385 2.43738 9.43739 2.40198 9.44403C2.36658 9.45066 2.33021 9.45026 2.29497 9.44284L1.33485 9.24051C1.20396 9.21306 1.08651 9.14139 1.00223 9.03754C0.917954 8.93369 0.871993 8.804 0.87207 8.67026V8.51673Z"
                  fill="white"
                  stroke="white"
                  stroke-width="0.5"
                />
              </svg>
            </div>
          )}
        </div>
        <div className="suggestions1__fullname">
          <h2>{entry.display}</h2>
        </div>
        <div className="suggestions1__followers">
          <svg
            width="10"
            height="13"
            viewBox="0 0 10 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.78301 0.735851C4.44479 0.735851 4.10989 0.802467 3.79741 0.931897C3.48494 1.06133 3.20103 1.25104 2.96187 1.49019C2.72271 1.72935 2.53301 2.01326 2.40358 2.32574C2.27415 2.63821 2.20753 2.97311 2.20753 3.31133C2.20753 3.64954 2.27415 3.98445 2.40358 4.29692C2.53301 4.60939 2.72271 4.89331 2.96187 5.13246C3.20103 5.37162 3.48494 5.56133 3.79741 5.69076C4.10989 5.82019 4.44479 5.8868 4.78301 5.8868C5.46607 5.8868 6.12115 5.61546 6.60414 5.13246C7.08714 4.64947 7.35848 3.99439 7.35848 3.31133C7.35848 2.62827 7.08714 1.97319 6.60414 1.49019C6.12115 1.00719 5.46607 0.735851 4.78301 0.735851ZM1.47168 3.31133C1.47168 2.43311 1.82055 1.59086 2.44154 0.969865C3.06254 0.348871 3.90479 0 4.78301 0C5.66123 0 6.50347 0.348871 7.12447 0.969865C7.74546 1.59086 8.09433 2.43311 8.09433 3.31133C8.09433 4.18955 7.74546 5.03179 7.12447 5.65279C6.50347 6.27378 5.66123 6.62265 4.78301 6.62265C3.90479 6.62265 3.06254 6.27378 2.44154 5.65279C1.82055 5.03179 1.47168 4.18955 1.47168 3.31133Z"
              fill="#718193"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1.31369e-07 10.6697C1.31369e-07 9.40121 0.503925 8.18463 1.40092 7.28763C2.29791 6.39064 3.51449 5.88672 4.78303 5.88672C6.05157 5.88672 7.26815 6.39064 8.16514 7.28763C9.06213 8.18463 9.56606 9.40121 9.56606 10.6697V10.8758C9.56606 11.2459 9.30704 11.5653 8.945 11.6411L7.65652 11.9126C6.36363 12.1849 5.04131 12.271 3.73003 12.1716C3.63378 12.1629 3.54484 12.1166 3.48243 12.0428C3.42003 11.969 3.38916 11.8736 3.39651 11.7772C3.40385 11.6809 3.44882 11.5913 3.52169 11.5278C3.59456 11.4643 3.68949 11.432 3.78595 11.438C5.02807 11.5322 6.28048 11.4505 7.50494 11.1922L8.79341 10.9214C8.8038 10.9194 8.81316 10.9138 8.81987 10.9056C8.82658 10.8974 8.83024 10.8871 8.83021 10.8765V10.6697C8.83021 9.59637 8.40381 8.56695 7.64482 7.80796C6.88582 7.04897 5.85641 6.62257 4.78303 6.62257C3.70965 6.62257 2.68023 7.04897 1.92124 7.80796C1.16225 8.56695 0.735851 9.59637 0.735851 10.6697V10.8758C0.735851 10.8979 0.750568 10.9163 0.772643 10.9214L2.06112 11.1922C2.10842 11.2022 2.1533 11.2213 2.19319 11.2486C2.23308 11.2759 2.26721 11.3108 2.29362 11.3513C2.32003 11.3918 2.3382 11.4371 2.34711 11.4846C2.35602 11.5321 2.35548 11.5809 2.34552 11.6282C2.33557 11.6755 2.3164 11.7204 2.2891 11.7603C2.2618 11.8002 2.22692 11.8343 2.18643 11.8607C2.14594 11.8871 2.10065 11.9053 2.05314 11.9142C2.00563 11.9231 1.95683 11.9226 1.90953 11.9126L0.621058 11.6411C0.445399 11.6042 0.287775 11.508 0.174675 11.3687C0.0615764 11.2293 -0.000103895 11.0553 1.31369e-07 10.8758V10.6697Z"
              fill="#718193"
            />
          </svg>
          <p>{entry.followersCount}</p>
        </div>
      </div>
    );
  };
  const getHashtags = async (str, callback) => {
    if (!str) return;
    const nameSearch = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/hash-profile-tag/searchHashTag",
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
      data: {
        searchText: str,
        pageNumber: 1,
        pagePerSize: 5,
      },
    };
    axios(nameSearch)
      .then(({ data }) => {
        return data.map((data) => {
          return {
            id: data._id,
            display: "#" + data.hashTagText,
          };
        });
      })
      .then(callback);
  };

  const handleCommentSubmit = () => {
    postComment();
    setComments("");
  };
  const postComment = () => {
    if (!accessToken && !postObj.feed_id && !comments) return;
    const submitComment = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/job/sharejob/comment/create",
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
      data: {
        shareJobId: postObj.feed_id,
        commentText: comments,
      },
    };
    axios(submitComment)
      .then(() => {
        updateCommentinShareJob()
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === 401) {
          clearStorage();
          navigate("/login");
        }
      });
  };

  const getRunByUserIdAndShareJobId = (shareJobId) => {
    if (!userId && !shareJobId && !accessToken) return
    let options = {
      method: "get",
      url:
        global.config.ROOTURL.prod +
        `/job/sharejob/run/getRunByUserIdAndShareJobId/${userId}/${shareJobId}`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };
    axios(options)
      .then((response) => {
        // setRunsGiven(response.data === 0 ? true  false);
        setRunsGivenValue(response.data);

      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getSharejobDetailByShareJobId = (shareJobId) => {
    if (!accessToken && !shareJobId) return
    const option = {
      method: "GET",
      url:
        global.config.ROOTURL.prod +
        `/job/sharejob/getByShareJobId/${shareJobId}`,
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
    };
    axios(option)
      .then((res) => {
        // setPostObj(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // function replaceHashtags(description) {
  //   // Use a regular expression to match hashtags that are not already anchor tags
  //   const regex = /(?!<a[^>]*>)(#(\w+))(?![^<]*<\/a>)/g;
  //   // Replace hashtags with anchor tags
  //   if (description) return description?.replace(regex, '<a class="hashtagged" href="/hashtags?hashtag=$2">$1</a>');
  // }
  useEffect(() => {
    setOwnPost(userId === postObj?.userId);
    if (postObj.feed_id) {
      getSharejobDetailByShareJobId(postObj.feed_id);
      getRunByUserIdAndShareJobId(postObj.feed_id);
    }


    if (postObj?.shareJobMessage) {
      const datapostmeesage = replaceHashtags(postObj.shareJobMessage);
      setPostObj({ ...postObj, shareJobMessage: datapostmeesage })
    }
    realTimePitchUpdate();
   
  }, []);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          console.log(`Post ${postObj?.feed_id} is intersecting: ${entry.isIntersecting}`);

          if (entry.isIntersecting) {
            onEnterViewport(postObj?.feed_id);
            observer.unobserve(entry.target);
          }
        });
      },
      { root: null, rootMargin: '0px', threshold: 0.5 }
    );

    if (jobRef.current) {
      observer.observe(jobRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [postObj?.feed_id]);


  return (
    <div className="job-wrapper" ref={jobRef}>
      <div className="post-header">
        <div className="leftPost">
          <div className="avatar">
            <img
              className="avatar-image"
              src={
                postObj.userProfilePhoto
                  ? postObj.userProfilePhoto
                  : defaultAvatar
              }
              alt={""}
            />
          </div>
          <div className="avatar-cnt">
            <p>
              <Link
                className="shared-link"
                to={`/profile/${postObj.userId}`}
                style={{ textDecoration: "none" }}
              >
                {postObj?.userName}
              </Link>
            </p>
            <p className="date-time">
              {postObj?.createdDate && (
                <ReactTimeAgo
                  date={Date.parse(postObj?.createdDate)}
                  locale="en-US"
                />
              )}
              <br />
            </p>
          </div>
        </div>

        <div className="right_post">
          {postObj?.trending && (
            <img className="trending-post" src={TrendingBadge} alt="trending" />
          )}
          <div className="post-edit">
            <DropdownMenu
              type="post-menu"
              postType='shareJob'
              postId={postObj?.feed_id}
              userId={postObj?.userId}
              setPostObj={setPostObj}
              content={postObj?.shareJobMessage}
              sharedContent={newSharedDetail.sharedText}
              image={postObj?.shareJobImage}
              sharedDetail={postObj?.sharedDetail}
              postObj={postObj}
              pageLoad={props.hprops}
            />
          </div>
        </div>
      </div>
      <div className="job_post">
        <div
          dangerouslySetInnerHTML={{
            __html: postObj.shareJobMessage,
          }}
        />
      </div>
      <div className="job-Main">
        <div className="job-section-start">
          <hr />
          <p>Job</p>
        </div>
        <div className="job-section1">
          <div className="job-main1">
            <CardContent
              sx={{
                backgroundColor: "#FBFDFF",
                borderRadius: "8px",
                marginLeft: "24px",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    display: "inline-block",
                    color: "#0C5BA0",
                    cursor: "pointer",
                  }}
                >
                  {postObj?.jobTitle}
                </Typography>
              </Box>

              <Box sx={{ fontSize: "0.9rem" }}>
                <Typography sx={{ display: "inline-block", color: "#870499" }}>
                  <img src={jobCompanylogo} alt="" />
                  &nbsp;
                </Typography>
                <Typography sx={{ display: "inline-block" }}>
                  {" "}
                  {postObj?.companyName}
                </Typography>
                <Typography sx={{ display: "inline-block" }}>
                  ({postObj?.jobLocation})
                </Typography>
              </Box>
              <Box sx={{ display: "flex", fontSize: "0.9rem", height: 50 }}>
                <Box sx={{ display: "inline-block" }}>
                  <Typography
                    sx={{ display: "inline-block", color: " #808080" }}
                  >
                    <img src={jobExperience} alt="" />
                    &nbsp;{" "}
                  </Typography>
                  <br />
                  <Typography
                    sx={{ display: "inline-block", color: "#808080" }}
                  >
                    {" "}
                    <img src={jobSalarylogo} alt="" />
                    &nbsp;{" "}
                  </Typography>
                </Box>
                <Box sx={{ display: "inline-block" }}>
                  <Typography sx={{ display: "inline-block" }}>
                    {" "}
                    {postObj?.Remuneration}
                  </Typography>
                  <br />
                  <Typography sx={{ display: "inline-block" }}>
                    {" "}
                    {postObj?.Experience}
                  </Typography>
                </Box>
              </Box>
            </CardContent>

            <div className="job-button">
              <button
                style={{ background: "none", border: "none" }}
                onClick={() => navigateToJobDescription(postObj?.jobId)}
              >
                Show Job
              </button>
            </div>
          </div>

          <div className="job-main2">
            <div className="job-description">
              <h3>Job Description</h3>
              <div
                dangerouslySetInnerHTML={{
                  __html: postObj?.jobDescriptionsFileName,
                }}
              />
              {/* <p>{jobData?.jobDescriptionsFileName} </p> */}
            </div>
          </div>
        </div>
      </div>
      {/* multiple icons section */}
      <div className="post-footer post-header border post__width">
        <div className="runs-hld ">
          <div className="displayFlex run_icon_post">
            <div>
              {runsGivenValue === 0 && !ownPost && (
                <ScoreRuns
                  type="post-menu"
                  postType={"shareJob"}
                  setShowRuns={setShowRuns}
                  setRunsGiven={setRunsGiven}
                  setRunsScored={setRunsScored}
                  setRunsGivenValue={setRunsGivenValue}
                  ownPost={ownPost}
                  showRuns={showRuns}
                  postObj={postObj}
                  handleupdaterun={updateRuninShareJob}
                />
              )}
              {runsGivenValue !== 0 && !ownPost && (
                <div className="">
                  <div
                    style={{
                      margin: "auto",
                      display: "flex",
                      textAlign: "center",
                    }}
                  >
                    <img
                      src={runsImage}
                      style={{
                        // cursor: "not-allowed",
                        padding: "0px",
                        paddingRight: "3px",
                      }}
                      alt="img"
                      className="post_icon_image"
                    />
                  </div>
                </div>
              )}
              {ownPost && (
                <div className="">
                  <div
                    style={{
                      margin: "auto",
                      display: "flex",
                      textAlign: "center",
                    }}
                  >
                    <img
                      src={runs}
                      style={{
                        // cursor: "not-allowed",
                        padding: "0px",
                        paddingRight: "3px",
                      }}
                      alt="img"
                      className="post_icon_image"
                    />
                  </div>
                </div>
              )}
            </div>

            <p className="post_count hover_css" onClick={handleOpenRunModal}>{updateRun}</p>
          </div>
          <div className="icon_holder_style">Runs</div>
        </div>
        <div className="comments-hld  " onClick={handlePopup}>
          <div className="displayFlex">
            <img
              src={newcomment}
              className="icon-btn post_icon_image"
              alt=""
              role="button"
            />
            <div className="comments-stat post_count">{updateCommentCount}</div>
            <div className="mobile-comments-stat  post_count">
              {updateCommentCount}
            </div>
          </div>
          <div className="icon_holder_style">Comments</div>
        </div>

        <div className="share-hld">
          <div className="share-btn-container">
            {/* //add new image */}
            {postObj?.sharedDetail ?(
              <img
                src={emptyreshare}
                className="icon-btn post_icon_image"
                alt=""
                role="button"
                onClick={() => { setIsOpen(true); setJobData(jobData) }}
              />
            ) : (
              <img
                src={newreshare}
                className="icon-btn1 post_icon_image"
                alt=""
                role="button"
              />
            )}
            <div className="comments-stat">
              {/* {postlocation === "/pitch" && " Share"} */}
              {props?.post?.sharedPost ? (
                <p style={{ color: "#242a37" }} className="post_count">
                  {" "}
                  {updateRepostCount}
                </p>
              ) : (
                <p style={{ color: "#242a37" }} className="post_count">
                  0
                </p>
              )}
            </div>
          </div>
          <div className="icon_holder_style">Repost</div>
          <div className={`share-modal ${sharing ? "visible" : "hidden"}`}>
            <div className="share-modal__container">
              <div className="share-modal__container__form">
                <div className="input-textarea">
                  <textarea
                    placeholder="Write Something"
                    id="sharedBody"
                    onChange={getSharedBody}
                  ></textarea>
                </div>
                <div className="content leftPost">
                  <div className="avatar">
                    <img className="avatar-image" src={avatar} alt={""} />
                    <div className="avatar-cnt">
                      <p>{postObj.firstName + " " + postObj.lastName}</p>
                      <p className="date-time">{postObj.createdDate}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="share-modal__container__actions">
                <ChampButton
                  onClick={closeModal}
                  classes="cancel secondary"
                  label="Cancel"
                />
                <ChampButton
                  onClick={() => sharePost()}
                  classes="share primary"
                  label="Share"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className="comments-hld displayFlex"
        >
          <div>
            <img
              //  style={{ cursor: "not-allowed"}}
              src={newshare}
              onClick={handleShareToOtherAppsPopup}
              className="icon-btn post_icon_image"
              alt=""
              role="button"
            />
          </div>
          <div className="icon_holder_style">Share</div>
        </div>
      </div>
      {/* {comment} */}
      <div className={`comments-holder visible`}>
        <div className="post-comment">
          <div className="text-block">
            <MentionsInput
              value={comments ? comments : ""}
              name="message"
              placeholder="Start typing here"
              id="postContent"
              className="post-content"
              onChange={(e) => {
                setComments(e.target.value);
              }}
              style={MentionStyle}
              allowSpaceInQuery={true}
            >
              <Mention
                trigger="@"
                renderSuggestion={renderSuggestions}
                style={tagStyles}
                appendSpaceOnAdd={true}
                data={getUserMentions}
                markup="<a class='mentioned' href='/profile/__id__'>__display__</a>"
              />
              <Mention
                trigger="#"
                style={tagStyles}
                appendSpaceOnAdd={true}
                data={getHashtags}
                markup="<a class='hashtagged' href='/hashtags?id=__id__'>__display__</a>"
              />
            </MentionsInput>
          </div>
          <div
            className="action-block"
            style={{ display: `${comments ? "" : "none"}` }}
          >
            <ChampButton
              onClick={() => handleCommentSubmit()}
              classes="share"
              label="Post"
            />
          </div>
        </div>
        <div
          className={`post-header view-comments ${showComments ? "" : "hidden"
            }`}
        ></div>
        {popupData && (
          <ShareJobPopup
            props={postObj}
            postObj={postObj}
            open={isPostOpen}
            setOpen={setIsPostOpen}
            handleclose={handleclose}
            sharedetail={newSharedDetail}
            sharedisable={sharedisable}
            originalpost={shareOriginalPost}
          />
        )}
      </div>{" "}

      {isOpen && <JobPopup jobId={postObj.feed_id} handleClose={handleClose} sharedData={postObj} open={isOpen} companyName={postObj.jobCompanyName} companyEmail={postObj.jobCompanyEmail} companyContact={postObj.jobCompanyContact} />}
      {shareToOtherApps && <ShareToAppsPopupPage open={shareToOtherApps} url={url} handleClose={handleCloseShareToOtherAppsPopup} />}
      {openRunModal && <RunPopupModal shareJobId={postObj?.feed_id} handleClose={handleCloseRunModal} open={handleOpenRunModal} />}
    </div>

  );

};

export default JobMain;
