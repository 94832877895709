import { useEffect, useState } from "react";
import { IconButton, Typography, Box, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Input } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { getStorageItem } from "../../utils/sessionStorage";
import Modal from "@mui/material/Modal";
import axios from "axios";

const styleDesktop = {
  // position: "absolute",
  // top: "50%",
  // left: "50%",
  // transform: "translate(-50%, -50%)",
  width: "auto",
  margin: "auto",
  maxWidth: "400px",
  // bgcolor: "#FFFFFF",
  // border: "2px solid #000",
  // borderRadius: "20px",
  // boxShadow: 24,
  py: 1,
  px: 4,
};

const styleMobile = {
  width: "90%",
  py: 1,
  //   px: 4,
  height: "100%",
  width: "100%",
};

const DealModal = (props) => {
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [Open, setOpen] = useState(props.open);
  const matches = useMediaQuery("(min-width:600px)");
  const mobile = useMediaQuery("(max-width: 460px)");
  const [runsReq, setRunsReq] = useState(0);
  const [style, setStyle] = useState();
  const userName = getStorageItem("full_name");
  const userEmail = getStorageItem("user_email");
  const userId = getStorageItem("user_id");
  const [userRuns, setUserRuns] = useState(0);
  const accessToken = getStorageItem('token');
  useEffect(() => {
    if (!matches) {
      setStyle(styleMobile);
    } else {
      setStyle(styleDesktop);
    }
  }, [matches]);

  const { image, tag, handleClose, redeemDeal } = props;
  const { deal } = props;

  const escFunction = (e) => {
    if (e.key === "Escape") {
      handleClose();
    }
  };
  const handleclose = () => {
    props.handleClose();
    setOpen(false);
  };
  const handleSubmit = (e) => {
    setBtnDisabled(true);
    let name = e.target.name.value;
    let email = e.target.email.value;
    let mobile = e.target.mobile.value;

    e.preventDefault();
    redeemDeal(name, email, mobile);
  };
  const getRuns = () => {
    if (!accessToken) return;
    if (!userId) return console.error("userId required");

    const getTotalRun = {
      method: "GET",
      url: global.config.ROOTURL.prod + "/pitch/getTotalRun/" + userId,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };
    axios(getTotalRun)
      .then((response) => {
        setBtnDisabled(response.data < deal?.redeemrun);
        setRunsReq(deal?.redeemrun - response.data);
        setUserRuns(response.data);
      })
      .catch((error) => {
        console.log("1")
        console.log(error);
      });
  };
  useEffect(() => {
    getRuns();
    window.addEventListener("keydown", escFunction, false);
  }, []);

  return (
    <Modal
      open={Open}
      onClose={handleclose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="dealModalMain"
    >
      <div className="deal-modal1">
        <div className="deal-container">
          <form onSubmit={handleSubmit}>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              style={{
                position: "absolute",
                right: "0%",
                top: "0%",
                color: "black !important",
                backgroundColor: "white !important",
                border: "none !important",
              }}
            >
              <CloseIcon />
            </IconButton>
            <div className="deal-tag-cnt"></div>
            <div className="deal-content">
              <img src={deal?.image} alt="" />
              <div className="discount">
                <div
                  style={{ marginTop: "15px" }}
                  className="event-description"
                  dangerouslySetInnerHTML={{ __html: deal?.desc }}
                />
              </div>
              {userRuns < deal?.redeemrun && (
                <p className="runs-required">
                  {"You need "}
                  <span className="highlight">{runsReq}</span>
                  {" more runs to redeem this deal."}
                </p>
              )}

              <div className="code-block">
                <Box sx={style}>
                  <Typography sx={{ mt: 2, fontWeight: 500 }}>Name</Typography>
                  <Input
                    type="dropdown"
                    sx={{ width: "100%" }}
                    id="name"
                    required
                    defaultValue={userName}
                    readOnly
                  />
                  <Typography sx={{ mt: 2, fontWeight: 500 }}>Email</Typography>
                  <Input
                    type="dropdown"
                    sx={{ width: "100%" }}
                    id="email"
                    required
                    defaultValue={userEmail}
                    readOnly
                  />
                  <Typography sx={{ mt: 2, fontWeight: 500 }}>
                    Mobile*
                  </Typography>
                  <Input
                    type="dropdown"
                    sx={{ width: "100%" }}
                    id="mobile"
                    required
                  />
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={btnDisabled}
                    sx={{
                      borderRadius: 50,
                      width: "200px",
                      marginTop: "10px",
                      backgroundColor: "black",
                      width: "100%",
                    }}
                    className={`${btnDisabled ? "btn" : ""}`}
                  >
                    Submit
                  </Button>
                </Box>
              </div>
            </div>

            <div className="deal-footer">
              <p>
                <span
                  className="highlight"
                  style={{ fontWeight: "600", color: "#ff8700" }}
                >{`Note: `}</span>
                {`Deals will be only successfully redeemed if you enter a
              valid email id and phone number. `}
                <span style={{ fontWeight: "700" }}>
                  {`Incorrect data will lead to you
              not receiving your deals. `}
                </span>
                {`Also, if you use one mobile number for
              multiple accounts, all accounts except the first one will be
              suspended. `}
              </p>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default DealModal;
