import { useEffect, useState, useRef, useCallback, useContext } from "react";
import "./index.css";
import axios from "axios";
import config from "../../config";
import { getStorageItem } from "../../utils/sessionStorage";
import defaultAvatar from "../../assets/images/profile/default_avatar.png";
import DropdownMenu from "./../dropdown-menu/dropdownMenu";
import ReactTimeAgo from "react-time-ago";
// Mui
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Paper, Card, CardContent, Grid, Typography, Box, Modal, Button, Divider } from "@mui/material";
import { Link } from "react-router-dom";
// React-Icons
import { BsExclamationSquareFill, BsThreeDotsVertical } from "react-icons/bs";
import pollVoterListScroller from "../../components/poll/votedListUseScroller";
import Voterpagination from "../../components/poll/voterpagination";
// moment
import moment from "moment/moment";
import { BallTriangle } from "react-loader-spinner";

// Mui
import { toast } from "react-toastify";
import * as React from 'react';
const baseURL = config.ROOTURL.prod;
const userID = getStorageItem("user_id");
const accessToken = getStorageItem("token");
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: '500px',
  width: '400px',
  // left: '0px',
  // top: '0px',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  borderRadius: '12px',
  p: 4,

};
const Poll = (props) => {

  const { onEnterViewport } = props
  const [clicked, setClicked] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [postObj, setPostObj] = useState(props.post);
  const handleClose = () => setOpen(false);
  const [allPolls, setAllPolls] = useState([]);
  const [selected, setSelected] = useState([]);
  const [votedUser, setVotedUser] = useState([]);
  const [endPolls, setEndPolls] = useState(false);
  const [voted, setVoted] = useState(false);
  const [query, setQuery] = useState("");
  const [pageNum, setPageNum] = useState(1);
  const [pId, setPId] = useState("");
  const [optionId, setOptionId] = useState("");
  const [startDate, setStartDate] = useState(return1SecondPlusDate());
  const [pollQuestion, setPollQuestion] = useState('');
  const pollRef = useRef(null);

  const handleOpen = (pollId, optionId) => {
    !postObj.pinpoll ? setPId(postObj.feed_id) : setPId(postObj._id);
    setOptionId(optionId)
    setOpen(true);

  }

  const handlePoll = (pollId) => {
    setClicked(true);
    const data = selected.find((poll) => {
      return poll.pollId === pollId;
    });
    const optionId = data.optionId;
    axios
      .post(
        `${baseURL}/poll/votePoll`,
        { pollId, optionId },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        }
      )
      .then((response) => {
        // console.log("this is response data:", response.data);
        if (response.data !== "voted") {
          if (!postObj.pinpoll) {

            response.data.feed_id = response.data._id
          }
          setPostObj(response.data);
          // toast.error("Poll submitted already");
          // setVoted(true)
        }
        // getByPollId(pollId);

        toast.success("Poll submitted successfully");
      })
      .catch((error) => {
        toast.error(error);
      });

  };

  const endPollApi = (pollId) => {

    axios
      .get(
        `${baseURL}/poll/endPoll/${pollId}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        }
      )
      .then((response) => {
        if (!postObj.pinpoll) {
          response.data.feed_id = response.data._id
        }
        setPostObj(response.data);
        // console.log("this is response data:", response.data);
        // if (data.endPoll === true) {
        //   setEndPolls(true)

        // }

        // getByPollId(pollId);
      })
      .catch((error) => {
        toast.error(error);
      });

  };

  const calculateDiff = (createdDate, noOfActiveHours, pollId) => {
    const today = moment();
    const upcomingDate = moment(createdDate).add(noOfActiveHours, "hours");
    const duration = moment.duration(upcomingDate.diff(today));

    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();

    if (hours > 0) {
      const hourText = hours === 1 ? "hr" : "hrs";
      const minText = minutes > 0 ? ` ${minutes} min` : "";
      return `${hours} ${hourText}${minText} left`;
    } else {
      if (minutes <= 0) {
        endPollApi(pollId);
        return "Final Result";
      } else {
        return `${minutes} min left`;
      }
    }
  };


  // const calculateDiff = (createdDate, noOfActiveDay, pollId) => {
  //   let today = moment();
  //   let upcomingDate = moment(createdDate).add(noOfActiveDay, "days");
  //   let difference = upcomingDate.diff(today);
  //   let duration = moment.duration(difference);
  //   let days = Math.floor(duration.asDays());
  //   let hours = duration.hours();
  //   let minutes = duration.minutes();
  //   if (days > 0) {
  //     return days + " days and " + hours + " hr left";
  //   } else {
  //     if (hours < 1 && minutes > 0) {
  //       return minutes + "minutes left";
  //     }
  //     else if (minutes <= 0) {
  //       endPoll(pollId);
  //       return "Final Result";
  //     } else {
  //       return hours + " hr left";
  //     }
  //   }
  // };
  function replaceHashtags(description) {
    // Use a regular expression to match hashtags that are not already anchor tags
    const regex = /(?!<a[^>]*>)(#(\w+))(?![^<]*<\/a>)/g;
    // Replace hashtags with anchor tags
    // console.log({ postObj });
    if (description) return description?.replace(regex, '<a class="hashtagged" href="/hashtags?hashtag=$2">$1</a>');
  }

  const getByPollId = async (pollId) => {
    try {
      const response = await axios.get(`${baseURL}/poll/getPollByPollId/${pollId}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        });
      setPostObj(response.data);
    } catch (error) { console.log(error); }
  };
  useEffect(() => {
    if (props?.postquestion) {
      setPollQuestion(replaceHashtags(props?.postquestion))

    }
  }, []);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          console.log(`Post ${postObj?.feed_id} is intersecting: ${entry.isIntersecting}`);

          if (entry.isIntersecting) {
            onEnterViewport(postObj?.feed_id);
            observer.unobserve(entry.target);
          }
        });
      },
      { root: null, rootMargin: '0px', threshold: 0.5 }
    );

    if (pollRef.current) {
      observer.observe(pollRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [postObj?.feed_id]);
  console.log(" postObj.userId === userID", postObj.userId === userID)
  return (
    <div className="poll__main" ref={pollRef}>
      {

        postObj.userId === userID ?
          <div key={!postObj.pinpoll ? postObj.feed_id : postObj._id} className="poll__wrapper">
            <div className="poll__user">
              <img
                src={postObj.profilePhoto ? postObj.profilePhoto : defaultAvatar}
                alt="user"
              />
              <div className="user__details">
                <Link to={`/profile/${postObj.userId}`} style={{ textDecoration: 'none' }}> <h3 style={{
                  fontWeight: "600",
                  fontSize: "16px",
                  lineHeight: "24px",
                  letterSpacing: "0.1px",
                  color: "#0c5ba0"
                }}>{postObj.userName}</h3></Link>
                <p >
                  {postObj?.createdDate && (
                    <ReactTimeAgo date={Date.parse(postObj?.createdDate)} locale="en-US" />
                  )}
                </p>
              </div>
              <div style={{ textAlign: "right" }}>
                <div className="post-edit">
                  <DropdownMenu
                    type="post-menu"
                    postId={!postObj.pinpoll ? postObj.feed_id : postObj._id}
                    userId={postObj?.userId}
                    setPostObj={setPostObj}
                    postObj={postObj}
                    pageLoad={props.hprops}
                    setPollQuestion={setPollQuestion}
                  />
                </div>
              </div>
            </div>
            <p className="poll__question"
              dangerouslySetInnerHTML={{
                __html: pollQuestion
              }}
            />
            <div className="poll__header">
              <Button >{postObj.totalVotes} Voted</Button>
              {!postObj.endPoll ? <p className="poll__time--remaining">
                {/* 3 hour left */}
                {calculateDiff(postObj.createdDate, postObj.noOfActiveDay, !postObj.pinpoll ? postObj.feed_id : postObj._id)}
              </p> : <p className="poll__time--remaining">
                {/* 3 hour left */}
                Final Result
              </p>}
            </div>
            <section className="radio__section">
              <div className="poll__questions">
                {postObj.options.map((option, index) => {
                  return (
                    <div
                      className="question__percentage"
                      key={index}
                    >
                      <p
                        className="percent1"
                        style={{
                          width: `${(option.votes / postObj.totalVotes) * 100}%`,
                        }}
                      ></p>
                      <p className="question__label__percent1">
                        {option.text}
                      </p>
                      <p className="question__votes__count1" onClick={() => { handleOpen(!postObj.pinpoll ? postObj.feed_id : postObj._id, option._id) }}>
                        {option.votes} people
                      </p>
                    </div>
                  );
                })}
              </div>

            </section>
            {(!postObj.endPoll) && <button
              className="button--vote"
              onClick={() => {
                endPollApi(!postObj.pinpoll ? postObj.feed_id : postObj._id);
              }}
            >
              End Poll
            </button>}
          </div> :
          postObj.endPoll ?
            <div key={!postObj.pinpoll ? postObj.feed_id : postObj._id} className="poll__wrapper">
              <div className="poll__user">
                <img
                  src={postObj.profilePhoto ? postObj.profilePhoto : defaultAvatar}
                  alt="user"
                />
                <div className="user__details">
                  <Link to={`/profile/${postObj.userId}`} style={{ textDecoration: 'none' }}> <h3 style={{
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "24px",
                    letterSpacing: "0.1px",
                    color: "#0c5ba0"
                  }}>{postObj.userName}</h3></Link>
                  <p >
                    {postObj?.createdDate && (
                      <ReactTimeAgo date={Date.parse(postObj?.createdDate)} locale="en-US" />
                    )}
                  </p>
                </div>
                {/* <ButtonBase>
                <div className="poll__more__wrapper">
                  <BsThreeDotsVertical className="poll__more" />
                </div>
              </ButtonBase> */}
              </div>
              <p className="poll__question"
                dangerouslySetInnerHTML={{
                  __html: pollQuestion
                }} />


              <div className="poll__header">
                <Button >{postObj.totalVotes} Voted</Button>
                <p className="poll__time--remaining">
                  Final Result
                </p>
              </div>
              <section className="radio__section">

                <div className="poll__questions">
                  {postObj.options.map((option, index) => {
                    return (

                      <div
                        className=
                        {option.votedUserIds.filter(e => e.userId === userID).length > 0 ? "question__percentage__selected" : "question__percentage"}
                        key={index}
                      >
                        <p
                          className="percent1"
                          style={{
                            width: `${(option.votes / postObj.totalVotes) * 100}%`,
                          }}
                        ></p>
                        {/* <p className="question__label__percent">
                        {option.text}
                      </p> */}
                        <p className="question__label__percent1">
                          {option.text}
                        </p>
                        <p className="question__votes__count1" onClick={() => { handleOpen(!postObj.pinpoll ? postObj.feed_id : postObj._id, option._id) }}>
                          {option.votes} people
                        </p>
                      </div>

                    );
                  })}
                </div>

              </section>
              {/* <button
                className="button--endvote">
                Final Results
              </button> */}
            </div>
            : <div key={!postObj.pinpoll ? postObj.feed_id : postObj._id} className="poll__wrapper">
              <div className="poll__user">
                <img
                  src={postObj.profilePhoto ? postObj.profilePhoto : defaultAvatar}
                  alt="user"
                />
                <div className="user__details">
                  <Link to={`/profile/${postObj.userId}`} style={{ textDecoration: 'none' }}> <h3 style={{
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "24px",
                    letterSpacing: "0.1px",
                    color: "#1a1919"
                  }}>{postObj.userName}</h3></Link>

                  <p >
                    {postObj?.createdDate && (
                      <ReactTimeAgo date={Date.parse(postObj?.createdDate)} locale="en-US" />
                    )}
                    <br />
                  </p>
                </div>
                {/* <ButtonBase>
              <div className="poll__more__wrapper">
                <BsThreeDotsVertical className="poll__more"  />
              </div>
            </ButtonBase> */}
              </div>
              <p className="poll__question"
                dangerouslySetInnerHTML={{
                  __html: pollQuestion
                }}
              />

              <div className="poll__header">
                <p className="poll__votes">{postObj.totalVotes} Voted</p>
                {!postObj.endPoll ? <p className="poll__time--remaining">
                  {/* 3 hour left */}
                  {calculateDiff(postObj.createdDate, postObj.noOfActiveDay, !postObj.pinpoll ? postObj.feed_id : postObj._id)}
                </p> : <p className="poll__time--remaining">
                  {/* 3 hour left */}
                  Final Result
                </p>}
              </div>
              <section className="radio__section">
                {postObj.options.find((option) => {
                  return option.votedUserIds.find((id) => {
                    return id.userId === userID;
                  });
                }) ? (
                  <div className="poll__questions">
                    {postObj.options.map((option, index) => {
                      return (

                        <div
                          className={
                            option.votedUserIds.filter(e => e.userId === userID).length > 0
                              ? "question__percentage__selected"
                              : "question__percentage"
                          }
                          key={index}
                        >
                          <p
                            className="percent1"
                            style={{
                              width: `${(option.votes / postObj.totalVotes) * 100}%`,
                            }}
                          ></p>
                          <p className="question__label__percent1">
                            {option.text}
                          </p>
                          <p className="question__votes__count1" onClick={() => { handleOpen(!postObj.pinpoll ? postObj.feed_id : postObj._id, option._id) }}>
                            {option.votes} people
                          </p>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="radio__list">
                    {postObj.options.map((option) => {
                      return (
                        <div key={option._id} className="radio__item">
                          <input
                            type="radio"
                            name={!postObj.pinpoll ? postObj.feed_id : postObj._id}
                            id={option._id}
                            onChange={() => {
                              setSelected([
                                ...selected.filter((i) => {
                                  return i.pollId !== !postObj.pinpoll ? postObj.feed_id : postObj._id;
                                }),
                                {
                                  pollId: !postObj.pinpoll ? postObj.feed_id : postObj._id,
                                  optionId: option._id,
                                },
                              ]);
                            }}
                          />
                          <label htmlFor={option._id}>
                            {/* Choice 1 */}
                            {option.text}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                )}
              </section>
              {postObj.options.find((option) => {
                return option.votedUserIds.find((id) => {
                  return id.userId === userID;
                });
              }) ? <></> : <button type="submit"
                className="button--vote" disabled={clicked}
                onClick={() => {
                  handlePoll(!postObj.pinpoll ? postObj.feed_id : postObj._id);
                }}
              >
                Vote
              </button>}
            </div>

      }


      {open && <Voterpagination open={open} pollId={pId} optionId={optionId} handleClose={handleClose} />}
    </div>
  );
};
const return1SecondPlusDate = () => {
  let t = new Date();
  // console.log('ttt1',t);
  t.setSeconds(t.getSeconds() + 100);
  // console.log('ttt2',t);
  return t;
}
export default Poll;
