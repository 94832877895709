import React, { useState, useEffect, useRef } from "react";
import champLogo from "../../assets/images/icons/storyIcon.png";
import axios from "axios";
import {
  Modal,
  IconButton,
  Box,
  LinearProgress,
  Typography,
  Avatar,
} from "@mui/material";
import {
  PlayCircleFilled as PlayCircleFilledIcon,
  PauseCircleFilled as PauseCircleFilledIcon,
  VolumeUp as VolumeUpIcon,
  VolumeOff as VolumeOffIcon,
  ArrowBackIos as ArrowBackIosIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import { getStorageItem } from "../../utils/sessionStorage";
import "./index.scss";

import clickopennewball from "../../assets/images/Buttons.png";
import run5 from "../../assets/images/icons/5 Run.png";

const textStyle = {
  fontFamily: "Inter",
  fontSize: "14px",
  fontWeight: "500",
  lineHeight: "14px",
  color: "#FFFFFF",
  marginRight: "10px",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  width: "100%",
  backgroundColor: "#141414",
};

const ViewStories = ({ openModal, setOpenModal, videoIndex, storyData }) => {
  const [isPlaying, setIsPlaying] = useState(true);
  const [isMuted, setIsMuted] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(videoIndex);
  const [currentStoryIndex, setCurrentStoryIndex] = useState(0);
  const [progress, setProgress] = useState(0);
  const storyRef = useRef(null);
  const [media, setMedia] = useState("");
  const [views, setViews] = useState([]);
  const [giveRun, setGiveRun] = useState(false);
  const [isImage, setIsImage] = useState(false);
  const accessToken = getStorageItem("token");
  const storyId = storyData[currentIndex].stories[currentStoryIndex]._id;
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (openModal) {
      setIsLoading(true);
      setError(null);
      setCurrentIndex(videoIndex);
      setCurrentStoryIndex(0);
      setIsPlaying(true);
      if (storyData && storyData[videoIndex] && storyData[videoIndex].stories) {
        setProgress(new Array(storyData[videoIndex].stories.length).fill(0));
        getStoryViews();
        getStoryRuns();
      } else {
        setError("Story data is not available");
      }
      setIsLoading(false);
    }
  }, [openModal, videoIndex]);

  useEffect(() => {
    if (
      storyData &&
      storyData[currentIndex] &&
      storyData[currentIndex].stories
    ) {
      const currentMedia =
        storyData[currentIndex].stories[currentStoryIndex]?.media || "";
      setMedia(currentMedia);
      setIsImage(!isVideo(currentMedia));
      getStoryRuns()
    }
  }, [currentIndex, currentStoryIndex, storyData]);

  useEffect(() => {
    let timer;
    if (isImage && isPlaying) {
      timer = setTimeout(() => {
        slideNext();
      }, 10000); // 10 seconds
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [isImage, isPlaying, currentIndex, currentStoryIndex]);

  useEffect(() => {
    if (storyRef.current) {
      if (isPlaying) {
        storyRef.current.play();
      } else {
        storyRef.current.pause();
      }
    }
  }, [isPlaying]);

  const slideNext = () => {
    if (
      storyData &&
      storyData[currentIndex] &&
      storyData[currentIndex].stories
    ) {
      if (currentStoryIndex < storyData[currentIndex].stories.length - 1) {
        setCurrentStoryIndex((prevIndex) => prevIndex + 1);
      } else {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % storyData.length);
        setCurrentStoryIndex(0);
      }

  
        setGiveRun(false);
      
      setProgress(new Array(storyData[currentIndex].stories.length).fill(0));
      if (isImage) {
        setProgress((prevProgress) => {
          const newProgress = [...prevProgress];
          newProgress[currentStoryIndex] = 100;
          return newProgress;
        });
      }
    }
  };

  const slidePrev = () => {
    if (
      storyData &&
      storyData[currentIndex] &&
      storyData[currentIndex].stories
    ) {
      if (currentStoryIndex > 0) {
        setCurrentStoryIndex((prevIndex) => prevIndex - 1);
      } else {
        const prevIndex =
          currentIndex === 0 ? storyData.length - 1 : currentIndex - 1;
        setCurrentIndex(prevIndex);
        setCurrentStoryIndex(storyData[prevIndex].stories.length - 1);
      }

        setGiveRun(false);
   

      setProgress(new Array(storyData[currentIndex].stories.length).fill(0));
    }
  };

  const togglePlayPause = () => {
    if (isVideo(media)) {
      if (storyRef.current) {
        if (isPlaying) {
          storyRef.current.pause();
        } else {
          storyRef.current.play();
        }
      }
    }
    setIsPlaying(!isPlaying);
  };

  const toggleMute = () => {
    if (storyRef.current) {
      storyRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (isVideo(media) && storyRef.current) {
        const percent =
          (storyRef.current.currentTime / storyRef.current.duration) * 100;
        setProgress((prevProgress) => {
          const newProgress = [...prevProgress];
          newProgress[currentStoryIndex] = percent;
          return newProgress;
        });
      } else if (isImage && isPlaying) {
        setProgress((prevProgress) => {
          const newProgress = [...prevProgress];
          newProgress[currentStoryIndex] = Math.min(
            (newProgress[currentStoryIndex] || 0) + 1,
            100
          );
          return newProgress;
        });
      }
    }, 100);
    getStoryRuns()

    return () => clearInterval(interval);
  }, [currentStoryIndex, isImage, isPlaying, media]);

  const createdDate =
    storyData[currentIndex].stories[currentStoryIndex].createdDate;
  const [timeDifference, setTimeDifference] = useState(null);

  useEffect(() => {
    const timestamp = new Date(createdDate);
    const currentTime = new Date();
    const millisecondsDifference = currentTime - timestamp;

    // Convert milliseconds to seconds
    const secondsDifference = millisecondsDifference / 1000;

    // Calculate minutes, hours, and remaining seconds
    const minutes = Math.floor(secondsDifference / 60);
    const hours = Math.floor(minutes / 60);

    if (hours > 0) {
      setTimeDifference(`${hours} hours`);
    } else if (minutes > 0) {
      setTimeDifference(`${minutes} minutes`);
    } else {
      setTimeDifference(`${Math.floor(secondsDifference)} seconds`);
    }
  }, [createdDate]);

  const prevIndex =
    currentIndex === 0 ? storyData.length - 1 : currentIndex - 1;
  const nextIndex = (currentIndex + 1) % storyData.length;

  const getStoryViews = () => {
    if (!accessToken) return;
    const options = {
      method: "get",
      url: global.config.ROOTURL.prod + `/story/getStoryViews/${storyId}`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };

    axios(options)
      .then(({ data }) => {
        setViews([...data]);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          console.log("Unauthorized:", error);
        } else {
          console.log("Error fetching stories:", error);
        }
      });
  };

  const getStoryRuns = () => {
    if (!accessToken) return;

    const options = {
      method: "get",
      url: global.config.ROOTURL.prod + `/story/getRunGiven/${storyId}`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };

    axios(options)
      .then(({ data }) => {
        console.log("story runs", data);
        if (data.message === "Runs already given") setGiveRun(true);
        else {
          setGiveRun(false);
        }
        // Log the updated state using useEffect or a callback function after the state update
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          console.log("Unauthorized:", error);
        } else {
          console.log("Error fetching stories:", error);
        }
      });
  };

  //check wheteher video is in url
  const isVideo = (url) => {
    const videoExtensions = ["mp4", "webm", "ogg"];
    const extension = url.split(".").pop().toLowerCase();
    return videoExtensions.includes(extension);
  };
  const [imageProgress, setImageProgress] = useState(0);

  const imageTimeoutRef = useRef(null);
  useEffect(() => {
    if (
      !isVideo(media) &&
      storyData &&
      storyData[currentIndex] &&
      storyData[currentIndex].stories
    ) {
      setIsImage(true);
      setImageProgress(0);

      imageTimeoutRef.current = setInterval(() => {
        setImageProgress((prev) => {
          if (prev >= 100) {
            clearInterval(imageTimeoutRef.current);
            setProgress(100);
            slideNext();
            return 100;
          }
          return prev + 1.4286; // Approximately 7 seconds for full progress
        });
      }, 100); // Progress update every 100ms
    } else {
      setIsImage(false);
    }
    return () => {
      clearInterval(imageTimeoutRef.current);
    };
  }, [media, currentIndex, storyData]);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [runModal, setRunModal] = useState(false);
  const handleAvatarClick = (profileData) => {
    setSelectedProfile(profileData);
    setRunModal(true);
  };

  const handleGiveRun = () => {
    try {
      if (!accessToken) return;

      const options = {
        method: "post",
        url: global.config.ROOTURL.prod + `/story/addRunToStory/${storyId}`,
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      };

      axios(options)
        .then(({ data }) => {
          setGiveRun(true);
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            console.log("Unauthorized:", error);
          } else {
            console.log("Error fetching stories:", error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  // Rest of your component render code
  return (
    <Modal
      open={openModal}
      onClose={() => setOpenModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            position: "absolute",
            top: 25,
            left: "8.4rem",
            "@media (max-width: 769px)": {
              display: "none",
            },
          }}
        >
          <Avatar
            src={champLogo}
            alt=""
            sx={{ width: "30px", height: "30px" }}
          />
          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: "23px",
              fontWeight: "500",
              lineHeight: "23px",
              color: "#FFFFFF",
            }}
          >
            CHAMPHUNT
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "4%",
            "@media (max-width: 769px)": {
              gap: "1%",
            },
          }}
        >
          <Box
            sx={{
              width: "197px",
              height: "300px",
              borderRadius: "20px",
              position: "relative",
              backgroundImage:
                "linear-gradient(180deg, rgba(38, 38, 38, 0.8) 0%, rgba(38, 38, 38, 0) 100%, #000000 100%)",
              "@media (max-width: 769px)": {
                display: "none",
              },
            }}
          >
            <video
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                zIndex: 0,
                borderRadius: "20px",
              }}
              src={storyData[prevIndex].stories[0].media}
              loop
              muted
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
                position: "absolute",
                top: "100px",
                left: "60px",
                zIndex: 1,
              }}
            >
              <Avatar
                src={storyData[prevIndex].user?.profilePhoto || champLogo}
                alt=""
                sx={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                }}
              />
              <Typography sx={textStyle}>
                {storyData[prevIndex].user?.firstName}
              </Typography>
              <Typography sx={textStyle}>{timeDifference}</Typography>
            </Box>
          </Box>
          <IconButton
            onClick={slidePrev}
            sx={{
              width: "40px",
              height: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "#494D50",
              borderRadius: "50%",
              "&:hover": {
                background: "#494D50",
              },
              "@media (max-width: 769px)": {
                // display: "none",
                width: "30px",
                height: "30px",
                position: "relative",
                left: "+35px",
                zIndex: "1",
              },
            }}
          >
            <ArrowBackIosIcon />
          </IconButton>
          <Box
            sx={{
              width: "30%",
              height: "93vh",
              position: "relative",
              "@media (max-width: 769px)": {
                width: "100%",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "100px",
                display: "flex",
                flexDirection: "column",
                padding: "15px 15px 0px 15px",
                gap: "25px",
                backgroundImage:
                  "linear-gradient(180deg, rgba(38, 38, 38, 0.8) 0%, rgba(38, 38, 38, 0) 100%)",
              }}
            >
              <Box sx={{ display: "flex", gap: "5px" }}>
                {storyData[currentIndex].stories.map((story, index) => (
                  <LinearProgress
                    key={index}
                    variant="determinate"
                    value={progress[index] || 0}
                    sx={{
                      flexGrow: 1,
                      height: "2px",
                      backgroundColor: "#FFFFFF59",
                      "& .MuiLinearProgress-bar": {
                        backgroundColor:
                          index < currentStoryIndex ? "#FFFFFF" : "#FFFFFF59",
                      },
                    }}
                  />
                ))}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Avatar
                    src={
                      storyData[currentIndex].user?.profilePhoto || champLogo
                    }
                    alt=""
                    sx={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                    }}
                  />
                  <Typography sx={textStyle}>
                    {storyData[currentIndex].user?.firstName || "User"}
                  </Typography>
                  <Typography sx={textStyle}>{timeDifference}</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <IconButton
                    onClick={togglePlayPause}
                    sx={{
                      "@media (max-width: 769px)": {
                        display: "none",
                      },
                    }}
                  >
                    {isPlaying ? (
                      <PauseCircleFilledIcon sx={{ color: "#FFFFFF" }} />
                    ) : (
                      <PlayCircleFilledIcon sx={{ color: "#FFFFFF" }} />
                    )}
                  </IconButton>
                  <IconButton
                    onClick={toggleMute}
                    sx={{
                      "@media (max-width: 769px)": {
                        display: "none",
                      },
                    }}
                  >
                    {isMuted ? (
                      <VolumeOffIcon sx={{ color: "#FFFFFF" }} />
                    ) : (
                      <VolumeUpIcon sx={{ color: "#FFFFFF" }} />
                    )}
                  </IconButton>
                  <IconButton>
                    <CloseIcon
                      onClick={() => setOpenModal(false)}
                      sx={{ color: "#FFFFFF" }}
                    />
                  </IconButton>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                height: `calc(93vh - 100px)`,
              }}
            >
              {isVideo(media) ? (
                <video
                  ref={storyRef}
                  key={media}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  src={media}
                  type="video/mp4"
                  loop
                  autoPlay
                  muted={isMuted}
                  onPlay={() => setIsPlaying(true)}
                  onEnded={() => setProgress(100)}
                />
              ) : (
                <img
                  src={media}
                  alt="Story Content"
                  muted={isMuted}
                  onPlay={() => setIsPlaying(true)}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              )}
            </Box>
            <Box
              sx={{
                position: "absolute",
                bottom: "0",
                width: "100%",
                height: "91px",
                display: "flex",
                left: "0",
                justifyContent: "space-between", // Aligns items at opposite ends of the container
                alignItems: "center",
                padding: "0 15px",
              }}
              className="viewers"
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  width: "50px",
                  height: "32px",
                }}
              ></Box>
              <Typography sx={textStyle}>
                {giveRun ? (
                  <img src={run5} width={50} />
                ) : (
                  <img
                    src={clickopennewball}
                    width={48}
                    style={{ cursor: "pointer" }}
                    onClick={handleGiveRun}
                  />
                )}
              </Typography>
              <Modal
                open={runModal}
                onClose={() => setRunModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box
                  sx={{
                    position: "absolute",
                    width: "498px",
                    height: "520px",
                    top: "140px",
                    left: "540px",
                    borderRadius: "16px",
                    background: "rgba(38, 38, 38, 1)",
                    display: "flex",

                    flexDirection: "column",
                    "@media (max-width: 769px)": {
                      width: "350px",
                    },
                  }}
                >
                  <Box
                    sx={{
                      marginTop: "20px",
                      width: "65px",
                      height: "38px",
                      marginLeft: "40%",
                    }}
                  >
                    <p className="viewers-heading">Viewers</p>
                  </Box>
                  <div className="line-viewer"></div>

                  <Box
                    sx={{
                      marginTop: "10px",
                      padding: "0 20px",
                      overflowY: "auto",
                      maxHeight: "400px",
                    }}
                  >
                    {views.map((view, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Avatar
                          src={view.profilePhoto}
                          alt=""
                          sx={{ width: "50px", height: "50px", marginRight: 2 }}
                        />
                        <Box>
                          <Typography variant="body1" className="firstName">
                            {view.firstName}
                          </Typography>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                  <IconButton
                    onClick={() => setOpenModal(false)}
                    sx={{ position: "absolute", top: 8, right: 8 }}
                  >
                    <CloseIcon sx={{ color: "#ffffff" }} />
                  </IconButton>
                </Box>
              </Modal>
            </Box>
          </Box>
          <IconButton
            onClick={slideNext}
            sx={{
              width: "40px",
              height: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "#494D50",
              borderRadius: "50%",
              "&:hover": {
                background: "#494D50",
              },
              "@media (max-width: 769px)": {
                // display: "none",
                width: "30px",
                height: "30px",
                position: "relative",
                left: "-35px",
                zIndex: "1",
              },
            }}
          >
            <ArrowForwardIosIcon />
          </IconButton>
          <Box
            sx={{
              width: "197px",
              height: "300px",
              borderRadius: "20px",
              position: "relative",
              backgroundImage:
                "linear-gradient(180deg, rgba(38, 38, 38, 0.8) 0%, rgba(38, 38, 38, 0) 100%, #000000 100%)",
              "@media (max-width: 769px)": {
                display: "none",
              },
            }}
          >
            <video
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                zIndex: 0,
                borderRadius: "20px",
              }}
              src={storyData[nextIndex].stories[0].media}
              loop
              muted
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
                position: "absolute",
                top: "100px",
                left: "60px",
                zIndex: 1,
              }}
            >
              <Avatar
                src={storyData[nextIndex].user?.profilePhoto || champLogo}
                alt=""
                sx={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                }}
              />
              <Typography sx={textStyle}>
                {storyData[nextIndex].user.firstName}
              </Typography>
              <Typography sx={textStyle}>{timeDifference}</Typography>
            </Box>
          </Box>
        </Box>
        <IconButton
          onClick={() => setOpenModal(false)}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            "@media (max-width: 769px)": {
              display: "none",
            },
          }}
        >
          <CloseIcon sx={{ color: "#FFFFFF" }} />
        </IconButton>
      </Box>
    </Modal>
  );
};

export default ViewStories;
