import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import { ReactComponent as PitchIcon } from "../../assets/images/footer/pitch-icon.svg";
import { ReactComponent as PostIcon } from "../../assets/images/footer/post-icon.svg";
import { ReactComponent as DealIcon } from "../../assets/images/footer/deal-icon.svg";
import { ReactComponent as Bell } from "../../assets/images/icons/Notification.svg";
import { ReactComponent as Rewardicon } from "../../assets/images/footer/reward.svg";
import { ReactComponent as NewBell } from "../../assets/images/icons/Notification_unread.svg";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import HeaderMenu from "../header-menu";
import "./index.scss";
import Drawer from "../side-drawer/Drawer";
import { ReactComponent as Friend } from '../../assets/images/home/friend.svg';
import InviteModal from '../../commons/invitation';
import PostModal from "../../commons/components/post-modal/PostModal";
import { getStorageItem } from '../../utils/sessionStorage';
import axios from 'axios';
import defaultAvatar from "../../assets/images/profile/default_avatar.png";
import { useSelector } from "react-redux";

const Footer = (props) => {
  const [value, setValue] = React.useState('pitch');
  const [activeNotificationsCount, setActiveNotificationsCount] = useState(0);
  const ref = React.useRef(null);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => { setOpen(false); };
  const [userInfo, setUserInfo] = useState("");
  const [showModal, setShowModal] = React.useState(false);
  const accessToken = getStorageItem("token");
  const userId = getStorageItem("user_id");
  const path = useSelector((state) => state.path.path);

  const handleShowModalOpen = () => {
    setShowModal(true);
  }
  const handleShowModalClose = () => {
    setShowModal(false);
  };

  const styles = {
    root: {
      color: "black",
    },
    selected: {
      color: "white",
    },
  };


  const fetchUserInfo = () => {
    if (!userId) return;
    const getUserInfo = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/auth/profile/",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      data: {
        userId: userId,
      },
    };
    axios(getUserInfo)
      .then((response) =>
      {
        setUserInfo({
          firstName: response.data.firstName ? response.data.firstName : "",
          lastName: response.data.lastName ? response.data.lastName : "",
          avatar: response.data.profilePhoto
            ? response.data.profilePhoto
            : defaultAvatar,followersCount:response.data.followersCount?response.data.followersCount:0,
            totalRun: response.data.totalRun?response.data.totalRun:0,
        })
      }
       
      )
      .catch((error) => {
        console.log(error);
      });
  };
  

  useEffect(() => {
    fetchUserInfo();
    if (props.setURL) {
      props.setURL(window.location.pathname);
    }
    return () => {
      setUserInfo("")
    }

  }, []);



  useEffect(() => {


    if (path === '/pitch') {
      setValue('pitch')
    } else if (path === '/rewards') {
      setValue('rewards')
    } else if (path === '/deals') {
      setValue('Deals')
    } else {
      setValue(null)
    }
    return () => {
      setValue("")
    }

  }, [path])






  // useEffect(() => {
  //   global.config.socketInstance.on('onNewNotification', async (updatedValue) => {
  //       try {
  //           if (updatedValue.userId === userId) {
  //               //....
  //               setActiveNotificationsCount(activeNotificationsCount + 1);
  //           }
  //       } catch (err) {
  //           console.log('error on run change', err);
  //       }
  //   });
  // }, []);


  return (
    <div>


      <Box sx={{ pb: 7 }} ref={ref} className="footer">
        <CssBaseline />
        <Paper
          sx={{ position: "fixed", bottom: 0, left: 0, right: 0, color: "black" }}
          elevation={3}
        >
          <BottomNavigation
            showLabels
            value={value}
            // onChange={handleChange}
            sx={{
              justifyContent: "normal",
              overflow: "hidden",
              "& .MuiBottomNavigationAction-root, svg path": {
                fill: "grey",
              },
              "& .Mui-selected, .Mui-selected svg path": {
                color: "#0c5ba0" || "#25294a",
                fill: "#0c5ba0" || "#25294a",
              },
            }}
          >
            <BottomNavigationAction
              label="Pitch"
              value='pitch'
              component={Link}
              to="/pitch"
              icon={<PitchIcon style={{ height: "25px", width: "25px" }} />}

            ></BottomNavigationAction>

            <BottomNavigationAction
              label="Arena"
              value='rewards'
              component={Link}
              to="/rewards"
              icon={<Rewardicon style={{ height: "25px", width: "25px" }} />}
            />
            {/* {props.handlePostClick && ( */}
            <BottomNavigationAction
              label="Post"
              to="/deals"
              // component={Button}
              onClick={() => {
                handleOpen();
              }}
              icon={<PostIcon style={{ height: "25px", width: "25px" }} />}
            >
              {/* <NavLink /> */}
            </BottomNavigationAction>

            <BottomNavigationAction
              label="Deals"
              value='Deals'
              component={Link}
              to="/deals"
              icon={activeNotificationsCount > 0 ?
                <>
                  <div className="forfooterDor"></div>
                  <DealIcon style={{ height: "20px", width: "20px" }} />
                </>
                :
                <DealIcon style={{ height: "20px", width: "20px" }} />
              }

            />
            <Box sx={{ padding: "6px 12px 8px", maxWidth: "168px", flex: "1", display: "inline-flex", justifyContent: "center" }}>
              <Drawer userInfo={userInfo} ></Drawer>
              <InviteModal
                open={showModal}
                handleClose={handleShowModalClose}
                handleOpen={handleShowModalOpen}
              />
            </Box>
          </BottomNavigation>
        </Paper>
      </Box>
      <PostModal
        handleOpen={handleOpen}
        handleClose={handleClose}
        open={open}
      />
    </div>
  );
};

export default Footer;
