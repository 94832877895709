import { Box, Typography } from "@material-ui/core";
import React from "react";
import CarouselScroll from "../../components/manage-carousel/carouselScroll"

const LandingPageCarousel = () => {
  return (
    <Box>
      <Typography variant="h4">Carousel</Typography>
      <Box marginTop={2}> <CarouselScroll type="LandingPageCarousel"/></Box>
    </Box>
  );
};
export default LandingPageCarousel;

