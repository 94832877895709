
import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PredictionPlayNow from '../../PredictionMobile/PredictionGameStart/PredictionPlayNow';
import { useSelector } from 'react-redux';




export default function PlayNowPopup(props) {
    const mobileView = useMediaQuery("(max-width:600px)");

    const navigate = useNavigate();
    const [open, setOpen] = React.useState(props.openPlayNow);
    const handleClose = () => {
        props.handleClosePlayNow(false)
    };
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='iamfirstchild'>
                    <PredictionPlayNow handleclosePopup={handleClose} />
                </div>
            </Modal>
        </div>
    );
}
