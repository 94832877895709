import { Box, Input, Typography } from "@material-ui/core";
import { Grid } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
// import EventCreationForm from "../../components/event-creation-form";

import { Helmet } from "react-helmet";
import UserSearch from "../../components/search";
import { getStorageItem } from "../../utils/sessionStorage";

const FindUser = () => {
  const [searchText, setSearchText] = useState("");
  const fetchData = (pn, ps) => {
    if (searchText)
      return axios({
        method: "POST",
        url: global.config.ROOTURL.prod + "/auth/name-search",
        headers: {
          Authorization: "Bearer " + getStorageItem("token"),
          "Content-Type": "application/json",
        },
        data: {
          searchText: searchText,
          pageNumber: pn,
          pagePerSize: ps,
        },
      })
        .then((response) => {
          console.log({ response });
          return response?.data;
        })
        .catch((err) => {
          // setState({ ...state, pageNum: 0, error: err })
          // setIsFetching(false)
          console.log("error", err);
        });
  };

  // const fetchData = async (pn, ps, st) => {
  //   // console.log('inside fetchMoreListItems', pn, li.length, ps, st)
  //   await console.log("searchResult", getSearchResults());
  //   if (searchText)
  //     return getSearchResults(
  //       "POST",
  //       global.config.ROOTURL.prod + "/auth/name-search",
  //       {
  //         searchText: searchText,
  //         pageNumber: pn,
  //         pagePerSize: ps,
  //       }
  //     );
  // };

  return (
    <div>
      <Helmet>
        <title>Champhunt | Give-runs</title>
      </Helmet>

      <Box>
        <Grid container spacing={0} className="search_page">
          <Grid item xs={12} sm={6} md={6} style={{ margin: "auto" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: { xs: "center", sm: "left" },
              }}
            >
              <Typography variant="h4">Give runs to users</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6} style={{ margin: "auto" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: { xs: "center", sm: "right" },
              }}
            >
              <Input
                placeholder="Search user"
                onChange={(e) => {
                  console.log(e);
                  setSearchText(e.target.value);
                }}
                value={searchText}
              />
            </Box>
          </Grid>
        </Grid>
        <Box marginTop={2}>
          <UserSearch fetchData={fetchData} searchText={searchText} />
        </Box>
      </Box>
    </div>
  );
};

export default FindUser;
