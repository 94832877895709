import React, { useEffect } from "react";
import "./index.scss";
import CartItem from "../cart/CartItem";
import sort from "../../assets/images/marketplace/sort.png";
import { useState } from "react";
import StatusModal from "../statusModal/StatusModal";
import { useNavigate, useParams } from "react-router-dom";
import { getStorageItem } from "../../utils/sessionStorage";
import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
export default function OrderStatus() {
  const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  const [whichopen, setwhichopen] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const userId = getStorageItem("user_id");
  const userEmail = getStorageItem("user_email");
  const navigate = useNavigate();

  // Access Token
  const accessToken = getStorageItem("token");

  // Function to fetch product
  const fetchProduct = async (order) => {
    try {
      const response = await fetch(
        global.config.ROOTURL.prod +
        `/marketplace/product/getSingleProductByProductId/${order?.productId}`,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/json",
          },
        }
      );
      const product = await response.json();
      return {
        orderInfo: order,
        productInfo: product,
      };
    } catch (err) {
      console.log(err);
    }
  };

  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const handleSubmitEdit = async (orderId) => {
    if (!orderId && !accessToken) return
    try {
      const response = await fetch(
        global.config.ROOTURL.prod +
        `/marketplace/buyer/changeCurrentStatusOfAnOrderByOrderId/${orderId}`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            currentState: selectedValue,
          }),
        }
      );
      const data = await response.json();
      if (data) {
        toast.success("Order edited successfully");
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const fetchOrders = async (id) => {
    if (!id && !accessToken) return
    try {
      console.log(id);
      const response = await fetch(
        global.config.ROOTURL.prod +
        `/marketplace/buyer/allPurchasedProductsBySellerId/${id}`,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      const productPromises = data.map(async (order) => {
        const obj = await fetchProduct(order);
        return obj;
      });

      // Wait for all promises to resolve
      const resolvedProducts = await Promise.all(productPromises);
      setOrders(resolvedProducts);
      setFilteredOrders(resolvedProducts);
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchOrders(userId);
    }
  }, [userId]);

  const [selectedValue, setSelectedValue] = useState("");

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <div className="seller_order_main_conatiner">
      <div>
        <p>Seller Orders</p>
        {filteredOrders.length > 0 ? (
          <div className="seller-orders__wrapper">
            <h1 className="seller-orders--heading-main">Seller Orders</h1>
            <div className="seller-order__grid">
              {filteredOrders.map((doc, index) => {
                return (
                  <div key={index} className="seller-order__card-main">
                    <div className="seller-order__card--head">
                      {/* <h1 className="seller-order__card-title">
                        {doc?.productInfo?.title}
                      </h1> */}
                      {/* <h1 className="seller-order__card-price">
                        {doc?.productInfo?.price - doc?.productInfo?.discount}
                      </h1> */}
                    </div>
                    <div className="seller_product_image_container">
                      <img
                        src={doc?.productInfo?.images[0]}
                        alt="product"
                        className="seller-order__card-product"
                      />
                    </div>
                    <h1 className="seller-order__card-title">
                      {doc?.productInfo?.title}
                    </h1>
                    <div className="seller-order__card--head">
                      <h1 className="seller-order__card-price">
                        ₹{doc?.productInfo?.price - doc?.productInfo?.discount}
                      </h1>
                    </div>
                    <div className="seller-order__card-details-block--buyerInfo">
                      <p className="order_status_paragraph">Order Status</p>
                      <select
                        value={selectedValue}
                        onChange={handleSelectChange}
                      >
                        <option value="">Select status</option>
                        <option value="placed">placed</option>
                        <option value="dispatched">dispatched</option>
                        <option value="on-its-way">on-its-way</option>
                        <option value="delivered">delivered</option>
                        <option value="cancelled">cancelled</option>
                      </select>
                    </div>
                    <div className="seller-order__card-details">
                      <div className="seller-order__card-details-block--id">
                        <p>Order Id</p>
                        <p className="seller__value">{doc?.orderInfo?._id}</p>
                      </div>
                      <div className="seller-order__card-details-block--buyerInfo">
                        <p>Email</p>
                        <p className="seller__value">{doc?.orderInfo?.buyerEmail}</p>
                      </div>
                      <div className="seller-order__card-details-block--buyerInfo">
                        <p>Line 1</p>
                        <p className="seller__value">{doc?.orderInfo?.addressLine1}</p>
                      </div>
                      <div className="seller-order__card-details-block--buyerInfo">
                        <p>Street</p>
                        <p className="seller__value">{doc?.orderInfo?.street}</p>
                      </div>
                      <div className="seller-order__card-details-block--buyerInfo">
                        <p>City</p>
                        <p className="seller__value">{doc?.orderInfo?.city}</p>
                      </div>
                      <div className="seller-order__card-details-block--buyerInfo">
                        <p>State</p>
                        <p className="seller__value">{doc?.orderInfo?.state}</p>
                      </div>
                      <div className="seller-order__card-details-block--buyerInfo">
                        <p>Postal Code</p>
                        <p className="seller__value">{doc?.orderInfo?.postalCode}</p>
                      </div>
                      <div className="seller-order__card-details-block--buyerInfo">
                        <p>Product Quanity</p>
                        <p className="seller__value">{doc?.orderInfo?.productQuantity}</p>
                      </div>
                      <div className="seller-order__card-details-block--buyerInfo">
                        <p>Purchased Date</p>
                        <p className="seller__value">{moment(doc?.orderInfo?.createdDate)?.calendar()}</p>
                      </div>
                      <div className="seller-order__card-details-block--buyerInfo">
                        <p>Runs Deducted</p>
                        <p className="seller__value">{doc?.orderInfo?.runsDeducted}</p>
                      </div>
                      <div className="seller-order__card-details-block--buyerInfo">
                        <p>Current Status</p>
                        <p className="seller__value">{doc?.orderInfo?.currentState}</p>
                      </div>
                      {/* <div className="seller-order__card-details-block--buyerInfo">
                        <p>Order State</p>
                        <select
                          value={selectedValue}
                          onChange={handleSelectChange}
                        >
                          <option value="">Select status</option>
                          <option value="placed">placed</option>
                          <option value="dispatched">dispatched</option>
                          <option value="on-its-way">on-its-way</option>
                          <option value="delivered">delivered</option>
                          <option value="cancelled">cancelled</option>
                        </select>
                      </div> */}
                      <button
                        className="seller-order__card-details__submit-edit"
                        onClick={() => {
                          if (selectedValue !== "") {
                            handleSubmitEdit(doc?.orderInfo?._id);
                          }
                        }}
                      >
                        Submit Edit
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div className="empty-orders-1">
            <div>
              <h1 className="eo-heading-1">Nothing to show here</h1>
              <button
                className="eo-button-1"
                onClick={() => {
                  navigate("/marketplace");
                }}
              >
                Go back to marketplace
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
