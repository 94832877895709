

import React, { useEffect, useState } from 'react'
import tossball from "../../../../assets/images/rewards/tossball.png"
import Optionanswer from './Optionanswer'
export default function PredictionShowAnswer(props) {
    const dummy = [1, 2, 3, 4]
    // const [data,setdata]=useState(null);
    const [questionresult,setresult]=useState(null);
    

    useEffect(()=>{
        console.log( "question,", props)
        setresult(props?.PasspredictionQuestion)
        // setdata(props?.PasspredictionQuestion)
    },[props.PasspredictionQuestion])

    return (
        
        <div style={{color :'white',paddingTop:'45px'}}>
            <div className='predictiontextAlign predictionFont116 predictionPadding'>
                <p >Prediction</p>
            </div>
            {console.log("data", questionresult?.question)}
            <div className='prediQuestionHeading predictionFont10 predictiontextAlign predictionPadding'>
                <img src={tossball} />
                <p style={{ paddingLeft: '10px' }}>Earn Runs for each correct prediction</p>
            </div>
            <div className='questionPredFlex predictionPadding'>
                <div>
                    <p className="PredictionQuetionNo"> {props.count+1}</p>
                </div>
                <p className='predictionFont112' style={{paddingLeft:'20px'}}>{questionresult?.question}</p>
            </div>
            <div className='predictionPadding'style={{paddingTop:'20px'}}>
                { questionresult?.option.map((i,index) => (
                    <Optionanswer optiondata={i} key={index} wholequestion={questionresult}/>
                ))}
            </div>
        </div>
    )
}