import React from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import NotificationCard from "./notification-card";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

const NotifictionList = (props) => {
  const { list, isViewAllButtonShow } = props;
  const navigate = useNavigate();
  console.log("", list)
  return (
    <div className="cont">
      {/* {console.log(list)} */}
      {list &&
        list.map((notification, index) => (
          <Grid item xs={12} sm={12} lg={12} key={index}>
            <NotificationCard key={index} notification={notification} />
          </Grid>
        ))}
    </div>
  );
};

export default NotifictionList;
