import  React from "react";
import { useEffect, useState} from "react";
import axios from "axios";
import { useMediaQuery, IconButton,Modal, Button, TextField, Typography, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { getStorageItem,setStorageItem } from "../../utils/sessionStorage";
import { useNavigate } from "react-router-dom";


const styleDesktop = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
};

const styleMobile = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
};

export default function UpdateMObileNoModal(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const [style, setStyle] = useState();
  const navigate = useNavigate();
  

    function handleCancel() {
      props.handleClose();
    }


  useEffect(() => {
    if (!matches) {
      setStyle(styleMobile);
    } else {
      setStyle(styleDesktop);
    }
  }, [matches]);

  return (
    <div className='dealsMain-modal-popup'>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
         <Box sx={style} className='deals-content'>
          <Typography  component="h2" className='deals-modal-para' style={{width: "100%", textAlign: "center", fontSize: "14px"}}>
             Your Profile is incomplete.Click the <br /> Edit button to complete it
          </Typography>
        
           <div className='dealsModal-both-btn'>
             <Button className='confirm-btn' onClick={() => { navigate(`/profile_edit`) }}>Edit</Button>  
             <Button className="cancel-btn" onClick={handleCancel}>Cancel</Button>
             
           </div>
           </Box>
      </Modal>
    </div>
  );
}
