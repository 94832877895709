import React, { useState, useEffect } from "react";
import FantasyPlayNowPage from "./FantasyPlayNowPage";
import CskLogo from "../../assets/images/fantasyy/Chennai-Super-Kings-logo- 1.png";
import RrLogo from "../../assets/images/fantasyy/rrlogo.png";
import PbksLogo from "../../assets/images/fantasyy/pbkslogo.png";
import RcbLogo from "../../assets/images/fantasyy/rcb.png";
import LsgLogo from "../../assets/images/fantasyy/loknowlogo.png";
import MiLogo from "../../assets/images/fantasyy/milogo.png";
import GtLogo from "../../assets/images/fantasyy/gujarat_cricket_62e74247-b6a8-4079-959b-1d5d6620552e-removebg-preview 1.png";
import HyLogo from "../../assets/images/fantasyy/hyderabad-ipl-team-logo-removebg-preview 1.png";
import KkrLogo from "../../assets/images/fantasyy/kolkata_cricket_ced9e4c3-b5ce-4610-aed4-f2c297da6c21-removebg-preview 1.png";
import DcLogo from "../../assets/images/fantasyy/dclogo.png";


import AFGAvatar from "../../assets/images/fantasyy/WCavatar/AFG.png"
import AUSAvatar from "../../assets/images/fantasyy/WCavatar/AUS.png"
import BANAvatar from "../../assets/images/fantasyy/WCavatar/BAN.png"
import CANAvatar from "../../assets/images/fantasyy/WCavatar/CAN.png"
import ENGAvatar from "../../assets/images/fantasyy/WCavatar/ENG.png"
import INDAvatar from "../../assets/images/fantasyy/WCavatar/IND.png"
import IREAvatar from "../../assets/images/fantasyy/WCavatar/IRE.png"
import NEPAvatar from "../../assets/images/fantasyy/WCavatar/NEP.png"
import NZAvatar from "../../assets/images/fantasyy/WCavatar/NZ.png"
import PAKAvatar from "../../assets/images/fantasyy/WCavatar/PAK.png"
import SAAvatar from "../../assets/images/fantasyy/WCavatar/SA.png"
import SCOAvatar from "../../assets/images/fantasyy/WCavatar/SCO.png";
import SLAvata from "../../assets/images/fantasyy/WCavatar/SL.png";
import NAMAvatar from "../../assets/images/fantasyy/WCavatar/NAM.png";
import WIAvatar from  "../../assets/images/fantasyy/WCavatar/WI.png";
import UGAAvatar from  "../../assets/images/fantasyy/WCavatar/UGA.png";
import PNGAvatar from  "../../assets/images/fantasyy/WCavatar/PNG.png";
import NETAvatar from  "../../assets/images/fantasyy/WCavatar/NET.png";
import USAAvatar from  "../../assets/images/fantasyy/WCavatar/USA.png";
import OMAAvatar from  "../../assets/images/fantasyy/WCavatar/OMA.png";
import ZIMAvatar from  "../../assets/images/fantasyy/WCavatar/ZIM.png";


import BattleLogo from "../../assets/images/fantasyy/battleipl.png";
import DcAvatar from "../../assets/images/fantasyy/dcavatar.png";
import SrhAvatar from "../../assets/images/fantasyy/srhavatar.png";
import LsgAvatar from "../../assets/images/fantasyy/lsgavatr.png";
import KkrAvatar from "../../assets/images/fantasyy/kkravatr.png";
import GtAvatar from "../../assets/images/fantasyy/gtavatar (2).png";
import RrAvatar from "../../assets/images/fantasyy/rravatr.png";
import PbkAvatar from "../../assets/images/fantasyy/pbk.png";
import RcbAvatar from "../../assets/images/fantasyy/rcbavatar.png";
import CskAvatar from "../../assets/images/fantasyy/cropcsk.png";
import MiAvatar from "../../assets/images/fantasyy/mumbaiimg.png";


import INDLogo from "../../assets/images/fantasyy/IND.png";
import PAKLogo from "../../assets/images/fantasyy/PAK.png";
import AFGLogo from "../../assets/images/fantasyy/AFG.png";
import AUSLogo from "../../assets/images/fantasyy/AUS.png";
import BANLogo from "../../assets/images/fantasyy/BAN.png";
import CANLogo from "../../assets/images/fantasyy/CAN.png";
import ENGLogo from "../../assets/images/fantasyy/ENG.png";
import IRELogo from "../../assets/images/fantasyy/IRE.png";
import NEPLogo from "../../assets/images/fantasyy/NEP.png";
import NetherlandsLogo from "../../assets/images/fantasyy/Netherlands.png";
import NewZealandLogo from "../../assets/images/fantasyy/New_Zealand.png";
import OmanLogo from "../../assets/images/fantasyy/Oman.png";
import PapuaLogo from "../../assets/images/fantasyy/Papua_New_Guinea.png";
import ScotlandLogo from "../../assets/images/fantasyy/Scotland.png";
import SouthAfricaLogo from "../../assets/images/fantasyy/South_Africa.jpg";
import SriLankaLogo from "../../assets/images/fantasyy/Sri_Lanka.png";
import UgandaLogo from "../../assets/images/fantasyy/Uganda.png";
import USAlogo from "../../assets/images/fantasyy/USA.png";
import WIlogo from "../../assets/images/fantasyy/WI.jpg";
import NAMLogo from "../../assets/images/fantasyy/NAM.png";
import ZIMLogo from "../../assets/images/fantasyy/ZIM.png";


import "./_index.scss";
import axios from "axios";
import { getStorageItem } from "../../utils/sessionStorage";
const Index = () => {
  const [liveMatches, setLiveMatches] = useState([]);
 
  const teamNames = ["IND", "PAK", "IRE", "CAN", "USA", "ENG", "AUS", "NAM", "SCO", "OMA", "NZ", "WI", "AFG", "UGA", "PNG", "SA", "SL", "BAN", "NET", "NEP","ZIM"];
  // const teamGradients = {
  //   Chennai: "linear-gradient(180deg, #F7BA07 0%, #EF6116 100%)",
  //   Mumbai: "linear-gradient(180deg, #0276B9 0%, #0C3A83 100%)",
  //   Gujarat: "linear-gradient(180deg, #1D1E22 0%, #0E456C 100%)",
  //   Kolkata: "linear-gradient(180deg, #3D2360 0%, #59298C 100%)",
  //   Lucknow: "linear-gradient(180deg, #A02A4C 0%, #F6C626 100%)",
  //   Punjab: "linear-gradient(180deg, #C72127 0%, #821A1B 100%)",
  //   Rajasthan: "linear-gradient(180deg, #AD2770 0%, #081F41 100%)",
  //   Bangalore: "linear-gradient(98.3deg, rgb(0, 0, 0) 10.6%, rgb(255, 0, 0) 97.7%)",
  //   Delhi: "linear-gradient(180deg, #1665A7 0%, #033C7F 100%)",
  //   Hyderabad: "linear-gradient(180deg, #F06925 0%, #CF3E20 100%)",
  // };
  const teamGradients = {
    IND: "linear-gradient(180deg, #1C1C1C 0%, #87CEEB 100%)",
    PAK: "linear-gradient(180deg, #006400 0%, #228B22 100%)",
    AFG: "linear-gradient(180deg, #008080 0%, #4682B4 100%)",
    AUS: "linear-gradient(180deg, #FFD700 0%, #FF8C00 100%)",
    BAN: "linear-gradient(180deg, #006A4E 0%, #A52A2A 100%)",
    CAN: "linear-gradient(180deg, #FF0000 0%, #FFFFFF 100%)",
    ENG: "linear-gradient(180deg, #002147 0%, #C8102E 100%)",
    IRE: "linear-gradient(180deg, #169B62 0%, #FF883E 100%)",
    NEP: "linear-gradient(180deg, #003366 0%, #DC143C 100%)",
    NET: "linear-gradient(180deg, #21468B 0%, #FF4500 100%)",
    NZ: "linear-gradient(180deg, #000000 0%, #FFFFFF 100%)",
    OMA: "linear-gradient(180deg, #008000 0%, #FF0000 100%)",
    PNG: "linear-gradient(180deg, #FFD700 0%, #FF0000 100%)",
    SCO: "linear-gradient(180deg, #2E0854 0%, #800080 100%)",
    SA: "linear-gradient(180deg, #006341 0%, #FFD700 100%)",
    SL: "linear-gradient(180deg, #0033A0 0%, #FFD700 100%)",
    UGA: "linear-gradient(180deg, #FFCD00 0%, #D21034 100%)",
    USA: "linear-gradient(180deg, #B22234 0%, #3C3B6E 100%)",
    WI: "linear-gradient(180deg, #771900 0%, #FFD700 100%)",
    NAM: "linear-gradient(180deg, #002E63 0%, #A3C1AD 100%)",
    ZIM: "linear-gradient(180deg, #002E63 0%, #A3C1AD 100%)",
  };
  
  // const teamNames = ["csk", "mi", "gt", "kkr", "lsg", "pbks", "rr", "rcb"];
  // const teamLogos = {
  //   Chennai: CskLogo,
  //   Mumbai: MiLogo,
  //   Gujarat: GtLogo,
  //   Kolkata: KkrLogo,
  //   Lucknow: LsgLogo,
  //   Punjab: PbksLogo,
  //   Rajasthan: RrLogo,
  //   Bangalore: RcbLogo,
  //   Delhi: DcLogo,
  //   Hyderabad: HyLogo,
  // }
  const teamLogos = {
    IND: INDLogo,
    PAK: PAKLogo,
    AFG: AFGLogo,
    AUS: AUSLogo,
    BAN: BANLogo,
    CAN: CANLogo,
    ENG: ENGLogo,
    IRE: IRELogo,
    NEP: NEPLogo,
    NET: NetherlandsLogo,
    NZ: NewZealandLogo,
    OMA: OmanLogo,
    PNG: PapuaLogo,
    SCO: ScotlandLogo,
    SA: SouthAfricaLogo,
    SL: SriLankaLogo,
    UGA: UgandaLogo,
    USA: USAlogo,
    WI: WIlogo,
    NAM: NAMLogo,
    ZIM: ZIMLogo,
};
  const teamAvatars = {
    Chennai: CskAvatar,
    Mumbai: MiAvatar,
    Gujarat: GtAvatar,
    Kolkata: KkrAvatar,
    Lucknow: LsgAvatar,
    Punjab: PbkAvatar,
    Rajasthan: RrAvatar,
    Bangalore: RcbAvatar,
    Delhi: DcAvatar,
    Hyderabad: SrhAvatar,
    AFG:AFGAvatar,
    AUS:AUSAvatar,
    BAN:BANAvatar,
    CAN:CANAvatar,
    ENG:ENGAvatar,
    IND:INDAvatar,
    IRE:IREAvatar,
    NEP:NEPAvatar,
    NZ:NZAvatar,
    PAK:PAKAvatar,
    SA:SAAvatar,
    SCO:SCOAvatar,
    SL:SLAvata,
    NAM:NAMAvatar,
    WI:WIAvatar,
    UGA:UGAAvatar,
    PNG:PNGAvatar,
    NET:NETAvatar,
    USA:USAAvatar,
    OMA:OMAAvatar,
    ZIM:ZIMAvatar,
  };
   
  
  const getTeamAvatar = (teamName) => {
    const formattedTeamName = teamName && teamName.trim();
    return teamAvatars[formattedTeamName] || null;
  };

  const getTeamGradient = (teamName) => {
    const formattedTeamName = teamName && teamName.trim();
    return (
      teamGradients[formattedTeamName] ||
      "linear-gradient(to right, #FFFFFF, #FFFFFF)"
    );
  };

  useEffect(() => {
    const fetchLiveMatches = async () => {
      try {
        const accessToken = getStorageItem("token");
        const response = await axios.get(
          global.config.ROOTURL.prod + "/contest/fantasy/getAllLiveMatches",
          {
            headers: {
              Authorization: "Bearer " + accessToken,
            },
          }
        );

        setLiveMatches(
          response.data.map((match) => {
            const formattedMatch = {
              ...match,
              team1: match.team1.trim(),
              team2: match.team2.trim(),
            };
            return formattedMatch;
          })
        );
      } catch (error) {
        console.error("Error fetching live matches:", error.message);
      }
    };

    fetchLiveMatches();
  }, []);

  return (
    <>
      <div className="fantasy-main">
        <div className="zig-main-container">
          {liveMatches.map((match, index) => (
            <div key={index} className="zigg">
              <div
                className="zigzag-card1"
                style={{ background: getTeamGradient(match.team1) }}
              >
                <div className="player1-container">
                  <div className="csk-image-container">
                    <img
                      src={getTeamAvatar(match.team1)}
                      alt={`${match.team1} Avatar`}
                      className="csk-imgess"
                      style={{
                        width: "40%",
                        height: "100%",
                        objectFit: "cover",
                        marginRight: window.innerWidth > 768 ? "9rem" : "2rem", 
                      }}
                    />
                  </div>
                  <div
                    className="csk-logo-container"
                    style={{
                      position: "relative",
                      overflow: "hidden",
                      borderRadius: "50%",
                      backgroundColor: "white",
                      width: window.innerWidth > 768 ? "100px" : "53px",
                      height: window.innerWidth > 768 ? "100px" : "53px",
                      left: window.innerWidth > 768 ? "15rem" : "3rem",
                      bottom: window.innerWidth > 768 ? "1rem" : "0rem",
                    }}
                  >
                    <img
                      src={teamLogos[match.team1]}
                      alt={`${match.team1} Image`}
                      className="team1-logo-size"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                </div>
              </div>

            
              <div
                className="zigzag-card2"
                style={{ background: getTeamGradient(match.team2) }}
              >
                <div className="player2-container">
                  <div className="mi-image-container">
                    <img
                      src={getTeamAvatar(match.team2)}
                      alt={`${match.team2} Avatar`}
                      className="mi-imagess"
                      style={{
                        width: "40%",
                        height: "100%",
                        objectFit: "cover",
                        marginLeft: window.innerWidth > 768 ? "15rem" : "0rem"
                      }}
                    />
                    {/* <div className="team2-shadow-color"></div> */}
                  </div>
                  <div
                    className="mi-logo-container"
                    style={{
                      position: "relative",
                      overflow: "hidden",
                      borderRadius: "50%",
                      backgroundColor: "white",
                      width: window.innerWidth > 768 ? "100px" : "53px",
                      height: window.innerWidth > 768 ? "100px" : "53px",
                      left: window.innerWidth > 768 ? "27rem" : "4rem",
                      bottom: window.innerWidth > 768 ? "0rem" : "6px",
                    }}
                  >
                    <img
                      src={teamLogos[match.team2]}
                      alt={`${match.team2} Image`}
                      className="team2-logo-size"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="battle-logo-container">
                <img
                  src={BattleLogo}
                  alt="Battle Logo"
                  className="Battle-logo"
                />
              </div>

              <div className="Playnow-container">
                <FantasyPlayNowPage match={match} />
              </div>
            </div>
            ))}
        </div>
      </div>
    </>
  );
};
export default Index;
