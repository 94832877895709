import React, { useEffect, useState } from "react";
import { useMediaQuery } from "@material-ui/core";
import dealBall from "../../../../assets/images/rewards/dealBall.png";
import stripeBall from "../../../../assets/images/rewards/whiteball.png";
import whiteball from "../../../../assets/images/rewards/whitedivball.png";
import whiteballSelected from "../../../../assets/images/rewards/whiteballSelected.png";
import backarrow from "../../../../assets/images/rewards/backArrow.png";
import { Button } from "@mui/material";
import leftarrow from "../../../../assets/images/rewards/arrowleft.png";
import rightarrow from "../../../../assets/images/rewards/arrowright.png";
import PredictionReviewQuetion from "./PredictionReviewQuetion";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAuthToken } from "../../../../utils/auth";
import axios from "axios";
import Run from "../../../posts/run";

export default function StartReview(props) {
  const predictionid = useSelector((state) => state.prediction.prediction);
  const mobileView = useMediaQuery("(max-width:900px)");
  const navigate = useNavigate();
  const userPredictionData = props.userPredictionData;
  const accessToken = getAuthToken();
  const [prediction, setprediction] = useState([]);
  const [attendedQuestions, setAttendedQuestions] = useState([]);
  const [userPredictionQuestion, setUserPredictionQuestion] = useState(null);
  const [predictionQuestion, setPredictionQuestion] = useState(null);
  const [predictionQuestionIndex, setPredictionQuestionIndex] = useState(0);

  const getPredictionByPredictionId = () => {
    if (!accessToken && !predictionid) return;
    const getPrediction = {
      method: "GET",
      url:
        global.config.ROOTURL.prod +
        `/contest/predictionQuestion/findQuestionsByPredictionId/${predictionid}`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };
    axios(getPrediction)
      .then((response) => {
        console.log("pred", { response });
        setprediction(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    console.log({ userPredictionData });
    if (userPredictionData && prediction.length && userPredictionData.length) {
      setPredictionQuestion(prediction[predictionQuestionIndex]);
      setAttendedQuestions(userPredictionData?.map((val) => val.questionId));
    }
  }, [prediction, userPredictionData, predictionQuestionIndex]);

  useEffect(() => {
    getPredictionByPredictionId();
  }, []);

  const handleClose = () => {
    mobileView ? navigate("/prediction") : props?.handleclosePopup();
  };
  const handlequestionindex = (index) => {
    setPredictionQuestionIndex(index);
  };

  return (
    <div style={{ backgroundColor: "#1A1919" }}>
      <div className="predictinbackArrow">
        <div>
          <img src={backarrow} onClick={handleClose} />
        </div>
      </div>

      <div className="PredictionPlayContainer">
        <div className="PMobileRuns">
          <img src={dealBall} />
          <p className="Pmobilepara">
            <Run isRunValueNeeded={true} />
          </p>
        </div>

        <PredictionReviewQuetion
          userPredictionData={userPredictionData}
          predictionQuestion={predictionQuestion}
          prediction={prediction}
          count={predictionQuestionIndex}
        />
        <div className="arrowcontainer">
          <div
            onClick={() => {
              if (predictionQuestionIndex > 0) {
                setPredictionQuestionIndex(
                  parseInt(predictionQuestionIndex - 1)
                );
              }
            }}
          >
            <img src={leftarrow} />
          </div>

          <div className="prediQuestionHeading">
            {userPredictionData &&
              userPredictionData.length &&
              prediction?.length ? (
              prediction.map((value, index, array) => (
                <div className="predictPaddingleft">
                  <img
                    alt="ball"
                    onClick={() => handlequestionindex(index)}
                    src={
                      predictionQuestionIndex === index
                        ? whiteballSelected
                        : attendedQuestions.includes(value._id) &&
                          predictionQuestionIndex !== index
                          ? whiteball
                          : stripeBall
                    }
                  />
                </div>
              ))
            ) : (
              <div></div>
            )}
          </div>
          <div
            onClick={() => {
              if (predictionQuestionIndex < prediction.length - 1) {
                setPredictionQuestionIndex(
                  parseInt(predictionQuestionIndex + 1)
                );
              }
            }}
          >
            <img src={rightarrow} />
          </div>
        </div>
        <div className="closeContainer">
          <Button
            className="predictionFont116 closeButtonPre"
            onClick={handleClose}
          >
            Close
          </Button>
        </div>
      </div>
    </div>
  );
}
