import { Box, Typography } from "@material-ui/core";
import React from "react";
import FindOneCarousel from "../../components/find-carousel"
// import FindOneEvent from "../../components/find-event";
const FindCarousel = () => {
  return (
    <Box>
      <Box marginTop={2}>
        <FindOneCarousel />
      </Box>
    </Box>
  );
};

export default FindCarousel;