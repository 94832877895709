import * as React from 'react';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useMediaQuery, Card, Box, Grid, Link, Container, main, Button, ThemeProvider, createTheme, Avatar } from "@mui/material";
import suitcase from './../../assets/images/icons/suitcase.png';
// import PostedJobs from './posted-jobs';
import AppliedJobs from './applied-jobs';

const MyJob = () => {
    const navigate = useNavigate();
    const navigateToJobs = () => {
        navigate('/jobs');
    };
    const mobileView = useMediaQuery("(max-width:959px)");

    const theme = createTheme(
        {
            typography: {
                fontFamily: 'poppins, Arial',
            },
        }
    );
    return (
        
        
        <ThemeProvider theme={theme}>
            {/* <main className="pitch-main"> */}
            <Card variant="outlined" sx={{ minWidth: 275, mb: .3, borderRadius: 3 }}  >
                {/* <React.Fragment> */}
                <Grid container spacing={mobileView ? 0 : 2}>
                    {/* spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} */}

                    <Grid item xs={9} sm={10} md={10} lg={10} >
                        <CardContent>
                            {/* <Link onClick={(event) => window.location.href = '/job-description'} about='_blank' sx={{ textDecoration: 'none' }}> */}

                            <Grid  >
                                <Box component="span" m={1} sx={{ textAlign: 'center', mb: 3 }} >
                                    <Button variant="contained" color="primary" onClick={navigateToJobs}> Back</Button>
                                    <Typography variant="h4">Posted Jobs</Typography>
                                </Box>
                                
                                {/* <PostedJobs ></PostedJobs> */}
                           </Grid>

                            <Grid  >
                                <Box sx={{ textAlign: 'center', mb: 3 }}><Typography variant="h4">Applied Jobs</Typography></Box>
                                <AppliedJobs ></AppliedJobs>
                            </Grid>
                        </CardContent>
                    </Grid>
                </Grid>

            </Card>

        </ThemeProvider >
        

    );




};
export default MyJob;