import * as React from "react";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";
import CardMedia from "@mui/material/CardMedia";
import {
  useMediaQuery,
  Grid,
  Button,
  Box,
  Card,
  CardActions,
  Typography,
  Link,
} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import PostedJob from "../posted-job";
import axios from "axios";
import { getStorageItem } from "../../utils/sessionStorage";
import config from "../../config";
import parse from "html-react-parser";
import {
  faCalendarDays,
  faLink,
  faLinkSlash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
// import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import { faCaretUp, faCode } from "@fortawesome/free-solid-svg-icons";
// import { faLightbulb } from "@fortawesome/free-solid-svg-icons";
// import { faBookmark } from "@fortawesome/free-solid-svg-icons";
// import suitcase from "../../assets/images/icons/suitcase.png";
const userID = getStorageItem("user_id");
const accessToken = getStorageItem("token");

const baseURL = config.ROOTURL.prod;

const FindOneDeal = (props) => {
  const navigate = useNavigate();
  const navigateToEvents = () => {
    navigate("/admin/all-deals");
  };
  const { id } = useParams();
  const [deal, setDeal] = useState("");
  const [error, setError] = useState(null);
  const editFunCall = (data) => {
    let dealId = data;
    navigate(`/admin/edit-deal-admin/${dealId}`);
  };
  const deleteFunCall = (id) => {
    console.log("This is delete function call:");
    console.log("this is Deal ID:", id);
    if (window.confirm("Delete Carousel?")) {
      axios
        .delete(`${baseURL}/deal/deleteById/${id}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        })
        .then((response) => {
          console.log("this is post response:", response);
          if (response) {
            toast.success("Carousel deleted successfully", {
              theme: "colored",
            });
            console.log("Carousel deleted successfully");
            setTimeout((e) => {
              navigateToEvents();
            }, 2000);
          } else {
            toast.error("Some error occured", { theme: "colored" });
            console.log("error occured:");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    axios
      .get(`${baseURL}/deal/getDealById/${id}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((response) => {
        // handlejobDescription(response);
        console.log("this is base url:", `${baseURL}`);
        console.log("this is response data:", response);
        setDeal(response.data);
        // console.log("this is post:", events)
      })
      .catch((error) => {
        setError(error);
        console.log("this is error:", error);
      });
  }, [id]);

  if (error) return `Error: ${error.message}`;
  if (!deal) return "No post!";
  const theme = createTheme({
    typography: {
      fontFamily: "poppins, Arial",
    },
  });

  return (
    <Grid container alignItems="center">
      {" "}
      <Grid item xs={12}>
        <Card xs={{ maxWidth: 400 }}>
          <CardMedia
            component="img"
            height="140"
            image={deal.dealImage}
            alt="bannerImage"
          />
          <CardContent>
            <Typography>
              <h2>
                <strong>
                  <center> {deal.name}</center>
                </strong>
              </h2>
            </Typography>
            <Typography>
              <FontAwesomeIcon icon={faLink} /> Link: {deal.link}
            </Typography>
            <Typography>
              <FontAwesomeIcon icon={faCaretUp} /> Priority: {deal.priority}
            </Typography>
            <Typography>
              <FontAwesomeIcon icon={faCalendarDays} /> Uploaded: {moment(deal.modifiedDate).format("LLL")}
            </Typography>

            {/* <Typography>
              <FontAwesomeIcon icon={faLocationDot} /> {carousels.eventLocation}
            </Typography> */}

            {/* <Typography>
              <FontAwesomeIcon icon={faMoneyBill} /> {carousels.registrationFee}
            </Typography> */}

            {/* <Typography>
              <p>
                <strong>Description:</strong>
                <p
                  dangerouslySetInnerHTML={{
                    __html: carousel.eventdescription,
                  }}
                />
              </p>
            </Typography> */}
          </CardContent>

          <CardActions>
            {" "}
            <Button
              variant="contained"
              color="primary"
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "left",
                ml: "auto",
              }}
              onClick={navigateToEvents}
            >
              {" "}
              Back
            </Button>
            {/* {<Button variant="contained" color="primary" sx={{ display: 'flex', flexDirection: 'row', alignItems: "left", ml: 'auto' }} onClick={() => editFunCall(events._id)}> Edit</Button>
} */}
            <Button
              variant="contained"
              color="primary"
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "left",
                ml: "auto",
              }}
              onClick={() => deleteFunCall(deal._id)}
            >
              {" "}
              Delete
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
};
export default FindOneDeal;
