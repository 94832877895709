import * as React from 'react';
import Logo from "../../assets/images/header/logo.png";
import { NavLink, useNavigate } from "react-router-dom";
import popupimage from "../../assets/images/deals/popupImage.png"
import clipboard from "../../assets/images/clipboard.png"
import fb from "../../assets/images/facebook.png"
import instagram from "../../assets/images/instagram.png"
import twitter from "../../assets/images/twitter.png"
import whatsapp from "../../assets/images/whatsapp.png"
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { useMediaQuery } from '@mui/material';
import {
  TextField,
  Input,
  Typography,
  Button,
  Fade,
  Modal,
  Box,
  Backdrop,
  Grid,
} from "@mui/material";
import './shareToOtherApps.scss'
import { toast } from 'react-toastify';
export default function Popup(props) {
  // const [open, setOpen] = React.useState(props.open);
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const navigate = useNavigate();
  const { open, handleClose, url } = props;
  const mobileView = useMediaQuery("(max-width:600px)");
  // console.log("props.open", props.open)
  let twitterUrl = `https://twitter.com/intent/tweet?text=${props.url}`
  let facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${props.url}`
  let whatsappUrl = "";
  if (isMobile) {
    whatsappUrl = `https://api.whatsapp.com/send?text=${props.url}`
  }
  else {
    whatsappUrl = `https://web.whatsapp.com/send?text=${props.url}`
  }

  const copyToClipboard = () => {
    const copyText = document.getElementById("outlined-adornment-amount");

    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    navigator.clipboard.writeText(copyText.value);
    toast.success("Url Copied")

  }




  // const shareToInstagram = () => {
  //   let url=props.url; // Replace with the URL you want to share
  //   const message = 'Check out this link: ' + url;
  //   const encodedMessage = encodeURIComponent(message);
  //   //  const shareUrl = `instagram://direct/?text=${encodeURIComponent(message)}`;
  //    const shareUrl = `instagram://direct/messages/create?text=${encodedMessage}`;

  //   console.log("url",props.url)
  //   console.log("url u",url)
  //   // const webURL = `https://www.instagram.com/direct/inbox/`;


  //   if (isMobile) {
  //     window.location.href = shareUrl;
  //   }
  //   //  else {
  //   //   window.open(webURL, '_blank');
  //   // }
  // }
  const shareToInstagram = () => {
    const message = 'Check out this link!';
    const encodedMessage = encodeURIComponent(message);
    const encodedUrl = encodeURIComponent(props.url);
    const instagramUrl = `instagram://direct/share?text=${encodedMessage}&url=${encodedUrl}`;

    if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
      // Open the Instagram app on iOS
      window.location.href = instagramUrl;
    } else if (navigator.userAgent.match(/(Android)/)) {
      // Open the Instagram app on Android
      window.location.href = `intent:${instagramUrl}#Intent;package=com.instagram.android;scheme=https;end;`;
    } else {
      // Use a fallback link for non-mobile devices or if the Instagram app is not installed
      window.open(`https://www.instagram.com/direct/new/?text=${encodedMessage}&url=${encodedUrl}`);
    }
  }

  const sendEmail = () => {
    const subject = 'Check out this link!';
    const body = `Hey,\n\nI wanted to share this link with you: ${props.url}`;
    const encodedSubject = encodeURIComponent(subject);
    const encodedBody = encodeURIComponent(body);
    const mailtoUrl = `mailto:?subject=${encodedSubject}&body=${encodedBody}`;
    const url = 'https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=&su=' + subject + '&body=' + body + '&ui=2&tf=1&pli=1';
    window.location.href = url;
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      ><div>

          <Box sx={!mobileView ? style : mobilestyle} className="box__share">
            <div className="inside__box__share">
              <div className='icon___wrapper'>
                <div className='all__icon__wrapper_share' >
                  <a href={facebookUrl} class="fb-xfbml-parse-ignore" target="_blank"><img src={fb} alt="" className='img_fb_insta_tw_wa' /></a>
                </div>
                {/* {isMobile&&  */}
                {/* <div className='all__icon__wrapper_share'>
                <img src={instagram} alt="" className='img_fb_insta_tw_wa'  onClick={shareToInstagram}/></div> */}
                {/* }   */}

                <div className='all__icon__wrapper_share'>
                  <a href={twitterUrl} target="_blank"><img src={twitter} alt="" className='img_fb_insta_tw_wa' /></a></div>
                <div className='all__icon__wrapper_share'>
                  <MailOutlineIcon onClick={sendEmail} style={{ margin: "8px" }} target="_blank" />
                </div>
                <div className='all__icon__wrapper_share'>
                  <a href={whatsappUrl} data-action="share/whatsapp/share" target="_blank"><img src={whatsapp} alt="" className='img_fb_insta_tw_wa' /></a></div>
              </div>
              <div className='clip__section'>
                <div className="extra__input__wrapper__share">
                  <input
                    type="text"
                    id="outlined-adornment-amount"
                    name="choice 3"
                    placeholder="Choice 3"
                    margin="normal"
                    sx={{ input: { paddingLeft: "5px" } }}
                    value={props.url}
                    className="input__tag"
                    readOnly
                  />
                  <div className="clipboard__css" onClick={copyToClipboard}> <img
                    src={clipboard}
                    alt="" className="clip__img"
                  /></div>
                </div>
              </div>
              {/*  */}

              {/*  */}
            </div>
            <div className="triangle"></div>
          </Box >
        </div>
      </Modal>
    </div>
  );
}












const style = {
  position: 'absolute',
  top: '60%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#0C5BA0',
  border: '2px solid #0C5BA0',
  boxShadow: 24,
  p: '5px',
  //   height: '280px',
  //   width: '340px',
  borderRadius: "0px 0px 22px 22px"
}

const mobilestyle = {
  position: 'absolute',
  top: '60%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '311px',
  bgcolor: '#0C5BA0',
  border: '2px solid #0C5BA0',
  //   boxShadow: 24,
  //   p: '5px',
  height: '130px',
  //   width: '350px',
  borderRadius: "0px 0px 22px 22px"

}
// const image = {
//   height: '212px',
//   width: '212px'

// };
// const logobox = {
//   height: '106px',
//   width: '79px',
//   backgroundColor: '#0C5BA0',
//   borderRadius: '28px',
//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center',
//   marginTop: '-93px',
//    marginLeft: '24px !important'
// };
// const lineHeight = {
//   backgroundColor: 'white',
//     height: '226px',
//     width: '4px',
//     marginTop: '-187px',
//     marginLeft: '61px',
// };
// const signContent = {
//   color: '#E53C96',
//   fontWeight: '600',
//   textAlign: 'center',
//   paddingBottom: '12px',
// }
// const flex = {
//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center',
//   flexDirection: 'column',

// }