import { Button, Grid, Typography } from "@mui/material";
import CommentsIconGray from "../../assets/images/icons/comments.svg";
import ShareIconGray from "../../assets/images/icons/share.svg";
import Logo from "../../assets/images/header/logo.png";
import CommentsIcon from "../../assets/images/comment_blue_icon.png";
// import ShareIcon from "../../assets/images/icons/share.svg";
import RunsIcon from "../../assets/images/icons/post-action.png";
import BallIcon from "../../assets/images/icons/ball-white.svg";
import ShareIcon from "../../assets/images/share_pink_icon.png";
import { Box } from "@mui/system";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import React from "react";
import ReactTimeAgo from "react-time-ago";
import Comments from "../../assets/images/icons/comments.svg";
import Share from "../../assets/images/icons/share.svg";
import Runs from "../../assets/images/icons/post-action.png";
import axios from "axios";
import defaultAvatar from "../../assets/images/profile/default_avatar.png";
import DropdownMenu from "./../dropdown-menu/dropdownMenu";
import ScoreRuns from "../runs-dropdown/dropdownMenu";
import RunCard from "../posts/run";
import NameCard from "../posts/name-card";
import config from "../../config";
import Header from "../header/header-without-signin";
import ModalSignin from "./Popup";
import "./profilePreview.scss";
import "./JobPreview.scss";
const baseURL = config.ROOTURL.prod;
const mobileStyle = {
  // background: "whitesmoke",
  minWidth: "370px",
  minHeight: "400px",
};
const style = {
  // height: "418px",
  // width: "1280px",
  borderRadius: "8px",
  minWidth: "650px",
  minHeight: "271px",
  marginTop: "45px",
  marginBottom: "62px",
  marginLeft: "82px",
  marginRight: "82px",
  // backgroundColor: "#F9FCFF"
};
const Posts = (props) => {
  var regex = /(http[s]?:\/\/.*\.(?:png|jpg|gif|svg|jpeg|webp))/i;
  const mobileView = useMediaQuery("(max-width:768px)");
  const navigate = useNavigate();
  const [userDetail, setUserDetail] = useState([]);
  const [postsOfUserId, setPostsOfUserId] = useState(null);
  const [comments, setComments] = useState([]);
  const [startDate, setStartDate] = useState(return1SecondPlusDate());

  const [pageNumber, setPageNumber] = useState(1);
  const [IsOpen, setIsOpen] = useState(false);
  const [runsGivenValue, setRunsGivenValue] = useState(0);
  const [runsImage, setRunsImage] = useState(Runs);
  const [runsGiven, setRunsGiven] = useState(false);
  const [open, setOpen] = useState(false);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [runsScored, setRunsScored] = useState();
  const [showComments, setShowComments] = useState(false);
  const [comment, setComment] = useState("");
  const handleOpenPopUp = () => {
    setOpenPopUp(true);
    console.log("working ", openPopUp)
  }
  const handleClosePopUp = () => {
    setOpenPopUp(false);
  };
  const params = useParams();
  const userId = params.id;
  // const handleOpenPopUp=()=>{
  //   setOpenPopUp(true);
  // }
  const fetchDataByUserId = () => {
    axios
      .get(`${baseURL}/auth/profileForPreview/${userId}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("response.data?.data?", response);
        setUserDetail(response.data);
        fetchSamplePitchesByUserId();
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const fetchSamplePitchesByUserId = () => {
    axios
      .get(`${baseURL}/pitch/getPitchByUserIdForProfilePreview/${userId}/${pageNumber}/3`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("response.data?.data?", response.data);
        setPostsOfUserId(response.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const URL_REGEX =
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;
  // /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  let youtubeLink;

  function replaceHashtags(description) {
    // Use a regular expression to match hashtags that are not already anchor tags
    const regex = /(?!<a[^>]*>)(#(\w+))(?![^<]*<\/a>)/g;
    // Replace hashtags with anchor tags
    return description?.replace(
      regex,
      '<a class="hashtagged" href="/hashtags?hashtag=$2">$1</a>'
    );
  }
  // if (posts.postMessage) {
  //   posts.postMessage = replaceHashtags(posts.postMessage);
  // }

  function youtubeParser(url) {
    if (url !== undefined) {
      var checks = url.split(" ");

      return (
        <p>
          {checks.map((words, index) => {
            var word;

            words.includes("/watch?v=")
              ? (word = words?.replace("/watch?v=", "/embed/"))
              : (word = words?.replace("youtu.be/", "www.youtube.com/embed/"));

            if (word.includes("http://") || word.includes("https://")) {
              youtubeLink = word;
            }

            return words.match(URL_REGEX) ? <></> : words + " ";
          })}
        </p>
      );
    }
  }

  // youtubeParser(posts.postMessage);
  useEffect(() => {
    fetchDataByUserId();
    // fetchSamplePitchesByUserId();

  }, []);


  return (
    <section className="main_header_color">
      {mobileView ?
        <Box sx={mobileStyle}><Header />
          {/* <div > */}
          <div className="child1">
            <div className="image__wrapper__profile__mv"><img
              src={userDetail?.profilePhoto ? userDetail?.profilePhoto : defaultAvatar}
              alt="user" className="img__profile__photo"
              onClick={handleOpenPopUp}
            />



            </div>
            {/* <div className="child2"> */}
            <div className="user__name">
              <h5> {userDetail?.firstName}  {userDetail?.lastName}</h5>
            </div>
            <div style={{ margin: "10px 20px", color: "#B9B9B9" }}>{userDetail?.aboutMe}</div>
            <div className="container__profile__preview">
              <div className="pitch__prev" >
                <div className="pitch___count">{userDetail?.pitchCount}</div>
                <div className="text__design" >Posts</div>
              </div>
              <div style={{ borderRight: "1px solid #D0D0D0" }}></div>
              <div className="following__prev">
                <div className="pitch___count">{userDetail?.followingCount}</div>
                <div className="text__design">Following</div>
              </div>   <div style={{ borderRight: "1px solid #D0D0D0" }}></div>

              <div class="follower__prev">
                <div className="pitch___count">{userDetail?.followersCount}</div>
                <div className="text__design">Follower</div>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button variant="contained"
                style={{ backgroundColor: "#FECC08", marginTop: "40px", color: "#00247B", height: "30px" }} onClick={handleOpenPopUp} >Follow</Button>
            </div>

          </div>


          <div className="container__preview__middle"><div className="left__text">some post from {userDetail?.firstName}</div>
            <div className="right__text" onClick={handleOpenPopUp} >View All</div></div>
          {/* middle section end */}
          {/* sample pitch */}
          {postsOfUserId?.map((data,index) => (
            <div className="parent" key={index}>
              {console.log("iam in ",data)}
              <div className="child122">
                <div className="userDetailPost">
                  <img
                    className="post__profile"
                    src={data?.userProfilePhoto ? data?.userProfilePhoto : defaultAvatar}
                    alt="user"
                    onClick={handleOpenPopUp}
                  />

                  <div>
                    {" "}
                    <h3
                      style={{
                        marginTop: "5px",
                        fontWeight: "600",
                        fontSize: "16px",
                        lineHeight: "24px",
                        letterSpacing: "0.1px",
                        color: "#1a1919",
                      }}
                    >
                      {data?.userName}
                    </h3>
                    <p className="userDetail__align">
                      {" "}
                      {data?.createdDate && (
                        <ReactTimeAgo date={Date.parse(data?.createdDate)} locale="en-US" />
                      )}{" "}
                    </p>
                  </div>
                </div>
                {/* <p className="comment__text1">{posts.postMessage}</p> */}
                {data?.sharedDetail && data?.sharedDetail?.sharedText ?
                  <p className="comment__text1" dangerouslySetInnerHTML={{ __html: data?.sharedDetail?.sharedText }} />
                  :
                  <p className="comment__text1" dangerouslySetInnerHTML={{ __html: data?.postMessage }} />}
                {data?.sharedDetail && data?.sharedDetail !== {} ?
                  <div className="child__shared">
                    <div className="userDetailPost">
                      <img
                        className="post__profile"
                        src={data?.sharedDetail?.profilePhoto ? data?.sharedDetail?.profilePhoto : defaultAvatar}
                        alt="user"
                      />

                      <div>
                        {" "}
                        <h3
                          style={{
                            marginTop: "5px",
                            fontWeight: "600",
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.1px",
                            color: "#1a1919",
                          }}
                        >
                          {data?.sharedDetail?.firstName}    {data?.sharedDetail?.lastName}
                        </h3>
                        <p className="userDetail__align">
                          {" "}
                          {data?.createdDate && (
                            <ReactTimeAgo date={Date.parse(data?.createdDate)} locale="en-US" />
                          )}{" "}
                        </p>
                      </div>
                    </div>
                    {/* <p className="comment__text1">{posts.postMessage}</p> */}

                    <p className="comment__text1" dangerouslySetInnerHTML={{ __html: data?.postMessage }} />
                    {data?.postImageURL !== "" && <div className={data?.postImageURL ? "image__wrapper__shared" : "display__nothing"}>
                      {data?.postImageURL ? (
                        data?.postImageURL.match(regex) ? (
                          <img
                            className="post__url"
                            src={data?.postImageURL}
                            alt=""
                            style={{ cursor: "pointer" }}
                          />
                        ) : (
                          <video className="post__url" controls>
                            <source
                              src={data?.postImageURL}
                              style={{ cursor: "pointer" }}
                            />
                          </video>
                        )
                      ) : (
                        ""
                      )}
                    </div>}

                  </div> : <div className="image__wrapper__profile_mv">
                    {data?.postImageURL ? (
                      data?.postImageURL.match(regex) ? (
                        <img
                          className="post__url"
                          src={data?.postImageURL}
                          alt=""
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <video className="post__url" controls>
                          <source
                            src={data?.postImageURL}
                            style={{ cursor: "pointer" }}
                          />
                        </video>
                      )
                    ) : (
                      ""
                    )}
                  </div>}
                {/* </div>   */}
                {/*  */}
                {/* {posts.sharedDetail!==""&&} */}

                <div class="container__preview">
                  {data?.postCommentCount > 0 ? <div class="left">
                    <img
                      src={CommentsIcon}
                      className="comment__icon"
                      alt=""
                      role="button"
                    />
                    <p style={{ marginLeft: "2px" }} onClick={handleOpenPopUp}>{data?.postCommentCount} Comment</p>
                  </div> : <div class="left">
                    <img
                      src={CommentsIconGray}
                      className="comment__icon__gray"
                      alt=""
                      role="button"
                    />
                    <p style={{ marginLeft: "2px", color: "white" }} onClick={handleOpenPopUp}>{data?.postCommentCount} Comment</p>
                  </div>}
                  {data?.sharedPost?.length > 0 ? <div style={{ color: "pink", fontSize: "16px" }} class="center__preview">
                    <img
                      src={ShareIcon}
                      className="share__icon"
                      alt=""
                      role="button"
                      onClick={handleOpenPopUp}

                    />
                    {data?.sharedPost
                      ? <p style={{ color: '#242a37' }}>  {data?.sharedPost?.length}</p>
                      :
                      <p style={{ color: '#242a37' }}>0</p>
                    }
                  </div> :
                    <div class="center__preview">
                      <img
                        src={ShareIconGray}
                        className="share__icon__gray"
                        alt=""
                        role="button"
                        onClick={handleOpenPopUp}
                      />
                    </div>}
                  <div class="right">
                    <RunCard run={data?.postRunCount} />
                    <img
                      src={RunsIcon}
                      className="icon-btn imge"
                      alt=""
                      role="button"
                      onClick={handleOpenPopUp}
                    />
                  </div>
                </div>
              </div></div>
          ))}
          {/* sample pitch end */}
        </Box> : <><Header /><Box sx={style}>

          <div className="profile__parent">
            <div className="child1">
              <div className="userDetail?">
                <img
                  src={userDetail?.profilePhoto ? userDetail?.profilePhoto : defaultAvatar}
                  alt="user"
                  onClick={handleOpenPopUp}
                />


              </div>
            </div>
            <div className="child2">
              <div className="user__name">
                <h5> {userDetail?.firstName}  {userDetail?.lastName}</h5>
              </div>
              <div style={{ margin: "10px 0px", color: "#B9B9B9" }}>{userDetail?.aboutMe}</div>
              <div className="container__profile__preview">
                <div className="pitch__prev">
                  <div className="pitch___count">{userDetail?.pitchCount}</div>
                  <div className="text__design">Posts</div>
                </div> <div style={{ borderRight: "1px solid #D0D0D0" }}></div><div className="following__prev">
                  <div className="pitch___count">{userDetail?.followingCount}</div>
                  <div className="text__design">Following</div>
                </div>
                <div style={{ borderRight: "1px solid #D0D0D0" }}></div>
                <div class="follower__prev">
                  <div className="pitch___count">{userDetail?.followersCount}</div>
                  <div className="text__design">Follower</div>
                </div>
              </div>
              <Button variant="contained" style={{ backgroundColor: "#FECC08", marginTop: "51px", color: "#00247B", height: "30px" }} onClick={handleOpenPopUp} >Follow</Button></div>


          </div>
          {/* <div className="middle__text">Sign up for our best experience</div> */}
          <div className="container__preview__middle"><div className="left__text">Some posts from {userDetail?.userName}</div>
            <div className="right__text" onClick={handleOpenPopUp} >View All</div></div>
          {/* pitch iteration starts */}
          <Grid container spacing={1} style={{ display: "flex", marginTop: "20px" }}>
            {postsOfUserId?.map((data, index) => (
              <Grid item xs={6}key={index} >
                <Box
                  style={{
                    // width: "50%",
                    backgroundColor: "#ffffff",
                    borderBottom: "1px solid #ddd",
                    margin: "0px auto",
                    padding: 20,
                    borderRadius: 12
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <Box>
                      {/* <div className="child1"> */}
                      <div className="userDetail__post">
                        <img
                          className="post__profile"
                          src={data?.userProfilePhoto ? data?.userProfilePhoto : defaultAvatar}
                          alt="user"
                        />

                        <div>
                          {" "}
                          <h3
                            style={{
                              marginTop: "5px",
                              fontWeight: "600",
                              fontSize: "16px",
                              lineHeight: "24px",
                              letterSpacing: "0.1px",
                              color: "#1a1919",
                            }}
                          >
                            {data?.userName}
                          </h3>
                          <p className="userDetail__align">
                            {" "}
                            {data?.createdDate && (
                              <ReactTimeAgo date={Date.parse(data?.createdDate)} locale="en-US" />
                            )}{" "}
                          </p>
                        </div>
                      </div>
                      {/* <p className="comment__text1">{posts.postMessage}</p> */}
                      {data?.sharedDetail && data?.sharedDetail?.sharedText ?
                        <p className="comment__text1" dangerouslySetInnerHTML={{ __html: data?.sharedDetail?.sharedText }} />
                        :
                        <p className="comment__text1" dangerouslySetInnerHTML={{ __html: data?.postMessage }} />}
                      {data?.sharedDetail && data?.sharedDetail !== {} ?
                        <div className="child__shared">
                          <div className="userDetailPost">
                            <img
                              className="post__profile"
                              src={data?.sharedDetail?.profilePhoto ? data?.sharedDetail?.profilePhoto : defaultAvatar}
                              alt="user"
                              onClick={handleOpenPopUp}
                            />

                            <div>
                              {" "}
                              <h3
                                style={{
                                  marginTop: "5px",
                                  fontWeight: "600",
                                  fontSize: "16px",
                                  lineHeight: "24px",
                                  letterSpacing: "0.1px",
                                  color: "#1a1919",
                                }}
                              >
                                {data?.sharedDetail?.firstName}    {data?.sharedDetail?.lastName}
                              </h3>
                              <p className="userDetail__align">
                                {" "}
                                {data?.createdDate && (
                                  <ReactTimeAgo date={Date.parse(data?.createdDate)} locale="en-US" />
                                )}{" "}
                              </p>
                            </div>
                          </div>
                          {/* <p className="comment__text1">{posts.postMessage}</p> */}

                          <p className="comment__text1" dangerouslySetInnerHTML={{ __html: data?.postMessage }} />
                          {data?.postImageURL !== "" && <div className="image__wrapper__shared">
                            {data?.postImageURL ? (
                              data?.postImageURL.match(regex) ? (
                                <img
                                  className="post__url"
                                  src={data?.postImageURL}
                                  alt=""
                                  style={{ cursor: "pointer" }}
                                />
                              ) : (
                                <video className="post__url" controls>
                                  <source
                                    src={data?.postImageURL}
                                    style={{ cursor: "pointer" }}
                                  />
                                </video>
                              )
                            ) : (
                              ""
                            )}
                          </div>}

                        </div> :
                        <div className={data?.postImageURL ? "image__wrapper__profile" : "display__nothing"}>
                          {data?.postImageURL ? (
                            data?.postImageURL.match(regex) ? (
                              <img
                                className="post__url"
                                src={data?.postImageURL}
                                alt=""
                                style={{ cursor: "pointer" }}
                              />
                            ) : (
                              <video className="post__url" controls>
                                <source
                                  src={data?.postImageURL}
                                  style={{ cursor: "pointer" }}
                                />
                              </video>
                            )
                          ) : (
                            ""
                          )}
                        </div>}
                      {/* </div>   */}
                      {/*  */}
                      {/* {posts.sharedDetail!==""&&} */}

                      <div class="container__preview">
                        {data?.postCommentCount > 0 ? <div class="left">
                          <img
                            src={CommentsIcon}
                            className="comment__icon"
                            alt=""
                            role="button"
                          />
                          <p style={{ marginLeft: "2px" }} onClick={handleOpenPopUp}>{data?.postCommentCount} Comment</p>
                        </div> : <div class="left">
                          <img
                            src={CommentsIconGray}
                            className="comment__icon__gray"
                            alt=""
                            role="button"
                          />
                          <p style={{ marginLeft: "2px", color: "white" }} onClick={handleOpenPopUp}>{data?.postCommentCount} Comment</p>
                        </div>}
                        {data?.sharedPost?.length > 0 ? <div style={{ color: "pink", fontSize: "16px" }} class="center__preview">
                          <img
                            src={ShareIcon}
                            className="share__icon"
                            alt=""
                            role="button"
                          />
                          {data?.sharedPost
                            ? <p style={{ color: '#242a37' }}>  {data?.sharedPost?.length}</p>
                            :
                            <p style={{ color: '#242a37' }}>0</p>
                          }
                        </div> :
                          <div class="center__preview">
                            <img
                              src={ShareIconGray}
                              className="share__icon__gray"
                              alt=""
                              role="button"
                            />
                          </div>}
                        <div class="right">
                          <RunCard run={data?.postRunCount} />
                          <img
                            src={RunsIcon}
                            className="icon-btn imge"
                            alt=""
                            role="button"
                          />
                        </div>
                      </div>
                      {/* </div> */}
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ))}

          </Grid>
          {/* pitch iteration starts */}
        </Box>

          {
            !mobileView &&
            <Box className="footer_container_">
              <div className='footer_first'>
                <p className='footerPara_'><hr /></p>
                <img src={Logo} className="footerimage_" />
                <p className='footerPara_2'><hr /></p>
              </div>
              <p className="footer__" style={{ paddingBottom: '70px' }}>Sign up for our best experience </p>
            </Box>

          }

        </>}
      {
        mobileView &&
        <Box className=" mobilefooter">
          <div className='footer_first'>
            <img src={Logo} className="" />
            <p className='mobilefootername'>ChampHunt</p>
          </div>
          <p className="mobilefooter__" style={{ paddingBottom: '10px' }}>Champhunt is a 360 degree platform for all things cricket. Your one-stop destination to earn from your love of cricket on the internet.</p>
        </Box>

      }
      {openPopUp && <ModalSignin Open={openPopUp} handleClose={handleClosePopUp} />}
    </section>
  );
};

const return1SecondPlusDate = () => {
  let t = new Date();
  // console.log('ttt1',t);
  t.setSeconds(t.getSeconds() + 100);
  // console.log('ttt2',t);
  return t;
};
export default Posts;
