import React from 'react'
import UspFirst from './UspFirst';
import SecondBlocksec from './SecondUsp';
import "./usplandingindex.scss";
import UspThirdBlock from './Uspthird';
import UspFooter from './UspFooter';



  const ThirdLandingPages = () => {
   return (
     <>
       <UspFirst />
       <SecondBlocksec />
       <UspThirdBlock /> 
       <UspFooter /> 
     </>
  )
}

export default ThirdLandingPages;
  