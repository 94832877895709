import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import RadioBtn from "../radio-button/radioBtn";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect, useState } from "react";
import cancel from "../../assets/images/home/cancel.png"
import Reportpopup from './Reportpopup';
import "./index.scss"
const styleDesktop = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  borderRadius: 5,
};

const styleMobile = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  borderRadius: 5,
};

export default function ReportModel({ handlRepoetClose, open,handlepassreport }) {
  const matches = useMediaQuery("(min-width:600px)");
  const [style, setStyle] = useState();
  const [reporttype,setReportType] = useState("");
  const [open1, setopen] = useState(open);
  const [openreport, setreport] = useState(false);
  const handleclose = () => { handlRepoetClose() }
  const handlesubmit=()=>{
   handlepassreport(reporttype)
    handlRepoetClose()
  }

  useEffect(() => {
    if (!matches) {
      setStyle(styleMobile);
    } else {
      setStyle(styleDesktop);
    }
  }, [matches]);

  // const {post_id} = props;
  const reportTypeRef = React.useRef();
  const handleChange = (e) => {
    setReportType(e.target.value);
  };

  return (
    <div>
      <Modal
        open={open1}
        onClose={handleclose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box  className="Report_popup_heading">
            <p style={{fontSize:'22px'}}>
              Report Account
            </p>
            <img src={cancel} onClick={handleclose} />
          </Box>
          <hr/>

          <RadioBtn className="ReportButton">
            <FormControlLabel
              name="report_type"
              ref={reportTypeRef}
              value="Spam"
              onChange={handleChange}
              control={<Radio />}
              label="Spam"
              className="Report_popup_heading2"
            />
            <p className="Report_popup_para">The Profile voilates our span policy</p>
            <FormControlLabel
              name="report_type"
              ref={reportTypeRef}
              control={<Radio />}
              label="Abuse"
              value='Abuse'
              onChange={handleChange}
              className="Report_popup_heading2"
               />
                <p className="Report_popup_para">The Profile is offensive to certain people</p>
            <FormControlLabel
              name="report_type"
              ref={reportTypeRef}
              value="Plagiarism"
              control={<Radio />}
              label="Plagiarism"
              onChange={handleChange}
              className="Report_popup_heading2"
            />
              <p className="Report_popup_para">This Profile shares content owned by me or somebody else</p>
            <FormControlLabel
              name="report_type"
              value="Adult Content"
              ref={reportTypeRef}
              control={<Radio />}
              label="Adult Content"
              onChange={handleChange}
              className="Report_popup_heading2"
            />
              <p className="Report_popup_para">This Profile’s content is not suitable for all audience.</p>
          </RadioBtn>
          <div className="Reportsubmit">
            <Button variant="text" onClick={handleclose} >Cancel</Button>
            <Button variant="contained"  onClick={handlesubmit}>Submit</Button>
          </div>
        </Box>
       
    
      </Modal>
    </div>
  );
}
