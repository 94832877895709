import React, { useState } from "react";
import moment from "moment";
import { getStorageItem, clearStorage } from "../../utils/sessionStorage";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./index.scss";

const Card = ({ seller, index }) => {
  const [isVerified, setIsVerified] = useState(seller?.isSellerVerified);
  const navigate = useNavigate();
  const accessToken = getStorageItem("token");
  const userId = getStorageItem("user_id");
  const [page, setPage] = useState(0);
  const makeSellerVerified = (sellerId) => {

    if (!accessToken && !sellerId) return
    const options = {
      method: "PATCH",
      url:
        global.config.ROOTURL.prod +
        `/marketplace/seller/makeSellerVerifiedBySellerId/${sellerId}`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };
    axios(options)
      .then((response) => {
        if (response.data) {
          setIsVerified(true);
        }
      })
      .catch((error) => console.log(error));
  };
  const makeSellerUnverified = (sellerId) => {
    if (!accessToken && !sellerId) return
    const options = {
      method: "PATCH",
      url:
        global.config.ROOTURL.prod +
        `/marketplace/seller/makeSellerUnverifiedBySellerId/${sellerId}`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };
    axios(options)
      .then((response) => {
        if (response.data) {
          setIsVerified(false);
        }
      })
      .catch((error) => console.log(error));
  };
  return (
    <div key={seller?._id} className="all-sellers_card">
      {/* <p className="all-sellers_index">{index}</p> */}
      <div className="all-sellers_sellerCardHeader">
        <div>
          <p className="all-sellers_sellerName">Name: {seller?.sellerName}</p>
          <p className="all-sellers_sellerEmail">
            Email: {seller?.sellerEmail}
          </p>
        </div>
        <img
          src={seller?.sellerProfile}
          alt="seller-profile"
          className="all-sellers_sellerProfile"
        />
      </div>
      <p className="all-sellers_sellerCreatedAt">
        Joined On: {moment(seller?.createdAt).format("DD/MM/YYYY")}
      </p>
      <p
        className="all-sellers_moreDetailButton"
        onClick={() => {
          navigate(`/admin/marketplace/single-seller/${seller?._id}`);
        }}
      >
        More Detail
      </p>
      {(!isVerified) ? (
        <button
          className="all-sellers_makeSellerVerified"
          onClick={() => {
            makeSellerVerified(seller?._id);
          }}
        >
          Make Seller Verified
        </button>
      ) : (
        <button
          className="all-sellers_makeSellerVerified"
          onClick={() => {
            makeSellerUnverified(seller?._id);
          }}
        >
          Unverify user
        </button>
      )}
    </div>
  );
};

export default Card;
