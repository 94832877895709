import { Box, Typography } from "@material-ui/core";
import React from "react";
import DefaultUserScroll from "../../components/default-users/defaultUserScroll"
// import EventScroll from "../../components/find-all-events/eventScroll";
import CarouselScroll from "../../components/manage-carousel/carouselScroll"
import DealScroll from "../../components/manage-deal/dealScroll"

const ManageDefaultUsers = () => {
  return (
    <Box>
      <Typography variant="h4">Default Users</Typography>
      <Box marginTop={2}> <DefaultUserScroll/></Box>
    </Box>
  );
};

export default ManageDefaultUsers;
