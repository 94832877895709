import axios from 'axios';
import { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import './index.scss'
import SignUp from "../../components/signin-signup/signup/signup.component";
import { clearStorage } from '../../utils/sessionStorage';

const ReferralPage = (props) => {
    const navigate = useNavigate()
    const [referral, setReferral] = useState(false);
    const [referredFromUserDetail, setReferredFromUserDetail] = useState({});
    const params = useParams();
    const uid = params.uid;
    const key = params.key;
    localStorage.setItem("uid", uid);
    localStorage.setItem("inviteKey", key);
    const validateReferral = () => {
        if (!uid && !key) return
        axios({
            method: "GET",
            url: global.config.ROOTURL.prod + "/invite/validate/" + uid + "/" + key,
        })
            .then((response) => {
                if (response.data.message == "success") {
                    setReferral(true);
                    setReferredFromUserDetail(response.data.userDetail)
                }
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    clearStorage();
                    navigate('/login');
                }
            });
    }

    useEffect(() => {
        validateReferral();
    }, [])

    return <div className="page profile">
        <SignUp
            uid={uid}
            inviteKey={key}
            referral={referral}
            referredFromUserDetail={referredFromUserDetail}
        />
    </div>
}

export default ReferralPage;