import React, { useState, useContext, useEffect, useRef } from "react";
import Modal from "@mui/material/Modal";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Popover from "@mui/material/Popover";
import Box from "@material-ui/core/Box";
import { getStorageItem } from "../../../utils/sessionStorage";
import { format } from "date-fns";

import { Ellipsis } from "lucide-react";
import { Smile } from "lucide-react";

import hahaEmoji from "../../../assets/images/chatreactions/haha.gif";
import likeEmoji from "../../../assets/images/chatreactions/like.gif";
import sadEmoji from "../../../assets/images/chatreactions/sad.gif";
import clapEmoji from "../../../assets/images/chatreactions/clap.gif";
import loveEmoji from "../../../assets/images/chatreactions/love.gif";
import celebrateEmoji from "../../../assets/images/chatreactions/celebrate.gif";
import reportImg from "../../../assets/images/chatreactions/report.png";
import { SocketContext } from "../../../context/SocketContext";
import axios from "axios";
import ReportSuccessModal from "./ReportSuccessModal";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
  p: 4,
};

const MessageSection = ({ chat, ind }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElReport, setAnchorElReport] = React.useState(null);
  const [socket] = useContext(SocketContext);
  const [localReactions, setLocalReactions] = useState(chat.reactions || []);
  const profileImg = chat.senderDetails?.profilePhoto;
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const videoRef = useRef(null);
  const userId = getStorageItem("user_id");
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const [openSuccess, setOpenSuccess] = useState(false);

  const handleCloseSuccessModal = () => setOpenSuccess(false);

  const openReport = Boolean(anchorElReport);
  const idReport = openReport ? "simple-popover" : undefined;

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const accessToken = useState("token");

  const handleCloseReport = () => {
    setAnchorElReport(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickReport = (event) => {
    setAnchorElReport(event.currentTarget);
  };

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth <= 600);
    };

    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on("reaction received", (data) => {
        if (data.messageId === chat._id) {
          setLocalReactions((prevReactions) => [...prevReactions, data]);
        }
      });
    }

    return () => {
      if (socket) {
        socket.off("reaction received");
      }
    };
  }, [socket, chat._id]);

  const formattedDate = format(new Date(chat.createdDate), "HH:mm");

  const reportUserHandler = async () => {
    try {
      if (!accessToken) return;

      const options = {
        method: "post",
        url: global.config.ROOTURL.prod + `/groupchat/reportUser`,
        headers: {
          Authorization: "Bearer " + accessToken,
        },
        data: {
          userId: chat?.senderDetails?._id,
          groupId: chat?.groupId,
          reportedBy: userId,
        },
      };
      axios(options)
        .then(({ data }) => {
          // console.log(data);
          setOpenSuccess(true);
          setOpenModal(false);
          handleCloseReport(false);
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            console.log("Unauthorized:", error);
          } else {
            console.log("Error fetching stories:", error);
          }
        });
    } catch (error) {
      console.error("Error in reactMessage:", error);
    }
  };

  const renderContent = () => {
    const renderReactions = () => {
      if (localReactions.length > 0) {
        const reactionCounts = localReactions.reduce((acc, reaction) => {
          acc[reaction.reaction] = (acc[reaction.reaction] || 0) + 1;
          return acc;
        }, {});
        // if (chat.reactions && chat.reactions.length > 0) {
        //   const reactionCounts = chat.reactions.reduce((acc, reaction) => {
        //     acc[reaction.reaction] = (acc[reaction.reaction] || 0) + 1;
        //     return acc;
        //   }, {});

        const reactionElements = Object.entries(reactionCounts).map(
          ([reaction, count]) => {
            let emojiSrc;
            switch (reaction) {
              case "haha":
                emojiSrc = hahaEmoji;
                break;
              case "like":
                emojiSrc = likeEmoji;
                break;
              case "sad":
                emojiSrc = sadEmoji;
                break;
              case "celebrate":
                emojiSrc = celebrateEmoji;
                break;
              case "love":
                emojiSrc = loveEmoji;
                break;
              case "clap":
                emojiSrc = clapEmoji;
                break;
              default:
                return null;
            }

            return (
              <span
                key={reaction}
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  marginRight: "5px",
                }}
              >
                <img
                  src={emojiSrc}
                  alt={reaction}
                  style={{ width: "20px", height: "20px" }}
                />
                {count > 2 ? `${count}+` : ""}
              </span>
            );
          }
        );

        return <div style={{ marginTop: "5px" }}>{reactionElements}</div>;
      }
      return null;
    };

    switch (chat.messageType) {
      case "text":
        return (
          <div>
            {chat?.senderDetails?._id !== userId ? (
              <div>
                <div>
                  <div className="recieverchat">
                    <p style={{ fontWeight: "bold" }}>
                      {chat.senderDetails._id !== userId ? (
                        chat?.senderDetails?.firstName +
                        " " +
                        chat?.senderDetails?.lastName
                      ) : (
                        <></>
                      )}
                    </p>
                    <br />
                    {chat.content}
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <p>{renderReactions()}</p>
                  <ListItemText
                    align="right"
                    secondary={formattedDate}
                  ></ListItemText>
                </div>
              </div>
            ) : (
              <div>
                <div>
                  <span className="senderchats">{chat.content}</span>
                </div>
                <div>
                  <p>{renderReactions()}</p>
                </div>
              </div>
            )}
          </div>
        );
      case "image":
        return (
          <div>
            <p>
              {chat.senderDetails._id !== userId ? (
                chat?.senderDetails?.firstName +
                " " +
                chat?.senderDetails?.lastName
              ) : (
                <></>
              )}
            </p>
            <img
              src={chat.content}
              alt="Shared image"
              style={{
                width: isSmallScreen ? "130px" : "200px",
                height: isSmallScreen ? "160px" : "200px",
                objectFit: "cover",
                borderRadius: "10px",
              }}
            />
            <div style={{ display: "flex" }}>
              <p>{renderReactions()}</p>
              <ListItemText
                align="right"
                secondary={formattedDate}
              ></ListItemText>
            </div>
          </div>
        );
      case "video":
        return (
          <div
            className="video-container"
            style={{
              width: isSmallScreen ? "160px" : "300px",
              height: isSmallScreen ? "120px" : "auto",
              borderRadius: "10px",
              overflow: "hidden",
              boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
              backgroundColor: "#f0f0f0",
            }}
          >
            <div style={{ position: "relative", height: "100%" }}>
              <video
                ref={videoRef}
                width="100%"
                height="100%"
                style={{ objectFit: "cover" }}
                onClick={() => setIsPlaying(!isPlaying)}
                onPlay={() => setIsPlaying(true)}
                onPause={() => setIsPlaying(false)}
                muted={isMuted}
              >
                <source src={chat.content} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <div
                className="video-controls"
                style={{
                  position: "absolute",
                  bottom: "10px",
                  left: "10px",
                  right: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: "rgba(0,0,0,0.5)",
                  borderRadius: "5px",
                  padding: "5px",
                }}
              >
                <button
                  onClick={() => {
                    isPlaying
                      ? videoRef.current.pause()
                      : videoRef.current.play();
                  }}
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    color: "white",
                    cursor: "pointer",
                  }}
                >
                  {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
                </button>
                <button
                  onClick={() => setIsMuted(!isMuted)}
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    color: "white",
                    cursor: "pointer",
                  }}
                >
                  {isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
                </button>
              </div>
            </div>
            <div style={{ display: "flex", padding: "10px" }}>
              <p>{renderReactions()}</p>
              <ListItemText
                align="right"
                secondary={formattedDate}
              ></ListItemText>
            </div>
          </div>
        );
      default:
        return <span>Unsupported content type</span>;
    }
  };

  const reactMessage = async (messageId, reaction) => {
    try {
      const newReaction = { messageId, reaction, userId };
      setLocalReactions((prevReactions) => [...prevReactions, newReaction]);
      socket.emit("reaction", { ...newReaction, groupId: chat.groupId });
      const options = {
        method: "put",
        url: global.config.ROOTURL.prod + `/groupchat/reactMessage`,
        headers: {
          Authorization: "Bearer " + accessToken,
        },
        data: newReaction,
      };

      await axios(options);
      handleClose();
    } catch (error) {
      console.error("Error in reactMessage:", error);
      // Revert the optimistic update if the API call fails
      setLocalReactions((prevReactions) =>
        prevReactions.filter(
          (r) =>
            !(
              r.messageId === messageId &&
              r.reaction === reaction &&
              r.userId === userId
            )
        )
      );
    }
  };

  return (
    <div key={ind}>
      {chat?.senderDetails?._id === userId || chat?.senderId?._id === userId ? (
        <ListItem key="1">
          <Grid container>
            <Grid item xs={12}>
              <ListItemText align="right">{renderContent()}</ListItemText>
            </Grid>
            <Grid item xs={12}>
              <ListItemText
                align="right"
                secondary={formattedDate}
              ></ListItemText>
            </Grid>
          </Grid>
        </ListItem>
      ) : (
        <ListItem key="2">
          <Grid container>
            <Grid item xs={12} style={{ display: "flex" }}>
              <div
                align="left"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <img
                    src={profileImg}
                    width={35}
                    height={35}
                    style={{
                      borderRadius: "50%",
                      alignItems: "end",
                      justifyContent: "end",
                    }}
                  />
                </div>

                <ListItemText align="left">{renderContent()}</ListItemText>
                <Smile
                  onClick={handleClick}
                  style={{ cursor: "pointer", color: "grey" }}
                  size={20}
                />
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      padding: "5px",
                      backgroundColor: "#832990",
                      paddingLeft: "13px",
                      paddingRight: "13px",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      alt="react"
                      src={likeEmoji}
                      onClick={() => {
                        reactMessage(chat._id, "like");
                      }}
                      width={20}
                      height={20}
                    />
                    <img
                      alt="react"
                      src={celebrateEmoji}
                      onClick={() => {
                        reactMessage(chat._id, "celebrate");
                      }}
                      width={20}
                      height={20}
                    />
                    <img
                      alt="react"
                      src={hahaEmoji}
                      onClick={() => {
                        reactMessage(chat._id, "haha");
                      }}
                      width={20}
                      height={20}
                    />
                    <img
                      alt="react"
                      src={sadEmoji}
                      onClick={() => {
                        reactMessage(chat._id, "sad");
                      }}
                      width={20}
                      height={20}
                    />
                    <img
                      alt="react"
                      src={loveEmoji}
                      onClick={() => {
                        reactMessage(chat._id, "love");
                      }}
                      width={20}
                      height={20}
                    />
                    <img
                      alt="react"
                      src={clapEmoji}
                      onClick={() => {
                        reactMessage(chat._id, "clap");
                      }}
                      width={20}
                      height={20}
                    />
                  </div>
                </Popover>
                <Ellipsis
                  style={{
                    rotate: "90deg",
                    color: "grey",
                    cursor: "pointer",
                  }}
                  size={20}
                  onClick={handleClickReport}
                />
                <Popover
                  id={idReport}
                  open={openReport}
                  anchorEl={anchorElReport}
                  onClose={handleCloseReport}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      padding: "5px",
                      paddingLeft: "13px",
                      paddingRight: "13px",
                      cursor: "pointer",
                    }}
                    onClick={handleOpenModal}
                  >
                    Report
                  </div>
                </Popover>
                <Modal
                  open={openModal}
                  onClose={handleCloseModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <img
                        src={reportImg}
                        alt="report png"
                        width={140}
                        height={140}
                      />
                      <p
                        style={{
                          color: "#313335",
                          fontSize: "17px",
                          marginTop: "10px",
                        }}
                      >
                        Report User
                      </p>
                      <p
                        style={{
                          color: "#F37121",
                          fontSize: "17px",
                          marginTop: "10px",
                        }}
                      >
                        Encountered something inappropriate? Let us know!
                      </p>
                      <p style={{ color: "#62666A", marginTop: "10px" }}>
                        Are you sure you want to report this user? This action
                        can't be undone.
                      </p>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "25px",
                          width: "300px",
                          justifyContent: "space-between",
                        }}
                      >
                        <button
                          style={{
                            border: "none",
                            cursor: "pointer",
                            padding: "10px 20px",
                            borderRadius: "7px",
                          }}
                          onClick={handleCloseModal}
                        >
                          Cancel
                        </button>
                        <button
                          style={{
                            backgroundColor: "#0C5BA0",
                            cursor: "pointer",
                            color: "white",
                            border: "none",
                            padding: "10px 30px",
                            borderRadius: "7px",
                          }}
                          onClick={reportUserHandler}
                        >
                          Report
                        </button>
                      </div>
                    </div>
                  </Box>
                </Modal>
              </div>
            </Grid>
            <Grid item xs={12}></Grid>
          </Grid>
        </ListItem>
      )}

      <ReportSuccessModal
        messagetype="report"
        openSuccess={openSuccess}
        handleCloseSuccessModal={handleCloseSuccessModal}
      />
    </div>
  );
};

export default MessageSection;
