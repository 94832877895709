import React from 'react'
import pink from '../../assets/images/landigpage2/pink.png'
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router';

export default function Third() {
    const mobileView = useMediaQuery("(max-width:600px)");
   const navigate=  useNavigate()
    return (

        <Box sx={{ height: { xs: '835px', md: "529px" }, backgroundColor: { xs: '#FFFFFF', md: "#F8F4EE" } }}>
            <Grid container spacing={2} sx={{ paddingLeft: { xs: '5vw', md: '2vw' } }}>

                <Grid item xs={12} md={6}>
                    {mobileView && <p className={mobileView ? 'font20' : 'font36'} style={{ color: '#323232', paddingTop: '20px', textAlign: 'center' }}> If you are from <span style={{ color: '#E22F9D' }}>Aurangabad </span> or <br /> any other town nearby, here’s <br /> your chance to shine!</p>}

                    <img src={pink} className={mobileView ? 'width100' : ''} />

                </Grid>

                <Grid item xs={12} md={6}>

                    {!mobileView && <p className={mobileView ? 'font20' : 'font36'} style={{ color: '#323232', paddingTop: '20px' }}> If you are from <span style={{ color: '#E22F9D' }}>Aurangabad </span> or <br /> any other town nearby, here’s <br /> your chance to shine!</p>}
                    <p className={mobileView ? 'font14 texaling' : 'font24popins'} style={{ paddingTop: 20, color: '#323232' }}>We are coming to your city to find the best <br />young cricketers amongst the hundreds of <br /> enthusiasts.</p>

                    <div className='flexbox3 font14 carrybag'>
                        <p style={{ fontWeight: '600' }} > Open Selection Trial</p>
                        <div className='flexbox2' style={{ width: 333 }}>
                            <div style={{ width: 155, borderRight: '2px solid gray' }}>
                                <p> MGM Cricket Stadium</p>
                                <p>Aurangabad  </p>
                            </div>
                            <div>
                                <p> 16th Jan - 20th Jan, 2023</p>
                                <p>08:00 AM - 05:00 PM </p>
                            </div>
                        </div>
                    </div>

                  
                    <div style={{}}> <Button size="small" style={{ backgroundColor: '#0C5BA0', color: '#FFFFFF', height: 42, width: 178, marginTop: '20px' }} className="font16" onClick={()=>navigate('/signup')}  >Register Now </Button></div>
              
                    <div>

                    </div>
                </Grid>

            </Grid>

        </Box>
    )
}
