import React, { useState, useEffect, useRef } from "react";
import champLogo from "../../assets/images/icons/storyIcon.png";
import {
  Modal,
  IconButton,
  Box,
  Typography,
  Avatar,
  LinearProgress,
  Popover,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Button } from "@mui/material";
// import "./index.scss"
import { getStorageItem } from "../../utils/sessionStorage";
import axios from "axios";
import animation from "../../assets/images/Animation.gif";

import {
  PlayCircleFilled as PlayCircleFilledIcon,
  PauseCircleFilled as PauseCircleFilledIcon,
  VolumeUp as VolumeUpIcon,
  VolumeOff as VolumeOffIcon,
  ArrowBackIos as ArrowBackIosIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
  Close as CloseIcon,
} from "@mui/icons-material";

const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  backgroundColor: "white",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const ViewPersonalStory = ({
  handleOpenStory,
  openModalView,
  setOpenModalView,
  myStories,
  handleAfterStoryDelete,
}) => {
  const [isPlaying, setIsPlaying] = useState(true);
  const [isMuted, setIsMuted] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentStoryIndex, setCurrentStoryIndex] = useState(0);
  const [progress, setProgress] = useState([]);
  const [views, setViews] = useState([]);
  const [isImage, setIsImage] = useState(false);
  const [fetchGivenRuns, setFetchGivenRuns] = useState([]);
  const [totalGivenRuns, setTotalGivenRuns] = useState(0);
  const [allUsers, setAllUsers] = useState([]);
  const avatar = getStorageItem("avatar");
  const [duration, setDuration] = useState(10000); // Default duration for images (10 seconds)

  const accessToken = getStorageItem("token");

  const userId = getStorageItem("user_id");

  const storyRef = useRef(null);
  const [media, setMedia] = useState("");
  const [timeDifference, setTimeDifference] = useState(null);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openView, setOpenView] = React.useState(false);
  const imageTimeoutRef = useRef(null);
  const [imageProgress, setImageProgress] = useState(0);

  useEffect(() => {
    if (openModalView) {
      setCurrentIndex(0);
      setIsPlaying(true);
      setProgress(new Array(myStories.length).fill(0));
    }
  }, [openModalView]);
  const getGivenRuns = async () => {
    try {
      if (!accessToken) return;
      const options = {
        method: "get",
        url:
          global.config.ROOTURL.prod +
          `/story/getRun/${myStories[currentIndex]._id}`,
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      };

      axios(options)
        .then(({ data }) => {
          console.log(data.UsersWhoGaveRuns);
          setFetchGivenRuns(data.UsersWhoGaveRuns);
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            console.log("Unauthorized:", error);
          } else {
            console.log("Error fetching runs:", error);
          }
        });
    } catch (error) {
      console.log("error in fetch runs");
    }
  };

  useEffect(() => {
    if (myStories[currentIndex] && myStories[currentIndex].media) {
      setMedia(myStories[currentIndex].media);
      getStoryViews();
      getGivenRuns();
      setAllUsers([...fetchGivenRuns, ...views]);

      if (isVideo(myStories[currentIndex].media)) {
        const video = document.createElement("video");
        video.src = myStories[currentIndex].media;
        video.onloadedmetadata = () => {
          setDuration(video.duration * 1000); // Convert to milliseconds
        };
      } else {
        setDuration(10000); // 10 seconds for images
      }

      setProgress((prevProgress) => {
        const newProgress = [...prevProgress];
        newProgress[currentIndex] = 0;
        return newProgress;
      });
    }
  }, [currentIndex, myStories]);

  useEffect(() => {
    if (storyRef.current) {
      if (isPlaying) {
        storyRef.current.play();
      } else {
        storyRef.current.pause();
      }
    }
  }, [isPlaying]);

  useEffect(() => {
    let timer;
    if (isPlaying) {
      const startTime = Date.now() - (progress[currentIndex] * duration) / 100;
      timer = setInterval(() => {
        const elapsedTime = Date.now() - startTime;
        const newProgress = Math.min((elapsedTime / duration) * 100, 100);
        setProgress((prevProgress) => {
          const newProgressArray = [...prevProgress];
          newProgressArray[currentIndex] = newProgress;
          return newProgressArray;
        });

        if (newProgress >= 100) {
          clearInterval(timer);
          slideNext();
        }
      }, 30); // Update more frequently for smoother progress
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [currentIndex, isPlaying, duration]);
  useEffect(() => {
    let timer;
    if (isPlaying) {
      const startTime = Date.now() - (progress[currentIndex] * duration) / 100;
      timer = setInterval(() => {
        const elapsedTime = Date.now() - startTime;
        const newProgress = Math.min((elapsedTime / duration) * 100, 100);
        setProgress((prevProgress) => {
          const newProgressArray = [...prevProgress];
          newProgressArray[currentIndex] = newProgress;
          return newProgressArray;
        });

        if (newProgress >= 100) {
          clearInterval(timer);
          slideNext();
        }
      }, 30); // Update more frequently for smoother progress
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [currentIndex, isPlaying, duration]);

  useEffect(() => {
    if (myStories[currentIndex]?.createdDate) {
      const timestamp = new Date(myStories[currentIndex].createdDate);
      const currentTime = new Date();
      const millisecondsDifference = currentTime - timestamp;

      // Convert milliseconds to seconds
      const secondsDifference = millisecondsDifference / 1000;

      // Calculate minutes, hours, and remaining seconds
      const minutes = Math.floor(secondsDifference / 60);
      const hours = Math.floor(minutes / 60);

      if (hours > 0) {
        setTimeDifference(`${hours} hours`);
      } else if (minutes > 0) {
        setTimeDifference(`${minutes} minutes`);
      } else {
        setTimeDifference(`${Math.floor(secondsDifference)} seconds`);
      }
    }
  }, [currentIndex, myStories]);

  const getStoryViews = () => {
    if (!accessToken) return;
    const options = {
      method: "get",
      url:
        global.config.ROOTURL.prod +
        `/story/getStoryViews/${myStories[currentIndex]._id}`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };

    axios(options)
      .then(({ data }) => {
        setViews([...data]);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          console.log("Unauthorized:", error);
        } else {
          console.log("Error fetching stories:", error);
        }
      });
  };
  const slideNext = () => {
    if (currentIndex < myStories.length - 1) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
      setIsPlaying(true);
    } else {
      setOpenModalView(false);
    }
  };

  const slidePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
      setIsPlaying(true);
    }
  };

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  const toggleMute = () => {
    if (storyRef.current) {
      storyRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  const handleAvatarClick = (profileData) => {
    console.log("Profile Clicked:", profileData);
    setOpenView(true);
    // Implement logic for handling avatar click
  };

  const handleDeleteStory = () => {
    try {
      const options = {
        method: "delete",
        url:
          global.config.ROOTURL.prod +
          `/story/deleteStory/${myStories[currentIndex]._id}`,
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      };

      axios(options)
        .then(({ data }) => {
          console.log(data);
          setOpenModalView(false);
          handleAfterStoryDelete();
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {}
    console.log("Delete Story Clicked");
    handleAfterStoryDelete();
    handleClose();
  };

  const textStyle = {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "14px",
    color: "#FFFFFF",
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    width: "100%",
    backgroundColor: "#141414",
  };
  console.log(views);

  return (
    <Modal
      open={openModalView}
      onClose={() => setOpenModalView(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            position: "absolute",
            top: 25,
            left: "8.4rem",
            "@media (max-width: 769px)": {
              display: "none",
            },
          }}
        >
          <Avatar
            src={champLogo}
            alt=""
            sx={{ width: "30px", height: "30px" }}
          />
          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: "23px",
              fontWeight: "500",
              lineHeight: "23px",
              color: "#FFFFFF",
            }}
          >
            CHAMPHUNT
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "4%",
            "@media (max-width: 769px)": {
              gap: "1%",
              // position: "absolute",
            },
          }}
        >
          <IconButton
            onClick={slidePrev}
            sx={{
              width: "40px",
              height: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "#494D50",
              borderRadius: "50%",
              "&:hover": {
                background: "#494D50",
              },
              "@media (max-width: 769px)": {
                // display:"none"
                width: "30px",
                height: "30px",
                position: "relative",
                left: "+35px",
                zIndex: "1",
              },
            }}
          >
            <ArrowBackIosIcon />
          </IconButton>
          <Box
            sx={{
              width: "30%",
              height: "93vh",
              position: "relative",
              "@media (max-width: 769px)": {
                width: "100%",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "100px",
                display: "flex",
                flexDirection: "column",
                padding: "15px 15px 0px 15px",
                gap: "25px",
                backgroundImage:
                  "linear-gradient(180deg, rgba(38, 38, 38, 0.8) 0%, rgba(38, 38, 38, 0) 100%)",
              }}
            >
              <Box sx={{ display: "flex", gap: "5px" }}>
                {myStories.map((story, index) => (
                  <LinearProgress
                    key={index}
                    variant="determinate"
                    value={progress[index] || 0}
                    sx={{
                      flexGrow: 1,
                      height: "2px",
                      backgroundColor: "#FFFFFF59",
                      "& .MuiLinearProgress-bar": {
                        backgroundColor:
                          index <= currentIndex ? "#FFFFFF" : "#FFFFFF59",
                      },
                    }}
                  />
                ))}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Avatar
                    src={champLogo}
                    alt=""
                    sx={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                    }}
                  />
                  <Typography sx={textStyle}></Typography>
                  <Typography sx={textStyle}>{timeDifference}</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <IconButton
                    onClick={togglePlayPause}
                    sx={{
                      "@media (max-width: 769px)": {
                        display: "none",
                      },
                    }}
                  >
                    {isPlaying ? (
                      <PauseCircleFilledIcon sx={{ color: "#FFFFFF" }} />
                    ) : (
                      <PlayCircleFilledIcon sx={{ color: "#FFFFFF" }} />
                    )}
                  </IconButton>
                  <IconButton
                    onClick={toggleMute}
                    sx={{
                      "@media (max-width: 769px)": {
                        display: "none",
                      },
                    }}
                  >
                    {isMuted ? (
                      <VolumeOffIcon sx={{ color: "#FFFFFF" }} />
                    ) : (
                      <VolumeUpIcon sx={{ color: "#FFFFFF" }} />
                    )}
                  </IconButton>
                  <IconButton>
                    <DeleteOutlineIcon
                      onClick={handleOpen}
                      style={{ color: "white" }}
                    />
                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style1}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              textAlign: "center",
                              width: "300px",
                              borderRadius: "8px",
                            }}
                          >
                            <img
                              src={animation}
                              alt="Animation"
                              style={{
                                width: "150px",
                                borderRadius: "8px 8px 0 0",
                              }}
                            />
                            <div style={{ padding: "20px" }}>
                              <h3>Delete this story?</h3>
                              <p
                                style={{
                                  fontSize: "13px",
                                  marginBottom: "5px",
                                }}
                              >
                                Are you sure you want to delete this story?
                              </p>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  marginTop: "20px",
                                }}
                              >
                                <button
                                  style={{
                                    color: "gray",
                                    border: "1px solid black",
                                    borderRadius: "5px",
                                    padding: "10px 35px",
                                    marginRight: "10px",
                                    cursor: "pointer",
                                  }}
                                  onClick={handleClose}
                                >
                                  Cancel
                                </button>
                                <button
                                  style={{
                                    backgroundColor: "#285a9b",
                                    color: "white",
                                    border: "none",
                                    borderRadius: "5px",
                                    padding: "10px 35px",
                                    cursor: "pointer",
                                  }}
                                  onClick={handleDeleteStory}
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Box>
                    </Modal>
                  </IconButton>
                  <IconButton>
                    <CloseIcon
                      onClick={() => setOpenModalView(false)}
                      sx={{ color: "#FFFFFF" }}
                    />
                  </IconButton>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                height: `calc(93vh - 100px)`,
              }}
            >
              {media && isVideo(media) ? (
                <video
                  ref={storyRef}
                  key={myStories[currentIndex].media}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  src={media}
                  type="video/mp4"
                  loop={false}
                  autoPlay
                  muted={isMuted}
                  onPlay={() => setIsPlaying(true)}
                  onPause={() => setIsPlaying(false)}
                />
              ) : (
                media && (
                  <img
                    src={media}
                    alt="Story Content"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                )
              )}
              {!media && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                    padding: "20px",
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      color: "#FFFFFF",
                      fontWeight: "bold",
                      marginBottom: "20px",
                      textAlign: "center",
                    }}
                  >
                    Share Your Story!
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#CCCCCC",
                      textAlign: "center",
                      maxWidth: "80%",
                      marginBottom: "20px",
                    }}
                  >
                    You haven't uploaded a story yet. Share your moments,
                    achievements, or exciting updates with your friends and
                    followers!
                  </Typography>
                  {/* <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#285a9b",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#1c4170",
                      },
                    }}
                    onClick={handleOpenStory}
                  >
                    Upload a Story
                  </Button> */}
                </Box>
              )}
            </Box>
            <Box
              sx={{
                position: "absolute",
                bottom: "0",
                width: "100%",
                height: "91px",
                display: "flex",
                left: "0",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0 15px",
              }}
              className="viewers"
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  width: "50px",
                  height: "32px",
                }}
              >
                {views.slice(0, 4).map((view, index) => (
                  <Avatar
                    key={index}
                    src={view.profilePhoto}
                    alt="profile photo"
                    onClick={() => handleAvatarClick(view)}
                    sx={{
                      position: "absolute",
                      right: `${index * -20}px`,
                      width: "30px",
                      height: "30px",
                      borderRadius: "50%",
                      border: "2px solid #141414",
                      zIndex: views.length - index,
                      cursor: "pointer",
                    }}
                  />
                ))}
              </Box>
              <Typography sx={textStyle}>
                Seen by {views.length || 0}
              </Typography>
              <Modal
                open={openView}
                onClose={() => setOpenView(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box
                  sx={{
                    width: "498px",
                    height: "520px",
                    margin: "auto",
                    borderRadius: "16px",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    background: "rgba(38, 38, 38, 1)",
                    display: "flex",
                    flexDirection: "column",
                    "@media (max-width: 769px)": {
                      width: "350px",
                    },
                  }}
                >
                  <Box
                    sx={{
                      marginTop: "20px",
                      width: "65px",
                      height: "38px",
                      marginLeft: "40%",
                    }}
                  >
                    <p className="viewers-heading">Viewers</p>
                  </Box>
                  <div className="line-viewer"></div>

                  <Box
                    sx={{
                      marginTop: "10px",
                      padding: "0 20px",
                      overflowY: "auto",
                      maxHeight: "400px",
                    }}
                  >
                    {views.map(
                      (view, index) =>
                        view._id !== userId && (
                          <div key={index}>
                            <Box
                              key={index}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "10px",
                              }}
                            >
                              <Avatar
                                src={view.profilePhoto}
                                alt=""
                                sx={{
                                  width: "50px",
                                  height: "50px",
                                  marginRight: 2,
                                }}
                                onClick={() => {
                                  openView(true);
                                }}
                              />
                              <Box>
                                <Typography
                                  variant="body1"
                                  className="firstName"
                                >
                                  {view.firstName}
                                </Typography>
                              </Box>
                            </Box>
                          </div>
                        )
                    )}
                  </Box>
                  <IconButton
                    onClick={() => setOpenView(false)}
                    sx={{ position: "absolute", top: 8, right: 8 }}
                  >
                    <CloseIcon sx={{ color: "#ffffff" }} />
                  </IconButton>
                </Box>
              </Modal>
            </Box>
          </Box>
          <IconButton
            onClick={slideNext}
            sx={{
              width: "40px",
              height: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "#494D50",
              borderRadius: "50%",
              "&:hover": {
                background: "#494D50",
              },
              "@media (max-width: 769px)": {
                // display: "none",
                width: "30px",
                height: "30px",
                position: "relative",
                left: "-35px",
                zIndex: "1",
              },
            }}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </Box>
        <IconButton
          onClick={() => setOpenModalView(false)}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            "@media (max-width: 769px)": {
              display: "none",
            },
          }}
        >
          <CloseIcon sx={{ color: "#FFFFFF" }} />
        </IconButton>
      </Box>
    </Modal>
  );
};

export default ViewPersonalStory;

function isVideo(url) {
  const videoExtensions = ["mp4", "webm", "ogg"];
  const extension = url.split(".").pop().toLowerCase();
  return videoExtensions.includes(extension);
}
