import { Box, Typography } from "@material-ui/core";
import React from "react";
import CarouselCreationForm from "../../components/carousel-creation-form";
import DealCreationForm from "../../components/deal-creation-form"
// import EventCreationForm from "../../components/event-creation-form";

const DealCreate = () => {
  return (
    <Box>
      <Typography variant="h4">Create new deal</Typography>
      {/* <Typography variant="p">
        Want to create a new event. Register an event here in few minutes.
      </Typography> */}
      <Box marginTop={2}>
        {/* <CarouselCreationForm />
         */}
         <DealCreationForm />
      </Box>
    </Box>
  );
};

export default DealCreate;
