import React, { useState, useEffect } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FantasyRunIcon from "../../assets/images/fantasyy/runsipl.png";
import FantassyModal from "./FantassyModal";
import DcAvatar from "../../assets/images/fantasyy/dcavatar.png";
import SrhAvatar from "../../assets/images/fantasyy/srhavatar.png";
import LsgAvatar from "../../assets/images/fantasyy/lsgavatr.png";
import KkrAvatar from "../../assets/images/fantasyy/kkravatr.png";
import GtAvatar from "../../assets/images/fantasyy/gtavatar (2).png";
import RrAvatar from "../../assets/images/fantasyy/rravatr.png";
import PbkAvatar from "../../assets/images/fantasyy/pbk.png";
import RcbAvatar from "../../assets/images/fantasyy/rcbavatar.png";

import AFGAvatar from "../../assets/images/fantasyy/WCavatar/AFG.png"
import AUSAvatar from "../../assets/images/fantasyy/WCavatar/AUS.png"
import BANAvatar from "../../assets/images/fantasyy/WCavatar/BAN.png"
import CANAvatar from "../../assets/images/fantasyy/WCavatar/CAN.png"
import ENGAvatar from "../../assets/images/fantasyy/WCavatar/ENG.png"
import INDAvatar from "../../assets/images/fantasyy/WCavatar/IND.png"
import IREAvatar from "../../assets/images/fantasyy/WCavatar/IRE.png"
import NEPAvatar from "../../assets/images/fantasyy/WCavatar/NEP.png"
import NZAvatar from "../../assets/images/fantasyy/WCavatar/NZ.png"
import PAKAvatar from "../../assets/images/fantasyy/WCavatar/PAK.png"
import SAAvatar from "../../assets/images/fantasyy/WCavatar/SA.png"
import SCOAvatar from "../../assets/images/fantasyy/WCavatar/SCO.png";
import SLAvata from "../../assets/images/fantasyy/WCavatar/SL.png";
import NAMAvatar from "../../assets/images/fantasyy/WCavatar/NAM.png";
import WIAvatar from  "../../assets/images/fantasyy/WCavatar/WI.png";
import UGAAvatar from  "../../assets/images/fantasyy/WCavatar/UGA.png";
import PNGAvatar from  "../../assets/images/fantasyy/WCavatar/PNG.png";
import NETAvatar from  "../../assets/images/fantasyy/WCavatar/NET.png";
import USAAvatar from  "../../assets/images/fantasyy/WCavatar/USA.png";
import OMAAvatar from  "../../assets/images/fantasyy/WCavatar/OMA.png";
import ZIMAvatar from  "../../assets/images/fantasyy/WCavatar/ZIM.png";


// import CskAvatar from "../../assets/images/fantasyy/cskimg.png";
import CskAvatar from "../../assets/images/fantasyy/cropcsk.png";
import MiAvatar from "../../assets/images/fantasyy/mumbaiimg.png";
import ConfirmationModal from "./ConfirmationModal";
import PlayerScorePageModal from "./PlayerScorePage";
import SufficientRunsModal from "./SufficientRunsModal";
import { getAuthToken } from "../../utils/auth";
import { clearStorage, getStorageItem } from "../../utils/sessionStorage";
import axios from "axios";
import "./_index.scss";

const PlayerCard2 = ({
  color,
  playerData,
  onClick,
  setSelectedPlayersCount,
  onBuyClick,
  isFantassyModalOpen,
}) => {
  const { playerDetails, basePrice} = playerData;
  const { playerName,  role, team,country} = playerDetails;
  const [isAdded2, setIsAdded2] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isPlayerScorePageModalOpen, setIsPlayerScorePageModalOpen] =
    useState(false);
    const [isSufficientRunsModalOpen, setIsSufficientRunsModalOpen] =
    useState(false);
  const [isConfirmed, setIsConfirmed] = useState(playerData?.played);
  const [played, setPlayed] = useState(false);
  const [isAdded, setIsAdded] = useState(false);
  const [userRuns, setUserRuns] = useState(0);
  const userId = getStorageItem("user_id");
    const accessToken = getAuthToken();
 
  const teamAvatars = {
    Chennai: CskAvatar,
    Mumbai: MiAvatar,
    Gujarat: GtAvatar,
    Kolkata: KkrAvatar,
    Lucknow: LsgAvatar,
    Punjab: PbkAvatar,
    Rajasthan: RrAvatar,
    Bangalore: RcbAvatar,
    Delhi: DcAvatar,
    Hyderabad: SrhAvatar,
    AFG:AFGAvatar,
    AUS:AUSAvatar,
    BAN:BANAvatar,
    CAN:CANAvatar,
    ENG:ENGAvatar,
    IND:INDAvatar,
    IRE:IREAvatar,
    NEP:NEPAvatar,
    NZ:NZAvatar,
    PAK:PAKAvatar,
    SA:SAAvatar,
    SCO:SCOAvatar,
    SL:SLAvata,
    NAM:NAMAvatar,
    WI:WIAvatar,
    UGA:UGAAvatar,
    PNG:PNGAvatar,
    NET:NETAvatar,
    USA:USAAvatar,
    OMA:OMAAvatar,
    ZIM:ZIMAvatar,   
  };
  const teamGradients = {
    Chennai: "linear-gradient(180deg, #F7BA07 0%, #EF6116 100%)",
    Mumbai: "linear-gradient(180deg, #0276B9 0%, #0C3A83 100%)",
    Gujarat: "linear-gradient(180deg, #1D1E22 0%, #0E456C 100%)",
    Kolkata: "linear-gradient(180deg, #3D2360 0%, #59298C 100%)",
    Lucknow: "linear-gradient(180deg, #A02A4C 0%, #F6C626 100%)",
    Punjab: "linear-gradient(180deg, #C72127 0%, #821A1B 100%)",
    Rajasthan: "linear-gradient(180deg, #AD2770 0%, #081F41 100%)",
    Bangalore: "linear-gradient(98.3deg, rgb(0, 0, 0) 10.6%, rgb(255, 0, 0) 97.7%)",
    Delhi: "linear-gradient(180deg, #1665A7 0%, #033C7F 100%)",
    Hyderabad: "linear-gradient(180deg, #F06925 0%, #CF3E20 100%)",
    IND: "linear-gradient(180deg, #1C1C1C 0%, #87CEEB 100%)",
    PAK: "linear-gradient(180deg, #006400 0%, #228B22 100%)",
    AFG: "linear-gradient(180deg, #008080 0%, #4682B4 100%)",
    AUS: "linear-gradient(180deg, #FFD700 0%, #FF8C00 100%)",
    BAN: "linear-gradient(180deg, #006A4E 0%, #A52A2A 100%)",
    CAN: "linear-gradient(180deg, #FF0000 0%, #FFFFFF 100%)",
    ENG: "linear-gradient(180deg, #002147 0%, #C8102E 100%)",
    IRE: "linear-gradient(180deg, #169B62 0%, #FF883E 100%)",
    NEP: "linear-gradient(180deg, #003366 0%, #DC143C 100%)",
    NET: "linear-gradient(180deg, #21468B 0%, #FF4500 100%)",
    NZ: "linear-gradient(180deg, #000000 0%, #FFFFFF 100%)",
    OMA: "linear-gradient(180deg, #008000 0%, #FF0000 100%)",
    PNG: "linear-gradient(180deg, #FFD700 0%, #FF0000 100%)",
    SCO: "linear-gradient(180deg, #2E0854 0%, #800080 100%)",
    SA: "linear-gradient(180deg, #006341 0%, #FFD700 100%)",
    SL: "linear-gradient(180deg, #0033A0 0%, #FFD700 100%)",
    UGA: "linear-gradient(180deg, #FFCD00 0%, #D21034 100%)",
    USA: "linear-gradient(180deg, #B22234 0%, #3C3B6E 100%)",
    WI: "linear-gradient(180deg, #771900 0%, #FFD700 100%)",
    NAM: "linear-gradient(180deg, #002E63 0%, #A3C1AD 100%)",
    ZIM: "linear-gradient(180deg, #002E63 0%, #A3C1AD 100%)",
  };
  const getTeamGradient = (teamName) => {
    const formattedTeamName = teamName && teamName.trim();
    return (
      teamGradients[formattedTeamName] ||
      "linear-gradient(to right, #FFFFFF, #FFFFFF)"
    );
  };
  const getTeamAvatar = (teamName) => {
    const formattedTeamName = teamName && teamName.trim();
    return teamAvatars[formattedTeamName] || null;
  };
  const teamColorsObj = {
    Chennai: "#F7BA07",
    Mumbai: "#0276B9",
    Gujarat: "#1D1E22",
    Kolkata: "#3D2360",
    Lucknow: "#A02A4C",
    Punjab: "#C72127",
    Rajasthan: "#AD2770",
    Bangalore: "#FF0000",
    Delhi: "#1665A7",
    Hyderabad: "#F06925",
    IND: "#448EE4",
    PAK: "#448EE4",
    AFG: "#448EE4",
    AUS: "#448EE4",
    BAN: "#448EE4",
    CAN: "#448EE4",
    ENG: "#448EE4",
    IRE: "#448EE4",
    NEP: "#448EE4",
    NET: "#448EE4",
    NZ: "#448EE4",
    OMA: "#448EE4",
    PNG: "#448EE4",
    SCO: "#448EE4",
    SA: "#448EE4",
    SL: "#448EE4",
    UGA: "#448EE4",
    USA: "#448EE4",
    WI: "#448EE4",
    NAM: "#448EE4",
    ZIM: "#448EE4",
  };

  const getTeamColors = (teamName) => {
    const formattedTeamName = teamName && teamName.trim();
    return teamColorsObj[formattedTeamName] || "#FFFFFF";
  };

  // const handleAddClick2 = () => {
  //   setIsConfirmationModalOpen(true);
  //   setIsAdded2(true);
  //   if (onBuyClick) {
  //     onBuyClick();
  //   }
  // };
  const getRuns = () => {
    if (!userId) return console.error("userId required");
  
    const getTotalRun = {
      method: "GET",
      url: global.config.ROOTURL.prod + "/pitch/getTotalRun/" + userId,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };
  
    axios(getTotalRun)
      .then((response) => {
        setUserRuns(response.data);
      })
      .catch((error) => {
        console.error("Error fetching user runs:", error);
      });
  };
  const handleAddClick2 = () => {
    if (basePrice > userRuns) {
      setIsSufficientRunsModalOpen(true);
    } else {
      setIsConfirmationModalOpen(true);
      setIsAdded(true);
      if (onBuyClick) {
        onBuyClick();
      }
    }
  };
 

  const handleModalClose = (isConfirmed, hasPlayed) => {
    if (isConfirmed) {
      // setIsAdded2(true);
      // setSelectedPlayersCount((prevCount) => prevCount + 1);
      setPlayed(hasPlayed);
    }
    setIsConfirmationModalOpen(false);
    setIsPlayerScorePageModalOpen(false);
  };

  const handleClick = () => {
    setIsPlayerScorePageModalOpen(true);
    if (onClick) {
      onClick(playerData);
    }
  };
  useEffect(() => {
    getRuns();
}, []);
  return (
    <div className="card-main-cont">
      {/* {second Player Team} */}
      <div
        className="Player-box"
        style={{
          background: getTeamGradient(country),
        }}
      >
        {isConfirmed ? (
          <CheckCircleIcon
            className="addicon-fantasy"
            style={{ color: getTeamColors(country) }}
          />
        ) : (
          <AddCircleIcon
            className="addicon-fantasy"
            style={{ color: getTeamColors(country) }}
            onClick={handleAddClick2}
          />
        )}

        <div className="fantasycard-content-cont">
          <img
            src={getTeamAvatar(team)}
            className="CradPlayerimg"
            onClick={handleClick}
          />

          <div className="content-wrapper-fantassy">
            <div className="Player-name-conatiner">
              <p className="player-name">{playerName}</p>
              <p className="player-role">{role}</p>
            </div>

            <div className="player-team-container">
              <div className="player-team-wrapper">
                <p className="player-team">Team Name</p>
                <p className="player-team-name">{country}</p>
              </div>

              <div className="price-container">
                <p className="price-label">Price</p>
                <div className="price-details">
                  <img
                    src={FantasyRunIcon}
                    className="price-icon"
                    alt="Fantasy Run Icon"
                  />
                  <p className="runs">{basePrice}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isConfirmationModalOpen && (
        <ConfirmationModal
          open={isConfirmationModalOpen}
          onClose={(isConfirmed) => handleModalClose(isConfirmed)}
          playerName={playerName}
          baseRuns={basePrice}
          playerData={playerData}
          onYesClick={() => setIsConfirmed(true)}
          played={played}
        />
      )}
      {isPlayerScorePageModalOpen && (
        <PlayerScorePageModal
          open={isPlayerScorePageModalOpen}
          onClose={() => setIsPlayerScorePageModalOpen(false)}
          playerData={playerData}
          teamAvatars={teamAvatars}
          teamGradient={teamGradients} 
        />
      )}
      {isSufficientRunsModalOpen && (
      <SufficientRunsModal
        open={isSufficientRunsModalOpen}
        onClose={() => setIsSufficientRunsModalOpen(false)}
        requiredRuns={basePrice - userRuns}
      />
      )}
    </div>
  );
};

export default PlayerCard2;
