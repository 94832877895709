import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import { Modal, Box } from "@mui/material";
import { toast } from "react-toastify";
import { getStorageItem } from "../../../utils/sessionStorage";
import { FormControl,InputLabel,MenuItem,Select } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
const userID = getStorageItem("user_id");
const accessToken = getStorageItem("token");
const moment = require("moment");

const Index = (props) => {
  const [name, setName] = useState("");
  const [runs, setRuns] = useState(0);
  const [team1, setteam1] = useState(props?.matchData?.team1);
  const [team2, setteam2] = useState(props?.matchData?.team2);
  const handleEditPlayer = async () => {
    if (!accessToken) return
    
    const data1 = {
      id: props?.matchData?._id,
      matchName: name ? name : props?.matchData?.matchName,
      runsRequired: runs?runs: props?.matchData?.runsRequired,
      team1: team1?team1: props?.matchData?.team1,
      team2: team2?team2: props?.matchData?.team2,
      
    };
    const options = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/contest/fantasy/updateMatch",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
        role: "Admin",
      },
      data: data1,
    };
    axios(options)
      .then(({ data }) => {
        toast.success(" Match Edited successfully");
        props.setData(data)
        props.handleClose();
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          console.log(error);
        }
      });
  };
  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <div className="contest-admin-create">
          <div className="contest-admin-create-wrapper">
            <div className="contest-admin-create-wrapper-main">
              <Box style={{ float: "right" }}>
                <IconButton onClick={props.handleClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <div className="contest-admin-create-wrapper-header">
                <p className="contest-admin-create-wrapper-header--text">Edit Match</p>
              </div>
              
              <div className="contest-admin-create-wrapper-form">
                <div className="contest-create-name-wrapper">
                  Match Name-
                  <input
                    type="text"
                    className="contest-create-name"
                    placeholder="Match Name"
                    defaultValue={props?.matchData?.matchName}
                    onChange={(event) => {
                      setName(event.target.value);
                    }}
                    required
                  />
                  </div>
                  <div className="contest-create-name-wrapper">
                  Runs required-
                  <input
                    type="text"
                    className="contest-create-name"
                    placeholder="runs"
                    defaultValue={props?.matchData?.runsRequired}
                    onChange={(event) => {
                      setRuns(event.target.value);
                    }}
                    required
                  />
                  </div>
                  <div className="contest-create-name-wrapper">
                  Team 1 -
                  <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                 <InputLabel id="demo-select-small-label">Team1</InputLabel>
                    <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={team1}
                  label="Team1"
                  onChange={(event)=>{
                    setteam1(event.target.value);
                  }}
                >
                  <MenuItem value="">
                    <em>Team 1</em>
                  </MenuItem>
                  {['IND', 'PAK', 'IRE', 'CAN', 'USA', 'ENG', 'AUS', 'NAM', 'SCO', 'OMA', 'NZ', 'WI', 'AFG', 'UGA', 'PNG', 'SA', 'SL', 'BAN', 'NET', 'NEP','ZIM'].map((item) => (
                      <MenuItem key={item} value={item}>{item}</MenuItem>
                  ))}
                      </Select>
                      </FormControl>
                  </div>
                  <div className="contest-create-name-wrapper">
                    Team 2 -
                  <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                      <InputLabel id="demo-select-small-label">Team 2</InputLabel>
                    <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={team2}
                  label="Team2"
                  onChange={(event)=>{
                    setteam2(event.target.value);
                  }}
                >
                  <MenuItem value="">
                    <em>Team 2</em>
                  </MenuItem>
                  {['IND', 'PAK', 'IRE', 'CAN', 'USA', 'ENG', 'AUS', 'NAM', 'SCO', 'OMA', 'NZ', 'WI', 'AFG', 'UGA', 'PNG', 'SA', 'SL', 'BAN', 'NET', 'NEP','ZIM'].map((item) => (
                      <MenuItem key={item} value={item}>{item}</MenuItem>
                  ))}
                      </Select>
      
              </FormControl>
                 </div>
                 
                
                
                
                <button
                  onClick={handleEditPlayer}
                  className="contest-preediction-admin-creation-submit-button"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Index;
