import {
  Grid,
  TextField,
  Button,
  Box,
  FormControl,
  InputAdornment,
  Paper, Select, InputLabel, MenuItem
} from "@mui/material";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { clearStorage, getStorageItem } from "../../utils/sessionStorage";
import Compressor from "compressorjs";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment/moment";
// jodit-react
import JoditEditor from "jodit-react";

const EditDealComponent = (props) => {
  const [status, setStatus] = useState(true)
  const [redeemableOnce, setRedeemableOnce] = useState(false);
  const [dealIsActive, setDealIsActive] = useState("");
  const [dealName, setDealName] = useState("");
  const [startTime, setStartTime] = useState("");
  const [dealDescription, setDealDescription] = useState("");
  const [state, setState] = useState("");
  const [startDate, setStartDate] = useState("");
  const [dealImage, setDealImage] = useState("");
  const [redeemRun, setRedeemRun] = useState("");
  const [Rundeal, setRunDeal] = useState(false);
  const [RunDealAmount, setRunDealAmount] = useState(0);
  const [emailId, setEmailId] = useState("");
  const [dealCode, setDealCode] = useState("");
  const [dealCount, setDealCount] = useState(0);
  const [priority, setPriority] = useState();
  const [totalDeal, setTotalDeal] = useState(0);
  const [endTime, setEndTime] = useState("");
  const [endDate, setEndDate] = useState("");
  const [discount, setDiscount] = useState(0);
  const [runcondition, setruncondition] = useState({
    lowerCount: 0,
    upperCount: 0
  });
  const [discountEndTime, setDiscountEndTime] = useState("");
  const [discountEndDate, setDiscountEndDate] = useState("");
  const [discountStartTime, setDiscountStartTime] = useState("");
  const [discountStartDate, setDiscountStartDate] = useState("");
  const [isUserInfoApiCall, setIsUserInfoApiCall] = useState(false);
  const [error, setError] = useState(false);
  const [appOnly, setAppOnly] = useState(false);
  const [category, setCategory] = useState("");
  const [isLocked, setIsLocked] = useState(false);
  const [conditionForUnlock, setConditionForUnlock] = useState({
    post: 0,
    comment: 0,
    highlight: 0,
  });
  const [conditionCount, setConditionCount] = useState(0);
  const accessToken = getStorageItem("token");
  const navigate = useNavigate();
  const styles = {
    paperContainer: {
      height: 300,
      width: "100%",
      backgroundImage: `url(${dealImage})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    },
  };
  const { id } = useParams();
  const fetchUserInfo = () => {
    axios
      .get(`${global.config.ROOTURL.prod}/deal/getDealById/${id}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((response) => {
        // setEndDate(response.data.endDate ? response.data.endDate : "");
        setruncondition({
          lowerCount: response?.data?.runcondition?.lowerCount,
          upperCount: response?.data?.runcondition?.upperCount
        });
        setRunDeal(response?.data?.runDeal);
        setRunDealAmount(response.data.runDealAmount)
        setEndTime(response.data.endTime ? response.data.endTime : "");
        setDealImage(response.data.dealImage ? response.data.dealImage : "");
        setEmailId(response.data.partnerEmailId ? response.data.partnerEmailId : "");
        setDealCode(
          response.data.encrptedcode ? response.data.encrptedcode : ""
        );
        setDealIsActive(response.data.active ? response.data.active : "");
        setDealName(response.data.name ? response.data.name : "");
        setDealDescription(response.data.desc ? response.data.desc : "");
        // setStartDate(response.data.startDate ? response.data.startDate : "");
        setStartTime(response.data.startTime ? response.data.startTime : "");
        // const [day, month, year] = response?.data?.startDate?.split('-');
        // setStartDate(`${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`);
        setStartDate(response.data.startDate ? moment(response.data.startDate).format("YYYY-MM-DD") : "")
        // const [day1, month1, year1] = response?.data?.endDate.split('-');
        // setEndDate(`${year1}-${month1.padStart(2, '0')}-${day1.padStart(2, '0')}`);

        setEndDate(response.data.endDate ? moment(response.data.endDate).format("YYYY-MM-DD") : "")
        setRedeemRun(response.data.redeemrun ? response.data.redeemrun : "");
        setDealCount(response.data.count ? response.data.count : 0);
        setTotalDeal(response.data.totalDeal ? response.data.totalDeal : 0)
        setAppOnly(response.data.appOnly ? true : false);
        setCategory(response.data.categories ? response.data.categories : "All");
        setIsLocked(response.data.isLocked ? true : false);
        setRedeemableOnce(response.data.redeemableOnce ? true : false);
        setConditionForUnlock({
          post: response?.data?.conditionForUnlock?.post,
          comment: response?.data?.conditionForUnlock?.comment,
          highlight: response?.data?.conditionForUnlock?.highlight,
        });
        // setConditionCount(response.data.conditionCount ? response.data.conditionCount : "")
        setDiscount(response.data.discount ? response.data.discount : 0);
        setDiscountStartTime(response.data.discountStartTime ? response.data.discountStartTime : "");
        // const [day2, month2, year2] = response?.data?.discountStartDate?.split('-');
        // setDiscountStartDate(`${year2}-${month2.padStart(2, '0')}-${day2.padStart(2, '0')}`);
        setDiscountStartDate(response.data.discountStartDate ? response.data.discountStartDate : "")
        // const [day3, month3, year3] = response?.data?.discountEndDate.split('-');
        // setDiscountEndDate(`${year3}-${month3.padStart(2, '0')}-${day3.padStart(2, '0')}`);
        setDiscountEndDate(response.data.discountEndDate ? response.data.discountEndDate : "")
        setDiscountEndTime(response.data.discountEndTime ? response.data.discountEndTime : "");
        setPriority(response?.data?.priorityNo);
      })
      .catch((error) => {
        setError(error);
        console.log("this is error:", error);
      });
  };

  const editor = useRef(null);
  // const [content, setContent] = useState("");

  const saveDeal = (event) => {
    event.preventDefault();
    if (!id) return;
    console.log("event", event);

    // const startDate = startDateRef?.current?.value;
    // const endDate = endDateRef?.current?.value;
    // const startTime = startTimeRef?.current?.value;
    // const endTime = endTimeRef?.current?.value;
    let data = {
      priorityNo: priority,
      dealId: id,
      name: dealName,
      desc: dealDescription,
      dealImage: dealImage,
      redeemrun: redeemRun,
      encrptedcode: dealCode,
      redeemableOnce,
      count: dealCount,
      totalDeal,
      runDeal: Rundeal,
      runDealAmount: RunDealAmount,
      appOnly,
      runcondition,
      conditionForUnlock,
      isLocked, categories: category,
      partnerEmailId: emailId,
      conditionCount, startTime, endTime,
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"), discount,
      discountStartDate: discount > 0 && discountStartDate !== "Invalid Date" ? moment(discountStartDate).format("YYYY-MM-DD") : "",
      discountEndDate: discount > 0 && discountEndDate !== "Invalid Date" ? moment(discountEndDate).format("YYYY-MM-DD") : "",
      discountStartTime: discount > 0 ? discountStartTime : "", discountEndTime: discount > 0 ? discountEndTime : ""
    };
    var saveEventOptions = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/deal/updateDeal",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      data: data,
    };
    axios(saveEventOptions)
      .then((response) => {
        console.log("response.data", response.data);
      })
      .then(() => navigate("/admin/update-deal"))
      .catch((error) => {
        console.log("error", error);
      });
  };

  const onChangePicture = (event) => {
    const { name, value } = event.target;
    if (name === "image") {
      if (event.target.files[0].type.includes("image")) {
        const file = event.target.files[0];
        const formData = new FormData();
        //...not using compress object to pass service the issue is that after compress object is blob so in service multer is not able to parse it
        new Compressor(file, {
          quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
          success: (compressedResult) => {
            if (compressedResult.size > 5000000) {
              setStatus(true);
            } else {
              formData.append("uploader", file);
              console.log("This is upload img function call:");
              axios
                .post(`${global.config.ROOTURL.prod}/upload-file`, formData, {
                  headers: {
                    Authorization: "Bearer " + accessToken,
                  },
                })
                .then((response) => {
                  if (response.data) {
                    console.log("Image saved successfully");
                    setDealImage(response.data[0].location);
                  } else {
                    console.log("error occured:");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
              setStatus(false);
            }
          },
        });
      }
    }
  };
  useEffect(() => {
    fetchUserInfo();
  }, []);

  return (
    // isUserInfoApiCall && (
    <Grid container columnSpacing={2}>
      <Grid item sm={6} xs={12}>
        <Box component="form" noValidate autoComplete="off">
          <Grid container columnSpacing={1}>
            {/* Title */}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  label="title"
                  InputLabelProps={{ shrink: true }}
                  id="title"
                  name="title"
                  margin="normal"
                  defaultValue={dealName}
                  multiline
                  onChange={(e) => {
                    setDealName(e.target.value);
                  }}
                />
              </FormControl>
            </Grid>
            {/* Location */}
            <Grid item xs={12} lg={4}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-adornment-amount"
                  InputLabelProps={{ shrink: true }}
                  name="Code"
                  label="Code"
                  margin="normal"
                  defaultValue={dealCode}
                  multiline
                  onChange={(e) => {
                    setDealCode(e.target.value);
                  }}
                />
              </FormControl>
            </Grid>
            {/* Price */}
            <Grid item xs={12} lg={4}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-adornment-amount"
                  label="Redeem runs"
                  startAdornment={
                    <InputAdornment position="start">INR</InputAdornment>
                  }
                  InputLabelProps={{ shrink: true }}
                  margin="normal"
                  defaultValue={redeemRun}
                  multiline
                  onChange={(e) => {
                    setRedeemRun(e.target.value);
                  }}
                />
              </FormControl>
            </Grid>
            {/* Email Id */}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  label="email"
                  InputLabelProps={{ shrink: true }}
                  id="email"
                  name="email"
                  margin="normal"
                  defaultValue={emailId}
                  multiline
                  onChange={(e) => {
                    setEmailId(e.target.value);
                  }}
                />
              </FormControl>
            </Grid>
            {/* TotalDeal */}
            <Grid item xs={12} lg={4}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-adornment-amount"
                  label="setIndividualDealCount"
                  type="number"
                  InputLabelProps={{ shrink: true }}
                  margin="normal"
                  defaultValue={totalDeal}
                  multiline
                  onChange={(e) => {
                    setTotalDeal(e.target.value);
                  }}
                />
              </FormControl>
            </Grid>
            {/* Code */}
            <Grid item xs={12} lg={4}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-adornment-amount"
                  type="number"
                  label="Count"
                  InputLabelProps={{ shrink: true }}
                  margin="normal"
                  defaultValue={dealCount}
                  multiline
                  onChange={(e) => {
                    setDealCount(e.target.value);
                  }}
                //   dangerouslySetInnerHTML={{
                //     __html: eventdescription.substring(0, 200),
                //   }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={4}>
              <FormControl fullWidth>
                <InputLabel id="event-code-label">Run Deal </InputLabel>
                <Select
                  labelId="Run Deal"
                  id="redeem-once-select"
                  value={Rundeal}
                  onChange={(e) => setRunDeal(e.target.value)}
                  label="Run Deal"
                >
                  <MenuItem value={false}>False</MenuItem>
                  <MenuItem value={true}>True</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {Rundeal && <Grid item xs={12} lg={4}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-adornment-amount"
                  label="Run Deal Reward"
                  type='number'
                  margin="normal"
                  value={RunDealAmount}
                  onChange={(e) => { setRunDealAmount(e.target.value) }}
                // multiline
                />
              </FormControl>
            </Grid>}
            <Grid item xs={12} lg={4}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-adornment-amount"
                  label="RunCondition lowerCount"
                  margin="normal"
                  type="number"
                  value={runcondition?.lowerCount}
                  onChange={(e) => {
                    setruncondition((prevState) => { return { ...prevState, lowerCount: parseInt(e.target.value, 10) } });
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={4}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-adornment-amount"
                  label="RunCondition upperCount"
                  margin="normal"
                  type="number"
                  value={runcondition?.upperCount}
                  onChange={(e) => {
                    setruncondition((prevState) => { return { ...prevState, upperCount: parseInt(e.target.value, 10) } });
                  }}
                />
              </FormControl>
            </Grid>
            {/* category */}

            <Grid item xs={12} lg={4}>
              <FormControl fullWidth>
                <InputLabel id="category-label">Category</InputLabel>
                <Select
                  labelId="category-label"
                  id="category-select"
                  value={category}
                  onChange={(e) => {
                    setCategory(e.target.value);
                  }}
                  label="Category"
                >
                  <MenuItem value="All" >All</MenuItem>
                  <MenuItem value="E-Commerce">E-Commerce</MenuItem>
                  <MenuItem value="FoodAndBeverage">Food And Beverage</MenuItem>
                  <MenuItem value="Exclusive">Exclusive</MenuItem>
                  <MenuItem value="TimeLimit">TimeLimit</MenuItem>
                  <MenuItem value="Offers">Offers</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {/* app only */}

            <Grid item xs={12} lg={4}>
              <FormControl fullWidth>
                <InputLabel id="event-code-label">App only</InputLabel>
                <Select
                  labelId="event-code-label"
                  id="event-code-select"
                  value={appOnly}
                  onChange={(e) => {
                    setAppOnly(e.target.value);
                  }}
                  label="App only"
                >
                  <MenuItem value={true} >True</MenuItem>
                  <MenuItem value={false}>False</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Start Date */}
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  id="start-date"
                  label="Start date"
                  type="date"
                  // defaultValue="2023-01-01"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={startDate}
                  onChange={(e) => {
                    setStartDate(e.target.value);
                  }}
                  margin="normal"
                />
              </FormControl>
            </Grid>
            {/* End Date */}
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  id="end-date"
                  label="End date"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={endDate}
                  onChange={(e) => {
                    setEndDate(e.target.value);
                  }}
                  margin="normal"
                  required
                />
              </FormControl>
            </Grid>
            {/* Start Time */}
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  id="start-time"
                  label="Start time"
                  type="time"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={startTime}
                  onChange={(e) => {
                    setStartTime(e.target.value);
                  }}
                  margin="normal"
                />
              </FormControl>
            </Grid>
            {/* End Time */}
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  id="end-time"
                  label="End time"
                  type="time"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={endTime}
                  onChange={(e) => {
                    setEndTime(e.target.value);
                  }}
                  margin="normal"
                />
              </FormControl>
            </Grid>
            {/* redeem once */}
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  id="end-date"
                  label="Priority"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={priority}
                  onChange={(e) => {
                    setPriority(e.target.value);
                  }}
                  margin="normal"
                  required
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={4}>
              <FormControl fullWidth>
                <InputLabel id="redeem-once-label">Redeemable once</InputLabel>
                <Select
                  labelId="redeem-once-label"
                  id="redeem-once-select"
                  value={redeemableOnce}
                  onChange={(e) => {
                    setRedeemableOnce(e.target.value);
                  }}
                  label="Redeem once"
                >
                  <MenuItem value={true} >True</MenuItem>
                  <MenuItem value={false}>False</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {/* lock or unlock */}
            <Grid item xs={12} lg={4}>
              <FormControl fullWidth>
                <InputLabel id="event-code-label">lock deal</InputLabel>
                <Select
                  labelId="event-code-label"
                  id="event-code-select"
                  value={isLocked}
                  onChange={(e) => {
                    setIsLocked(e.target.value);
                  }}
                  label="is locked"
                >
                  <MenuItem value={true} >True</MenuItem>
                  <MenuItem value={false}>False</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {/* conditions to lock */}
            {isLocked && <Grid item xs={12} lg={4}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-adornment-amount"
                  label=" post condition count"
                  type='number'
                  margin="normal"
                  value={conditionForUnlock?.post}
                  onChange={(e) => {
                    setConditionForUnlock((prevState) => { return { ...prevState, post: parseInt(e.target.value, 10) } });
                  }}
                // multiline
                />
              </FormControl>
            </Grid>}
            {isLocked && <Grid item xs={12} lg={4}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-adornment-amount"
                  label=" comment condition count"
                  type='number'
                  margin="normal"
                  value={conditionForUnlock?.comment}
                  onChange={(e) => {
                    setConditionForUnlock((prevState) => { return { ...prevState, comment: parseInt(e.target.value, 10) } });
                  }}
                // multiline
                />
              </FormControl>
            </Grid>}
            {(isLocked && appOnly) && <Grid item xs={12} lg={4}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-adornment-amount"
                  label=" highlight condition count"
                  type='number'
                  margin="normal"
                  value={conditionForUnlock?.highlight}
                  onChange={(e) => {
                    setConditionForUnlock((prevState) => { return { ...prevState, highlight: parseInt(e.target.value, 10) } });
                  }}
                // multiline
                />
              </FormControl>
            </Grid>}
            <Grid item xs={12} lg={4}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-adornment-amount"
                  label="Discount Percentage"
                  InputLabelProps={{ shrink: true }}
                  margin="normal"
                  value={discount}
                  multiline
                  onChange={(e) => {
                    setDiscount(e.target.value);
                  }}
                />
              </FormControl>
            </Grid>

            {/*Discount Start Date */}
            {discount > 0 &&
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <TextField
                    id="discount-start-date"
                    label="Discount Start date"
                    type="date"
                    // defaultValue="2023-01-01"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={discountStartDate}
                    onChange={(e) => {
                      setDiscountStartDate(e.target.value);
                    }}
                    margin="normal"
                  />
                </FormControl>
              </Grid>}
            {/*Discount End Date */}
            {discount > 0 &&
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <TextField
                    id="discount-end-date"
                    label="Discount End date"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={discountEndDate}
                    onChange={(e) => {
                      setDiscountEndDate(e.target.value);
                    }}
                    margin="normal"
                    required
                  />
                </FormControl>
              </Grid>}
            {/*Discount Start Time */}
            {discount > 0 &&
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <TextField
                    id="discount-start-time"
                    label="Discount Start time"
                    type="time"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={discountStartTime}
                    onChange={(e) => {
                      setDiscountStartTime(e.target.value);
                    }}
                    margin="normal"
                  />
                </FormControl>
              </Grid>}
            {/*Discount End Time */}
            {discount > 0 && <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  id="discount-end-time"
                  label="Discount End time"
                  type="time"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={discountEndTime}
                  onChange={(e) => {
                    setDiscountEndTime(e.target.value);
                  }}
                  margin="normal"
                />
              </FormControl>
            </Grid>
            }
            {/* Banner */}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  label="banner"
                  InputLabelProps={{ shrink: true }}
                  type="file"
                  margin="normal"
                  name="image"
                  accept="image/*"
                  defaultValue={dealImage}
                  onChange={onChangePicture}
                />
              </FormControl>
            </Grid>
            {/* Description */}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <JoditEditor
                  ref={editor}
                  value={dealDescription}
                  tabIndex={1} // tabIndex of textarea
                  onBlur={(content) => setDealDescription(content)} // preferred to use only this option to update the content for performance reasons
                  onChange={(content) => { }}
                  fullWidth
                  multiline
                  rows={5}
                  className="editor-main"
                  margin="normal"
                />
              </FormControl>
            </Grid>
            {/* Button */}
            <Grid item xs={12}>
              <FormControl fullWidth></FormControl>
              <Button
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                margin="normal"
                onClick={saveDeal}
                fullWidth
              >
                Update Event
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item sm={6} xs={12}>
        <Paper component="img" style={styles.paperContainer}></Paper>
      </Grid>
      <ToastContainer />
    </Grid>
    // )
  );
};

export default EditDealComponent;
