import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  dealsData: null,
  dealsIsLoading: false,
  dealsError: null,
}

export const dealsSlice = createSlice({
  name: 'deals',
  initialState,
  reducers: {
    fetchDealsData: (state) => ({
      ...state,
      dealsIsLoading: !initialState.dealsIsLoading,
    }),
    setDealsData: (state, action) => ({
      ...initialState,
      ...state,
      dealsData: action.payload || initialState.data,
      dealsIsLoading: initialState.dealsIsLoading,
    }),
    setDealsLoading: (state, action) => ({
      ...initialState,
      ...state,
      dealsIsLoading: (typeof action.payload === 'undefined') ? initialState.dealsIsLoading : action.payload,
    }),
    setDealsError: (state, action) => ({
      ...initialState,
      ...state,
      dealsError: action.payload || initialState.dealsError,
    }),
    resetDeals: (state) => ({
      ...initialState,
    }),
  },
})

// Action creators are generated for each case reducer function
export const {
  fetchDealsData, setDealsLoading, setDealsData, resetDeals, setDealsError,
} = dealsSlice.actions

export default dealsSlice.reducer
