
import React, { useEffect } from 'react'
import './GoogleSignup.scss';
import { useNavigate } from 'react-router-dom';
import axios from "axios";


export default function GoogleSignup() {
    const navigate = useNavigate();
    const google = window.google;
    const handleCallBackResponse = (response) => {
        loginThroughGoogle(response.credential);
    }

    const loginThroughGoogle = async (res) => {
        if (!res) return;

        const options = {
            method: "POST",
            url: global.config.ROOTURL.prod + "/auth/singleSignInByGoogle",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            data: {
              token:res
              },
        };
        await axios(options)
            .then((response) => {
                localStorage.setItem("token", response.data.data.token);
                localStorage.setItem("user_id", response.data.data.payload._id);
                localStorage.setItem("user_email", response.data.data.payload.email);
                localStorage.setItem("first_name", response.data.data.payload.firstName);
                localStorage.setItem("last_name", response.data.data.payload.lastName);
                localStorage.setItem("full_name", response.data.data.payload.firstName + " " + response.data.data.payload.lastName);
                localStorage.setItem("avatar", response.data.data.payload.profilePhoto);
                navigate('/pitch')
            })
            .catch((error) => {
                console.log(error)
            });

    }
    useEffect(() => {
        if(window.google){

            google.accounts.id.initialize({
                
                client_id: '138509092351-o35mrl0i1q8rabpc15nv6t6v3mkkmhcn.apps.googleusercontent.com',
                callback: handleCallBackResponse
            })
            google.accounts.id.renderButton(
                document.getElementById('signIndDiv'),
                { theme: 'outline', size: 'large' },
                
                
                )
            }
    }, [window.google])
    return (
        <div style={{ Padding: '20 20' }}>
            <div id="signIndDiv">

            </div>
        </div>
    )
}
