import { createSlice } from '@reduxjs/toolkit';



const initialState = {
    PitchSelction:""
}

const PitchSelectionSlice = createSlice({
    name: 'pitchS',
    initialState,
    reducers: {
        handlePitchSelction(state, action) {
           state.PitchSelction=action.payload.payload
        },
       

    }
});


export const PathAction = PitchSelectionSlice.actions;

export default PitchSelectionSlice;