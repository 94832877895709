import React, { useEffect, useState } from 'react'
import axios from "axios";
import { getStorageItem } from '../../utils/sessionStorage';
import { useNavigate } from 'react-router-dom';
import './AdminSection.scss'

export default function AdminSectionChat() {
    const accessToken = getStorageItem("token");
    const userId = getStorageItem('user_id');

    const navigate = useNavigate()

    const [items, setItems] = useState([]);
    const [Messages, setMessages] = useState(true);
    const [olderItem, setOlderItem] = useState([]);


    const handleuserChat = (id) => {
        navigate(`/profile/chat/${id}`)
    }

    const handleMessage = () => {
        setMessages(!Messages);
    }
    const fetchData = async () => {
        const onlineuser = {
            method: "GET",
            url: global.config.ROOTURL.prod + `/chat/getNotSeenMessage`,
            headers: {
                Authorization: "Bearer " + accessToken,
                "Content-Type": "application/json",
            },
        };
        await axios(onlineuser)
            .then((response) => {
                setItems(response.data);
            }).catch((error) => {
                console.log(error);
            })

    };
    const fetchData2 = async () => {
        const onlineuser = {
            method: "GET",
            url: global.config.ROOTURL.prod + `/chat/getOldAdminMessages`,
            headers: {
                Authorization: "Bearer " + accessToken,
                "Content-Type": "application/json",
            },
        };
        await axios(onlineuser)
            .then((response) => {
                setOlderItem(response.data);
            }).catch((error) => {
                console.log(error);
            })

    };
    useEffect(() => {
        fetchData();
        fetchData2();
    }, [])


    return (
        <div>
            <div className='MessageHeadingContainer'>
                <h1 onClick={handleMessage} className= {Messages ? 'ActiveMessageContainer':''} style={{cursor:'pointer'}}>New Messages</h1>
                <h1 onClick={handleMessage} className= {!Messages ? 'ActiveMessageContainer':''} style={{cursor:'pointer'}}>Older Messages</h1>
            </div>

            <div>

                {
                    Messages
                        ? items?.map((data, index) => (
                            <div key={index} className='MainContainerAdminChat' onClick={() => handleuserChat(data._id)}>
                                <div className='imageAndName'>
                                    <p>{data.firstName + " " + data.lastName}</p>
                                    <img src={data.profilePhoto} className='img' />
                                </div>
                                <p className='MessageContainer'>{data.message}</p>
                            </div>
                        ))
                        : olderItem?.map((data, index) => (
                            <div key={index} className='MainContainerAdminChat' onClick={() => handleuserChat(data._id)}>
                                <div className='imageAndName'>
                                    <p>{data.firstName + " " + data.lastName}</p>
                                    <img src={data.profilePhoto} className='img' />
                                </div>
                                <p className='MessageContainer'>{data.message}</p>
                            </div>
                        ))

                }


            </div>
        </div>
    )
}
