import React, { useEffect, useState } from "react";
import "./card.scss";
import { getStorageItem } from "../../utils/sessionStorage";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Rating from "@mui/material/Rating";
import { useContext } from "react";
import AppContext from "../../context/AppContext";
import { PathAction } from "../../redux/reducers/globalPath";
import { useDispatch } from "react-redux";
import { CartContext } from "../../context/CartContext";
import { toast } from "react-toastify";
import star from "../../assets/images/marketplace/Stars.png";
import ball from "../../assets/images/marketplace/redball.png";
import badge from "../../assets/images/marketplace/badge.png";
import editIcon from "../../assets/images/marketplace/editIcon.png";
import deleteIcon from "../../assets/images/marketplace/deleteIcon.png";
import dateIcon from "../../assets/images/marketplace/dateIcon.png";

// User Information
const userId = getStorageItem("user_id");
const accessToken = getStorageItem("token");

const NewCard = ({ product, buyerDetails, index, type, productType, handleModal, verifiedSellerChecked }) => {

    const navigate = useNavigate();
    const path = window.location.pathname;
    const dispatch = useDispatch();
    dispatch(PathAction.handlePath({ payload: path }));
    const appContext = useContext(AppContext);
    const { setShowFooter } = appContext;
    const [cart, setCart] = useContext(CartContext);
    const [rating, setRating] = useState(0);
    const [ratingSubmitted, setRatingSubmitted] = useState(false)
    const [wishListed, setWishListed] = useState(false);
    const [isProductPresent, setIsProductPresent] = useState(false);
    const [sellerInfo, setSellerInfo] = useState(null);

    const handleCheckIfProductIsWishLitedOrNot = async () => {
        if (!accessToken && !userId) return
        try {
            const response = await fetch(
                global.config.ROOTURL.prod +
                `/marketplace/product/checkIfProductIsWishListedByUserId`,
                {
                    method: "POST",
                    headers: {
                        Authorization: "Bearer " + accessToken,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        userId: userId,
                        productId: product?._id,
                    }),
                }
            );
            const res = await response.json();
            if (res === true) {
                setWishListed(true);
            } else {
                setWishListed(false);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handleGetSellerInformation = async () => {
        try {
            const response = await fetch(
                global.config.ROOTURL.prod +
                `/marketplace/seller/findInformationOfSingleSellerById/${product?.sellerId}`,
                {
                    headers: {
                        Authorization: "Bearer " + accessToken,
                        "Content-Type": "application/json",
                    },
                }
            );
            const res = await response.json();
            setSellerInfo(res);
        } catch (err) {
            console.log(err);
        }
    };

    const handleRatingChange = async (newRating) => {
        setRating(newRating);
        try {
            const response = await axios.post(
                `${global.config.ROOTURL.prod}/marketplace/product/addRating`,
                {
                    productId: product._id,
                    userId: userId,
                    rating: newRating,
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": "application/json",
                    },
                }
            );
            if (response.status === 200) {
                setRatingSubmitted(true);
            } else {
                console.error("Error submitting rating");
            }
        } catch (error) {
            console.error("Error submitting rating:", error);
        }
    };

    useEffect(() => {
        handleGetSellerInformation();
    }, []);
    useEffect(() => {
        handleCheckIfProductIsWishLitedOrNot();
    }, []);

    useEffect(() => {
        // Login to check if the product exists in cart or not
        if (cart.length > 0) {
            const productFound = cart.find((item) => {
                return item?.productInfo?._id === product._id;
            });
            if (productFound) {
                setIsProductPresent(true);
            }
        }
    }, [cart]);

    const handleStarClick = async (_id) => {
        // handle the star icon click here
        if (!_id && !userId) return
        try {
            const response = await fetch(
                global.config.ROOTURL.prod +
                "/marketplace/product/addProductToWishList",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                    body: JSON.stringify({
                        userId: userId,
                        productId: product?._id,
                    }),
                }
            );
            const res = await response.json();
            console.log(res);
            setWishListed(true);
            toast.success("Added this item to wishlist");
        } catch (err) {
            console.log(err);
        }
    };
    const handleStarUnselect = async (_id) => {
        // handle the star icon click here
        if (!accessToken && !userId) return
        try {
            const response = await fetch(
                global.config.ROOTURL.prod +
                `/marketplace/product/removeProductFromWishList`,
                {
                    method: "POST",
                    headers: {
                        Authorization: "Bearer " + accessToken,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        userId: userId,
                        productId: product?._id,
                    }),
                }
            );
            const res = await response.json();
            setWishListed(false);
            toast.success("Removed this item from wishlist");
        } catch (err) {
            console.log(err);
        }
    };

    const handleAddToCart = async (product) => {
        if (!product) return;
        const productSellerInfo = {
            productInfo: product,
            sellerInfo: sellerInfo,
        };
        productSellerInfo.productInfo.quantity = 1;
        setCart([...cart, productSellerInfo]);
        localStorage.setItem("cart", JSON.stringify([...cart, productSellerInfo]));
        toast.success(`${product?.title} added to cart`);
    };

    useEffect(() => {
        // getRuns();
        setShowFooter(true);
        return () => {
            setShowFooter(false);
        };
    }, []);

    return type === "editable" ? (
        <div>
            <div
                className="card_container"
                key={product._id}
                onClick={() => {
                    navigate(`/marketplace/single/${product?._id}`);
                }}
            >
                <div className="card__choice_bage" style={{ background: product?.stock > 0 ? "#EDFEE7" : "#CCCCCC" }}>
                    <p className="badge_text" style={{ color: product?.stock > 0 ? "#2C9207" : "#4D4D4D" }}>
                        {product?.stock > 0 ? (
                            "Active"
                        ) : (
                            "Sold Out"
                        )}
                    </p>
                </div>
                <div className="edit_delete_container">
                    <img
                        className="delete_icon"
                        src={deleteIcon}
                        alt=""
                        onClick={(e) => {
                            e?.stopPropagation();
                            handleModal(product?._id);
                        }}
                    />
                    <img
                        className="edit_icon"
                        src={editIcon}
                        alt=""
                        onClick={(e) => {
                            e?.stopPropagation();
                            navigate(`/marketplace/edit/${product?._id}`);
                        }}
                    />
                </div>
                <div className="product_image_container">
                    <img className="product_image"
                        src={
                            product?.images[0] ||
                            "https://images.unsplash.com/photo-1447752875215-b2761acb3c5d?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&ixid=eyJhcHBfaWQiOjE0NTg5fQ"
                        } alt=""
                    />
                </div>
                <div className="name_content_container">
                    <div className="product_name_container">
                        <p className="product_name">
                            {product?.title?.length > 20 ? (
                                <h3>{product?.title.substring(0, 20)}...</h3>
                            ) : (
                                <h3>{product?.title}</h3>
                            )}
                        </p>
                    </div>
                    <div className="rating_container">
                        <img className="rating_star" src={star} alt="rating" />
                        <p className="rating_text">{product?.averageRating}</p>
                    </div>
                    <div className="price_runs_container">
                        <div className="price_container">
                            <del className="del_price">{product?.discount > 0 ? `₹${product?.discount}` : ""}</del>
                            <p className="original_price">₹{product?.price}</p>
                        </div>
                        <div className="runs_container">
                            <img src={ball} alt="" />
                            <p className="runs_text">{product?.runs} Runs</p>
                        </div>
                    </div>
                </div>
                <div className="posted_date_container">
                    <img
                        className="date_icon"
                        src={dateIcon}
                        alt=""
                    />
                    {product?.modifiedDate ? (
                        <h2 className="date_name">Updated on <storng style={{ color: "#221F1F", fontWeight: 500 }}>{product?.modifiedDate?.substring(0, 10)}</storng></h2>
                    ) : (
                        <h2 className="date_name">Posted on <storng style={{ color: "#221F1F", fontWeight: 500 }}>{product?.createdDate?.substring(0, 10)}</storng></h2>
                    )}
                </div>
            </div></div>
    ) : type === "purchased" ? (
        <div
            className="card_container"
            key={product._id}
            onClick={() => {
                navigate(`/marketplace/single/${product?._id}`);
            }}
        >
            <div className="purchasedId_container">
                <p className="order_text">
                    Order ID:
                </p>
                <small className="order_Id">
                    {buyerDetails?._id}
                </small>
            </div>
            <div className="product_image_container">
                <img className="product_image"
                    src={
                        product?.images[0] ||
                        "https://images.unsplash.com/photo-1447752875215-b2761acb3c5d?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&ixid=eyJhcHBfaWQiOjE0NTg5fQ"
                    } alt=""
                />
            </div>
            <div className="name_content_container">
                <div className="product_name_container">
                    <p className="product_name">
                        {product?.title?.length > 20 ? (
                            <h3>{product?.title.substring(0, 20)}...</h3>
                        ) : (
                            <h3>{product?.title}</h3>
                        )}
                    </p>
                </div>
                <div className="rating_container"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <Rating
                        name={`rating-${product._id}`}
                        value={rating}
                        onChange={(event, newValue) => handleRatingChange(newValue)}
                        disabled={ratingSubmitted}
                    />
                    <p className="rating_text">{product?.averageRating}</p>
                </div>
                <div className="price_runs_container">
                    <div className="price_container">
                        <del className="del_price">{product?.discount > 0 ? `₹${product?.discount}` : ""}</del>
                        <p className="original_price">₹{product?.price}</p>
                    </div>
                    <div className="runs_container">
                        <img src={ball} alt="" />
                        <p className="runs_text">{product?.runs} Runs</p>
                    </div>
                </div>
            </div>
            {/* <div className="seller__profile_container">
                <img
                    className="seller_avatar"
                    src={sellerInfo?.sellerProfile}
                    alt={sellerInfo?.sellerName}
                />
                <h2 className="seller_name">{sellerInfo?.sellerName}</h2>
                {sellerInfo?.isSellerVerified && (
                    <img className="seller_badge" src={badge} alt="" />
                )}
            </div> */}
            <div className="order_date_status">
                <p className="order_date">Ordered on <strong style={{ color: "#313335", fontWeight: 500 }}>{product?.createdDate.substring(0, 10)}</strong></p>
                <p className="order_stauts">Current Status <strong style={{ color: "#313335", fontWeight: 500 }}>{buyerDetails?.currentState}</strong></p>
            </div>
        </div>
    ) : (
        <div
            className="card_container"
            key={product._id}
            onClick={() => {
                navigate(`/marketplace/single/${product?._id}`);
            }}
        >
            {index === 0 && (
                <div className="card__choice_bage">
                    <p className="badge_text" style={{ color: "#FFFFFF" }}>
                        {productType === "trending" ? (
                            "Limited"
                        ) : (
                            "Popular"
                        )}
                    </p>
                    <small className="badge_text" style={{ color: "#0BFD40" }}>
                        {productType === "trending" ? (
                            "Deals"
                        ) : (
                            "Choice"
                        )}
                    </small>
                </div>
            )}
            {!wishListed ? (
                <button
                    className="button-reset add_to_wishlist"
                    onClick={(event) => {
                        event.stopPropagation();
                        handleStarClick(product?._id);
                    }}
                >
                    <img
                        src="/marketplace/star-nofill.svg"
                        alt="star-nofill"
                        className="star"
                    />
                </button>
            ) : (
                <button
                    className="button-reset add_to_wishlist"
                    onClick={(event) => {
                        event.stopPropagation();
                        handleStarUnselect(product?._id);
                    }}
                >
                    <svg
                        className="star"
                        width="27px"
                        height="16"
                        viewBox="0 0 22 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M13.1274 1.64942C12.9476 1.52448 12.8359 1.32177 12.8359 1.10287C12.8359 0.746184 12.5468 0.457031 12.1901 0.457031H9.79584C9.43563 0.457031 9.14363 0.749033 9.14363 1.10924C9.14363 1.32901 9.03222 1.53283 8.85202 1.65864C8.44456 1.94313 7.99238 2.29879 7.70773 2.64453C7.38397 3.03778 7.14173 3.59473 6.9742 4.08316C6.8253 4.51732 6.43138 4.83203 5.9724 4.83203H4.52825C2.48904 4.83203 0.835938 6.51096 0.835938 8.58203V11.707C0.835938 13.7781 2.48904 15.457 4.52825 15.457H17.1436C19.1828 15.457 20.8359 13.7781 20.8359 11.707V8.58203C20.8359 6.51096 19.1828 4.83203 17.1436 4.83203H15.9816C15.5308 4.83203 15.141 4.52859 14.9904 4.10372C14.8082 3.58961 14.5468 2.99387 14.2115 2.58666C13.935 2.25082 13.5123 1.917 13.1274 1.64942ZM12.8017 3.04416C12.6312 2.78515 12.3338 2.64453 12.0237 2.64453H9.91973C9.62069 2.64453 9.33364 2.77566 9.15796 3.01765C8.98833 3.25131 8.82511 3.50874 8.73337 3.73828C8.72667 3.75505 8.72015 3.77185 8.71381 3.78867C8.50981 4.32995 8.99015 4.83203 9.56859 4.83203H12.2584C12.8773 4.83203 13.3661 4.2663 13.1445 3.68836C13.1383 3.67213 13.132 3.65597 13.1255 3.6399C13.0469 3.44323 12.9279 3.23588 12.8017 3.04416Z"
                            fill="#0C5BA0"
                            stroke="#0C5BA0"
                            strokeWidth="0.6"
                        />
                    </svg>
                </button>
            )}
            <div className="product_image_container">
                <img
                    className="product_image"
                    src={
                        product?.images && product.images.length > 0
                            ? product.images[0]
                            : "https://images.unsplash.com/photo-1447752875215-b2761acb3c5d?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&ixid=eyJhcHBfaWQiOjE0NTg5fQ"
                    }
                    alt=""
                />
            </div>
            <div className="name_content_container">
                <div className="product_name_container">
                    <p className="product_name">
                        {product?.title?.length > 20 ? (
                            <h3>{product?.title.substring(0, 20)}...</h3>
                        ) : (
                            <h3>{product?.title}</h3>
                        )}
                    </p>
                </div>
                <div className="rating_container">
                    <img className="rating_star" src={star} alt="rating" />
                    <p className="rating_text">{product?.averageRating}</p>
                </div>
                <div className="price_runs_container">
                    <div className="price_container">
                        <del className="del_price">{product?.discount > 0 ? `₹${product?.discount}` : ""}</del>
                        <p className="original_price">₹{product?.price}</p>
                    </div>
                    <div className="runs_container">
                        <img src={ball} alt="" />
                        <p className="runs_text">{product?.runs} Runs</p>
                    </div>
                </div>
            </div>
            <div className="seller__profile_container">
                <img
                    className="seller_avatar"
                    src={sellerInfo?.sellerProfile}
                    alt={sellerInfo?.sellerName}
                />
                <h2 className="seller_name">{sellerInfo?.sellerName}</h2>
                {sellerInfo?.isSellerVerified && (
                    <img className="seller_badge" src={badge} alt="" />
                )}
            </div>
        </div>
    );
}

export default NewCard;
