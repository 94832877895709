import Header from "../../components/header";
import { useEffect, useState, useRef, useCallback, useContext } from "react";
import Followings from "../../components/followers/followers";
import { Grid } from "@mui/material";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "./index.scss";
import axios from "axios";
import AppContext from "../../context/AppContext";
import { clearStorage, getStorageItem } from "../../utils/sessionStorage";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { PathAction } from "../../redux/reducers/globalPath";
import { Helmet } from "react-helmet";
import InfiniteScroll from 'react-infinite-scroll-component';
import { BallTriangle } from 'react-loader-spinner';

const SuggestionsPage = () => {
  const navigate = useNavigate()
  const userId = getStorageItem("user_id");
  const accessToken = getStorageItem("token");
  const [suggestions, setSuggestions] = useState([]);
  const [followings, setFollowings] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [pageNum, setPageNum] = useState(1);
  const [items, setItems] = useState([]);
  const path = window.location.pathname;
  const dispatch = useDispatch();
  const [shouldload, setShouldload] = useState(false);
  dispatch(PathAction.handlePath({ payload: path }))



  const fetchMoreData = () => {
    // Fetch additional data
    if (!accessToken) return
    const req = {
      method: "GET",
      url:
        global.config.ROOTURL.prod +
        `/auth/get-all-suggestion-users/${pageNum}/${20}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    axios(req).then(response => {

      if (response.data.length === 0) {
        setHasMore(false);
      } else {
        // Append new data to existing items
        setItems(prevItems => prevItems.concat(response.data));
        setPageNum(prevPage => prevPage + 1);
        setShouldload(true);
      }
    })
      .catch(error => console.log(error));
  };


  const fetchFollowing = () => {
    if (!userId && !accessToken) return;
    const getFollowing = {
      method: "GET",
      url:
        global.config.ROOTURL.prod +
        "/auth/get-all-following-byuserid/" +
        `${userId}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    axios(getFollowing)
      .then((response) => {
        // console.log(response.data)
        setFollowings(response.data ? response.data : []);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          clearStorage();
          navigate('/login');
        }
      });
  };

  const appContext = useContext(AppContext);
  const { setShowFooter } = appContext


  useEffect(() => {
    window.scrollTo(0, 0);
    fetchMoreData();
    fetchFollowing();
    setShowFooter(true)
  }, []);

  return (
    <div>
      <Helmet>
        <title>Champhunt | Suggested-Profiles</title>
      </Helmet>

      {shouldload && (
        <div>
          {console.log("list", items)}

          <div className="page suggestion">
            <div className="tabs suggestions-main">
              <Tabs style={{ width: "100%" }}>
                <TabList>
                  <Tab style={{ color: "black" }}>People to follow</Tab>
                </TabList>
                <TabPanel>
                  <Grid>
                    <InfiniteScroll
                      dataLength={items.length}
                      next={fetchMoreData}
                      hasMore={hasMore}
                      loader={
                        <BallTriangle
                          height="100"
                          width="100"
                          color="grey"
                          ariaLabel="loading"
                        />
                      }
                    >
                      <div className="infinite-sub-container">
                        {items.map((suggestion, index) => (
                          <Grid item key={index} xs={12} sm={6} lg={4}>
                            <Followings
                              following={suggestion}
                              followings={followings ? followings : []}
                            />
                          </Grid>
                        ))}
                      </div>
                    </InfiniteScroll>
                  </Grid>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SuggestionsPage;

