

import { useState, useEffect, useRef, useCallback, useContext } from "react";
import Header from "../../components/header";
import Suggestions from "../../components/suggestions";
import RegistrationBonus from "../../components/registration-bonus";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import NotifictionList from "./list.js";
import NotificationCard from "./notification-card.js";
import { Grid } from "@mui/material";
import { BallTriangle } from "react-loader-spinner";
import axios from "axios";
import AppContext from "../../context/AppContext";
import { getStorageItem } from "../../utils/sessionStorage";
import { useDispatch } from "react-redux";
import { PathAction } from "../../redux/reducers/globalPath";
import InfiniteScroll from 'react-infinite-scroll-component';

const Notification = () => {
  const accessToken = getStorageItem("token");
  const userId = getStorageItem("user_id");
  const [shouldLoadPage, setShouldLoadPage] = useState(false);
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const path = window.location.pathname;
  const dispatch = useDispatch();
  dispatch(PathAction.handlePath({ payload: path }))



  const fetchMoreData = async () => {
    // Fetch additional data
    if (!userId && !accessToken) return;
    const req = {
      method: "Post",
      url: global.config.ROOTURL.prod + `/notification/get-all-by-userid`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
      data: {
        id: userId,
        pageNumber: page,
        pagePerSize: global.config.pagePerSize,
      },
    };

    axios(req).then(response => {

      if (response.data.length === 0) {

        // No more data to fetch
        setHasMore(false);
      } else {

        // Append new data to existing items
        setItems(prevItems => prevItems.concat(response.data));
        setPage(prevPage => prevPage + 1);
        setShouldLoadPage(true);
      }
    })
      .catch(error => console.log(error));
  };



  const appContext = useContext(AppContext);
  const { setShowFooter } = appContext

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchMoreData()
    setShowFooter(true)
  }, []);

  return (
    <>
      {shouldLoadPage &&
        <div className="page pitch">
          {console.log("items", items)}

          <main className="notification-page">
            <h2 className="head-notifi">Notifications</h2>
            <div className="notifications">
              <Grid container spacing={2} >
                <InfiniteScroll
                  dataLength={items.length}
                  next={fetchMoreData}
                  hasMore={hasMore}
                  loader={<BallTriangle
                    height="100"
                    width="100"
                    color="grey"
                    ariaLabel="loading"

                  />}
                >
                  {items.map((notification, index) => (
                    <Grid item xs={12} sm={12} lg={12} key={index} style={{ margin: '20px' }}>
                      <NotificationCard key={index} notification={notification} />
                    </Grid>

                  ))}
                </InfiniteScroll>
                {items.length === 0 &&
                  <Grid item xs={12} sm={12} lg={12}>
                    <p style={{ textAlign: "center", fontSize: "19px", paddingBottom: "10px", }}>"You are all caught Up, No new Notification!"</p>
                  </Grid>
                }
              </Grid>
            </div>

          </main>


        </div>
      }
    </>
  );
};

export default Notification;
