import React, { useContext, useEffect, useState } from "react";
import "./treanding.scss";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { getStorageItem } from "../../utils/sessionStorage";
import AppContext from "../../context/AppContext";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { PathAction } from "../../redux/reducers/globalPath";

export default function Treanding() {
  const [trendingHashtags, setTrendingHashtags] = useState();
  const accessToken = getStorageItem("token");
  //   const navigate = useNavigate();
  const findTrendingHashtags = async () => {
    if (!accessToken) return
    let creationOptions = {
      method: "GET",
      url:
        global.config.ROOTURL.prod +
        `/hash-profile-tag/getTopTrandingHashTag/1/10`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    await axios(creationOptions)
      .then((response) => {
        setTrendingHashtags(response.data.data);
      })
      .catch((error) => {
        console.log("Creation error" + error);
        if (error?.response?.status == 401) {
          console.log(error);
        }
      });
  };
  const path = window.location.pathname;
  const dispatch = useDispatch();
  dispatch(PathAction.handlePath({ payload: path }));

  const appContext = useContext(AppContext);
  const { setShowFooter } = appContext;

  useEffect(() => {
    findTrendingHashtags();
    setShowFooter(true);
    return () => {
      setShowFooter(false);
    };
  }, []);

  return (
    <Box className="content-box123">
      <Typography variant="h6" className="box-1__heading">
        Trending Hashtags
      </Typography>
      <ul className="box-1__list">
        {trendingHashtags?.map((hashtag, index) => {
          return (
            <li key={hashtag._id}>
              <span>{index + 1}</span>
              <a
                className="anchor"
                onClick={() =>
                  (window.location.href = `/hashtags?id=${hashtag._id}`)
                }
              >
                #{hashtag.hashTagText}
                <span className="list__post">
                  {hashtag.totalUsedPitchCount} post
                </span>
              </a>
              <span className="fire-icon">&#128293;</span>
            </li>
          );
        })}
      </ul>
    </Box>
  );
}
