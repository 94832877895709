import React from 'react'
import { Grid, Box,Typography } from "@mui/material";
import mobileimg from "./img/mobileimg.png";
import Eclipsemobile from "./img/Eclipsemobile.png";
import "./index.scss";

const FifthSection = () => {
  return (
    <Box className='fifthSection'>
    <Grid container spacing={2} >
    
    <Grid className='fifthtSec_mobile' item xs={12} md={5} sx={{ textAlign: { xs: 'center', md: 'left' } }}>
        <img src={mobileimg} alt='mobileimages'></img>            
    </Grid>
    <div>
        <img className='corner_circle' src={Eclipsemobile} alt=" " />
    </div>
    
    <Grid item xs={12} md={7} sx={{ textAlign: { xs: 'center', md: 'left' } }}>
    <label className='fifthtSec_text'><strong>About Champhunt</strong></label>
    <Typography className='fifthtSec_paragraph'>
       Champhunt is a one of its kind community<br/> 
       that aims to bring all stakeholders of cricket <br/>
       together on one platform. It is a virtual home<br/>
       of cricket lovers where they can interact with <br/> 
       other fans, share their opinions, have<br/> 
       discussions, analyze games as<br/> 
       well as showcase their talent.
    </Typography>

    </Grid>
    </Grid>
    </Box>
  );
}

export default FifthSection;
