import React, { useContext, useEffect, useState } from "react";
import "./index.scss";
import { SocketContext } from "../../../context/SocketContext";
import { getStorageItem } from "../../../utils/sessionStorage";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { CiSearch } from "react-icons/ci";
import { Avatar, Box, Button, Stack, Typography } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import bookCricketBg from "../../../assets/images/book-cricket/book-cricket.png"
import { useHistory } from "react-router-dom";

const BattleList = () => {
  const [socket, setSocket, isVerified, setIsVerified] =
    useContext(SocketContext);
  const navigate = useNavigate();
  const userId = getStorageItem("user_id");
  const [searchValue, setSearchValue] = useState("");
  const [users, setUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [inviteSend, setInviteSend] = useState(false);
  const [timer, setTimer] = useState(60);


  useEffect(() => {
    if (!isVerified) {
      navigate("/games/book-cricket");
    }
  }, [isVerified]);

  useEffect(() => {
    socket?.on("user-joined", (message) => {
      toast.info(message);
    });

    socket?.on("update-players-list", (users) => {
      const restActivePlayers = users.filter((player) => {
        return String(player?.userId) !== userId;
      });
      setUsers(restActivePlayers);
      setAllUsers(restActivePlayers);
      setFilteredUsers(restActivePlayers);
    });

    return () => {
      socket?.off("user-joined");
      socket?.off("update-players-list");
    };
  }, [socket, userId]);

  useEffect(() => {
    socket?.emit("add-user", userId, (err, res) => {
      if (err) {
        console.error(err);
        toast.error(err);
      } else {
        console.log(res);
      }
    });
    return () => {
      socket?.off("add-user");
    };
  }, []);

  const handleSendInvite = (player) => {
    setInviteSend(true);
    socket.emit(
      "invite-request",
      {
        inviterUserId: userId,
        friendUserId: player?.userId,
      },
      (err, res) => {
        if (err) {
          toast.error(err);
          setInviteSend(false);
        } else {
          console.log(res);
          let seconds = 60;
          const intervalId = setInterval(() => {
            if (seconds === 0) {
              setInviteSend(false);
              clearInterval(intervalId);
            } else {
              setTimer(seconds - 1);
              seconds--;
            }
          }, 1000);
        }
      }
    );
  };

  const handleSearchFilter = (e) => {
    setSearchValue(e.target.value);
    const search = e.target.value;
    // Update filteredUsers when allUsers changes
    const regex = new RegExp(search, "i");
    const filtered =
      search === ""
        ? allUsers
        : allUsers.filter((user) => regex.test(user.userName));
    setFilteredUsers(filtered);
  };

  const handleArrowClick = () => {
    navigate(-1);
  };

  {
    return isVerified ? (
      <div className="player-list--wrapper">
        {/* <img className='book-cricket' src={bookCricketBg} alt="No Image" /> */}
        <div className="player-list">
          <div>
            <p className="heading"><ArrowBackIcon className="arrow-icon" onClick={handleArrowClick}></ArrowBackIcon> Select Opponent</p>
          </div>
          <div className="player-search-wrapper">
            <input
              type="search"
              className="player-search"
              placeholder="search players...."
              value={searchValue}
              onChange={(e) => {
                handleSearchFilter(e);
              }}
            />
            {searchValue === "" && <CiSearch className="search-icon" />}
          </div>
          <div className="player-map">
            {filteredUsers?.map((user) => {
              return (
                <div key={user._id} className="single-player">
                  <Stack direction="row" gap={2} alignItems="center">
                    <Avatar alt={user?.userId} src={user?.userProfile} />
                    <Box>
                      <Typography variant="h3" className="player-name">
                        {user?.userName}
                      </Typography>
                      <Typography variant="h6" className="player-info">
                        online
                      </Typography>
                    </Box>
                  </Stack>
                  <Stack direction="row" gap={1} alignItems="center">
                    <Button
                      className={
                        inviteSend ? "button-invite--disabled" : "button-invite"
                      }
                      variant="contained"
                      onClick={() => {
                        handleSendInvite(user);
                      }}
                      disabled={inviteSend}
                    >
                      {!inviteSend && "Send Invite"} {inviteSend && timer}
                    </Button>
                  </Stack>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    ) : (
      <div>Content when not verified</div>
    );
  }
};

export default BattleList;
