import React from 'react'
import "./Gifcomponent.scss"
export default function GifComponent({getgifdata}) {
 const GIPHY_API = "https://api.giphy.com/v1/gifs/search?api_key=yULkvV4TG1ZZ5NBllLEb7Fl9i8vckS5D&limit=20&offset=0&q=";

  let [search, setSearch] = React.useState("");
  let [gifs, setGifs] = React.useState([]);
  let [loadingState, setLoadingState] = React.useState(false);
  
  let searchGif = () => {
    if(search.length > 0){
      setLoadingState(true);
      fetch(GIPHY_API+search)
      .then((res)=>{
        setLoadingState(false);
        return res.json();
      })
      .then((result)=>{
        console.log(result);
        setGifs(result.data.map((gif)=>{
          return gif.images.fixed_height.url;
        }))
      })
      .catch(()=>{
        alert("Something went wrong");
        setLoadingState(false);
      })
    }
  }
  const getdata=(gif)=>{
    getgifdata(gif)
    console.log("hsedns", gif)
  }
  return (
    <div className="body123">
    <div className="header123">
      <div>
        <input 
          type="text" 
          placeholder="Search GIF"
          value={search}
          onChange={(e)=>setSearch(e.target.value)} 
        />
        <button onClick={searchGif}>
          Search
        </button>
      </div>
    </div>
    <div className="result123">
      {
        (loadingState) ? (
          <div className="loading123">
            <div className="loader123">
            </div>
          </div>
        ) : (
          <div className="list123">
            {
              gifs.map((gif)=>{
                return (
                  <div className="item123">
                    <img src={gif} onClick={() => getdata(gif)}/>  
                  </div>
                )
              })
            }
          </div>
        )
      }
    </div>
  </div>
  )
}

