import React from 'react'
import { Helmet } from 'react-helmet'

const DeleteEvent = () => {
  return (
    <div>
    <Helmet>
      <title>Champhunt | Delete-Event</title>
    </Helmet>
    <div>index</div>
    </div>
  )
}

export default DeleteEvent