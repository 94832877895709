import React from "react";
import { useNavigate } from "react-router-dom";
import "./_index.scss";

const FantasyPlayNowPage = ({ match }) => {
  const navigate = useNavigate();
  const handleOpenPlayerList = () => {
    navigate("/all-player", {
      state: {
        // matchData: match,
        ...match
      },
    });
  };

  const apiData = {
    matchTitle: match.matchName,
    date: match.date,
  };

  return (
    <div className="FantasyPlayNowPage">
      <p className="heading-venue">MATCH</p>
      <p className="venue">{apiData.matchTitle}</p>
      {/* <p className='heading-venue'>VENUE</p>
      <p className="venue">{apiData.venue}</p> */}
      <p className="heading-time">Play with Fav. Players</p>
      <p className="timings">{apiData.date}</p>
      <button onClick={handleOpenPlayerList}>PLAY NOW</button>
    </div>
  );
};

export default FantasyPlayNowPage;
