import Images from "../../../../assets/images/rewards/whitedivball.png";
import ArrowImg from "../../../../assets/images/rewards/backArrow.png";
import Image from "../../../../assets/images/rewards/dealBall.png";
import StartReview from "./StartReview";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { getAuthToken } from "../../../../utils/auth";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import { getStorageItem } from "../../../../utils/sessionStorage";

export default function PredictionReview(props) {
  const predictionid = useSelector((state) => state.prediction.prediction);
  const [userRuns, setUserRuns] = useState(0);
  const [openReview, setOpenReview] = useState(false);
  const [userPredictionData, setUserPredictionData] = useState([]);
  const [userselectedQuestion, setuserQuestion] = useState(0);
  const [totalnoOfQuestion, setAllQuestion] = useState(0);

  // const [predictionQuestion, setPredictionQuestion] = useState({});

  const mobileView = useMediaQuery("(max-width:900px)");
  const navigate = useNavigate();
  const userId = localStorage.getItem("user_id");
  const handlePredictionPage = () => {
    mobileView ? navigate("/prediction") : props?.handleclosePopup();
  };
  const handleOpenReview = () => {
    setOpenReview(true);
  };
  const user_id = getStorageItem("user_id");
  const accessToken = getAuthToken();

  const checkQuestionAttemept = async () => {
    if (user_id && predictionid && accessToken) {
      const checkuserStatus = {
        method: "GET",
        url:
          global.config.ROOTURL.prod +
          `/contest/userPredictionQuestion/getQuestionMarkedCountByUserIdAndPredictionId/${userId}/${predictionid}`,
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      };
      axios(checkuserStatus)
        .then((response) => {
          if (response && response.data) {
            console.log("iam compleetd", response);
            // setTotalQuestion(response.data.TotalQuestions);
            // setAttemptedTotalQuestion(response.data.QuestionAttemptedByUser);
            setuserQuestion(response.data.QuestionAttemptedByUser);
            setAllQuestion(response.data.TotalQuestions);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };


  const fetchPredictionById = () => {
    if (user_id && predictionid && accessToken) {
      const fetchPrediction = {
        method: "GET",
        url:
          global.config.ROOTURL.prod +
          `/contest/userPredictionQuestion/getByUserIdAndPredictionId/${user_id}/${predictionid}`,
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      };
      axios(fetchPrediction)
        .then((response) => {
          setUserPredictionData(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    // getRuns();
    fetchPredictionById();
    checkQuestionAttemept();
    return () => {
      setUserRuns(0);
    };
  }, [openReview]);
  return (
    <div className="PlayNow_container">
      {!openReview ? (
        <div className="endHeader">
          <div className="end_nav">
            <img src={ArrowImg} alt="arrowbtn" onClick={handlePredictionPage} />
          </div>
          <div className="topbtn">
            <div className="imgPara">
              <img src={Image} alt="ball-logo" />
              <p style={{ paddingLeft: "24px" }}>{userRuns}</p>
            </div>
          </div>

          <div className="sevondPara">
            <p>Prediction</p>
            <h1>{`${userselectedQuestion} / ${totalnoOfQuestion}`}</h1>

            <div className="thirdPara">
              <p>
                Runs will be received
                <br />
                at the end of the contest!
              </p>
            </div>
            <div className="dot_img">
              <img src={Images} alt="dotimg" />
              <img src={Images} alt="dotimg" />
              <img src={Images} alt="dotimg" />
              <img src={Images} alt="dotimg" />
              <img src={Images} alt="dotimg" />
              <img src={Images} alt="dotimg" />
            </div>

            <div className="btn_container">
              <button className="end_contest_btn1" onClick={handleOpenReview}>
                View Answers
              </button>
              <div className="end_contest_btn2">
                <button onClick={handlePredictionPage}>close</button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <StartReview
          userPredictionData={userPredictionData}
          handleclosePopup={props?.handleclosePopup}
        />
      )}
    </div>
  );
}
