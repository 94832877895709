import React, { useContext, useEffect, useRef, useState } from "react";
import "./index.scss";
import {
  Avatar,
  Box,
  Button,
  Stack,
  Typography,
  IconButton,
} from "@mui/material";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { Icon } from "@mui/material/IconButton";
import GameModal from "../game-modal";
import { useNavigate, useParams } from "react-router-dom";
import { getStorageItem } from "../../../utils/sessionStorage";
import { SocketContext } from "../../../context/SocketContext";
import { toast } from "react-toastify";
import CoverPhoto from "./../../coverPhoto/create/CoverPhoto";
// import bookAnimation from "../../../assets/images/book-cricket/book-animation.mp4";
import bookAnimation from "../../../assets/images/rewards/animation.json";
import WhiteBallIcon from "../../../assets/images/rewards/ballwhite.png";
import RedBallIcon from "../../../assets/images/rewards/redBall.png";
import choukaIcon from "../../../assets/images/rewards/chouka.png";
import Lottie from "react-lottie";
import SwipeAnimation from "../../../assets/images/book-cricket/swipeAnimation.json";
import outAnimation from "../../../assets/images/book-cricket/out-animation.gif";
import winAnimation from "../../../assets/images/book-cricket/win-animation.gif";
import twoRuns from "../../../assets/images/book-cricket/two-run.svg";
import fourRuns from "../../../assets/images/book-cricket/four-run.svg";
import sixRuns from "../../../assets/images/book-cricket/six-run.svg";
import wicket from "../../../assets/images/book-cricket/wicket.svg";


const Arena = () => {
  const [title, setTitle] = useState("You won");
  const [gameAnimation, setGameAnimation] = useState(null);
  const [countOut, setCountOut] = useState(2);
  const [isSwipeButtonDisabled, setIsSwipeButtonDisabled] = useState(true);
  const [description, setDescription] = useState("You won 2000 runs");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { id } = useParams();
  const userId = getStorageItem("user_id");
  const [socket, setSocket, isVerified, setIsVerified] =
    useContext(SocketContext);
  const [game, setGame] = useState({});
  const [isInviter, setIsInviter] = useState(false);
  const [isFriend, setIsFriend] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const videoRef = useRef(null);

  const navigate = useNavigate();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleGetGame = async () => {
    socket?.emit("get game", id, (res, err) => {
      if (err) {
        console.log(err);
        toast.error(err);
      }
      if (res) {
        if (userId === res?.inviterUserId?._id) {
          setIsInviter(true);
          setIsSwipeButtonDisabled(false);
        }
        if (userId === res?.friendUserId?._id) {
          setIsFriend(true);
        }
        setGame(res);
      }
    });
  };

  const handleSwipe = async () => {
    setIsVideoPlaying(true);

    socket.emit("swipe", id, userId, (err, runs) => {
      // Play the video
      if (videoRef.current) {
        videoRef.current.play();
      }

      if (err) {
        console.log(err);
        toast.error(err);
      } else {
        if (runs === Number(4)) {
          setGameAnimation(fourRuns);
          setTitle("Four-tastic!");
          setDescription(
            "Bam! A powerful shot, and it's a boundary for 4 runs!"
          );
        } else if (runs === Number(6)) {
          setGameAnimation(sixRuns);
          setTitle("Sixer Supreme!");
          setDescription(
            "Unbelievable! It's a colossal sixer! What a magnificent shot!"
          );
        } else if (runs === Number(0)) {
          setGameAnimation(wicket);
          setTitle("OUT");
          setDescription(
            <>
              {countOut > 0 ? (
                <>
                  <span style={{ color: "#EB4E4E", fontSize: "15px", fontWeight: 600 }}>{countOut} wickets</span> remaining
                </>
              ) : (
                "No wickets remaining"
              )}
            </>
          );
        } else if (runs === "No change") {
          setGameAnimation(outAnimation);
          setCountOut((prevOut) => prevOut - 1);
          setTitle("Already 3 outs");
          setDescription("Oops! You can not continue anymore.");
        }
        if (
          runs === Number(0) ||
          runs === Number(4) ||
          runs === Number(6) ||
          runs === "No change"
        ) {
          setOpen(true);
          setTimeout(() => {
            setOpen(false);
          }, 2000);
        }
      }

      // Wait for the video to finish playing before setting isVideoPlaying to false
      videoRef.current.addEventListener("ended", () => {
        setIsVideoPlaying(false);
      });
    });
  };

  const handleVideoEnd = () => {
    setIsVideoPlaying(false);
  };

  useEffect(() => {
    // Get initial game state
    handleGetGame();
  }, []);

  useEffect(() => {
    const updatedGameListener = (gameId, updatedGame) => {
      const gameInfoArrayLenght = updatedGame && updatedGame?.gameInfo ? updatedGame?.gameInfo?.length : 0;
      const lastPlayedGameInfo = updatedGame?.gameInfo[gameInfoArrayLenght - 1];
      if (gameId === id) {
        if (lastPlayedGameInfo.run === 0 && lastPlayedGameInfo.userId === userId) {
          setIsSwipeButtonDisabled(true);
        }
        else {
          setIsSwipeButtonDisabled(false);
        }
        if (
          updatedGame?.outCount?.inviter !== 3 &&
          updatedGame?.outCount?.friend === 3 &&
          updatedGame?.gameScores.inviterScore >
          updatedGame?.gameScores.friendScore
        ) {
          socket.emit(
            "won-match",
            updatedGame?.inviterUserId,
            gameId,
            (res, err) => {
              if (err) {
                console.log(`Error: ${err}`);
                return;
              } else {
                console.log("Acknowledged");
              }
            }
          );
          setTitle("Congrats !");
          setGameAnimation(winAnimation);
          setDescription(
            `${updatedGame?.inviterUserId?.firstName} won the match`
          );
          socket.emit("mark out", id, (data, error) => {
            if (error) {
              console.log(error);
              return;
            } else {
              setOpen(true);
              setTimeout(() => {
                setOpen(false);
                navigate("/games/book-cricket");
              }, [10000]);
            }
          });
        } else if (
          updatedGame?.outCount?.inviter === 3 &&
          updatedGame?.outCount?.friend !== 3 &&
          updatedGame?.gameScores.inviterScore <
          updatedGame?.gameScores.friendScore
        ) {
          socket.emit(
            "won-match",
            updatedGame?.friendUserId,
            gameId,
            (res, err) => {
              if (err) {
                console.log(`Error: ${err}`);
                return;
              } else {
                console.log("Acknowledged");
              }
            }
          );
          setTitle("Congrats !");
          setGameAnimation(winAnimation);
          setDescription(
            `${updatedGame?.friendUserId?.firstName} won the match`
          );
          socket.emit("mark out", id, (data, error) => {
            if (error) {
              console.log(error);
              return;
            } else {
              setOpen(true);
              setTimeout(() => {
                setOpen(false);
                navigate("/games/book-cricket");
              }, [10000]);
            }
          });
        } else if (
          updatedGame?.outCount?.inviter === 3 &&
          updatedGame?.outCount?.friend === 3
        ) {
          if (
            updatedGame?.gameScores?.inviterScore >
            updatedGame?.gameScores?.friendScore
          ) {
            socket.emit(
              "won-match",
              updatedGame?.inviterUserId,
              gameId,
              (res, err) => {
                if (err) {
                  console.log(`Error: ${err}`);
                  return;
                } else {
                  console.log("Acknowledged");
                }
              }
            );
            setTitle("Congrats !");
            setGameAnimation(winAnimation);
            setDescription(`${game?.inviterUserId?.firstName} won the match`);
            socket.emit("mark out", id, (data, error) => {
              if (error) {
                console.log(error);
                return;
              } else {
                setOpen(true);
              }
            });
          } else if (
            updatedGame?.gameScores?.inviterScore <
            updatedGame?.gameScores?.friendScore
          ) {
            socket.emit(
              "won-match",
              updatedGame?.friendUserId,
              gameId,
              (res, err) => {
                if (err) {
                  console.log(`Error: ${err}`);
                  return;
                } else {
                  console.log("Acknowledged");
                }
              }
            );
            setTitle("Congrats !");
            setGameAnimation(winAnimation);
            setDescription(
              `${updatedGame?.friendUserId?.firstName} won the match`
            );
            socket.emit("mark out", id, (data, error) => {
              if (error) {
                console.log(error);
                return;
              } else {
                setOpen(true);
                setTimeout(() => {
                  setOpen(false);
                  navigate("/games/book-cricket");
                }, [10000]);
              }
            });
          } else if (
            updatedGame?.gameScores?.inviterScore ===
            updatedGame?.gameScores?.friendScore
          ) {
            setTitle("Congrats !");
            setGameAnimation(winAnimation);
            setDescription("It's a draw");
            socket.emit("mark out", id, (data, error) => {
              if (error) {
                console.log(error);
                return;
              } else {
                setOpen(true);
                setTimeout(() => {
                  setOpen(false);
                  navigate("/games/book-cricket");
                }, [10000]);
              }
            });
          }
        }
        setGame(updatedGame);
      }
    };

    // Attach socket event listener
    socket?.on("updated game", updatedGameListener);

    // Clean up the event listener when the component unmounts
    return () => {
      socket?.off("updated game", updatedGameListener);
    };
  }, [id, socket]);

  return game ? (
    <>
      <div className="book-cricket-arena">
        {open && (
          <GameModal
            gameAnimation={gameAnimation}
            title={title}
            description={description}
            open={open}
            setOpen={setOpen}
            game={game}
            handleClose={handleClose}
          />
        )}
        {id ? (
          <div className="arena-pitch">
            {/* Scores */}
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              className="user-stack"
            >
              {/* Your profile */}
              <Stack
                direction="column"
                alignItems="center"
                justifyContent="center"
                gap={1}
              >
                <Typography
                  variant="h6"
                  color="#ffffff"
                  className="arena-user-name"
                >
                  {game?.inviterUserId?.firstName}
                </Typography>
                <Avatar
                  className="user-arena-avatar"
                  sx={{ border: "2px solid #ffffff" }}
                  src={game?.inviterUserId?.profilePhoto || ""}
                />
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  gap={0}
                >
                  {[...Array(game?.outCount?.inviter || 0)].map((index) => {
                    {
                      /* return <div key={index} className="ball-arena--red"></div>; */
                      return (
                        <img
                          src={RedBallIcon}
                          key={index}
                          alt="Red Ball Icon"
                          className="ball-arena--red"
                        />
                      );
                    }
                  })}
                  {[...Array(3 - game?.outCount?.inviter || 0)].map((index) => {
                    {
                      /* <div key={index} className="ball-arena--white"></div> */
                      return (
                        <img
                          src={WhiteBallIcon}
                          key={index}
                          alt="ball-arena--white"
                          className="ball-arena--white"
                        />
                      );
                    }
                  })}
                </Stack>
              </Stack>
              {/* Scoreboard */}
              <Box textAlign="center">
                <Typography
                  variant="h6"
                  color="#ffffff"
                  fontSize="40px"
                  fontWeight={700}
                  className="Vs-arena"
                >
                  VS
                </Typography>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  marginTop={0}
                  gap={2}
                  backgroundColor="#002C53;"
                  // border="1px solid #ffffff"
                  padding={1}
                  borderRadius="6px"
                  fontWeight={600}
                >
                  {/* Your scores */}
                  <Stack
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Box>
                      <Typography
                        variant="h6"
                        fontSize="12px"
                        fontWeight={600}
                        color="#ffffff"
                      >
                        {game?.gameScores?.inviterScore}/
                        {game?.outCount?.inviter}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        variant="h6"
                        fontSize="12px"
                        fontWeight={600}
                        color="#ffffff"
                      >
                        {game?.totalChances?.inviter}
                      </Typography>
                    </Box>
                  </Stack>
                  {/* Score titles */}
                  <Stack
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Box
                      width="70px"
                      sx={{ backgroundColor: "#ffffff" }}
                      borderRadius="4px"
                    >
                      <Typography variant="p" fontSize="10px" fontWeight={600}>
                        Score
                      </Typography>
                    </Box>
                    <Box
                      width="70px"
                      sx={{ backgroundColor: "#ffffff" }}
                      borderTop="1px solid #000000"
                      borderRadius="4px"
                    >
                      <Typography variant="p" fontSize="10px" fontWeight={600}>
                        No.of Tries
                      </Typography>
                    </Box>
                  </Stack>
                  {/* User scores */}
                  <Stack
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Box>
                      <Typography
                        variant="h6"
                        fontSize="12px"
                        fontWeight={600}
                        color="#ffffff"
                      >
                        {game?.gameScores?.friendScore}/{game?.outCount?.friend}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        variant="h6"
                        fontSize="12px"
                        fontWeight={600}
                        color="#ffffff"
                      >
                        {game?.totalChances?.friend}
                      </Typography>
                    </Box>
                  </Stack>
                </Stack>
              </Box>
              {/* User Profile */}
              <Stack
                direction="column"
                alignItems="center"
                justifyContent="center"
                gap={1}
              >
                <Typography
                  variant="h6"
                  color="#ffffff"
                  className="arena-user-name"
                >
                  {game?.friendUserId?.firstName}
                </Typography>
                <Avatar
                  className="user-arena-avatar"
                  sx={{ border: "2px solid #ffffff" }}
                  src={game?.friendUserId?.profilePhoto || ""}
                />
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  gap={0}
                >
                  {[...Array(game?.outCount?.friend || 0)].map((index) => {
                    {
                      /* return <div className="ball-arena--red"></div>; */
                    }
                    return (
                      <img
                        src={RedBallIcon}
                        key={index}
                        alt="Red Ball Icon"
                        className="ball-arena--red"
                      />
                    );
                  })}
                  {[...Array(3 - game?.outCount?.friend || 0)].map((index) => {
                    {
                      /* return <div className="ball-arena--white"></div>; */
                    }
                    return (
                      <img
                        src={WhiteBallIcon}
                        key={index}
                        alt="White Ball Icon"
                        className="ball-arena--white"
                      />
                    );
                  })}
                </Stack>
              </Stack>
            </Stack>

            {/* Balls */}
            <Stack direction="column" className="second-section-main">
              <div className="arena-parent1">
                <div className="overflow-row">
                  {game?.gameInfo?.length > 0 &&
                    game?.gameInfo?.map((detail, index) => {
                      return (
                        userId === detail.userId && (
                          <Box
                            key={index}
                            height={15}
                            width={15}
                            borderRadius="50%"
                            // backgroundColor={
                            //   detail?.run === 0 ? "#ff0000" : "#ffffff"
                            // }
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            fontSize="10px"
                            fontWeight="800"
                            flexShrink={0}
                          >
                            {/* {detail?.run} */}
                            {detail?.run === 0 && (
                              <img
                                src={RedBallIcon}
                                alt="Red Ball"
                                className="size-runs-icon"
                              />
                            )}
                            {detail?.run === 2 && (
                              <img
                                src={twoRuns}
                                alt="Two Runs"
                                className="size-runs-icon"
                              />
                            )}
                            {detail?.run === 4 && (
                              <img
                                src={choukaIcon}
                                alt="White Ball"
                                className="size-runs-icon"
                              />
                            )}
                            {detail?.run === 6 && (
                              <img
                                src={sixRuns}
                                alt="Sixer"
                                className="size-runs-icon"
                              />
                            )}
                          </Box>
                        )
                      );
                    })}
                </div>
              </div>
              <Typography
                variant="h6"
                color="#0C5BA0"
                fontSize="1.25rem"
                marginLeft={isMobile ? "0px" : "69px"}
                marginTop="2px"
              >
                {isInviter && game?.inviterUserId?.firstName}
                {isFriend && game?.friendUserId?.firstName}
              </Typography>

              <div className="arena-parent2">
                <div className="overflow-row1">
                  {game?.gameInfo?.length > 0 &&
                    game?.gameInfo?.map((detail, index) => {
                      return (
                        userId !== detail.userId && (
                          <Box
                            key={index}
                            height={15}
                            width={15}
                            borderRadius="50%"
                            // backgroundColor={
                            //   detail?.run === 0 ? "#ff0000" : "#ffffff"
                            // }
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            fontSize="10px"
                            fontWeight="800"
                            className="not-resized-ball"
                            flexShrink={0}
                          >
                            {/* {detail?.run} */}
                            {detail?.run === 0 && (
                              <img
                                src={RedBallIcon}
                                alt="Red Ball"
                                className="size-runs-icon"
                              />
                            )}
                            {detail?.run === 2 && (
                              <img
                                src={twoRuns}
                                alt="Two Runs"
                                className="size-runs-icon"
                              />
                            )}
                            {detail?.run === 4 && (
                              <img
                                src={choukaIcon}
                                alt="White Ball"
                                className="size-runs-icon"
                              />
                            )}
                            {detail?.run === 6 && (
                              <img
                                src={sixRuns}
                                alt="Sixer"
                                className="size-runs-icon"
                              />
                            )}
                          </Box>
                        )
                      );
                    })}
                </div>
              </div>
              <Typography
                style={{
                  color: "#0C5BA0",
                  fontSize: "1.25rem",
                  marginLeft: isMobile ? "0px" : "69px",
                  marginTop: "2px",
                }}
              >
                {!isInviter && game?.inviterUserId?.firstName}
                {!isFriend && game?.friendUserId?.firstName}
              </Typography>

              <Typography
                style={{
                  color: "#F37121",
                  fontSize: "35px",
                  fontWeight: "700",
                  textAlign: "center",
                }}
              >
                {isInviter && game?.gameScores?.inviterScore}
                {isFriend && game?.gameScores?.friendScore}
              </Typography>
              {/* <video
                ref={videoRef}
                autoPlay={false}
                muted
                onEnded={handleVideoEnd}
                className="book-closed"
                // style={{ display: isVideoPlaying ? "block" : "none" }}
              >
                <source src={bookAnimation} type="video/mp4" />
              </video> */}
              <div>
                <Lottie
                  options={{
                    loop: false,
                    autoplay: false,
                    animationData: bookAnimation,
                  }}
                  width="205px"
                  height="145px"
                  onEnded={handleVideoEnd}
                  // className="book-closed"
                  isStopped={!isVideoPlaying}
                  isPaused={!isVideoPlaying}
                />
              </div>
              <Button
                fontSize="14px"
                onClick={handleSwipe}
                className="swipe-button"
              >
                {!isSwipeButtonDisabled && (
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: SwipeAnimation,
                    }}
                    width={50}
                    height={50}
                  />
                )}
                {/* <KeyboardDoubleArrowLeftIcon /> Swipe */}
              </Button>
            </Stack>
          </div>
        ) : (
          "<h1>No id</h1>"
        )}
      </div>
    </>
  ) : (
    <p>Loading...</p>
  );
};

export default Arena;
