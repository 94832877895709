import React, { useEffect, useState } from 'react'
import moment from "moment";


export default function Chat(props) {
    const { chatdata, type, number } = props;

    const formattedTime = moment(chatdata.createdDate).format('LT');
    const currentDate = moment(chatdata.createdDate).format('ll')
    return (
        <>
            {
                chatdata.sendertype !== type ?

                    <div className='leftchatContainer'>
                        <div className='chatparentcontainer'>
                            <img src={chatdata.profilePhoto} className='chatimagecontainer' />

                            <div className='chattextcontainer'>
                                <p className='chatData'>{chatdata.message}</p>
                                <p>{formattedTime}  {currentDate}</p>
                            </div>
                        </div>
                    </div >
                    :
                    <div className='rightchatContainer'>
                        <div className='chatparentcontainer'>
                            <div className='chattextcontainer'>
                                <p className='chatData'>{chatdata.message}</p>
                                <p style={{ textAlign: 'end' }}>{formattedTime}  {currentDate}</p>
                            </div>
                            {number === 0 && (
                                (chatdata.sendertype === 'user' && chatdata.adminseen) ||
                                (chatdata.sendertype !== 'user' && chatdata.userseen)
                            ) && <p style={{
                                margin: 'auto',
                                fontSize: '10px',
                                fontWeight: '600'
                            }}>Seen</p>}
                        </div>
                    </div>

            }
        </>


    )
}



