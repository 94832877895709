import * as React from "react";
import Modal from "@mui/material/Modal";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect, useState } from "react";
import { IconButton, Typography, Box, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
const styleDesktop = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
};

const styleMobile = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
};

export default function DealReedeemedModal(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const [style, setStyle] = useState();
  const { open,handleClose } = props;

  useEffect(() => {
    if (!matches) {
      setStyle(styleMobile);
    } else {
      setStyle(styleDesktop);
    }
  }, [matches]);

  return (
    <div>
       <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
    
      <IconButton
              aria-label="close"
              onClick={handleClose}
              style={{
                position: "absolute",
                right: "0%",
                top: "0%",
                color: "black !important",
                backgroundColor: "white !important",
                border: "none !important",
              }}
            >
              <CloseIcon />
            </IconButton>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        You have reached the maximum redeem limit for this particular deal today. Come back tomorrow for more redeems.
        </Typography>
      </Box>
    </Modal>
    </div>
  );
}
