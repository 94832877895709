import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { FaUserCircle } from "react-icons/fa";
import ReactTimeAgo from "react-time-ago";
import { Link, useNavigate } from "react-router-dom";
import DropdownMenu from "../dropdown-menu/dropdownMenu";
import { getStorageItem } from "../../utils/sessionStorage";
const NestedComments = (props) => {
    const { nComment, handleData, comment, type } = props
    const userId = getStorageItem('user_id')
    const [description, setDescription] = useState("");
    const [shortDescription, setShortDescription] = useState("");
    const [count, setCount] = useState(0);
    const [giphyUrl, setGiphyUrl] = useState("");
    const [readMore, setReadMore] = useState(false);
    const navigate=useNavigate();
    const countWords = (desc) => {
        // Split the description into an array of words
        const words = desc.split(" ");

        // Initialize a new string and a counter variable
        let wordCount = 0;

        // Iterate over the array of words
        for (const word of words) {

            wordCount++;
        }

        //Setting up the word count
        return wordCount
    };
    function Description(desc) {
        // Split the description into an array of words
        const words = desc.split(" ");

        // Initialize a new string and a counter variable
        let truncatedDescription = "";
        let wordCount = 0;

        // Iterate over the array of words
        for (const word of words) {

            // Add the word to the new string
            truncatedDescription += word + " ";
            wordCount++;

            // If the counter has reached 150, break out of the loop
            if (wordCount >= 30) {
                break;
            }
        }

        // Truncate the string to remove any excess words
        return truncatedDescription.trim();

        // setShortDescription(truncatedDescription);
    }

    useEffect(() => {
        let text = nComment.text;
        if (text.includes("giphy.com/media/")) {
            // Extract the giphy URL from the comment text
            const url = text
                .split(" ")
                .find((word) => word.includes("giphy.com/media/"));
            setGiphyUrl(url);
            nComment.text = "";
        }
    }, [nComment]);

    useEffect(() => {
        setDescription(nComment.text);
        Description(nComment.text);
        countWords(nComment.text);
    }, []);

    return (
        < Grid container wrap="nowrap" spacing={2} style={{ marginLeft: "25px" }} >
            <Grid item className="comment-image-wrapper">
                {nComment?.userProfilePhoto ? (
                    <img
                        alt=""
                        src={nComment.userProfilePhoto}
                        style={{ height: "40px", width: "40px", borderRadius: "50%" }}
                    />
                ) : (
                    <FaUserCircle className="comments-user-icon" />
                )}
            </Grid>
            <div className="width__nested__comment">
                <Grid justifyContent="left" item xs zeroMinWidth className="border__comment">
                    <div className="comment-name" >
                        <p style={{ margin: 0, textAlign: "left" }} onClick={()=>navigate(`/profile/${nComment.userId}`)}>{nComment.userName}</p>
                        {userId === nComment.userId && <DropdownMenu isCommentDelete={true} type={'post-menu'} userId={userId} deleteCommentId={nComment._id} postId={nComment?.postId} shareJObId={nComment?.shareJobId} nestedComment={true} parentCommentId={nComment?.parentCommentId} />}
                    </div>
                    <p className="comment-time" style={{ fontWeight: "300" }}>
                        {nComment.createdDate && (
                            <ReactTimeAgo date={Date.parse(nComment.createdDate)} locale="en-US" />
                        )}
                    </p>
                    {giphyUrl && (
                        <img src={giphyUrl} alt="Giphy" className="react-giphy__giphs" />
                    )}

                    {count < 50 ? (
                        // <p className="comment-text">{comment.text} </p>
                        <div> <p
                            className="comment-text"
                            dangerouslySetInnerHTML={{
                                __html: description.replace(/\n\r?/g, "<br />"),
                            }}
                        />
                        </div>

                    ) : !readMore ? (
                        <div className="comment-text">
                            <p
                                className="comment-text"
                                dangerouslySetInnerHTML={{
                                    __html: shortDescription.replace(/\n\r?/g, "<br />"),
                                }}
                            />
                            <span
                                className="read-more"
                                onClick={() => {
                                    setReadMore(!readMore);
                                }}
                            >
                                read more
                            </span>
                            ...             </div>
                    ) : (
                        <div>
                            <p
                                className="comment-text"
                                dangerouslySetInnerHTML={{
                                    __html: description.replace(/\n\r?/g, "<br /> "),
                                }}
                            />
                            <span
                                className="read-more"
                                onClick={() => {
                                    setReadMore(!readMore);
                                }}
                            >
                                show less
                            </span>
                        </div>
                    )}


                </Grid>

                <div style={{ display: "flex", flexDirection: "row-reverse" }}
                    onClick={() => {
                        handleData(type === 'shareJob' ? comment?.shareJobId : comment?.postId, nComment?.parentCommentId, nComment?.isChildComment,
                            comment?._id, nComment?.userName, comment?.userId)
                    }}>

                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.9989 9.50019H7.40892L8.70892 8.21019C8.89722 8.02188 9.00301 7.76649 9.00301 7.50019C9.00301 7.23388 8.89722 6.97849 8.70892 6.79019C8.52062 6.60188 8.26522 6.49609 7.99892 6.49609C7.73262 6.49609 7.47722 6.60188 7.28892 6.79019L4.28892 9.79019C4.19788 9.88529 4.12651 9.99743 4.07892 10.1202C3.9789 10.3636 3.9789 10.6367 4.07892 10.8802C4.12651 11.0029 4.19788 11.1151 4.28892 11.2102L7.28892 14.2102C7.38188 14.3039 7.49248 14.3783 7.61434 14.4291C7.7362 14.4798 7.86691 14.506 7.99892 14.506C8.13093 14.506 8.26164 14.4798 8.3835 14.4291C8.50536 14.3783 8.61596 14.3039 8.70892 14.2102C8.80265 14.1172 8.87704 14.0066 8.92781 13.8848C8.97858 13.7629 9.00472 13.6322 9.00472 13.5002C9.00472 13.3682 8.97858 13.2375 8.92781 13.1156C8.87704 12.9937 8.80265 12.8831 8.70892 12.7902L7.40892 11.5002H16.9989C17.2641 11.5002 17.5185 11.6055 17.706 11.7931C17.8936 11.9806 17.9989 12.235 17.9989 12.5002V16.5002C17.9989 16.7654 18.1043 17.0198 18.2918 17.2073C18.4794 17.3948 18.7337 17.5002 18.9989 17.5002C19.2641 17.5002 19.5185 17.3948 19.706 17.2073C19.8936 17.0198 19.9989 16.7654 19.9989 16.5002V12.5002C19.9989 11.7045 19.6829 10.9415 19.1202 10.3789C18.5576 9.81626 17.7946 9.50019 16.9989 9.50019Z" fill="#B7CFE3" />
                    </svg>
                </div></div>
        </Grid>)
}
export default NestedComments;