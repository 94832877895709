import React from "react";
import axios from "axios";
import { getStorageItem } from "../../utils/sessionStorage";
import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import config from "../../config";
import { Box } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import { faCode } from "@fortawesome/free-solid-svg-icons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faMoneyBill } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
const baseURL = config.ROOTURL.prod;
const pagePerSize = config.pagePerSize;
const id = getStorageItem("user_id");
const accessToken = getStorageItem("token");
const FindAllEventsComponent = () => {
  const [events, setEvents] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const getEventById = (id) => {
    console.log("this is eventid function", id);
    navigate(`/admin/find-event/${id}`);
  };
  const fetchData = async () => {
    if (!accessToken) return;
    let pageNumber = 1;
    let pagePerSize = 10;
    const getAllEvents = {
      method: "GET",
      url:
        global.config.ROOTURL.prod +
        `/events/getAll/${pageNumber}/${pagePerSize}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    axios(getAllEvents)
      .then((response) => {
        console.log("response.data", response.data);
        setEvents(response.data);
      })
      .catch((error) => {
        setError(error);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);
  if (error) return `Error: ${error.message}`;
  if (!events) return "No post!";

  //
  //   return events.map((data) => {
  //     console.log("data", data)
  return (
    <Grid container spacing={4} justifyItems="center" marginTop={2}>
      {events.map((data, index) => (
        <Grid key={index} item xs={12} sm={6} md={4}>
          <Card sx={{ maxWidth: 500 }} onClick={() => getEventById(data._id)}>
            <CardMedia
              component="img"
              height="140"
              image={data.eventBanner}
              alt="bannerImage"
            />
            <CardContent>
              <Typography>
                <h2>
                  <strong>
                    <center> {data.eventTitle}</center>
                  </strong>
                </h2>
              </Typography>
              <Typography>
                <FontAwesomeIcon icon={faCalendarDays} /> {data.startDate}{" "}
                {data.startTime} Onwards
              </Typography>
              <Typography>
                <FontAwesomeIcon icon={faLocationDot} /> {data.eventLocation}
              </Typography>
              <Typography>
                <FontAwesomeIcon icon={faMoneyBill} /> {data.registrationFee}{" "}
                INR
              </Typography>
              <Typography>
                <FontAwesomeIcon icon={faCode} /> {data.eventNameCode}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};
export default FindAllEventsComponent;
