import { Box, useMediaQuery } from '@material-ui/core'
import { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { getStorageItem } from "../../utils/sessionStorage";
import SingleChallenge from './SingleChallenge';
import { useDispatch } from 'react-redux';
import axios from "axios";
import InfiniteScroll from 'react-infinite-scroll-component';
import { BallTriangle } from 'react-loader-spinner';
import './Challenge.scss'
export default function AllChallengesList(props) {
    const [shouldLoadPage, setShouldLoadPage] = useState(false);
    const [items, setItems] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);

    const { runs } = props

    const accessToken = getStorageItem("token");
    const mobileView = useMediaQuery("(max-width:900px)");
    const navigate = useNavigate();
    const dispatch = useDispatch()



    const fetchMoreData = async () => {
        // setShouldLoadPage(true);
        if (!accessToken) return
        // Fetch additional data
        const req = {
            method: "GET",
            url: global.config.ROOTURL.prod + `/contest/challenge/getAllChallenges/${page}/${global.config.pagePerSize}`,
            headers: {
                Authorization: "Bearer " + accessToken,
            },
        };

        axios(req).then(response => {

            if (response.data.length === 0) {

                // No more data to fetch
                setShouldLoadPage(false);
                setHasMore(false);
            } else {

                // Append new data to existing items
                setItems(prevItems => prevItems.concat(response.data));
                setPage(prevPage => prevPage + 1);
                setShouldLoadPage(true);
            }
            if (response.data.length < global.config.pagePerSize) {
                setHasMore(false);
                // setShouldLoadPage(false);
            }
        })
            .catch(error => console.log(error));
    };



    useEffect(() => {
        window.scrollTo(0, 0);
        setItems([]);
        fetchMoreData();
        return () => {
            setShouldLoadPage(false);
            setItems([]);
            setHasMore(true)
            setPage(1);
        }
    }, [])


    return (
        <>
            {shouldLoadPage
                &&
                <div className="challenge">

                    <InfiniteScroll
                        dataLength={items.length}
                        next={fetchMoreData}
                        hasMore={hasMore}
                        loader={<BallTriangle
                            height="100"
                            width="100"
                            color="grey"
                            ariaLabel="loading"

                        />}
                    >
                        {items &&
                            items?.map((i, index) => (
                                <SingleChallenge
                                    data={i}
                                    key={index}
                                    runs={runs}
                                />

                            )
                            )}
                    </InfiniteScroll>

                </div>

            }
        </>
    )

}


